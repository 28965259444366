import React from "react";
// 
// Feeds filter component
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../../hoc/wrap";

// Style
import "./feedsFilter.css";

const feedsFilier = ( props ) => (
    <Wrap>
        <div className="col-12 feeds-filter">
            <label className="filter-label">feed type: </label>
            <div className="filter-list-wrap">
                <select value={ props.filterValue } onChange={ event => props.onFilterChange(event) }>
                    <option>Inline</option>
                    <option>Nedbank</option>
                    <option>Video</option>
                    <option>Article</option>
                    <option>Status</option>
                </select>
            </div>
        </div>
    </Wrap>
);

export default feedsFilier;