import React from 'react';
// 
// Slides info component: lesson slide editor row 
// 

// 
// Components
// 

// Wrap component
import Wrap from '../../../hoc/wrap';

// Style
import './slidesInfo.css';

const slidesInfo = ( props ) => (
    <div className="slides-info-wrap col-12">
        <div className="page-tool-heading row align-items-center justify-content-between">
            <div className="col-7">
                <h3>{"slides in " + props.lessonName + " lesson"} </h3>
            </div>
            <div className="col-4">
                <p>{ props.slidesCount + " slide" + ( props.slidesCount > 1 ? "s" : "" ) + " found" }</p>
            </div>
        </div>
        <section className="slides-table-heading row justify-content-between">
            <div className="col-8">
                <h6>slides JSON object</h6>
            </div>
            <div className="col-2">
                <a href="#" className={ props.slideEditState === true ? "saveActive" : "" } onClick={ props.slideEditState ? event => props.handleUpdateSlides( event, props.lessonId ) : null }>
                    <i className="fa fa-floppy-o" aria-hidden="true"></i>
                </a>
            </div>
        </section>
        <section className="slides-json-text row">
            <div className="col-12">
                <textarea name={ props.slideId } value={ props.slideAsString } onChange={ props.handleInputOnChange }></textarea>
            </div>
        </section>
    </div>
);

export default slidesInfo;
