import React from "react";
// 
// Table title component : Applications item titles
// 

// Style
import "./tableTitle.css";

const tableTitle = ( props ) => (
    <div className="row table-titles">
        <div className="col-3">
            <h5>Name</h5>
        </div>
        <div className="col-7">
            <h5>Applications and Status</h5>
        </div>
        <div className="col-2">
            <h5>Actions</h5>
        </div>
    </div>
);

export default tableTitle;