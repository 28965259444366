// 
// Background page reducer : Dispatched actions for contents page content change
// 

// Action types
import * as actionTypes from "../../actions/actionTypes";
// Utitlies
import * as utilities from "../utilities";
const initialState = {
    backgroundPageHeading: "Background",
    backgroundPageInfo: `
Gradesmatch lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mollis, dolor sit amet dignissim venenatis, mauris felis bibend Um lectus, sed dignissim velit nulla ac ipsum
                    
Gradesmatch lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mollis, dolor sit amet 
                    
Gradesmatch lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
    backgroundPageImage: {
        file: "",
        previewURL: "",
        fileName: ""
    }
}

const backgroundPageReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.UPDATE_TEXT_BACKGROUND_PAGE:
            return utilities.handleTextOnChange(state, action);

        case actionTypes.UPLOAD_IMAGE_BACKGROUND_PAGE:
            return utilities.handleImageUpload(state, action, "backgroundPageImage");

        default:
            return {
                ...state
            }
    }
}

export default backgroundPageReducer;