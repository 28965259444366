// 
// Background page reducer : Dispatched actions for contents page content change
// 

// Action types
import * as actionTypes from "../../actions/actionTypes";
// Utitlies
import * as utilities from "../utilities";


const initialState = {
    schoolsPageHeading: "7 Schools represented at the Expo",
    subjectsTitle: "Gateway subject entries to scarce skills in higher learning institutions",
    mathematics: "47% Pure Mathematics",
    physics: "49% Physics",
    lifeScience: "43% Life Science",
    accounting: "57% Accounting",
    schoolsPageImage: {
        file: "",
        previewURL: "",
        fileName: ""
    },
    grade10Total: "Grade 10",
    grade11Total: "Grade 11",
    grade12Total: "Grade 12",
    chartData: []
}

const schoolsPageReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.UPDATE_TEXT_SCHOOLS_PAGE:
            return utilities.handleTextOnChange(state, action);

        case actionTypes.UPLOAD_IMAGE_SCHOOLS_PAGE:
            return utilities.handleImageUpload(state, action, "schoolsPageImage");

        case actionTypes.SET_SCHOOLS_EXPO_DATA:
            let chartData = [];
            let gradesTotal = 0;
            let grade10Total = 0;
            let grade11Total = 0;
            let grade12Total = 0;

            chartData.push(["Grades attendence", "Number of Leaners per grade"]);

            action.payload.expoInfo.totalPerGrade.forEach( totalPerGrade => {
                switch( totalPerGrade.grade ) {
                    case "10":
                        gradesTotal += totalPerGrade.total;
                        chartData.push(["grade 10", parseInt(totalPerGrade.total)]);
                        break;

                    case "11":
                        gradesTotal += totalPerGrade.total;
                        chartData.push(["grade 11", parseInt(totalPerGrade.total)]);
                        break;

                    case "12":
                        gradesTotal += totalPerGrade.total;
                        chartData.push(["grade 12", parseInt(totalPerGrade.total)]);
                        break;
                }
            });

            action.payload.expoInfo.totalPerGrade.forEach( totalPerGrade => {
                switch( totalPerGrade.grade ) {
                    case "10":
                        grade10Total = (totalPerGrade.total / gradesTotal);
                        break;

                    case "11":
                        grade11Total = (totalPerGrade.total / gradesTotal);
                        break;

                    case "12":
                        grade12Total = (totalPerGrade.total / gradesTotal);
                        break;
                }
            });    

            return {
                ...state,
                chartData: chartData,
            }

        default:
            return {
                ...state
            }
    }
}

export default schoolsPageReducer;