import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import UserServices from "./js/library/services/userservice";
// 
// App component
// 

// 
// Components
// 

// Layout compoent
import Layout from './components/layout/layout';

// 
// Style
// 

// App style
import './App.css';

class App extends Component {

  state = {
    email: "",
    password: "",
    loginLoading: false,
    loginError: "",
    loginErrorMessage: "",
    showToolsNav: false,
    showProfileNav: false,
    userToken: ""
  }

  componentDidUpdate( prevProps ) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        showToolsNav: false,
        showProfileNav: false
      });
    }
  }

  handleLogin = () => {
    this.setState({
      loginLoading: true,
      loginError: false,
      loginErrorMessage: ""
    });

    UserServices.login(this.state.email, this.state.password).then( userInfo => {
      if (userInfo.userToken) {
        this.setState({
          loginLoading: false,
          userToken: userInfo.userToken
        });
        this.props.history.push('/admintools');
  
      } else if (userInfo.loginError) {
        this.setState({
          loginError: true,
          loginLoading: false,
          loginErrorMessage: userInfo.loginErrorMessage
        });
      }
    });
  }

  handleLogOut = ( event ) => {
    event.preventDefault();

    UserServices.logout()
    this.props.history.push('/');
  }

  handleInputChange = ( event ) => {
    let inputName = event.target.name;
    let inputValue = event.target.value;

    if ( inputName == "email" ) {
      this.setState({
        email: inputValue
      });

    } else {
      this.setState({
        password: inputValue
    });
    }
  }

  handleShowToolsNav = ( event ) => {
    event.preventDefault();

    let showToolsNav = this.state.showToolsNav ? false : true;

    this.setState({
      showToolsNav: showToolsNav
    });
    
  }

  handleShowProfileNav = ( event ) => {
    event.preventDefault();

    let showProfileNav = this.state.showProfileNav ? false : true;

    this.setState({
      showProfileNav: showProfileNav
    })
  }

  render() {
    return (
      <div className="App">
        <Layout 
          handleLogin={ this.handleLogin } 
          handleInputChange={ this.handleInputChange } 
          loginLoading={ this.state.loginLoading } 
          loginErrorMessage={ this.state.loginErrorMessage } 
          showToolsNav={ this.state.showToolsNav }
          showProfileNav={ this.state.showProfileNav }
          toggleToolsNav={ this.handleShowToolsNav }
          toggleProfileNav={ this.handleShowProfileNav }
          handleLogout={ this.handleLogOut }
        />
      </div>
    );
  }
}

export default withRouter(App);
