import React from 'react';
// 
// Main content wrap component: main page content layout wrapper
// 

// Style
import './mainContentWrap.css';

const mainContentWrap = ( props ) => (
    <section id="main-content">
        <div className="container">
            { props.children }
        </div>
    </section>
);

export default mainContentWrap;