import React from "react";
// 
// Total count card component: card containing stats info totals for matched, viewed, liked and unliked
// 

// Style
import "./totalCountCard.css";

const totalCountCard = ( props ) => (
    <div className="total-count-card">
        <p className="total-count">{ props.totalCount }</p>
        <p className="total-title">{ props.totalTitle }</p>
    </div>
);

export default totalCountCard; 