import React from 'react';
// 
// Backdrop component
// 

// Style
import './backdrop.css';

const backdrop = ( props ) => <div className="backdrop" style={{ display: props.backdropShow ? "block" : "none" }} onClick={ props.backdropHide }></div>

export default backdrop;