import React from "react";
// 
// Report footer component : footer content for report pages
// 

// 
// Assets
// 

// G logo symbol
import logoSymbol from "../../../../../assets/images/g.png";

// Style
import "./reportFooter.css";

const reportFooter = () => (
    <div className="row justify-content-end report-footer">
        <div className="col-1">
            <img src={logoSymbol} alt="gradesmatch g logo symbol" width="40em" />
        </div>
    </div>
)

export default reportFooter;