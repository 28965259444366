import HTTPRequest from "../HTTPConfig/axiosConfig";
import helpers from "../helpers/helpers";
// 
// manage applications status services
// 

const applicationsStatusService = {
    fetchStatusList,
    addStatus,
    editStatus
}

function fetchStatusList() {
    let statusList = [];

    return HTTPRequest({
        method: "POST",
        url: "applications/getapplicationstatus",
        data: {},
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            statusList = response.data.data;

        } else {
            statusList.push("error");
            statusList.push(response.data.reason);
        }
        return statusList;

    }).catch( error => {
        statusList.push("error");
        statusList.push("Error fetching documents");

        return statusList;
    });
}

function addStatus(status) {
    let statusId = "";

    return HTTPRequest({
        method: "POST",
        url: "applications/createapplicationStatus",
        data: {
            name: status
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            statusId = response.data.data;

        } else {
            statusId = "error";
        }
        return statusId;

    }).catch( error => {
        return statusId = "error";
    });
}

function editStatus(statusId, status) {
    return HTTPRequest({
        method: "POST",
        url: "applications/editapplicationstatus",
        data: {
            id: statusId,
            name: status,
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            return true;

        } else {
            return false;
        }

    }).catch( error => {
        return false
    });
}

export default applicationsStatusService;
