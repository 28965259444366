import React from "react";
// 
// Application info component
// 

// 
// Components
// 

// Button component
import Button from "../../../../../UI/button/button";

// Style
import "./applicationInfo.css";

const applicationInfo = ( props ) => {
    return(
        <div className="row justify-content-start application-info">
            <div className="col-12">
                <h5 className="applications-title">Application login details for { props.viewedStudentApplications.name } </h5>
            </div>
            <div className="col-12 login-details">
                <div className="row justify-content-between">
                    <div className="col-10">
                        <div className="row">
                            <div className="col-4">
                                <label className="form-label">Username</label>
                                <div className="form-text-wrap">
                                    <input className="form-text-wrap" value={ props.viewedStudentApplications.username } onChange={ props.applicantLoginInfoChange } name="username" placeholder="Username" /> 
                                </div>
                            </div>
                            <div className="col-4">
                                <label className="form-label">Password</label>
                                <div className="form-text-wrap">
                                    <input value={ props.viewedStudentApplications.password } onChange={ props.applicantLoginInfoChange } name="password" placeholder="Password" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        <Button buttonClassName="save-login-info" clickHandler={ props.saveApplicantLoginInfo }>
                            <i className="fa fa-floppy-o" aria-hidden="true"></i>
                            save
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default applicationInfo;