import React from 'react';

// Wrap component
import Wrap from "../../../../../hoc/wrap";
// Button component
import Button from "../../../../../UI/button/button";
// table titles component
import TableTitles from "../applicationListTitles/applicationsListTitle";

// Style
import "./newBursaryItem.css";

const newBursaryItem = ( props ) => {
    let content = "";
    let bursaryList = [];
    let bursaryItems = [];

    if ( props.bursaryItems.length > 0 ) {
        bursaryItems = props.bursaryItems.map( bursary => {
            return ;
        });
    }

    if ( props.newBursaryList && props.newBursaryList.length > 0 ) {
        bursaryList = props.newBursaryList.map( bursary => {
            return <li onClick={ () => props.setSelectedBursary(bursary.ID, bursary.Name) }>{ bursary.Name }</li>
        });
    }

    content = (
        <div className="col-4">
            <div className="auto-complete-search">
                <div className="form-text-wrap">
                    <input type="text" value={ props.searchBursaryText } onChange={ props.searchBursary } placeholder="Bursary"></input>
                </div>
                {
                    props.newBursaryList.length > 0 ? (
                        <div className="search-list">
                            <ul>
                                { bursaryList }
                            </ul>   
                        </div>
                    ) : ""
                }
            </div>    
        </div>
    );

    return(
        <Wrap>
            <div className="row align-items-center application-item">
                { content }
            </div>
            <Button buttonClassName="add-new-institution" clickHandler={ () => props.addNewBursaryItem("", "") }>
                <i className="fa fa-plus"></i>
                add new bursary
            </Button>
        </Wrap>
    );
}

export default newBursaryItem;
