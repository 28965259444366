import React from "react";
// 
// APS Dashboard component; application programmer dashboard
// 

// 
// Components
// 

// APItem component
import APItem from "./APItem/apItem";

// Style
import "./apDashboard.css";

const apDashboard = ( props ) => {
    let APItems = Object.keys(props.dashboard).map( (APItemKey, index) => {
        return <APItem 
                    key={ index }
                    APName={ props.dashboard[APItemKey][0].Name +  props.dashboard[APItemKey][0].Surname }
                    allocationItemList={ props.dashboard[APItemKey] }
                />;
    });
    
    return (
        <section className="row dashboard ap-dashboard">
            <div className="col-12">
                <div className="row dashboard-heading">
                    <div className="col-6">
                        <h5>application programmer statistics</h5>
                    </div>
                </div>
                <div className="row group">
                    <div className="col-12 table-headings">
                        <div className="row">
                            <div className="col-4">
                                 <h6>application programmer</h6>
                            </div>
                            <div className="col-8">
                                 <h6>group allocations</h6>
                            </div>
                        </div>
                    </div>
                     <div className="col-12 ap-list-wrap flex-nowrap">
                        { APItems }
                     </div>
                </div>
            </div>
        </section>
    )
}

export default apDashboard;