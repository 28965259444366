import React from "react";
// 
// Request documents modal component
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../../../../hoc/wrap";
// Backdrop component
import Backdrop from "../../../../../UI/backdrop/backdrop";
// Button component
import Button from "../../../../../UI/button/button";

// Style 
import "./requestDocsModal.css";

const requestDocsModal = ( props ) => {

    // let requestDocs = props.requestDocs.map( (doc, index) => {
    //     return <h5 key={ index }> { doc.Name } </h5>
    // });

    // let requestedDocIds = props.requestDocs.map( doc => {
    //     return doc.ID;
    // });

    return(
        <Wrap>
            <Backdrop backdropShow={ props.showDocsModal } backdropHide={ props.hideDocsModal } />
            <section className="container request-docs-modal" style={{ display: props.showDocsModal ? "block" : "none" }}>
                <div className="row">
                    <div className="col-12">
                        <Button buttonClassName="close-modal" clickHandler={ props.hideDocsModal }>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </Button>
                        <h3 className="section-title">send request for documents</h3>   
                    </div>
                    <div className="col-12">
                        <p className="section-intro">send request for: { "document list" }</p>   
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <textarea value={ props.requestedDocsText } onChange={ event => props.requestedDocsTextChange( event ) }></textarea>
                    </div>
                    <div className="col-12">
                        <Button buttonClassName="send-request" clickHandler={ props.sendDocRequest }>
                            <i className="fa fa-envelope" aria-hidden="true"></i>
                            send request
                        </Button>
                    </div>
                </div>
            </section>
        </Wrap>
    ) 
};

export default requestDocsModal;