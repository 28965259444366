import React from "react";
import configConstants from "../../../../js/library/configConstants/configConstants";
// 
// Post item component: feet post item
// 

// 
// Components
// 

// inline post
import InlinePost from "./postItemTypes/inlinePost/inlinePost";

// Style
import "./postItem.css";

const postItem = ( props ) => {

    let postItemContent = "";

    postItemContent = <InlinePost post={ props.post } />

    return(
        <div className="col-12 post-item-wrap">
            <div className="row no-gutters">
                <div className="col-2">
                    <img className="profile-picture" src={ configConstants.SERVER_NAME + "/images/profile/" + props.profilePicture } alt="profile picture" />
                </div>
                <div className="col-7">
                    <h5 className="user-name">{ props.userName }</h5>
                    { postItemContent }
                    <a href="#" onClick={ event => props.showHidePost(event, props.post.ID) } className="post-icon hide"><i className="fa fa-eye-slash" aria-hidden="true"></i></a>
                </div>
                <div className="col-3">
                    <span className="post-icon"><i className="fa fa-comments" aria-hidden="true"></i> { props.postComments }</span>
                    <span className="post-icon"><i className="fa fa-heart" aria-hidden="true"></i> { props.postLikes }</span>
                </div>
            </div>
        </div>
    );
}

export default postItem;