import React, { Component } from 'react';
import adminToolsService from '../../js/library/services/admintoolsservice';
// 
// Admin tolls navigation component: UI for admin tools navigation
// 

// 
// Components
// 

// Admin tool component
import AdminTool from "../../components/adminTool/adminTool";

// Style
import './adminToolsNav.css';

class AdminToolsNav extends Component {
    state = {
        adminTools: [],
        adminToolsInfo: {}
    }

    componentDidMount() {
        adminToolsService.getTools().then( tools => {
            this.setState({
                adminTools: tools
            });
        });
    }

    render() {
        let adminTools = [];
        let toolPage = "";
        let adminToolsInfo = adminToolsService.ADMIN_TOOLS_INFO;

        if ( this.state.adminTools.length > 0 && this.state.adminTools[0].indexOf("error") === -1 ) {
            adminTools = this.state.adminTools.map( ( toolName, index) => {
                if ( toolName === "applications" ) {
                    toolPage = toolName + "/manage";

                } else {
                    toolPage = toolName;
                }

                if ( adminToolsInfo[toolName] ) {
                    return (
                        <div className="admin-tool-wrap" key={ index }>
                            <AdminTool adminToolIcon={ adminToolsInfo[toolName].icon } adminToolTitle={ adminToolsInfo[toolName].title }  adminToolpage={ toolPage } />
                        </div>
                    );
                }
            });
        }

        return(
            <div className="admin-tools-container">
                <a className={ "admin-tools-toggle" + ( this.props.showToolsNav ? " active" : "" ) } href="#" onClick={ event => this.props.toggleToolsNav(event) }>
                    <i className="fa fa-th" aria-hidden="true"></i>
                </a>
                <div className="admin-tools" style={{ display : this.props.showToolsNav ? "block": "none" } }>
                    <div className="admin-tool-title">
                        <h3>admin tools</h3>
                        <a href="#" onClick={ event => this.props.toggleToolsNav(event) }>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </a>
                    </div>
                    { adminTools }
                </div>
            </div>
        );
    }
}

export default AdminToolsNav;