import React from "react";
// 
// Message box component
// 

// 
// Components
// 

// Wrap
import Wrap from "../../../hoc/wrap";
// Backdrop component
import Backdrop from "../../../UI/backdrop/backdrop";
// Button component
import Button from "../../../UI/button/button";
// Spinner component
import Spinner from "../../../UI/spinner/spinner";

// 
// Dependecies
// 

// Date time picker component
import DateTimePicker from "react-datetime-picker";

// Style
import "./messageBox.css";

const messageBox = ( props ) => {

    let buttonContent = "";

    if ( props.sendingSMS ) {
        buttonContent = <Spinner />;

    } else {
        buttonContent = (
            <Wrap>
                <i className="fa fa-envelope" aria-hidden="true"></i>
                send message
            </Wrap>
        );
    }

    return(
        <Wrap>
            <Backdrop backdropShow={ props.backdropShow } backdropHide={ props.backdropHide } />
            <div className="message-box container" style={{"display": props.backdropShow ? "block" : "none" }}>
                <div className="row">
                    <div className="col-12">
                        <Button buttonClassName="modal-close" clickHandler={ props.backdropHide }>
                            <i className="fa fa-times"></i>
                        </Button>
                        <h3>{ "send message to " + props.usersCount + " user" + ( props.usersCount > 1 ? "s": "" ) }</h3>
                        <div className="time-filter">
                            <label className="filter-label">send sms on : </label>
                            <div className="time-filter-wrap">
                                <DateTimePicker value={ props.smsDate != null ? props.smsDate : new Date()} onChange={ value => props.smsDateSelect(value) } clearIcon={ null } showLeadingZeros={ true } disableClock={ true } />
                            </div>
                        </div>
                        <p className="charater-count">
                            <em>
                                Characters:
                            </em>
                            <span>{ props.smsMessage.length } / 160</span>
                        </p>
                        <textarea value={ props.smsMessage } onChange={ event => props.messageBoxInputChange(event) }></textarea>
                        <Button buttonClassName="send-message" clickHandler={ props.sendSMS } >
                            { buttonContent }
                        </Button>
                    </div>
                </div>
            </div>
        </Wrap>
    );
}

export default messageBox;