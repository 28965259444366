import HTTPRequest from "../HTTPConfig/axiosConfig";
import Helpers from "../helpers/helpers";
// 
// Admin tools services : method that determines access level to admin tools
// 

const adminToolsService = {
    getTools,
    ADMIN_TOOLS_INFO: {
        dashboard: { icon: "pie-chart", title: "dashboard"},
        stats: { icon: "bar-chart", title: "statistics"},
        addstudent: { icon: "user-plus", title: "add student"},
        viewusers: { icon: "list-ul", title: "view users"},
        managemodules: { icon: "files-o", title: "manage modules"},
        feeds: { icon: "rss", title: "manage feeds"},
        applications: { icon: "file-text", title: "applications"},
        report: { icon: "book", title: "expo report" },
        messenger: { icon: "comments-o", title: "messenger" }
    }
}

function getTools() {
    let tools = [];

    return HTTPRequest({
        method: "POST",
        url: "/adminstats/Access",
        data: {},
        headers: Helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            tools = response.data.data;

        } else {
            tools.push("error: " + response.data.response);
        }
        // Remove this after tool has been added to the call
        tools.push("report");
        tools.push("messenger");

        return tools;

    }).catch( error => {
        tools.push("error: fetching tools");
        return tools;
    });
}

export default adminToolsService;