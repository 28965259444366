// 
// Contents page reducer : Methods for handling text updates in contents page
// 

// Action types
import * as actionTypes from "../../actions/actionTypes";
// Utilities
import * as utilities from "../utilities";

const initialState = {
    contentsPageHeading: "Report Content",
    contentsTitle: "Section A",
    contents1: "Report Background",
    contents2: "High level attendance statistics",
    contents3: "Attendees breakdown & region relationship",
    contents4: "Anomalies",
    contents5: "Data usefulness",
}

const contentsPageReducer = ( state=initialState, action ) => {
    switch( action.type ) {
        case actionTypes.UPDATE_TEXT_CONTENTS_PAGE: 
            return utilities.handleTextOnChange(state, action);
        
        default:
            return {
                ...state
            }
    }
}

export default contentsPageReducer;