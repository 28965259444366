import React from "react";
// 
// Applications item component : component showing applicant and list of their applications
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../../../hoc/wrap";
// Button component
import Button from "../../../../UI/button/button";
// Applicant info component
import ApplicantInfo from "./applicantInfo/applicantInfo";

// Style
import "./applicationsItem.css";

const applicationsItem = ( props ) => {

    let applicationsList = [];
    let adminList = [];
    let adminPList = [];
    let APListCloseBtn = (
                        <li className="close" key="-1" onClick={ props.hideAPList }>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </li>
                        );

    let ACListCloseBtn = (
                        <li className="close" key="-1" onClick={ props.hideACList }>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </li>
                        );
    
    if ( props.adminList.length > 0 ) {
        adminList = props.adminList.map( (admin, index) => {
            return <li key={ index } onClick={ () => props.assignApplicationToAdmin( admin.ID, props.studentId ) }>{ admin.Name + ' ' + admin.Surname }</li>
        });

        adminList.splice(0, 0, ACListCloseBtn);
    }

    if ( props.adminList.length > 0 ) {
        adminPList = props.adminList.map( (admin, index) => {
            return <li key={ index } onClick={ () => props.assignApplicationToAP( admin.ID, props.studentId, props.APAssignId ) }>{ admin.Name + ' ' + admin.Surname }</li>
        });

        adminPList.splice(0, 0, APListCloseBtn)
    }

    if ( Object.keys(props.application).length > 0 ) {
        applicationsList = props.application.Institutions.map( (application, index) => {
            return (
                <div className="col-4" key={ index }>
                    <div className="row no-gutters">
                         <div className="col-11">
                            <a href="#" onClick={ event => props.showStudentApplications(event, props.application.Name, props.studentId, application.InstitutionID) } className="institution">
                                { application.Abbreviation + "(" + application.Status + ")"}
                            </a>
                         </div>
                         <div className="col-1">
                            <Button buttonClassName={ "assign" + (application.Ap ? " active" : "") } clickHandler={ () => props.showAdminAPList(props.studentId, application.InstitutionID)}>
                                <i className="fa fa-heart" aria-hidden="true" title={ application.Ap ? application.Ap.Name + " " + application.Ap.Surname : "" }></i>
                            </Button>
                            {
                                adminPList.length > 0 ? 
                                    <ul className="adminList" style={{ "display": props.institutionToAssign === props.studentId + "." + application.InstitutionID ? "block" : "none" }}>
                                        { adminPList }
                                    </ul>
                                    : ""
                            }
                         </div>
                    </div>
                </div>
            );
        });
    }

    return(
        <Wrap>
            <ApplicantInfo 
                showApplicantInfoBackdrop={ props.showApplicantInfoBackdrop }
                hideApplicantInfoBackdrop={ props.hideApplicantInfo }
                applicantName={ props.application.Name + " " + props.application.Surname }
                applicationInfo={ props.applicationInfo }
                subjectList={ props.subjectList }
                applicantInfoOnChange={ props.applicantInfoOnChange }
                userInfo={ props.userInfo }
                subjectInputChange={ props.subjectInputChange }
                subjectSearchList={ props.subjectSearchList }
                focusedInput={ props.focusedInput }
                saveMarks={ props.saveMarks }
                savingMarks={ props.savingMarks }
                loadingApplicantInfo={ props.loadingApplicantInfo }
                applicantContactInfo={ props.applicantContactInfo }
                saveStudentInfo={ props.saveStudentInfo }
                saveParentInfo={ props.saveParentInfo }
                saveUserMarks={ props.saveUserMarks }
                schoolList={ props.schoolList }
            />
            <div className="row align-items-center application-item">
                <div className="col-3">
                    <a href="#" className="view-info" style={{ display: ( props.active ? "inline": "none" ), marginRight: "0.5em" }} onClick={ event => props.showApplicationsList(event, props.viewedInstitution.name, props.viewedInstitution.id, props.viewedStatus.name, props.viewedStatus.id) }>
                        <i className={ 'fa fa-caret-' + ( props.active ? "down" : "right" ) } aria-hidden="true"></i>
                    </a>
                    <a href="#" className="applicant-info" onClick={ (event) => props.showApplicantInfo(event, props.studentId)}>
                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                    </a>
                    <p className="applicant-name">
                        { props.application.Name + " " + props.application.Surname }
                        <br/>
                        <span>{ props.application.contact ? "( " + props.application.contact.UserName + " )" : "" }</span>
                    </p>
                </div>
                <div className="col-7">
                    <div className="row">
                        { applicationsList }
                    </div>
                </div>
                <div className="col-2">
                    <a href="#" className="view-info" onClick={ event => props.showApplicationDocs(event, props.studentId) } >
                        <i className="fa fa-file-text" aria-hidden="true"></i>
                    </a>
                    <a href="#" className="view-info" onClick={ event => props.showBursaries(event, props.studentId, props.application.Name + " " + props.application.Surname) } >
                        <i className="fa fa-money" aria-hidden="true"></i>
                    </a>
                    {
                        ( props.userRole === 13 || props.userRole === 3 ) ?  
                            <Button buttonClassName={ "assign" + (props.assigned ? " active" : "") } clickHandler={ () => props.handleShowAdminList(props.studentId) }>
                                <i className="fa fa-heart" aria-hidden="true"></i> 
                            </Button>
                        :
                            <Button buttonClassName={ "assign" + (props.assigned ? " active" : "") } clickHandler={ () => props.assignApplication(props.studentId) }>     
                                <i className="fa fa-heart" aria-hidden="true"></i>
                            </Button>
                    }
                </div>
                {
                    adminList.length > 0 ?
                        <ul className="adminList" style={{ "display": props.showAdminList === props.studentId ? "block" : "none" }}>
                            { adminList }
                        </ul>
                        : ""
                }
            </div>
        </Wrap>
    );
};

export default applicationsItem;