import React from "react";
// 
// New application qualification component
// 

// 
// Components
// 

// Wrap coomponent
import Wrap from "../../../../../../../hoc/wrap";
// Button component
import Button from "../../../../../../../UI/button/button";

// Style
import "./newApplicationQualification.css";

const newApplicationQualification = ( props ) => {
    let content = "";
    let qualificationList = [];

    if ( props.qualificationName !== "" ) {
        content = (
            <Wrap>
                <div className="col-6">
                    { props.qualificationName }
                </div>
                <div className="col-4">
                    { props.qualificationRate }
                </div>
            </Wrap>
        ); 

    } else {
        if ( props.newQualificationList.length > 0 ) {
            qualificationList = props.newQualificationList.map( qualification => {
                return <li key={ qualification.ID } onClick={ () => props.setSelectedQaulification(props.position, qualification.ID, qualification.Name, qualification.Rate) }>{ qualification.Name }</li>
            });
        }

        content = (
            <div className="col-4">
                <div className="auto-complete-search">
                    <div className="form-text-wrap">
                        <input type="text" name="qualification" value={ props.searchQualificationText } onChange={ (event) => props.searchQualification(event) } placeholder="Qualification"></input>
                    </div>
                    {
                        props.newQualificationList.length > 0 ? (
                            <div className="search-list">
                                <ul>
                                    { qualificationList }
                                </ul>   
                            </div>
                        ) : ""
                    }
                </div>    
            </div>
        );
    }

    return(
        <div className="row align-items-center justify-content-between application-item">
            { content }
            <div className="">
                <Button buttonClassName="delete-institution" clickHandler={ () => props.deleteNewQualification(props.institutionName, props.qualificationId)} >
                    <i className="fa fa-trash-o" aria-hidden="true"></i>
                </Button>
            </div>
        </div>
    )
}

export default newApplicationQualification;