import React, { Component } from "react";
// 
// Image area component : report image upload control
// 

// 
// Components
// 

// Button component
import Button from "../../../../UI/button/button";

// 
// Assets
// 

// Partner logo preview
import ImagePreview from "../../../../../assets/images/partner_preview.png";

// Style
import "./imageArea.css";

class ImageArea extends Component {
    render() {
        let imagePreview = ImagePreview;

        if ( this.props.imageAreaPreview !== "" ) {
            imagePreview = this.props.imageAreaPreview;
        }

        return (
            <div className={"image-area-wrap col-" + this.props.imageAreaCol} >
                <div className="image-wrap" >
                    <img 
                        src={ imagePreview }
                        width={this.props.imageAreaWidth}
                        height={this.props.imageAreaHeight}
                        alt={this.props.imageAreaAlt}
                    />
                </div>
                <div className="area-controls">
                    <span>{ this.props.imageName }</span>
                    <div>
                        <input name="image-upload-control" type="file" ref={ ref => this.imgaeUpload = ref } onChange={ event => this.props.imageUpload(event) } style={{ display: "none" }} />
                        <a href="#" className="round-button" onClick={ event => this.imgaeUpload.click() }>
                            <i className="fa fa-picture-o" aria-hidden="true"></i>
                        </a>
                        <a href="#" className="round-button">
                            <i className="fa fa-floppy-o" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default ImageArea;