import HTTPRequest from "../HTTPConfig/axiosConfig";
import helpers from "../helpers/helpers";
// 
// manage applications documents services
// 

const applicationsDocService = {
    fetchDocuments,
    saveDocument,
    editDocument
}

function fetchDocuments() {
    let applicationsDocs = [];

    return HTTPRequest({
        method: "POST",
        url: "applications/docnames",
        data: {},
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            applicationsDocs = response.data.data;

        } else {
            applicationsDocs.push("error");
            applicationsDocs.push(response.data.reason);
        }
        return applicationsDocs;

    }).catch( error => {
        applicationsDocs.push("error");
        applicationsDocs.push("Error fetching documents");

        return applicationsDocs;
    });
}

function saveDocument( name, required, description, color ) {
    let docId = "";

    return HTTPRequest({
        method: "POST",
        url: "applications/adddocname",
        data: {
            name: name,
            required: required,
            description: description,
            color: color
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            docId = response.data.data;

        } else {
            docId = "error";
        }
        return docId;

    }).catch( error => {
        return docId = "error";
    });
}

function editDocument( name, required, id, description, color ) {
    return HTTPRequest({
        method: "POST",
        url: "applications/editdocname",
        data: {
            id: id,
            name: name,
            required: required,
            description: description,
            color: color
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            return true;

        } else {
            return false;
        }

    }).catch( error => {
        return false
    });
}

export default applicationsDocService;