import React from 'react';
// 
// Header component : page header componet
// 

// 
// Components
// 

// Wrap component
import Wrap from '../hoc/wrap';
// User profile link component
import UserProfileLink from '../UI/userProfileLink/userProfileLink';
// Admin tools nav component
import AdminToolsNav from '../../containers/adminToolsNav/adminToolsNav';
// Notifications component
import Notifications from '../UI/notifications/notifications';

// Style
import './header.css';

const header = ( props ) => {
    
    let headerLayout = "";
    
    switch( props.whichHeader ) {
        case "login":
            headerLayout = (
                <div className="col-8 offset-2">
                    <h1>sign in</h1>
                </div>
            );
            break;

            case "admin-tools":
                headerLayout = (
                    <Wrap>
                        <div className="col-4 offset-2">
                            <h1>admin tools</h1>
                        </div>
                        <div className="col-1">
                            <UserProfileLink 
                                showProfileNav={ props.showProfileNav } 
                                toggleProfileNav={ props.toggleProfileNav } 
                                handleLogout={ props.handleLogout } 
                                isInsidePage={ false }
                            />
                        </div>
                    </Wrap>
                );
                break;

            case "add-user":
                headerLayout = (
                    <Wrap>
                        <div className="col-4">
                            <div className="row align-items-center ">
                                <div className="col-4">
                                    <AdminToolsNav showToolsNav={ props.showToolsNav } toggleToolsNav={ props.toggleToolsNav } />
                                </div>
                                <div className="col-8">
                                    <h1>add student</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-2" style={{ textAlign: "right" }}>
                            <Notifications />
                            <UserProfileLink 
                                showProfileNav={ props.showProfileNav } 
                                toggleProfileNav={ props.toggleProfileNav } 
                                handleLogout={ props.handleLogout } 
                                isInsidePage={ true }
                            />
                        </div>
                    </Wrap>
                );
                break;

            case "modules":
                headerLayout = (
                    <Wrap>
                        <div className="col-4">
                            <div className="row align-items-center ">
                                <div className="col-4">
                                    <AdminToolsNav showToolsNav={ props.showToolsNav } toggleToolsNav={ props.toggleToolsNav } />
                                </div>
                                <div className="col-8">
                                    <h1>manage modules</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-2" style={{ textAlign: "right" }}>
                            <Notifications />
                            <UserProfileLink 
                                showProfileNav={ props.showProfileNav } 
                                toggleProfileNav={ props.toggleProfileNav } 
                                handleLogout={ props.handleLogout } 
                                isInsidePage={ true }
                            />
                        </div>
                    </Wrap>
                );
                break;
            
            case "users":
                headerLayout = (
                    <Wrap>
                        <div className="col-4">
                            <div className="row align-items-center ">
                                <div className="col-4">
                                    <AdminToolsNav showToolsNav={ props.showToolsNav } toggleToolsNav={ props.toggleToolsNav } />
                                </div>
                                <div className="col-8">
                                    <h1>view users</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-2" style={{ textAlign: "right" }}>
                            <Notifications />
                            <UserProfileLink 
                                showProfileNav={ props.showProfileNav } 
                                toggleProfileNav={ props.toggleProfileNav } 
                                handleLogout={ props.handleLogout } 
                                isInsidePage={ true }
                            />
                        </div>
                    </Wrap>
                );
                break;

            case "stats": 
                headerLayout = (
                    <Wrap>
                        <div className="col-4">
                            <div className="row align-items-center ">
                                <div className="col-4">
                                    <AdminToolsNav showToolsNav={ props.showToolsNav } toggleToolsNav={ props.toggleToolsNav } />
                                </div>
                                <div className="col-8">
                                    <h1>Statistics</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-2" style={{ textAlign: "right" }}>
                            <Notifications />
                            <UserProfileLink 
                                showProfileNav={ props.showProfileNav } 
                                toggleProfileNav={ props.toggleProfileNav } 
                                handleLogout={ props.handleLogout } 
                                isInsidePage={ true }
                            />
                        </div>
                    </Wrap>
                );
                break;

            case "feeds":
                headerLayout = (
                    <Wrap>
                        <div className="col-4">
                            <div className="row align-items-center ">
                                <div className="col-4">
                                    <AdminToolsNav showToolsNav={ props.showToolsNav } toggleToolsNav={ props.toggleToolsNav } />
                                </div>
                                <div className="col-8">
                                    <h1>Manage feeds</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-2" style={{ textAlign: "right" }}>
                            <Notifications />
                            <UserProfileLink 
                                showProfileNav={ props.showProfileNav } 
                                toggleProfileNav={ props.toggleProfileNav } 
                                handleLogout={ props.handleLogout } 
                                isInsidePage={ true }
                            />
                        </div>
                    </Wrap>
                );
                break;

            case "applications":
                headerLayout = (
                    <Wrap>
                        <div className="col-4">
                            <div className="row align-items-center ">
                                <div className="col-4">
                                    <AdminToolsNav showToolsNav={ props.showToolsNav } toggleToolsNav={ props.toggleToolsNav } />
                                </div>
                                <div className="col-8">
                                    <h1>applications</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-2" style={{ textAlign: "right" }}>
                            <Notifications />
                            <UserProfileLink 
                                showProfileNav={ props.showProfileNav } 
                                toggleProfileNav={ props.toggleProfileNav } 
                                handleLogout={ props.handleLogout } 
                                isInsidePage={ true }
                            />
                        </div>
                    </Wrap>
                );
                break;

            case "expo-report":
                headerLayout = (
                    <Wrap>
                        <div className="col-4">
                            <div className="row align-items-center ">
                                <div className="col-4">
                                    <AdminToolsNav showToolsNav={ props.showToolsNav } toggleToolsNav={ props.toggleToolsNav } />
                                </div>
                                <div className="col-8">
                                    <h1>expo report</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-2" style={{ textAlign: "right" }}>
                            <Notifications />
                            <UserProfileLink 
                                showProfileNav={ props.showProfileNav } 
                                toggleProfileNav={ props.toggleProfileNav } 
                                handleLogout={ props.handleLogout } 
                                isInsidePage={ true }
                            />
                        </div>
                    </Wrap>
                );
                break;

            case "messenger":
                headerLayout = (
                    <Wrap>
                        <div className="col-4">
                            <div className="row align-items-center ">
                                <div className="col-4">
                                    <AdminToolsNav showToolsNav={ props.showToolsNav } toggleToolsNav={ props.toggleToolsNav } />
                                </div>
                                <div className="col-8">
                                    <h1>messenger</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-2" style={{ textAlign: "right" }}>
                            <Notifications />
                            <UserProfileLink 
                                showProfileNav={ props.showProfileNav } 
                                toggleProfileNav={ props.toggleProfileNav } 
                                handleLogout={ props.handleLogout } 
                                isInsidePage={ true }
                            />
                        </div>
                    </Wrap>
                );
                break;

        default:
            headerLayout = null;
            break;
    }

    const alignItemsCenter = ( props.whichHeader === "login" || props.whichHeader === "admin-tools" ) ? "align-items-end ": "align-items-center ";
    const alighItemsEnd    = props.whichHeader !== "login"? " justify-content-between" : "";
    const containerType    = ( props.whichHeader !== "login" && props.whichHeader !== "admin-tools" ) ? "container-fluid ": "container " ;

    return (
        <header className={ containerType + props.whichHeader }>
            <div className={ "row " + alignItemsCenter + alighItemsEnd }>
                { headerLayout }
            </div>
        </header>
    );
};

export default header;