import React from "react";
// 
// group item component
// 

// Style
import "./groupItem.css";

const groupItem = ( props ) => {
    let groupItems = props.groupItemStatus.map( (status, index) => {
        return (
            <div className="col-6" key={ index }>
                <h3>{ status.value }</h3>
                <span className="line"></span>
                <p>{ status.name }</p>
            </div>
        )
    });

    return (
        <div className="col-4 group-item">
            <div className="row justify-content-between group-item-heading">
                <div className="col-4">
                    <h5>{ props.groupItemName }</h5>
                </div>
                <div className="col-6">
                    <h6>total: { props.groupItemTotal }</h6>
                </div>
            </div>
            <div className="row group-item-content">
                { groupItems }
            </div>
        </div>
    )
}

export default groupItem;