import React from 'react';
// 
// Add user page component: page for adding a newstudent user made up of header, toolbar and form component 
// 

// 
// Components
// 

// Wrap component
import Wrap from '../../hoc/wrap';
// Header
import Header from '../../header/header';
// toolbar
import Toolbar from '../../UI/toolbar/toolbar';
// Main content wrap component
import MainContentWrap from '../../UI/mainContentWrap/mainContentWrap';
// Student info form component
import StudentInfoForm from '../../../containers/studentInfoForm/studentInfoform';
// Batch register component : for batch user registration
import BatchRegister from "../../../containers/studentInfoForm/batchRegister/batchRegister";

// Style
import './addUserPage.css';

const addUserPage = ( props ) => (
    <Wrap>
        <Header 
            whichHeader="add-user" 
            showToolsNav={ props.showToolsNav } 
            toggleToolsNav={ props.toggleToolsNav } 
            showProfileNav={ props.showProfileNav } 
            toggleProfileNav={ props.toggleProfileNav } 
            handleLogout={ props.handleLogout }
        />
        <main className="add-user-page">
            <Toolbar adminTool="students" />
            <MainContentWrap>
                <StudentInfoForm />
            </MainContentWrap>
        </main>
    </Wrap>
);

export default addUserPage;