import React from "react";
// 
// Group container component
// 

// 
// Components
// 

// Button component
import Button from "../../../../../UI/button/button";
// Group item component
import GroupItem from "../groupItem/groupItem";

// Style
import "./groupContainer.css";

const groupContainer = ( props ) => {
    let groupItems = props.groupInstitutions.map( (groupItem, index) => {
        return <GroupItem 
                    key={ index }
                    groupItemName={ groupItem.name }
                    groupItemTotal={ groupItem.total }
                    groupItemStatus={ groupItem.status }
                />
    });

    return (
        <div className="col-12">
            <div className="row group-heading">
                <div className="col-2">
                    <h5>{ props.groupName }</h5>
                </div>
                <div className="col-10 group-title-line">
                    <hr />
                </div>
            </div>
            <div className="row flex-nowrap groups">
                { groupItems }
            </div>
            <div className="row justify-content-end group-scroll-btns">
                <div className="col-2">
                    <Button buttonClassName="left-scroll">
                        <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                    </Button>
                    <Button buttonClassName="right-scroll">
                        <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default groupContainer;