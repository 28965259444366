import React from "react";
import moment from "moment";
// 
// Message item component
// 

// 
// Components
// 

// messageDate
import MessageDate from "./messageDate/messageDate"

// Style
import "./messageItem.css";

// Assest Item component
import proPic from "../../../../assets/images/character-face.png";

const messageItem = ( props ) => {
    let messageDate = moment(props.messageDate).format("DD-MM-YYYY");
    
    return (
        <div className="col-12 message-item">
            <div className={ "user-pro-pic " + props.picPositions }>
                <img src={ proPic } alt="user =rofile picture" />
            </div>
            <div className={ "message " + ( props.picPositions === "right" ? "blue" : "green" ) } dangerouslySetInnerHTML={{ __html: props.messageText }}></div>
            <p className={ "message-status " + props.picPositions}>
                <i className="fa fa-calendar" aria-hidden="true"></i>
                { messageDate }
            </p>
        </div>
    ) 
};

export default messageItem;