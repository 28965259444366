import React from 'react';
// 
// Qualification match info component: pop up of qualification match info
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../../../../hoc/wrap";
// Backdrop component
import Backdrop from "../../../../../UI/backdrop/backdrop";
// loading data component
import LoadingData from "../../../../../UI/loadingData/loadingData";
// Subject iem component
import SubjectItem from "../subjectItem/subjectItem";

// 
// Assests
// 

// POS image
import POSImage from "../../../../../../assets/images/pos.png";
// TOS image
import TOSImage from "../../../../../../assets/images/tos.png";
// NBT image
import NBTImage from "../../../../../../assets/images/nbt.png";
// portfolio image
import PortfolioImage from "../../../../../../assets/images/pos.png";


// Style
import "./qualificationMatchInfo.css";

const qualificationMatchInfo = ( props ) => {
    let contentView = "";

    if ( props.loadingQualificationMatch) {
        contentView = <LoadingData title="fetching qualification match" />;

    } else {
        if ( props.showMatchInfoBackdrop ) {
            let matchRateInfo = "";
            let matchRateInfoColor = "";
            let warnTechnical = false;
            let requiredSubjects = [];
            let requiredSubjectsNotTaken = [];
            let otherSubjects = [];

            for ( let sub of props.QMInfo.MatchedSubjects ) {
                if ( sub.SubjectId == 21 ) {
                    warnTechnical = true;
                    break;
                }
            }

            if ( props.QMInfo.Rate < 2 ) {
                matchRateInfo = "You do not meet some of the requirements for this qualifications. You can improve your marks.";
                matchRateInfoColor = "red";

            } else if ( props.QMInfo.Rate == 2 && !warnTechnical ) {
                matchRateInfo="Congrats, you meet all the requirements";
                matchRateInfoColor = "green";

            } else if ( props.QMInfo.Rate == 2 && warnTechnical ) {
                matchRateInfo="Congrats, you meet all the requirements. #Please be aware that not all institutions accept Technical Maths. The institution may request an additional assessment as part of your application.";
                matchRateInfoColor = "green";
            }

            if ( props.QMInfo.MatchedSubjects.length > 0 ) {
                requiredSubjects = props.QMInfo.MatchedSubjects.map( (subject, index) => {
                    return <SubjectItem 
                                key={ index }
                                subjectName={ subject.Name }
                                subjectMark={ subject.UserMark }
                                subjectRequiredMark={ subject.RequiredMark }
                                APSPoints={ subject.APSPoints }
                                requirementMet={ subject.RequirementMet }
                            />
                });
            }

            if ( props.QMInfo.UnmatchedSubjects.length > 0 ) {
                requiredSubjectsNotTaken = props.QMInfo.UnmatchedSubjects.map( (subject, index) => {
                    return <SubjectItem 
                                key={ index }
                                subjectName={ subject.Name }
                                subjectMark={ subject.UserMark }
                                subjectRequiredMark={ subject.RequiredMark }
                                APSPoints={ subject.APSPoints }
                                requirementMet={ subject.RequirementMet }
                            />
                });
            }

            if ( props.QMInfo.OtherSubjects.length > 0 ) {
                otherSubjects = props.QMInfo.OtherSubjects.map( (subject, index) => {
                    return <SubjectItem 
                                key={ index }
                                subjectName={ subject.Name }
                                subjectMark={ subject.UserMark }
                                subjectRequiredMark={ subject.RequiredMark }
                                APSPoints={ subject.APSPoints }
                                requirementMet
                            />
                });
            }

            contentView = (
                <Wrap>
                    <div className="row align-items-end header">
                        <div className="col-3">
                            <img src={ props.QMInfo.Qualification.LogoURL } alt="institution logo" />
                        </div> 
                        <div className="col-8">
                            <h2>{ props.QMInfo.Qualification.Institution }</h2>
                        </div>
                    </div>
                    <div className="row justify-content-between rate-info">
                        <div className="col-4">
                            <h3>{ props.QMInfo.Qualification.Name }</h3>
                        </div>
                        <div className="col-8">
                            <p className={ matchRateInfoColor }>
                                { matchRateInfo }
                            </p>
                        </div>
                    </div>
                    <div className="row description">
                        <div className="col-12">
                            <h5>description</h5>
                            <p>{ props.QMInfo.Other.Description }</p>
                        </div>
                    </div>
                    <div className="row match-required-info">
                        <div className="col-3">
                            <img src={ POSImage } alt="pos image" />
                            <h5>Period of study</h5>
                            <p>{ props.QMInfo.Other.Duration }</p>
                        </div>
                        <div className="col-3">
                            <img src={ TOSImage } alt="tos image" />
                            <h5>Type of study</h5>
                            <p>{ props.QMInfo.Other.StudyType }</p>
                        </div>
                        <div className="col-3">
                            <img src={ NBTImage } alt="nbt image" />
                            <h5>NBT is required</h5>
                            <p>{ (props.QMInfo.Other.NBTRequired ? "Yes" : "No") }</p>
                        </div>
                        <div className="col-3">
                            <img src={ PortfolioImage } alt="portfolio image" />
                            <h5>Portfolio</h5>
                            <p>{ (props.QMInfo.Other.PortfolioRequired ? "Yes" : "No") }</p>
                        </div>
                    </div>
                    <div className="row match-points">
                        <div className="col-4">
                            <p>my points</p>
                            <p>{ (props.QMInfo.StudentPoints === 0 ? "N/A" : props.QMInfo.StudentPoints) }</p>
                        </div>
                        <div className="col-4">
                            <p>my required points</p>
                            <p>{ (props.QMInfo.RequiredPoints === 0 ? "N/A" : props.QMInfo.RequiredPoints) }</p>
                        </div>
                        <div className="col-4">
                            <p className={ (props.QMInfo.TotalSubjectsMet < props.QMInfo.TotalRequiredSubjects ? "red": "") }>my subjects</p>
                            <p className={ (props.QMInfo.TotalSubjectsMet < props.QMInfo.TotalRequiredSubjects ? "red": "") }>{ (props.QMInfo.TotalRequiredSubjects > 0 ? props.QMInfo.TotalSubjectsMet + "/" + props.QMInfo.TotalRequiredSubjects : "N/A" ) }</p>
                        </div>
                    </div>
                    <div className="row subjects">
                        {
                            requiredSubjects.length > 0 ?
                            <div className="col-12">
                                <h5>required subjects</h5>
                                { requiredSubjects }
                            </div>
                            : ""
                        }
                        {
                            requiredSubjectsNotTaken.length > 0 ?
                            <div className="col-12">
                                <h5>required subjects not taken</h5>
                                { requiredSubjectsNotTaken }
                            </div>
                            : ""
                        }
                        {
                            otherSubjects.length > 0 ?
                            <div className="col-12">
                                <h5>other subjects</h5>
                                { otherSubjects }
                            </div>
                            : ""
                        }
                    </div>
                </Wrap>
            );
        }
    }

    return (
        <Wrap>
            <Backdrop 
                backdropShow={ props.showMatchInfoBackdrop } 
                backdropHide={ props.hideQualificationMatchInfo } 
            />
            <section className="container qualification-match-info" style={{ display: props.showMatchInfoBackdrop ? "block" : "none" }}>
                { contentView }
            </section>
        </Wrap>
    )
}

export default qualificationMatchInfo;
