import React from "react"; 
// 
// View user page component
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../hoc/wrap";
// Header component
import Header from '../../header/header';
// Toolbar component
import Toolbar from '../../UI/toolbar/toolbar';
// Main content wrap component
import MainContentWrap from '../../UI/mainContentWrap/mainContentWrap';
// View users component
import Messenger from "../../../containers/messenger/messenger";

// Styles
import './messengerPage.css';

const messengerPage = ( props ) => (
    <Wrap>
        <Header 
            whichHeader="messenger" 
            showToolsNav={ props.showToolsNav } 
            toggleToolsNav={ props.toggleToolsNav } 
            showProfileNav={ props.showProfileNav } 
            toggleProfileNav={ props.toggleProfileNav } 
            handleLogout={ props.handleLogout } 
        />
        <main className="messenger">
            <Toolbar adminTool="messenger" />
            <MainContentWrap>
                <Messenger />
            </MainContentWrap>
        </main>
    </Wrap>
);

export default messengerPage;
