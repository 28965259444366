import React, { Component } from "react";
import HTTPRequest from "../../../js/library/HTTPConfig/axiosConfig";
import Helpers from '../../../js/library/helpers/helpers';
import formatDate from "../../../js/library/formatDate/formatDate";
// 
// User stats component
// 

// 
// Services
// 

// User services
import UserServices from "../../../js/library/services/userservice";
// User stats services
import userStatsServices from "../../../js/library/services/userstatsservice";

// 
// Components
// 

// Wrap component
import Wrap from "../../../components/hoc/wrap";
// Filter component
import Filter from "../../../components/UI/filter/filter";
// User stats tile component
import UserStatsTile from "../../../components/pages/statisticsPage/userStats/userStatsTile/userStatsTile";
// Bar chart component 
import BarChart from "../../../components/pages/statisticsPage/userStats/userStatsInfo/barChart/barChart";
// Chart info component
import ChartInfo from "../../../components/pages/statisticsPage/userStats/userStatsInfo/chartInfo/chartInfo";
// Spinner component
import Spinner from "../../../components/UI/spinner/spinner";
// Button component
import Button from "../../../components/UI/button/button";
// Style
import "./userStats.css";
import userstatsservice from "../../../js/library/services/userstatsservice";

class UserStats extends Component {
    state = {
        adminUserToken: "",
        filters: {
            defaultFilter: "name"
        },
        showUserStatsTiles: false,
        activeTile: "",
        filters: {
            defaultFilter: "Action Date"
        },
        filterOptions: {
            source: "",
            school: "",
            schoolId: "",
            province: "EC",
            actiondatefrom: new Date(),
            actiondateto: new Date(),
            createddatefrom: new Date(),
            createddateto: new Date(),
            grade: "",
            hasexamnumber: ""
        },
        schoolList: [],
        chartActionNames: [],
        chartData: [],
        chartDataTopQualificationMatched: [],
        chartDataTopCareerMatched: [],
        chartTotals: {},
        chartSortFilter: "month",
        topFiveQualificationsMatched: [],
        topFiveCareersMatched: [],
        loadingTiles: false,
        loadingTileInfo: false,
        chartHasData: true,
        topFiveQHasData: true,
        topFiveCHasData: true
    }

    componentWillMount() {
        let userInfo = {};
        if (UserServices.USER_TOKEN && UserServices.USER_TOKEN !== "") {
            this.setState({
                adminUserToken: UserServices.USER_TOKEN
            });
            
        } else {
            userInfo = JSON.parse(localStorage.getItem("userInfo"));
            
            this.setState({
                adminUserToken: userInfo.userToken
            });
        }
    }

    componentDidMount() {
        let dateMonthAgo = formatDate.getDateOneMonthAgo();
        let filterOptionsCopy = { ...this.state.filterOptions };

        filterOptionsCopy.actiondatefrom = dateMonthAgo;
        filterOptionsCopy.createddatefrom = dateMonthAgo;

        this.setState({
            filterOptions: filterOptionsCopy
        });
    }

    handleFilterChange = ( event ) => {
        const inputName = event.target.name;
        const selectedFilter = event.target.value;
        let filtersCopy = { ...this.state.filters };

        filtersCopy[inputName] = selectedFilter;

        this.setState({
            showshowUserStatsTilesUsers: false,
            filters: filtersCopy
        });
    }

    handleFetchUserStatsData = ( event ) => {
        event.preventDefault();
        let filtersObject = {};
        let filters = { ...this.state.filters };
        let filterValues = { ...this.state.filterOptions };
        let dateFrom;
        let dateTo;

        this.setState({
            loadingTiles: true
        });

        Object.keys(filters).forEach(filterName => {
            let filter = filters[filterName].replace(" ", "-").toLowerCase();

            switch (filter) {
                case "name":
                    filtersObject["name"] = filterValues["name"];
                    break;

                case "school":
                    filtersObject["school"] = [filterValues["schoolId"]];
                    break;

                case "province":
                    filtersObject["province"] = [filterValues["province"]];
                    break;

                case "activity":
                    filtersObject["activity"] = {
                        min: filterValues["activitymin"],
                        max: filterValues["activitymax"]
                    }
                    break;

                case "action-date":
                    dateFrom = filterValues["actiondatefrom"];
                    dateTo = filterValues["actiondateto"];

                    filtersObject["actiondate"] = {
                        from: dateFrom.getFullYear() + "-" + (dateFrom.getMonth() + 1) + "-" + dateFrom.getDate(),
                        to: dateTo.getFullYear() + "-" + (dateTo.getMonth() + 1) + "-" + dateTo.getDate()
                    }
                    break;

                case "grade":
                    filtersObject["grade"] = filterValues["grade"];
                    break;

                case "source":
                    filtersObject["source"] = filterValues["source"];
                    break;

                case "username":
                    filtersObject["username"] = filterValues["username"];
                    break;

                case "created-date":
                    dateFrom = filterValues["createddatefrom"];
                    dateTo = filterValues["createddateto"];

                    filtersObject["date"] = {
                        from: dateFrom.getFullYear() + "-" + (dateFrom.getMonth() + 1) + "-" + dateFrom.getDate(),
                        to: dateTo.getFullYear() + "-" + (dateTo.getMonth() + 1) + "-" + dateTo.getDate()
                    }
                    break;
            }
        });

        this.setState({
            searchFilter: filtersObject
        });
        
        HTTPRequest({
            method: "POST",
            url: "adminstats/dashboard",
            data: {
                "filters": filtersObject
            },
            headers: { "Authorization": "Bearer " + this.state.adminUserToken }

        }).then(response => {
            if (response.data.success) {
                
                this.setState({
                    chartTotals: response.data.data,
                    showUserStatsTiles: true,
                    loadingTiles: false,
                    statsTiles: [
                        {
                            tileTitle: "home page visits",
                            visits: {
                                count: response.data.data.homevisits
                            },
                            today: {
                                count: response.data.data.homevisitors
                            },
                            tileStatus: "inactive"
                        },
                        {
                            tileTitle: "top 5",
                            tileStatus: "inactive"
                        },
                        {
                            tileTitle: "users",
                            total: {
                                count: response.data.data.users,
                            },

                            new: {
                                count: response.data.data.todayUsers,
                            },
                            tileStatus: "inactive"
                        },
                        {
                            tileTitle: "qualifications",
                            viewed: {
                                count: response.data.data.qmatches,
                            },

                            liked: {
                                count: response.data.data.likeq,
                            },

                            unliked: {
                                count: response.data.data.unlikeqs,
                            },
                            tileStatus: "inactive"
                        },
                        {
                            tileTitle: "careers",
                            viewed: {
                                count: response.data.data.cmatches,
                            },

                            liked: {
                                count: response.data.data.likec,
                            },

                            unliked: {
                                count: response.data.data.unlikecs,
                            },
                            tileStatus: "inactive"
                        },
                        {
                            tileTitle: "bursaries",
                            viewed: {
                                count: response.data.data.bmatches,
                            },

                            matched: {
                                count: response.data.data.bvisitors,
                            },
                            tileStatus: "inactive"
                        }
                    ]
                });
            }
        }).catch(error => {
            console.log(error);
        });

       
    }

    handleAddFilter = ( event ) => {
        event.preventDefault();

        let filtersCount = Object.keys(this.state.filters).length;
        let filtersCopy = { ...this.state.filters };

        filtersCopy["extraFilter" + filtersCount] = "school";
        
        this.setState({
            filters: filtersCopy
        });
    }

    handleRemoveFilter = ( event, filterName ) => {
        event.preventDefault();

        let filtersCopy = { ...this.state.filters };
        delete filtersCopy[filterName];
        
        this.setState({
            filters: filtersCopy
        });
    }

    handleFilterValueChange = ( event ) => {
        event.preventDefault();

        let inputName = event.target.name;
        let inputValue = event.target.value;
        let filterOptionsCopy = { ...this.state.filterOptions };

        switch( inputName ) {
            case "school":
                HTTPRequest({
                    method: "POST",
                    url: "schools/find/limit-10-index-0",
                    data: {
                        "like": inputValue
                    }

                }).then( response => {
                    if ( response.data.success ) {
                        let responseData = response.data.data;
                        let schoolNamesList = [];

                        if ( responseData.length >= 1 ) {
                            if ( responseData.length === 1 && responseData[0].Name === "Can't Find School") {
                                schoolNamesList.push(
                                    <li key={ -1 } className="list-no-result">
                                        <em>Can't Find School</em>
                                    </li>
                                );
                                
                            } else {
                                schoolNamesList = responseData.map( schoolObj => {
                                    let province = schoolObj.Province
                                    let district = schoolObj.EIDistrict !== "NOT APPLICABLE" ? " " + schoolObj.EIDistrict  : "";

                                    return <li key={ schoolObj.ID } onClick={ () => this.handleSearchItemSelect( schoolObj.ID, schoolObj.Name ) }>{ schoolObj.Name + "(" + province + district + ")" }</li>
                                });
                            }
                        }

                        this.setState({
                            schoolList: schoolNamesList
                        });
                    }
                });

                break;
        }

        filterOptionsCopy[inputName] = inputValue;

        this.setState({
            filterOptions: filterOptionsCopy
        });
    }

    handleFilterDateSelect = ( selectedDate, filterName ) => {
        let filterOptionsCopy = { ...this.state.filterOptions };

        filterOptionsCopy[filterName] = selectedDate;

        this.setState({
            filterOptions: filterOptionsCopy
        });
    }

    handleSearchItemSelect = ( schoolId, schoolName ) => {
        let filterOptionsCopy = { ...this.state.filterOptions }

        filterOptionsCopy["school"] = schoolName;
        filterOptionsCopy["schoolId"] = schoolId;
        this.setState({
            filterOptions: filterOptionsCopy,
            schoolList: []
        });
    }

    handleShowTileStats = ( tileName ) => {
        let statsTilesCopy = { ...this.state.statsTiles };
        let statsTilesArray = [];
        let chartDataPoints = {};
        let actionNames = [];
        let chartHasData = true;

        switch( tileName ) {
            case "home page visits":
                actionNames = ["match.institutions", "match.institutions_actors"]
                break;

            case "users":
                actionNames = ["created"];
                break;

            case "qualifications":
                actionNames = ["match.qualificationmatch", "likeq", "me.unlikequalification"];
                break;

            case "careers":
                actionNames = ["careers.info", "likec", "me.unlikespecialization"]
                break;

            case "bursaries": 
                actionNames = ["match.bursary", "match.bursary_actors"];
                break;
        }

        this.setState({
            chartActionNames: actionNames,
            loadingTileInfo: true
        })

        if ( tileName !== "top 5" ) {
            userStatsServices.getChartData( this.state.searchFilter, actionNames, this.state.chartSortFilter ).then( chartData => {
                console.log("chartData", chartData)
                chartHasData = userStatsServices.IsEmptyChart(chartData, tileName);
                console.log("chartHasData", chartHasData)

                if ( chartHasData ) {
                    if (!chartData.error) {
                        chartDataPoints = Helpers.formatChartData(chartData, this.state.chartSortFilter);
                    }
        
                    console.log(chartData);
                    console.log(chartDataPoints);
        
                    this.setState({
                        chartData: chartDataPoints,
                        loadingTileInfo: false,
                        chartHasData: true
                    })

                } else {
                    this.setState({
                        chartHasData: false,
                        loadingTileInfo: false
                    });
                }
            });

        } else {
            let topCategories = ["TopQualificationMatched", "TopCareersMatched" ];

            topCategories.forEach( categoryName => {
                userStatsServices.getTopChartData( this.state.searchFilter, categoryName ).then( chartData => {
                    chartHasData = userstatsservice.IsEmptyChart(chartData, tileName);
                    
                    if (chartHasData) {
                        if (!chartData.error) {
                            chartDataPoints = Helpers.formatTopChartData(chartData, categoryName);
                        }
            
                        console.log(chartData);
    
                        if ( categoryName === "TopQualificationMatched" ) {
                            this.setState({
                                "chartDataTopQualificationMatched": chartDataPoints,
                                topFiveQualificationsMatched: chartData,
                                loadingTileInfo: false,
                                topFiveQHasData: true,
                            });
    
                        } else {
                            this.setState({
                                "chartDataTopCareerMatched": chartDataPoints,
                                topFiveCareersMatched: chartData,
                                loadingTileInfo: false,
                                topFiveCHasData: true,
                            });
                        }

                    } else {
                        if ( categoryName === "TopQualificationMatched" ) {
                            this.setState({
                                topFiveQHasData: false,
                                loadingTileInfo: false
                            });

                        } else {
                            this.setState({
                                topFiveCHasData: false,
                                loadingTileInfo: false
                            });
                        }
                    }
                });
            });
        }
        
        Object.keys(statsTilesCopy).forEach( tileKey => {
            let tile = statsTilesCopy[tileKey];

            if ( tile.tileTitle == tileName ) {
                tile.tileStatus = "active";
                statsTilesCopy[tileKey] = tile;
            }

            if ((tile.tileStatus == "active") && (tile.tileTitle != tileName)) {
                tile.tileStatus = "inactive";
                statsTilesCopy[tileKey] = tile;
            }
        });

        statsTilesArray = Object.keys(statsTilesCopy).map( tileCopyKey => {
            return statsTilesCopy[tileCopyKey];
        })

        this.setState({
            statsTiles: statsTilesArray,
            activeTile: tileName,
        });
    }

    handleSetSortFilterOption = ( event ) => {
        let inputValue = event.target.value;
        let chartDataPoints = [];

        this.setState({
            chartSortFilter: inputValue
        })

        userStatsServices.getChartData( this.state.searchFilter, this.state.chartActionNames, inputValue ).then( chartData => {
            if (!chartData.error) {
                chartDataPoints = Helpers.formatChartData(chartData, inputValue);
            }

            console.log(chartData);
            console.log(chartDataPoints);

            this.setState({
                chartData: chartDataPoints
            })
        });
    }

    render() {
        let extraFilters = [];
        let userStatsTiles = [];
        let userStatsSection = "";
        let userStatsInfo = "";
        let userStatsInfoSection = "";

        if ( Object.keys(this.state.filters).length > 1 ) {
            let filterIndex = 0;
            let filterInputName = "";

            Object.keys(this.state.filters).forEach( filterName => {
                filterInputName = this.state.filters[filterName].toLowerCase();

                if ( filterName !== "defaultFilter" ) {
                    extraFilters[filterIndex] = (
                        <Filter 
                            pageName={ "userStats" }
                            filterName={ filterName } 
                            filterValue={ this.state.filters[filterName] } 
                            onFilterChange={ this.handleFilterChange } 
                            removeExtraFilter={ this.handleRemoveFilter } 
                            key={ filterName }
                            filterInputName={ filterInputName }
                            filterInputValue={ this.state.filterOptions[filterInputName] }
                            activityMinValue={ this.state.filterOptions["activitymin"] }
                            activityMaxValue={ this.state.filterOptions["activitymax"] }
                            actionDateFrom= { this.state.filterOptions["actiondatefrom"] }
                            actionDateTo= { this.state.filterOptions["actiondateto"] }
                            createdDateFrom= { this.state.filterOptions["createddatefrom"] }
                            createdDateTo= { this.state.filterOptions["createddateto"] }
                            onFilterValueChange={ this.handleFilterValueChange }
                            onDateSelect={ this.handleFilterDateSelect }
                            schoolNamesList={ this.state.schoolList } 
                        />
                    );
                }
                filterIndex++;
            });

        }
        
        if ( this.state.showUserStatsTiles ) {
            userStatsTiles = this.state.statsTiles.map( userStatsTile => {
                switch( userStatsTile.tileTitle ) {
                    case "home page visits":
                        return(
                            <UserStatsTile 
                                tileTitle={ userStatsTile.tileTitle }
                                visitsCount={ userStatsTile.visits.count }
                                todayCount={ userStatsTile.today.count }
                                key={ userStatsTile.tileTitle }
                                tileStatus={ userStatsTile.tileStatus }
                                onTileClick={ () => this.handleShowTileStats(userStatsTile.tileTitle.toLowerCase()) }
                            />
                        );
                        
                    case "top 5":
                        return(
                            <UserStatsTile 
                                tileTitle={ userStatsTile.tileTitle }
                                key={ userStatsTile.tileTitle }
                                tileStatus={ userStatsTile.tileStatus }
                                onTileClick={ () => this.handleShowTileStats(userStatsTile.tileTitle.toLowerCase()) }
                            />
                        );

                    case "users":
                        return(
                            <UserStatsTile 
                                tileTitle={ userStatsTile.tileTitle }
                                totalCount={ userStatsTile.total.count }
                                newCount={ userStatsTile.new.count }
                                key={ userStatsTile.tileTitle }
                                tileStatus={ userStatsTile.tileStatus }
                                onTileClick={ () => this.handleShowTileStats(userStatsTile.tileTitle.toLowerCase()) }
                            />
                        );

                    case "qualifications":
                    case "careers":
                        return(
                            <UserStatsTile 
                                tileTitle={ userStatsTile.tileTitle }
                                viewedCount={ userStatsTile.viewed.count }
                                likedCount={ userStatsTile.liked.count }
                                unlikedCount={ userStatsTile.unliked.count }
                                key={ userStatsTile.tileTitle }
                                tileStatus={ userStatsTile.tileStatus }
                                onTileClick={ () => this.handleShowTileStats(userStatsTile.tileTitle.toLowerCase()) }
                            />
                        );
                    
                    case "bursaries":
                        return (
                            <UserStatsTile 
                                tileTitle={ userStatsTile.tileTitle }
                                viewedCount={ userStatsTile.viewed.count }
                                matchedCount={ userStatsTile.matched.count }
                                key={ userStatsTile.tileTitle }
                                tileStatus={ userStatsTile.tileStatus }
                                onTileClick={ () => this.handleShowTileStats(userStatsTile.tileTitle.toLowerCase()) }
                            />
                        );
                }
            });

            if ( this.state.activeTile !== "" ) {                
                switch( this.state.activeTile ) {
                    case "qualifications":
                        userStatsInfo = (
                            <section className="row user-stats-info">
                                <div className="col-8">
                                    <BarChart 
                                        tileTitle={ this.state.activeTile } 
                                        tileSubtitle="Qualifications’ Breakdown"
                                        chartSortFilter={ this.state.chartSortFilter } 
                                        setSortFilterOption={ this.handleSetSortFilterOption } 
                                        chartData = { this.state.chartData }
                                        chartHasData={ this.state.chartHasData }
                                    />
                                </div>
                                <div className="col-4">
                                    <ChartInfo 
                                        tileTitle={ this.state.activeTile } 
                                        totalMatched={ this.state.chartTotals.qmatches }
                                        totalLiked={ this.state.chartTotals.likeq }
                                        totalUnliked={ this.state.chartTotals.unlikeqs }
                                    />
                                </div>
                            </section>
                        )
                        break;
                    case "careers":
                    userStatsInfo = (
                        <section className="row user-stats-info">
                            <div className="col-8">
                                <BarChart 
                                    tileTitle={ this.state.activeTile } 
                                    tielSubtitle="Careers’ Page User Activity"
                                    chartSortFilter={ this.state.chartSortFilter } 
                                    setSortFilterOption={ this.handleSetSortFilterOption } 
                                    chartData = { this.state.chartData }
                                    chartHasData={ this.state.chartHasData }
                                />
                            </div>
                            <div className="col-4">
                                <ChartInfo 
                                    tileTitle={ this.state.activeTile } 
                                    totalMatched={ this.state.chartTotals.cmatches }
                                    totalLiked={ this.state.chartTotals.likec }
                                    totalUnliked={ this.state.chartTotals.unlikecs }
                                />
                            </div>
                        </section>
                    )
                    break;
                    case "bursaries":
                        userStatsInfo = (
                            <section className="row user-stats-info">
                                <div className="col-8">
                                    <BarChart 
                                        tileTitle={ this.state.activeTile } 
                                        tileSubtitle="Bursaries"
                                        chartSortFilter={ this.state.chartSortFilter } 
                                        setSortFilterOption={ this.handleSetSortFilterOption } 
                                        chartData = { this.state.chartData }
                                        chartHasData={ this.state.chartHasData }
                                    />
                                </div>
                                <div className="col-4">
                                    <ChartInfo 
                                        tileTitle={ this.state.activeTile } 
                                        totalMatched={ this.state.chartTotals.bmatches }
                                        totalVisitors={ this.state.chartTotals.bvisitors }
                                    />
                                </div>
                            </section>
                        )
                        break;

                    case "users":
                    userStatsInfo = (
                        <section className="row user-stats-info">
                            <div className="col-8">
                                <BarChart 
                                    tileTitle={ this.state.activeTile } 
                                    tileSubtitle="New users’ Analysis"
                                    chartSortFilter={ this.state.chartSortFilter } 
                                    setSortFilterOption={ this.handleSetSortFilterOption } 
                                    chartData={ this.state.chartData }
                                    chartHasData={ this.state.chartHasData }
                                />
                            </div>
                            <div className="col-4">
                                <ChartInfo tileTitle={ this.state.activeTile } totalNewUsers={ this.state.chartTotals.users } />
                            </div>
                        </section>
                    )
                    break;

                    case "home page visits":
                        userStatsInfo = (
                            <section className="row user-stats-info">
                                <div className="col-8">
                                    <BarChart 
                                        tileTitle={ this.state.activeTile } 
                                        tileSubtitle="Home Page Visits’ Analysis"
                                        chartSortFilter={ this.state.chartSortFilter } 
                                        setSortFilterOption={ this.handleSetSortFilterOption } 
                                        chartData={ this.state.chartData }
                                        chartHasData={ this.state.chartHasData }
                                    />
                                </div>
                                <div className="col-4">
                                    <ChartInfo tileTitle={ this.state.activeTile } totalVisits={ this.state.chartTotals.homevisits } totalVisitors={ this.state.chartTotals.homevisitors } />
                                </div>
                            </section>
                        )
                        break;

                    case "top 5":
                        userStatsInfo = (
                            <section className="row user-stats-info">
                                <div className="col-8">
                                    <BarChart 
                                        tileTitle={ this.state.activeTile } 
                                        chartData={ [this.state.chartDataTopQualificationMatched, this.state.chartDataTopCareerMatched] }
                                        topFiveCHasData={ this.state.topFiveCHasData }
                                        topFiveQHasData={ this.state.topFiveQHasData }
                                    />
                                </div>
                                <div className="col-4">
                                    <ChartInfo tileTitle={ this.state.activeTile } topFive={ [this.state.topFiveQualificationsMatched, this.state.topFiveCareersMatched] } />
                                </div>
                            </section>
                        )
                        break;
                }
            }
        }

        if ( this.state.loadingTiles ) {
            userStatsSection = (
                <section className="row justify-content-center loading-tiles">
                    <div className="col-2">
                        <Button>
                            <Spinner />
                        </Button>
                        <p>fetching tiles data</p>
                    </div>
                </section>
            );

        } else {
            userStatsSection = (
                <section className="row user-stats-tiles">
                    { userStatsTiles }
                </section>
            );
        }

        if ( this.state.loadingTileInfo ) {
            userStatsInfoSection = (
                <section className="row justify-content-center align-content-center user-stats-info loading-tiles" >
                    <div className="col-4">
                        <Button>
                            <Spinner />
                        </Button>
                        <p>fetching active tile data</p>
                    </div>
                </section>
            );

        } else {
            userStatsInfoSection = userStatsInfo
        }

        return(
            <Wrap>
                <div className="row no-gutters page-tool-heading">
                    <div className="col-12">
                        <h3>User Statistics</h3>
                    </div>
                </div>
                <section className="row no-gutters view-users-filter">
                    <Filter 
                        pageName={ "userStats" }
                        filterName={ "defaultFilter" } 
                        filterValue={ this.state.filters.defaultFilter } 
                        onFilterChange={ this.handleFilterChange } 
                        fetchData={ this.handleFetchUserStatsData } 
                        addExtraFilter={ this.handleAddFilter } 
                        filterInputName={ this.state.filters.defaultFilter.toLowerCase() }
                        filterInputValue= { this.state.filterOptions[this.state.filters.defaultFilter.toLowerCase()] }
                        activityMinValue={ this.state.filterOptions["activitymin"] }
                        activityMaxValue={ this.state.filterOptions["activitymax"] }
                        actionDateFrom= { this.state.filterOptions["actiondatefrom"] }
                        actionDateTo= { this.state.filterOptions["actiondateto"] }
                        createdDateFrom= { this.state.filterOptions["createddatefrom"] }
                        createdDateTo= { this.state.filterOptions["createddateto"] }
                        onFilterValueChange={ this.handleFilterValueChange }
                        onDateSelect={ this.handleFilterDateSelect }
                        schoolNamesList={ this.state.schoolList } 
                    />

                    { extraFilters }
                </section>
                { userStatsSection }
                { userStatsInfoSection }
            </Wrap>
        );
    }
}

export default UserStats;