import React from "react";
// 
// Table titles component: manage application documents table titles
// 

// Style
import "./tableTitles.css";

const tableTitles = () => (
    <div className="row no-gutters table-titles">
        <div className="col-3">
            <h5>Name</h5>
        </div>
        <div className="col-5">
            <h5>Description</h5>
        </div>
        <div className="col-2">
            <h5>Color</h5>
        </div>
        <div className="col-1">
            <h5>required</h5>
        </div>
        <div className="col-1">
            <h5 className="right-aligned">Save</h5>
        </div>
    </div>
);

export default tableTitles;