import HTTPRequest from "../HTTPConfig/axiosConfig";
import helpers from "../helpers/helpers";
// 
// User service : Services for all user based functions - login, logout ...
// 

const userService = {
    login,
    logout,
    getProfileInfo,
    USER_TOKEN: "",
    USER_ROLE: "",
    USER_ID: ""
}

function login(userName, password) {
    let userInfo = {};
    let userProfileInfo = {};

    return HTTPRequest({
        method: "POST",
        url: "account/login",
        data: {
            username: userName,
            password: password
        }

        }).then( response => {
            if ( response.data.success ) {
                userInfo["userName"] = userName;
                userInfo["password"] = password;
                userInfo["userToken"] = response.data.data.token;
                userInfo["userRole"] = response.data.data.role;
                this.USER_TOKEN = response.data.data.token;
                this.USER_ROLE =  response.data.data.role;
                
            } else {
                userInfo["loginError"] = true;
                userInfo["loginErrorMessage"] = response.data.reason
            }
            localStorage.setItem('userInfo', JSON.stringify(userInfo));

            if ( !userInfo.error ) {
                let userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {};

                this.getProfileInfo(response.data.data.token).then( profileInfo => {
                    if ( !profileInfo.error ) {
                        userInfo["userId"] = profileInfo.ID;
                    }
                    localStorage.setItem("userInfo", JSON.stringify(userInfo));
                });
            }

            return userInfo;

        }).catch( error => {
            userInfo["loginError"] = true;
            userInfo["loginErrorMessage"] = "Error trying to login";

            return userInfo;
        });
}

function logout() {
    localStorage.removeItem("userInfo");
}

function getProfileInfo( userToken ) {
    let user = {};

    return HTTPRequest({
        method: "POST",
        url: "me/user",
        headers: { 'Authorization': 'Bearer ' + userToken }

    }).then( response => {
        if ( response.data.success ) {
            user = response.data.data.user;
            this.USER_ID = response.data.data.user.ID;

        } else {
            user["error"] = true;
            user["message"] = response.data.reason;
        }
        return user;

    }).catch( error => {
        user["error"] = true;
        user["message"] = "Error fetching profile info";
        return user;
    });
}

export default userService;

