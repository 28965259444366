import HTTPRequest from "../HTTPConfig/axiosConfig";
import helpers from "../helpers/helpers";
// 
// Messenger tool services
// 

const messengerService = {
    getACList,
    getACUsersList,
    getConversations,
}

function getACList() {
    let ACList = [];

    return HTTPRequest({
        method: "POST",
        url: "posts/getac",
        data: {},
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            ACList = response.data.data;
        
        } else {
            ACList.push("error");
        }
        return ACList;

    }).catch( error => {
        return ACList.push("error");
    });
}

function getACUsersList( userId ) {
    let ACUserList = [];

    return HTTPRequest({
        method: "POST",
        url: "posts/getacusers/limit-50-index-0",
        data: {
            userId: userId
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            ACUserList = response.data.data;
        
        } else {
            ACUserList.push("error");
        }
        return ACUserList;

    }).catch( error => {
        return ACUserList.push("error");
    });
}

function getConversations(applicantId, ACUserId) {
    let conversations = {};

    return HTTPRequest({
        method: "POST",
        url: "posts/GetConversationAdmin/limit-50-index-0",
        data: {
            otherUserId: applicantId,
            acId: ACUserId
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            conversations = response.data.data;

        } else {
            conversations["error"] = true;
            conversations["errorMessage"] = response.data.reason;
        }
        console.log(conversations)
        return conversations;

    }).catch( error => {
        conversations["error"] = true;
        conversations["errorMessage"] = "Error fetching AC Applicant conversations";

        return conversations;
    });
}

export default messengerService;