import React, { Component } from "react";
// 
// Groups dashboard component
// 

// 
// Components
// 

// Group container component
import GroupContainer from "./groupContainer/groupContainer";

// Style
import "./groupsDashboard.css";

const groupsDashboard = ( props ) => {
    let groups = props.dashboard.groups.map( (group, index) => {
        return <GroupContainer 
                    key={ index }
                    groupName={ group.name }
                    groupTotal={ group.total }
                    groupInstitutions={ group.institutions }
                />
    });

    return(
        <section className="dashboard row">
            <div className="col-12">
                <div className="dashboard-heading row justify-content-between">
                    <div className="col-6">
                        <h5>application groups statistics</h5>
                    </div>
                    <div className="col-4">
                        <h5 className="right-align">total: { props.dashboard.total }</h5>
                    </div>
                </div>
                <div className="row group">
                    { groups }
                </div>
            </div>
        </section>
    )
}

export default groupsDashboard;