import React from "react";
// 
// Applications list component : Layout component for applications list
// 

// 
// Components
// 

// Applications item component
import ApplicationsItem from "./applicationsItem/applicationsItem";
// Table title component
import TableTitle from "./tableTitles/tableTitle";
// Student applications component
import StudentApplications from "./studentApplications/studentApplications";
// Student docs compoent
import StudentDocs from "./studentDocs/studentDocs";
// Bursary item component
import BursaryItem from "./bursaryItem/bursaryItem";
// Wrap component
import Wrap from "../../../hoc/wrap";
// Button component
import Button from "../../../UI/button/button";
// Loading data component
import LoadingData from "../../../UI/loadingData/loadingData";

// Style
import "./applicationsList.css"

const applicationsList = ( props ) => {
    let content;
    let viewMoreButtonNxt = "";
    let viewMoreButtonPrv = "";
    let viewedApplications = props.viewedApplications;
    let applications = {};
    let applicationsList; 

    if ( props.viewStudentApplication  || props.viewStudentApplicationDocs || props.viewStudentBursaries ) {
        Object.keys(viewedApplications).forEach( studentId => {
            if ( studentId === props.viewedStudentApplications.studentId ) {
                applicationsList = viewedApplications[studentId];
            }            
        });
    }

    if ( props.viewStudentApplication ) {
        content = (
                <div className="row">
                    <div className="col-12 applications-list">
                        <h4>{"Applications with status " + props.viewedStatus.name + " from " + props.viewedInstitution.name }</h4>
                        <TableTitle />
                        <ApplicationsItem 
                            active
                            application={applicationsList}
                            studentId={props.viewedStudentApplications.studentId}
                            viewedStatus={ props.viewedStatus }
                            viewedInstitution={ props.viewedInstitution }
                            showApplicationsList={ props.showApplicationsList }
                            showStudentApplications={ props.showStudentApplications }
                            showApplicationDocs={ props.showApplicationDocs }
                            viewStudentApplicationDocs={ props.viewStudentApplicationDocs }
                            adminList={ props.adminList }
                            userRole={ props.userRole }
                            showAdminAPList={ props.showAdminAPList }
                            APAssignId={ props.APAssignId }
                            assignApplicationToAP={ props.assignApplicationToAP }
                            institutionToAssign={ props.institutionToAssign }
                            hideAPList={ props.hideAPList }
                            hideACList={ props.hideACList }
                            showApplicantInfoBackdrop={ props.showApplicantInfoBackdrop }
                            showApplicantInfo={ props.showApplicantInfo }
                            hideApplicantInfo={ props.hideApplicantInfo }
                            applicationInfo={ props.applicationInfo }
                            subjectList={ props.subjectList }
                            applicantInfoOnChange={ props.applicantInfoOnChange }
                            userInfo={ props.userInfo }
                            subjectInputChange={ props.subjectInputChange }
                            subjectSearchList={ props.subjectSearchList }
                            focusedInput={ props.focusedInput }
                            saveMarks={ props.saveMarks }
                            savingMarks={ props.savingMarks }
                            showBursaries={ props.showBursaries }
                            loadingBursaries={ props.loadingBursaries }
                            loadingApplicantInfo={ props.loadingApplicantInfo }
                            applicantContactInfo={ props.applicantContactInfo }
                            saveStudentInfo={ props.saveStudentInfo }
                            saveParentInfo={ props.saveParentInfo }
                            saveUserMarks={ props.saveUserMarks }
                            schoolList={ props.schoolList }
                        />
                        <StudentApplications 
                            loadingStudentApplications={ props.loadingStudentApplications }
                            viewStudentApplication={ props.viewStudentApplication }
                            studentApplications={ props.studentApplications }
                            viewedStudentApplications={ props.viewedStudentApplications }
                            statusList={ props.statusList }
                            updateApplicationStatus={ props.updateApplicationStatus }
                            updateAllApplicationStatus={ props.updateAllApplicationStatus }
                            showMatchInfoBackdrop={ props.showMatchInfoBackdrop }
                            showQualificationMatchInfo={ props.showQualificationMatchInfo }                          
                            hideQualificationMatchInfo={ props.hideQualificationMatchInfo }   
                            loadingQualificationMatch={ props.loadingQualificationMatch }    
                            QMInfo={ props.QMInfo }        
                            applicantLoginInfoChange={ props.applicantLoginInfoChange }
                            saveApplicantLoginInfo={ props.saveApplicantLoginInfo }         
                        />
                    </div>
                </div>
        );

    } else if ( props.viewStudentApplicationDocs ) {
        content = (
            <div className="row">
                <div className="col-12 applications-list">
                    <h4>{"Applications with status " + props.viewedStatus.name + " from " + props.viewedInstitution.name }</h4>
                    <TableTitle />
                    <ApplicationsItem 
                        active
                        application={applicationsList}
                        studentId={props.viewedStudentApplications.studentId}
                        viewedStatus={ props.viewedStatus }
                        viewedInstitution={ props.viewedInstitution }
                        showApplicationsList={ props.showApplicationsList }
                        showStudentApplications={ props.showStudentApplications }
                        showApplicationDocs={ props.showApplicationDocs }
                        viewStudentApplicationDocs={ props.viewStudentApplicationDocs }
                        adminList={ props.adminList }
                        userRole={ props.userRole }
                        showAdminAPList={ props.showAdminAPList }
                        APAssignId={ props.APAssignId }
                        assignApplicationToAP={ props.assignApplicationToAP }
                        institutionToAssign={ props.institutionToAssign }
                        hideAPList={ props.hideAPList }
                        hideACList={ props.hideACList }
                        showApplicantInfoBackdrop={ props.showApplicantInfoBackdrop }
                        showApplicantInfo={ props.showApplicantInfo }
                        hideApplicantInfo={ props.hideApplicantInfo }
                        applicationInfo={ props.applicationInfo }
                        subjectList={ props.subjectList }
                        applicantInfoOnChange={ props.applicantInfoOnChange }
                        userInfo={ props.userInfo }
                        subjectInputChange={ props.subjectInputChange }
                        subjectSearchList={ props.subjectSearchList }
                        focusedInput={ props.focusedInput }
                        saveMarks={ props.saveMarks }
                        savingMarks={ props.savingMarks }
                        showBursaries={ props.showBursaries }
                        loadingBursaries={ props.loadingBursaries }
                        loadingApplicantInfo={ props.loadingApplicantInfo }
                        applicantContactInfo={ props.applicantContactInfo }
                        saveStudentInfo={ props.saveStudentInfo }
                        saveParentInfo={ props.saveParentInfo }
                        saveUserMarks={ props.saveUserMarks }
                        schoolList={ props.schoolList }
                    />
                    <StudentDocs 
                        loadingApplicationDocs={ props.loadingApplicationDocs } 
                        applicationDocs={ props.viewedApplicationDocs } 
                        showDocsModal={ props.showDocsModal }
                        hideDocsModal={ props.hideDocsModal }
                        requestedDocsText={ props.requestedDocsText }
                        requestedDocsTextChange={ props.requestedDocsTextChange }
                        requestDocument={ props.requestDocument }
                        sendDocRequest={ props.sendDocRequest }
                        applicationDocSelect={ props.applicationDocSelect }
                        studentId={props.viewedStudentApplications.studentId}
                        downloadDoc={ props.downloadDoc }
                        deleteDoc={ props.deleteDoc }
                    />
                </div>
            </div>
        );

    } else if ( props.viewStudentBursaries ) {
        content = (
            <div className="row">
                <div className="col-12 applications-list">
                    <h4>{"Applications with status " + props.viewedStatus.name + " from " + props.viewedInstitution.name }</h4>
                    <TableTitle />
                    <ApplicationsItem 
                        active
                        application={applicationsList}
                        studentId={props.viewedStudentApplications.studentId}
                        viewedStatus={ props.viewedStatus }
                        viewedInstitution={ props.viewedInstitution }
                        showApplicationsList={ props.showApplicationsList }
                        showStudentApplications={ props.showStudentApplications }
                        showApplicationDocs={ props.showApplicationDocs }
                        viewStudentApplicationDocs={ props.viewStudentApplicationDocs }
                        adminList={ props.adminList }
                        userRole={ props.userRole }
                        showAdminAPList={ props.showAdminAPList }
                        APAssignId={ props.APAssignId }
                        assignApplicationToAP={ props.assignApplicationToAP }
                        institutionToAssign={ props.institutionToAssign }
                        hideAPList={ props.hideAPList }
                        hideACList={ props.hideACList }
                        showApplicantInfoBackdrop={ props.showApplicantInfoBackdrop }
                        showApplicantInfo={ props.showApplicantInfo }
                        hideApplicantInfo={ props.hideApplicantInfo }
                        applicationInfo={ props.applicationInfo }
                        subjectList={ props.subjectList }
                        applicantInfoOnChange={ props.applicantInfoOnChange }
                        userInfo={ props.userInfo }
                        subjectInputChange={ props.subjectInputChange }
                        subjectSearchList={ props.subjectSearchList }
                        focusedInput={ props.focusedInput }
                        saveMarks={ props.saveMarks }
                        savingMarks={ props.savingMarks }
                        showBursaries={ props.showBursaries }
                        loadingBursaries={ props.loadingBursaries }
                        loadingApplicantInfo={ props.loadingApplicantInfo }
                        applicantContactInfo={ props.applicantContactInfo }
                        saveStudentInfo={ props.saveStudentInfo }
                        saveParentInfo={ props.saveParentInfo }
                        saveUserMarks={ props.saveUserMarks }
                        schoolList={ props.schoolList }
                    />
                    <BursaryItem 
                        loadingBursaries={ props.loadingBursaries } 
                        viewStudentBursaries={ props.viewStudentBursaries }
                        studentId={ props.viewedStudentApplications.studentId }
                        studentBursaries={ props.studentBursaries }
                        statusList={ props.statusList }
                        viewedStudentApplications={ props.viewedStudentApplications }
                        updateAllBursaryStatus={ props.updateAllBursaryStatus }
                        updateBursaryStatus={ props.updateBursaryStatus }
                    />
                </div>
            </div>
        );

    } else {
        if ( Object.keys(viewedApplications).length > 0 ) {
            applications = Object.keys(viewedApplications).map( (studentId, index) => {
                if ( index >= props.minFetchLimit && index < props.maxFetchLimit ) {
                    return <ApplicationsItem 
                                application={ viewedApplications[studentId] } 
                                studentId={ studentId } 
                                key={ index } 
                                showStudentApplications={ props.showStudentApplications }
                                showApplicationsList={ props.showApplicationsList }
                                viewedStatus={ props.viewedStatus }
                                viewedInstitution={ props.viewedInstitution }
                                showApplicationDocs={ props.showApplicationDocs }
                                viewStudentApplicationDocs={ props.viewStudentApplicationDocs }
                                assignApplication={ props.assignApplication }
                                adminList={ props.adminList }
                                showAdminList={ props.showAdminList }
                                handleShowAdminList={ props.handleShowAdminList }
                                assignApplicationToAdmin={ props.assignApplicationToAdmin }
                                userRole={ props.userRole }
                                showAdminAPList={ props.showAdminAPList }
                                APAssignId={ props.APAssignId }
                                assignApplicationToAP={ props.assignApplicationToAP }
                                institutionToAssign={ props.institutionToAssign }
                                hideAPList={ props.hideAPList }
                                hideACList={ props.hideACList }
                                showApplicantInfoBackdrop={ props.showApplicantInfoBackdrop }
                                showApplicantInfo={ props.showApplicantInfo }
                                hideApplicantInfo={ props.hideApplicantInfo }
                                applicationInfo={ props.applicationInfo }
                                subjectList={ props.subjectList }
                                applicantInfoOnChange={ props.applicantInfoOnChange }
                                userInfo={ props.userInfo }
                                subjectInputChange={ props.subjectInputChange }
                                subjectSearchList={ props.subjectSearchList }
                                focusedInput={ props.focusedInput }
                                saveMarks={ props.saveMarks }
                                savingMarks={ props.savingMarks }
                                showBursaries={ props.showBursaries }
                                loadingBursaries={ props.loadingBursaries }
                                loadingApplicantInfo={ props.loadingApplicantInfo }
                                applicantContactInfo={ props.applicantContactInfo }
                                saveStudentInfo={ props.saveStudentInfo }
                                saveParentInfo={ props.saveParentInfo }
                                saveUserMarks={ props.saveUserMarks }
                                schoolList={ props.schoolList }
                            />
                }
            });
            
            viewMoreButtonNxt = <Button buttonClassName="view-more" buttonTitle="Next" clickHandler={ () => props.showMoreApplications("next") } />
            viewMoreButtonPrv = <Button buttonClassName="view-more" buttonTitle="Previous" clickHandler={ () => props.showMoreApplications("previous") } />
    
            content = (
                <div className="row">
                    <div className="col-12 applications">
                        <h4>{"Applications with status " + props.viewedStatus.name + " from " + props.viewedInstitution.name }</h4>
                        <TableTitle />
                        { 
                            applications.length > 0 ? 
                            <Wrap>
                                { applications }
                                <div className="col-12">
                                    <div className="row justify-content-between view-more">
                                        <div className="col-3 d-flex justify-content-start">
                                            { viewMoreButtonPrv }
                                        </div>
                                        <div className="col-3 d-flex justify-content-end">
                                            { viewMoreButtonNxt }
                                        </div>
                                    </div>
                                </div>
                            </Wrap>
                            : ""
                        }
                    </div>
                </div>
            );
    
        } else {
            content = (
                <p className="no-applications">no applications</p>
            );
        }
    }

    return (
        <div className="col-12 applications-list">
            <h4 className="title-heading">
                <a href="#" onClick={ event => props.showApplicationsCards(event) }>
                    <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                </a>
                Applications List
            </h4>
            <div className="row no-gutters">
                <div className="col-12">
                    { content }
                </div>
            </div>
        </div>
    );
}

export default applicationsList;