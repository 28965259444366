import React from 'react';
// 
// Login pag: Login page layout
// 

// 
// Components
// 

// Wrap component
import Wrap from '../../hoc/wrap';
// Header component
import Header from '../../header/header';
// Login component
import Login from '../../login/login';

const loginPage = ( props ) => (
    <Wrap>
        <Header whichHeader={ props.whichHeader } />
        <main className="container">
            <Login handleLogin={ props.handleLogin } loginLoading={ props.loginLoading } onInputChange={ props.onInputChange } loginErrorMessage={ props.loginErrorMessage } />
        </main>
    </Wrap>
);

export default loginPage;