import React from 'react';
// 
// Modules table titles component : manage modules page table titles
// 

// Style
import './infoTableTitles.css';

const infoTableTitles = ( props ) => (
     <div className="module-table-titles row no-gutters">
        <div className="col-1">
            <h6>actions</h6>
        </div>
        <div className="col-5">
            <h6>{ props.tableItemTitle }</h6>
        </div>
        <div className="col-4">
            <h6>details</h6>
        </div>
        <div className="col-2">
            <h6 className="right-aligned">edits</h6>
        </div>
    </div>
);

export default infoTableTitles;