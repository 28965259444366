import React from "react";
// 
// Name filetr component : name search filter component
// 

// 
// Components
// 

// Spinner component
import Spinner from "../../../UI/spinner/spinner";

// Style
import "./nameFilter.css";

const nameFilter = ( props ) => {
    let nameSearchList = [];
    let viewMoreButton = "";

    if ( props.nameSearchList.length > 0 ) {
        nameSearchList = props.nameSearchList.map( user => {
            return(
                <li onClick={ () => props.setNameSearch(user.Name + " " + user.Surname, user.ID) } key={ user.ID }>{ user.Name + " " + user.Surname + " (" + user.SchoolName + ")" }</li>
            )
        });
        if ( props.loadingMoreUsers ) {
            viewMoreButton = (
                        <li className="view-more" key={ -1 }>
                            <Spinner />
                        </li>
                    )
        } else {
            viewMoreButton = <li className="view-more" onClick={ () => props.viewMoreUsers()} key={ -1 }>view more</li>;
        }
        
        nameSearchList.splice(nameSearchList.length, 0, viewMoreButton);
    }

    return (
        <div className="row align-items-end name-filter">
            <div className="col-11">
                <div className="auto-complete-search">
                    <p>Find application by user name</p>
                    <label className="filter-label">Name: </label>
                    <div className="filter-option-name-wrap">
                        <input type="text" value={ props.name } onChange={ event => props.inputChange(event) } />   
                    </div>
                    <div className="search-list" style={{display: props.nameSearchList.length > 0 ? "block": "none"}}>
                        <ul>
                            { nameSearchList }
                        </ul>
                    </div>
                    <div className="search-all">
                        <input type="checkbox" name="search-all" checked={ props.searchAllUsers }  onChange={ props.checkSearchAllUsers } />
                        <label>search all users</label>
                    </div>
                </div>
            </div>
            <div className="col-1">
                <a href="#" className="round-button search">
                    <i className="fa fa-search" aria-hidden="true"></i>
                </a>
            </div>
        </div>
    );
}

export default nameFilter;