import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
// 
// 
// 

// 
// Components
// 

// Image area component
import ImageArea from "../../../components/pages/expoReportPage/reportCopmposerControls/imageArea/imageArea";
// Text area component
import TextArea from "../../../components/pages/expoReportPage/reportCopmposerControls/textArea/textArea";
// Report footer
import ReportFooter from "../../../components/pages/expoReportPage/reportPages/reportFooter/reportFooter";

// 
// Assets
// 

// Background picture
import backgroundPagePic from "../../../assets/images/backgroundPage.png";

// Style
import "./backgroundPage.css";

class BackgroundPage extends Component {
    render() {

        let backgroundPageImage = backgroundPagePic;

        if ( this.props.backgroundPageImage.previewURL !== "" ) {
            backgroundPageImage = this.props.backgroundPageImage.previewURL;
        }

        let pageHeadingStyle = {
            width     : "100%",
            fontSize  : "2em",
            fontWeight: "600",
            resize    : "none",
            marginTop : "1em",
            textAlign : "center",
        }

        let backgroundInfoStyle = {
            width     : "100%",
            fontSize  : "1.2em",
            fontWeight: "400",
            resize    : "none",
            height    :  "20em", 
        }

        return (
            <div className="background-page">
                <div className="row page-title">
                    <TextArea 
                        textAreaName="backgroundPageHeading"
                        textAreaValue={ this.props.pageHeading }
                        textAreaOnChange={ this.props.textAreaOnChange }
                        textAreaPlaceholder=""
                        textAreaCol="6"
                        style={ pageHeadingStyle }
                    />
                </div>
                <div className="row">
                     <ImageArea 
                        imageAreaCol="5"
                        imageAreaPreview={ backgroundPageImage }
                        imageAreaName={ this.props.backgroundPageImage.fileName }
                        imageUpload={ this.props.imageUpload }
                        imageAreaWidth="335em"
                        imageAreaHeight=""
                        imageAreaAlt="Background Picture"
                     />
                     <TextArea 
                        textAreaName="backgroundPageInfo"
                        textAreaValue={ this.props.backgroundInfo }
                        textAreaOnChange={ this.props.textAreaOnChange }
                        textAreaPlaceholder=""
                        textAreaCol="7"
                        style={ backgroundInfoStyle }
                     />
                </div>
                <ReportFooter />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        pageHeading: state.backgroundPage.backgroundPageHeading,
        backgroundInfo: state.backgroundPage.backgroundPageInfo,
        backgroundPageImage: state.backgroundPage.backgroundPageImage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        textAreaOnChange: (event) => dispatch({type: actionTypes.UPDATE_TEXT_BACKGROUND_PAGE, payload: { event: event }}),
        imageUpload: (event) => dispatch({ type: actionTypes.UPLOAD_IMAGE_BACKGROUND_PAGE, payload: { event: event }})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundPage)