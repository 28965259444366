import React from 'react';
// 
// Login component : login form comonent
// 

// 
// Components
// 

// Button component
import Button from '../UI/button/button';
// Spinner Component
import Spinner from '../UI/spinner/spinner';

// Style
import './login.css';

const login = ( props ) => {

    let buttonContent = "";

    if ( props.loginLoading ) {
        buttonContent = (   
                            <Button buttonClassName="loginLoading">
                                <Spinner />
                            </Button>
                        );
    
    } else {
        buttonContent = <Button buttonTitle="sign in" clickHandler={ props.handleLogin } />;
    }

    return(
        <div className="row">
            <div className="col-7 offset-3">
                <ul className="main-login">
                    <li>
                        <label>email</label>
                        <input type="email" name="email" value={ props.email } onChange={ event => props.onInputChange(event) } placeholder="example@gradesmatch.com"/>
                    </li>
                    <li>
                        <label>password</label>
                        <input type="password" value={ props.password } onChange={ event => props.onInputChange(event) } placeholder="****************"/>
                    </li>
                    <li className="login-error">
                        <p>{ props.loginErrorMessage }</p>
                    </li>
                    <li>
                        { buttonContent }
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default login;