import React from "react";
// 
// Expo report page component: page for generating expo report
// 

// 
// Components
// 

// Wrap component
import Wrap from '../../hoc/wrap';
// Header
import Header from '../../header/header';
// toolbar
import Toolbar from '../../UI/toolbar/toolbar';
// Main content wrap component
import MainContentWrap from '../../UI/mainContentWrap/mainContentWrap';
// Expo report 
import ExpoReport from "../../../containers/expoReport/expoReport";

import "./expoReportPage.css";

const expoReportPage = ( props ) => (
    <Wrap>
        <Header 
            whichHeader="expo-report" 
            showToolsNav={ props.showToolsNav } 
            toggleToolsNav={ props.toggleToolsNav } 
            showProfileNav={ props.showProfileNav } 
            toggleProfileNav={ props.toggleProfileNav } 
            handleLogout={ props.handleLogout }
        />
        <main className="expo-report-page">
            <Toolbar adminTool="expo-report" />
            <MainContentWrap>
                <ExpoReport />
            </MainContentWrap>
        </main>
    </Wrap>
)

export default expoReportPage;