// 
// Auth header function : function to get HTTP authorization object
// 

const helpers = {
    getAuthorizationHeader,
    getFileAuthorizationsHeader,
    formatTopChartData,
    formatChartData,
    formatNumber
}

function getAuthorizationHeader() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if ( userInfo && userInfo.userToken ) {
        return { 'Authorization': 'Bearer ' + userInfo.userToken };

    } else {
        return {}
    }
}

function getFileAuthorizationsHeader() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if ( userInfo && userInfo.userToken ) {
        return { 
            'Authorization': 'Bearer ' + userInfo.userToken,
            'content-type': 'multipart/form-data'
        };

    } else {
        return {}
    }
}

function formatChartData( chartData, sortBy ) {
    let monthNames = {
        "1": "January",
        "2": "February",
        "3": "March",
        "4": "April",
        "5": "May",
        "6": "June",
        "7": "July",
        "8": "Agust",
        "9": "September",
        "10": "October",
        "11": "November",
        "12": "December"
    }
    let chartTitles = {
        "match.institutions": "Visits",
        "match.institutions_actors": "Visitors",
        "match.qualificationmatch": "Matched Qualifications",
        "likeq": "Liked Qualifications",
        "me.unlikequalification": "Unliked Qualifications",
        "careers.info": "Matched Careers",
        "likec": "Liked Careers",
        "me.unlikespecialization": "Unliked Careers",
        "match.bursary": "Bursaries Matched",
        "match.bursary_actors": "Bursaries Matchers",
        "created": "New Users"
    }
    let sortedChartData = [];

    sortedChartData[0] = Object.keys(chartData).map( actionName => {
        return chartTitles[actionName];
    });
    sortedChartData[0].splice(0, 0, sortBy);

    let actionLength = 0;
    let longestAction = "";

    let newChartData = {};

    Object.keys(chartData).forEach( (actionName, index) => {
        if ( chartData[actionName].length > actionLength ) {
            longestAction = actionName;
            actionLength = chartData[actionName].length;
        }
        newChartData[actionName] = {};

        chartData[actionName].forEach( dataPoint => {
            let key = null;

            switch(sortBy) {
                case "day":
                    let date = new Date(dataPoint.dday)
                    let day = date.getDate();
                    let month = monthNames[date.getMonth() + 1];
                    let year = date.getFullYear();
    
                    key = day + " " + month + " " + year;
                    break;
    
                case "year":
                    key = dataPoint.dyear
                    break;
    
                case "month":
                    key = monthNames[dataPoint.dmonth] + " (" + dataPoint.dyear + ")"
                    break;
                
                default:
                    return;
            }
            newChartData[actionName][key] = dataPoint.total;
        });
    });

    let keys = [];

    chartData[longestAction].forEach( dataPoint => {
        let row = [];

        for ( let i=0; i<= Object.keys(chartData).length; i++) {
            row.push(0);
        }

        switch(sortBy) {
            case "day":
                let date = new Date(dataPoint.dday)
                let day = date.getDate();
                let month = monthNames[date.getMonth() + 1];
                let year = date.getFullYear();

                row[0] = day + " " + month + " " + year;
                keys.push(row[0]);
                sortedChartData.push(row);
                break;

            case "year":
                row[0] = dataPoint.dyear
                keys.push(row[0]);
                sortedChartData.push(row);
                break;

            case "month":
                row[0] = monthNames[dataPoint.dmonth] + " (" + dataPoint.dyear + ")"
                keys.push(row[0]);
                sortedChartData.push(row);
                break;
            
            default:
                return;
        }
    });
    

    let z = 1;
    Object.keys(newChartData).forEach( actionName => {
        keys.forEach( (key, index) => {
            if ( newChartData[actionName][key] ) {
                sortedChartData[(index + 1)][z] = parseInt(newChartData[actionName][key]);
            }
        });
        z++;
    });

    return sortedChartData;
}

function formatTopChartData(chartData, chartYTitle) {
    let sortedChartData = [[chartYTitle, chartYTitle]];

    chartData.forEach( (chartDataItem, index) => {
        if ( chartYTitle === "TopQualificationMatched" ) {
            sortedChartData[(index + 1)] = [chartDataItem.Name + " \n(" + chartDataItem.Institution + ")"];

        }else {
            sortedChartData[(index + 1)] = [chartDataItem.Name];
        }
        
    });

    chartData.forEach( (chartDataItem, index) => {
        sortedChartData[(index + 1)].push(parseInt(chartDataItem.total));
    });

    return sortedChartData;
}

function formatNumber( number ) {
    if ( number < 999 ) {
        return number;
    }

    if ( number < 1000000 ) {
        return (number/1000).toFixed(1) + "K"
    }

    if ( number < 1000000000 ) {
        return (number/1000000).toFixed(1) + "M"
    }

    if( number < 1000000000000 ) {
		return (number/1000000000).toFixed(1) + "B";
    }
    
    return "1T+";
}

export default helpers;
