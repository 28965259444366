import * as actionTypes from "../../actions/actionTypes";
import * as utilities from "../utilities";
// 
// High level page reducer : reducer function for high level attandance breakdown report page
// 

const initialState = {
    highLevelPageHeading: "High level attendance stats",
    eventDuration: "Event duration 3 Days",
    dataTeam: "Data team 11 local unemployed youth and students",
    gradesList: 
    `Grade 8s : 13
Grade 9s : 20
Grade 10s : 33
Grade 11s : 23
Grade 12s : 211`,
    schoolsList: 
`School Name 1
School Name 2
School Name 3
School Name 4
School Name 5
School Name 6
School Name 7`,
    eventAttendees: 100,
    totalGrade12: 100,
    totalSchools: 100
}

const highlevelPagereducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case actionTypes.UPDATE_TEXT_HIGH_LEVEL_PAGE:
            return utilities.handleTextOnChange(state, action);

        case actionTypes.SET_HIGH_LEVEL_EXPO_DATA:
            let totalGrade12;
            let eventAttendees = action.payload.expoInfo.total;
            let totalSchools = action.payload.expoInfo.schoolTotal;
            let gradesList = "";
            let schoolsList = "";

            action.payload.expoInfo.totalPerGrade.forEach( totalPerGrade => {
                switch( totalPerGrade.grade ) {
                    case "8":
                        gradesList += "Grade 8: " + totalPerGrade.total + "\n";
                        break;

                    case "9":
                        gradesList += "Grade 9: " + totalPerGrade.total + "\n";
                        break;

                    case "10":
                        gradesList += "Grade 10: " + totalPerGrade.total + "\n";
                        break;

                    case "11":
                        gradesList += "Grade 11: " + totalPerGrade.total + "\n";
                        break;

                    case "12":
                        gradesList += "Grade 12: " + totalPerGrade.total;
                        totalGrade12 = totalPerGrade.total;
                        break;
                }
            });

            action.payload.expoInfo.schools.forEach( school => {
                schoolsList += school["institution_name"] + "\n";
            });

            console.log(gradesList, schoolsList)


            return {
                ...state,
                eventAttendees: eventAttendees,
                totalGrade12: totalGrade12,
                totalSchools: totalSchools,
                gradesList: gradesList,
                schoolsList: schoolsList
            }
        
        default:
            return {
                ...state
            }
    }
}

export default highlevelPagereducer