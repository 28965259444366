import React from "react";
import ImageSlider from "react-simple-image-slider";
import configConstants from "../../../../../js/library/configConstants/configConstants";
// 
// Post item image component : componne t for image / image slider component
// 

// Styele
import "./postItemImage.css";

const postItemImage = ( props ) => {
    let images = props.images.split(",");
    let imageList = images.map( image => {
        return { url: configConstants.SERVER_NAME + "/images/posts/" + image }
    });

    console.log(imageList);

    return(
        <div className="image-slider">
            <ImageSlider 
                width={ 340 }
                height={ 255 }
                images={ imageList }
                showNavs={ false }
            />
        </div>
    ) 
}

export default postItemImage;