import React from "react";
import configConstants from "../../../../../../js/library/configConstants/configConstants";
import Yoututbe from "react-youtube";
import feedsService from "../../../../../../js/library/services/feedsservice";
// 
// Inline post component : content layuot of an inline post item
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../../../../hoc/wrap";
// postImageItem component
import PostImageItem from "../../postItemImage/postItemImage";

// Style
import "./inlinePost.css";

const inlinePost = ( props ) => {
    let postContent = "";

    if ( props.post.Text ) {
        postContent = <p className="post-text">{ props.post.Text.replace("$inline", "") }</p>;
    }

    if ( props.post.ImageMobile ) {
        postContent = (
            <Wrap>
                { postContent }
                <PostImageItem images={ props.post.ImageMobile } />
                {/* <img className="post-image" 
                    src={ configConstants.SERVER_NAME + "/images/posts/" + props.post.ImageMobile} 
                    alt="inline post image" 
                    width="340"
                /> */}
            </Wrap>
        )
    }

    if ( props.post.Text.indexOf("youtube") !== -1 ) {
        let videoInfo = feedsService.getVideoInfoFromPostText(props.post.Text);
        let videoOptions = {
            height: '190',
            width: '340',
            playerVars: {
                autoplay: 0
            }
        }

        postContent = (
            <Wrap>
                <p className="post-text">{ props.post.Text.replace("$inline", "").replace(videoInfo.videoUrl, "") }</p>
                <Yoututbe 
                    videoId={ videoInfo.videoId }
                    opts={ videoOptions }
                />
            </Wrap>
        );
    }

    if ( props.post.Text.indexOf("url") !== -1 ) {
        let postObj = {};

        try {
            postObj = JSON.parse(props.post.Text.replace("$inline", ""));
        } catch ( error ){
            console.log( props.post, error );
        }
        

        console.log(postObj);

        postContent = (
            <Wrap>
                <a className="post-link" href={ postObj.url } target="_blank">
                    <div dangerouslySetInnerHTML={{ __html: postObj.html }}></div>
                   { 
                       props.post.ImageMobile ? 
                       <PostImageItem images={ props.post.ImageMobile } />
                        // <img 
                        //         className="post-image"
                        //         src={ configConstants.SERVER_NAME + "/images/posts/" + props.post.ImageMobile} 
                        //         alt="inline post image" 
                        //         width="340"
                        //     /> 
                        : ''
                    }
                </a>
            </Wrap>
        );
    }

    return  postContent;

};

export default inlinePost;