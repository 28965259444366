import React from "react";
// 
// Statistics page component : page containing all stats related tools
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../hoc/wrap";
// Header component
import Header from "../../header/header";
// Toolbar component
import Toolbar from '../../UI/toolbar/toolbar';
// Main content wrap component
import MainContentWrap from '../../UI/mainContentWrap/mainContentWrap';
// User stats component
import UserStats from "../../../containers/statistics/userStats/userStats";

// Style
import "./statisticsPage.css";

const statisticsPage = ( props ) => (
    <Wrap>
        <Header 
            whichHeader="stats" 
            showToolsNav={ props.showToolsNav } 
            toggleToolsNav={ props.toggleToolsNav } 
            showProfileNav={ props.showProfileNav } 
            toggleProfileNav={ props.toggleProfileNav } 
            handleLogout={ props.handleLogout } 
        />
        <main className="stats-container">
            <Toolbar adminTool="stats" />
            <MainContentWrap>
                <UserStats />
            </MainContentWrap>
        </main>
    </Wrap>
);

export default statisticsPage;