import React from "react";
// 
// Components
// 

// UserInfo row
import UserInfoRow from "../usersInfoRow";
// Wrap component
import Wrap from "../../../../hoc/wrap";
// User access card component
import UserAccessCard from "./userAccessCard/userAcessCard";
// Loading data component
import LoadingData from "../../../../UI/loadingData/loadingData";
// Subject input component
import SubjectInput from "../../../../UI/subjectInput/subjectInput";
// Button component
import Button from "../../../../UI/button/button";
// Spinner component
import Spinner from "../../../../UI/spinner/spinner";
// User info input component
import UserInfoInput from "./userInfoInput/userInfoInput";

// Style
import "./userInfo.css";

const userInfo = ( props ) => {
    let accessCardList = [];
    let subjectInputList = [];
    let accessCardsSection;
    let userProfileInfo = "";
    let userRolesList = [];

    if ( props.userRoles.length > 0 ) {
        props.userRoles.forEach( userRoleId => {
            let userRoleInfo = props.allUserRoles.filter( userRole => {
                return userRole.ID === userRoleId
            });

            accessCardList.push(<UserAccessCard accessRightName={ userRoleInfo[0].Name } accessRightId={ userRoleInfo[0].ID } removeUserRole={ () => props.removeUserRole(userRoleInfo[0].ID) } />);
        });

        userRolesList = props.allUserRoles.map( userRole => {
            return <option value={ userRole.ID }>{ userRole.Name }</option>
        });

        userRolesList.splice(0, 1, <option value="-1">-- Select --</option>)

        accessCardsSection = (
            <div className="col-10">
                { accessCardList } 
            </div>
        );
    }
    
    if ( props.loadingUserInfo ) {
        userProfileInfo = <LoadingData title="fetching user info" />;

    } else {
        let saveMarksBtn = (
            <Wrap>
                <i className="fa fa-floppy-o" aria-hidden="true"></i>
                save marks
            </Wrap>
        )

        if ( props.savingMarks ) {
            saveMarksBtn = <Spinner />
        }

        let subjectInputList = Object.keys(props.subjectList).map( subjectKey => {
            let subject = props.subjectList[subjectKey];
            return <SubjectInput  
                        subject={ subject.name }
                        subjectLabel={ subjectKey }
                        mark={ subject.mark }
                        markLabel="Mark"
                        subjectInputChange={ props.subjectInputChange }
                        subjectSearchList={ props.subjectSearchList }
                        focusedInput={ props.focusedInput }
                    />
        });

        userProfileInfo = (
            <div className="col-12">
                <div className="row page-tool-heading">
                    <div className="col-12">
                        <h3>User Profile Info:</h3>
                    </div>
                </div>
                <section className="user-profile-details row no-gutters">
                    <div className="col-9">
                        <h6>Profile access rights</h6>
                    </div>
                    <div className="col-2">
                        <h6>new Profile</h6>
                    </div>
                    <div className="col-9">
                        <div className="row user-role-liest">
                            <div className="col-9">
                                { accessCardsSection }
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="filter-list-wrap">
                            <select className="roles-list" onChange={ event => props.setUserRole(event, props.userId) }>
                                { userRolesList }
                            </select>     
                        </div>
                    </div>
                </section>
                <section className="user-profile-details row">
                    <div className="col-12">
                        <h6>User profile information</h6>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-4">
                                <UserInfoInput 
                                    intputType="text"
                                    inputName="gradesMail"
                                    inputValue={ props.userInfoForm.gradesMail.inputValue }
                                    inputClass=""
                                    inputPlaceholder="gradesmail"
                                    inputOnChange={ props.userInfoInputChange }
                                    inputLabel="grades mail"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="user-profile-details row ">
                    <div className="col-12">
                        <h6>Subject marks</h6>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            { subjectInputList }
                        </div>
                    </div>
                    <div className="col-12">
                        <Button buttonClassName="save" clickHandler={ props.saveMarks }>
                            { saveMarksBtn }
                        </Button>
                    </div>
                </section>
            </div>
        );
    }

    return(
        <Wrap>
            <UserInfoRow 
                userName={ props.userInfo.userInfo.user.Name } 
                userSurname={ props.userInfo.userInfo.user.Surname } 
                userEmail={ props.userInfo.userInfo.user.Email ? props.userInfo.userInfo.user.Email : "" } 
                userPhoneNumber={ props.userInfo.userInfo.user.Phone ? props.userInfo.userInfo.user.Phone : "" } 
                key={ props.userInfo.userInfo.user.Phone  } 
                arrowDir={ "down" }
                index={ null }
                viewUserInfo={ props.viewUserInfo }
                userId={ props.userId }
                setUserRole={ props.setUserRole }
            />
            <div className="col-12 user-info">
                <div className="row justify-content-center">
                    { userProfileInfo }
                </div>
            </div>
        </Wrap>
    )
}

export default userInfo;