import HTTPRequest from "../HTTPConfig/axiosConfig";
import helpers from "../helpers/helpers";
// 
// Expo report tool services
// 

const expoReportService = {
    fetchExpoReportData,
}

function fetchExpoReportData( filter ) {
    let reportInfo = {};

    return HTTPRequest({
        method: "POST",
        url: "/adminstats/report",
        data: {
            source: filter.source,
            createdDateStart: filter.createdDateStart,
            createdDateEnd: filter.createdDateEnd
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            reportInfo = response.data.data;

        } else {
            reportInfo["error"] = true;
            reportInfo["errorMessage"] = response.data.reason;
        }

        return reportInfo;

    }).catch( error => {
        reportInfo["error"] = true;
        reportInfo["errorMessage"] = "Error fetching posts";
        return reportInfo;
    });
}

export default expoReportService;