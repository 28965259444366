import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
// 
// 
// 

// 
// Components
// 

// Image area component
import ImageArea from "../../../components/pages/expoReportPage/reportCopmposerControls/imageArea/imageArea";
// Text area component
import TextArea from "../../../components/pages/expoReportPage/reportCopmposerControls/textArea/textArea";
// Report footer
import ReportFooter from "../../../components/pages/expoReportPage/reportPages/reportFooter/reportFooter";

// 
// Assets
// 

// Background picture
import attendancePagePic from "../../../assets/images/attendancePage.png";
// Attendess icon
import attendeesIcon from "../../../assets/images/attendeesIcon.png";

// Style
import "./attendancePage.css";

class AttendancePage extends Component {

    componentDidMount() {
        this.props.setAttendanceDataExpo(this.props.expoInfo);
    }

    render() {

        let attendancePageImage = attendancePagePic;

        if ( this.props.attendancePageImage.previewURL !== "" ) {
            attendancePageImage = this.props.attendancePageImage.previewURL;
        }

        let pageHeadingStyle = {
            width     : "100%",
            fontSize  : "2em",
            fontWeight: "600",
            resize    : "none",
            marginTop : "1em",
            textAlign : "center",
        }

        let attendanceImageInfoStyle = {
            width          : "100%",
            fontSize       : "1.4em",
            fontWeight     : "600",
            resize         : "none",
            height         :  "4em", 
            textAlign      : "center",
            backgroundColor: "transparent",
            color          :  "white" 
        }

        let breakdownTitleStyle = {
            width          : "100%",
            fontSize       : "1.4em",
            marginTop      : "1.4em",
            fontWeight     : "600",
            resize         : "none",
            height         :  "4em", 
            backgroundColor: "transparent",
            color          : "white"
        }

        let attendeesTitleStyle = {
            width          : "100%",
            fontSize       : "1.6em",
            marginTop      : "1.4em",
            fontWeight     : "400",
            resize         : "none",
            height         :  "4em", 
            backgroundColor: "transparent",
            color          : "#f5bf31"
        }

        return (
            <div className="attendance-page">
                <div className="row">
                    <div className="col-6">
                        <div className="row page-title">
                        <TextArea 
                            textAreaName="attendancePageHeading"
                            textAreaValue={ this.props.pageHeading }
                            textAreaOnChange={ this.props.textAreaOnChange }
                            textAreaPlaceholder=""
                            textAreaCol="12"
                            style={ pageHeadingStyle }
                        />
                        </div>
                        <div className="row">
                            <ImageArea 
                                imageAreaCol="12"
                                imageAreaPreview={ attendancePageImage }
                                imageAreaName={ this.props.attendancePageImage.fileName }
                                imageUpload={ this.props.imageUpload }
                                imageAreaWidth="335em"
                                imageAreaHeight=""
                                imageAreaAlt="Background Picture"
                            />
                        </div>
                        <div className="row justify-content-center image-title">
                            <TextArea 
                                textAreaName="attendanceImageTitle"
                                textAreaValue={ this.props.imageTitle }
                                textAreaOnChange={ this.props.textAreaOnChange }
                                textAreaPlaceholder=""
                                textAreaCol="8"
                                style={ attendanceImageInfoStyle }
                            />
                        </div>
                    </div>
                    <div className="col-6 dark-bg">
                        <div className="row">
                            <TextArea 
                                textAreaName="attendeesBreakdown"
                                textAreaValue={ this.props.breakdownTitle }
                                textAreaOnChange={ this.props.textAreaOnChange }
                                textAreaPlaceholder=""
                                textAreaCol="12"
                                style={ breakdownTitleStyle }
                            />
                            <div className="col-12 attendees-breakdown-title">
                                <div className="row">
                                    <div className="col-4">
                                        <img src={ attendeesIcon } alt="attendees title" />
                                    </div>
                                    <TextArea 
                                        textAreaName="youthAttendees"
                                        textAreaValue={ this.props.youthAttendees }
                                        textAreaOnChange={ this.props.textAreaOnChange }
                                        textAreaPlaceholder=""
                                        textAreaCol="8"
                                        style={ attendeesTitleStyle }
                                    />
                                </div>
                            </div>
                            <div className="col-12 attendees-breakdown-title">
                                <div className="row">
                                    <div className="col-4">
                                        <img src={ attendeesIcon } alt="attendees title" />
                                    </div>
                                    <TextArea 
                                        textAreaName="DETAttendees"
                                        textAreaValue={ this.props.DETAttendees }
                                        textAreaOnChange={ this.props.textAreaOnChange }
                                        textAreaPlaceholder=""
                                        textAreaCol="8"
                                        style={ attendeesTitleStyle }
                                    />
                                </div>
                            </div>
                            <div className="col-12 attendees-breakdown-title">
                                <div className="row">
                                    <div className="col-4">
                                        <img src={ attendeesIcon } alt="attendees title" />
                                    </div>
                                    <TextArea 
                                        textAreaName="FETAttendees"
                                        textAreaValue={ this.props.FETAttendees }
                                        textAreaOnChange={ this.props.textAreaOnChange }
                                        textAreaPlaceholder=""
                                        textAreaCol="8"
                                        style={ attendeesTitleStyle }
                                    />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <ReportFooter />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        pageHeading: state.attendancePage.attendancePageHeading,
        imageTitle: state.attendancePage.attendanceImageTitle,
        breakdownTitle: state.attendancePage.attendeesBreakdown,
        youthAttendees: state.attendancePage.youthAttendees,
        DETAttendees: state.attendancePage.DETAttendees,
        FETAttendees: state.attendancePage.FETAttendees,
        attendancePageImage: state.attendancePage.attendancePageImage,
        expoInfo: state.expoReport.reportInfo,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        textAreaOnChange: (event) => dispatch({type: actionTypes.UPDATE_TEXT_ATTENDANCE_PAGE, payload: { event: event }}),
        imageUpload: (event) => dispatch({ type: actionTypes.UPLOAD_IMAGE_ATTENDANCE_PAGE, payload: { event: event }}),
        setAttendanceDataExpo: (expoInfo) => dispatch({type: actionTypes.SET_ATTENDANCE_EXPO_DATA, payload: { expoInfo: expoInfo }})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendancePage)