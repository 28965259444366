import React from 'react';
// 
// Button component : button layout component
// 

// Style
import './button.css';

const button = ( props ) => {
    const defaultButtonStyle = {
            fontSize     : '1.0875em',
            textTransform: 'capitalize', 
            fontWeight   : '700', 
            height       : '2em', 
            borderRadius : '1em',
            outline      : 'none'
        }

    let buttonContent = ""

    if ( props.buttonTitle ) {
        buttonContent = props.buttonTitle;
    } else {
        buttonContent = props.children;
    }

    return (
        <button className={ props.buttonClassName } style={ defaultButtonStyle } onClick={ props.clickHandler } >
            { buttonContent }
        </button>
    );
};

export default button;