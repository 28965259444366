import React from "react";
import { Chart } from "react-google-charts";
// 
// Bar chart component : bar chart graph component used inb stats page
// 

// 
// Components
// 

// Chart sort filter component
import ChartSortFilter from "../chartSortFilter/chartSortFilter";
// Chart no info component
import ChartNoInfo from "./chartNoInfo/chartNoInfo";

// Style
import "./barChart.css";

const barChart = ( props ) => {
    let barChart = "";

    switch( props.tileTitle ) {
        case "qualifications":
        case "careers":
        case "bursaries":
        case "users":
        case "home page visits":
            if ( props.chartHasData ) {
                barChart = (
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <Chart
                                width={'100%'}
                                height={'500px'}
                                chartType="Bar"
                                loader={<div>Loading Chart</div>}
                                data={ props.chartData }
                                options={{
                                    chart: {
                                    title: props.tileTitle ,
                                    subtitle: props.tileSubtitle,
                                    }
                                }}
                            />
                        </div>
                        <div className="col-10">
                            <ChartSortFilter sortFilterOption={ props.chartSortFilter } setSortFilterOption={ props.setSortFilterOption } />
                        </div>
                    </div>
                );

            } else {
                barChart = <ChartNoInfo />
            }
            break;

        case "top 5": 
            barChart = props.chartData.map( (chartDataItem, index) => {
                let chartColor = "";
                let chartTitle = "";
                let chartSubTitle = "";
                let chartHasData;
                let chart = "";

                if ( index === 0 ) {
                    chartColor = "#EEC751";
                    chartTitle = " Top 5 Qualifications Matched";
                    chartSubTitle = " Table illustrating the trend on usage activity of users with regards to qualifications matched on the platform.";
                    chartHasData = props.topFiveQHasData;

                } else {
                    chartColor = "#789055";
                    chartTitle = " Top 5 Careers Matched";
                    chartSubTitle = " Table illustrating the trend on usage activity of users with regards to careers matched on the platform.";
                    chartHasData = props.topFiveCHasData;
                }

                console.log("chartDataItem", chartDataItem)

                if ( chartHasData ) {
                    chart = (
                        <div className="row justify-content-center bar-chart-graph" key={ index }>
                            <div className="col-10">
                                <Chart
                                    width={'100%'}
                                    height={'500px'}
                                    chartType="Bar"
                                    loader={<div>Loading Chart</div>}
                                    data={ chartDataItem }
                                    options={{
                                        chart: {
                                        title: chartTitle,
                                        subtitle: chartSubTitle,
                                        },
                                        series: {
                                            0: { color: chartColor },
                                        },
                                        bars: "horizontal",
                                    }}
                                />
                            </div>
                        </div>
                    );

                } else {
                    chart = <ChartNoInfo key={ index }/>
                }

                return chart;               
            });

            break;
    }

    return(
        <div className="bar-chart">
            <h4>{ "detailed stats on " + props.tileTitle }</h4>
            { barChart }
        </div>
    );
};

export default barChart;