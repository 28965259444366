import React from "react";
// 
// Docs list titles : table titles for applications documents
// 

// Style
import "./docsListTitles.css";

const docsListTitles = ( props ) => (
    <div className="col-11">
        <div className="row docs-list-titles">
            <div className="col-1">
                <input type="checkbox" />
            </div>
            <div className="col-8">
                <h5>document</h5>
            </div>
            <div className="col-3">
                <h5>document actions</h5>
            </div>
        </div>
    </div>
);

export default docsListTitles;