import React from 'react';
import { NavLink } from 'react-router-dom';
// 
// Admin tool component: admin tool link layout component
// 

// Style
import './adminTool.css';

const adminTool = ( props ) => (
    <NavLink className="admin-tool" to={ "/" + props.adminToolpage }>
        <i className={ "fa fa-" + props.adminToolIcon } aria-hidden="true"></i>
        <span>{ props.adminToolTitle }</span>
    </NavLink>
);

export default adminTool;