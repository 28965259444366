import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
// 
// Insights page component : report insights page component
// 

// 
// Components
// 

// Text area component
import TextArea from "../../../components/pages/expoReportPage/reportCopmposerControls/textArea/textArea";
// Report footer
import ReportFooter from "../../../components/pages/expoReportPage/reportPages/reportFooter/reportFooter";

// Style
import "./usefulnessPage.css";

class UsefulnessPage extends Component {
    render() {

        let pageHeadingStyle = {
            width     : "100%",
            fontSize  : "2em",
            fontWeight: "600",
            resize    : "none",
            marginTop : "1em",
            textAlign : "center",
        }

        let usefulnessHeadingStyle = {
            width          : "100%",
            fontSize       : "1em",
            fontWeight     : "600",
            resize         : "none",
            height         :  "3.15em", 
            backgroundColor: "transparent",
            color          : "white",
        }

        let usefulnessBodyStyle = {
            width          : "100%",
            fontSize       : "1em",
            fontWeight     : "400",
            resize         : "none",
            height         :  "13.5em", 
            backgroundColor: "transparent",
            color          : "white",
        }

        let usefulnessBlocks = Object.keys(this.props.usefulnessBlock).map( (usefulnessKey, index) => {
            return (
                <div className={ "col-2 usefulness-block " + usefulnessKey } key={ usefulnessKey }>
                    <div className="row no-gutters">
                        <TextArea 
                            textAreaName={ usefulnessKey + "-Heading"}
                            textAreaValue={ this.props.usefulnessBlock[usefulnessKey].usefulnessHeading }
                            textAreaOnChange={ this.props.textAreaOnChange }
                            textAreaPlaceholder=""
                            textAreaCol="12"
                            style={ usefulnessHeadingStyle }
                        />
                    </div>
                    <div className="row no-gutters">
                        <TextArea 
                            textAreaName={ usefulnessKey + "-Body" }
                            textAreaValue={ this.props.usefulnessBlock[usefulnessKey].usefulnessBody }
                            textAreaOnChange={ this.props.textAreaOnChange }
                            textAreaPlaceholder=""
                            textAreaCol="12"
                            style={ usefulnessBodyStyle }
                        />
                    </div>
                </div>
                );
        })

        return (
           <div className="usefulness-page">
                <div className="row page-title">
                    <TextArea 
                        textAreaName="usefulnessPageHeading"
                        textAreaValue={ this.props.pageHeading }
                        textAreaOnChange={ this.props.textAreaOnChange }
                        textAreaPlaceholder=""
                        textAreaCol="6"
                        style={ pageHeadingStyle }
                    />
                </div>
                <div className="row justify-content-center">
                     { usefulnessBlocks }
                </div>
                <ReportFooter />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        pageHeading: state.usefulnessPage.usefulnessPageHeading,
        usefulnessBlock: state.usefulnessPage.usefulnessBlocks,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        textAreaOnChange: (event) => dispatch({ type: actionTypes.UPDATE_TEXT_USEFULNESS_PAGE, payload: { event: event }}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsefulnessPage);