import HTTPRequest from "../HTTPConfig/axiosConfig";
import helpers from "../helpers/helpers";

const viewUserService = {
    fetchMoreUsers,
    getUserRoles,
    setUserRoles,
    getUserSubjects,
    getDefaultTerms,
    saveMarks,
    USER_ROLES_LIST: [],
    CURRENT_TERM: "",
    TERM_YEARS: []
}

function fetchMoreUsers( filterObject, fetchIndex ) {
    let users = {};

    return HTTPRequest({
        method: "POST",
        url: "adminusers/find/limit-10-index-" + fetchIndex,
        data: {
            filters: filterObject
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            users = response.data.data;

        } else {
            users["error"] = true;
            users["errorMessage"] = response.data.reason;
        }
        return users;

    }).catch( error => {
        users["error"] = true;
        users["errorMessage"] = "Error fetching more users";
        return users;
    });
}

function getUserRoles( userId ) {
    let userRoles = [];

    return HTTPRequest({
        method: "POST",
        url: "account/getuserroles",
        data: {
            userId: userId
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            userRoles = response.data.data.userRoles;
            this.USER_ROLES_LIST = response.data.data.allRoles;

        } else {
            userRoles[0] = "error";
            userRoles[1] = response.data.reason;
        }
        return userRoles;

    }).catch( error => {
        userRoles[0] = "error";
        userRoles[1] = "Error fetching user roles";
        return userRoles;
    })
}

function setUserRoles( userId, roles) {
    return HTTPRequest({
        method: "POST",
        url: "account/setuserroles",
        data: {
            userId: userId,
            roles: roles
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            return true;

        } else {
            return false;
        }

    }).catch( error => {
        return false;
    })
}

function getUserSubjects( userId, year, term ) {
    let userSubjects = [];

    return HTTPRequest({
        method: "POST",
        url: "adminusers/getmarks",
        data: {
            userId: userId,
            year: year,
            term: term
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            userSubjects = response.data.data;

        } else {
            userSubjects[0] = "error";
            userSubjects[1] = response.data.reason;
        }
        return userSubjects;

    }).catch( error => {
        userSubjects[0] = "error";
        userSubjects[1] = "Error fetching user subjects";
        return userSubjects;
    });
}

function getDefaultTerms() {
    let defaultTerms = {};

    return HTTPRequest({
        method: "POST",
        url: "subjects/defaultterms",
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            defaultTerms = response.data.data;
            this.TERM_YEARS = response.data.data.years;
            this.CURRENT_TERM = response.data.data.term;

        } else {
            defaultTerms["error"] = true;
            defaultTerms["errorMessage"] = response.data.reason;
        }
        return defaultTerms; 

    }).catch( error => {
        defaultTerms["error"] = true;
        defaultTerms["errorMessage"] = "Error fetching defualt terms";

        return defaultTerms;
    })
}

function saveMarks(marks, userId, termYear, term) {
    return HTTPRequest({
        method: "POST",
        url: "adminusers/savemarks",
        data: {
            marks: marks,
            userId: userId,
            year: termYear,
            term: term
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            return true;

        } else {
            return false;
        }

    }).catch( error => {
        return false;
    })
}

export default viewUserService;