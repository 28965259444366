import React from "react";
// 
// Loading data component : component showing spinner when data is being fetched from backend
// 

// 
// Components
// 

// Spinner component
import Spinner from "../spinner/spinner";
// Button component
import Button from "../button/button";

// Style
import "./loadingData.css";

const loadingData = ( props ) => (
    <div className="col-8 loading-data">
        <Button>
            <Spinner />
        </Button>
        <p>{ props.title }</p>
    </div>
);

export default loadingData;