import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
// 
// Content page component : report table of content page
// 

// 
// Components
// 

// Text area ocomponent
import TextArea from "../../../components/pages/expoReportPage/reportCopmposerControls/textArea/textArea";
// Report footer comopnent
import ReportFooter from "../../../components/pages/expoReportPage/reportPages/reportFooter/reportFooter";

// 
// Assets
// 

// Character 2 figure
import Character from "../../../assets/images/character-2.png";

// Style
import "./contentsPage.css";

class ContentsPage extends Component {
    render() {
        let pageHeadingStyle = {
            width     : "100%",
            fontSize  : "2em",
            fontWeight: "600",
            resize    : "none",
            marginTop : "1em",
            textAlign : "center",
        }

        let contentsStyle ={
            width     : "100%",
            fontSize  : "1.5em",
            fontWeight: "400",
            resize    : "none",
            textAlign : "left",
            color     : "white",
            background: "transparent"
        }

        let contentsTitleStyle = {
            width     : "100%",
            fontSize  : "1.5em",
            fontWeight: "600",
            resize    : "none",
            textAlign : "left",
            color     : "white",
            background: "transparent"
        }

        return(
            <div className="contents-page">
                <div className="row page-heading">
                    <div className="col-6">
                        <TextArea 
                            textAreaName="contentsPageHeading"
                            textAreaValue={ this.props.pageHeading }
                            textAreaOnChange={ this.props.textAreaOnChange }
                            textAreaPlaceholder=""
                            style={pageHeadingStyle}
                        />
                    </div>
                </div>
                <div className="row">
                     <div className="col-5">
                        <img src={ Character } width="350em" alt="Gradesmatch figure" />
                     </div>
                     <div className="col-7">
                        <ul>
                            <li className="title">
                                <TextArea 
                                    textAreaName="contentsTitle"
                                    textAreaValue={ this.props.contentsTitle }
                                    textAreaOnChange={ this.props.textAreaOnChange }
                                    textAreaPlaceholder=""
                                    style={ contentsTitleStyle }
                                />
                            </li>
                            <li className="contents1">
                                <TextArea 
                                    textAreaName="contents1"
                                    textAreaValue={ this.props.contents1 }
                                    textAreaOnChange={ this.props.textAreaOnChange }
                                    textAreaPlaceholder=""
                                    style={ contentsStyle }
                                />
                            </li>
                            <li className="contents2">
                                <TextArea 
                                    textAreaName="contents2"
                                    textAreaValue={ this.props.contents2 }
                                    textAreaOnChange={ this.props.textAreaOnChange }
                                    textAreaPlaceholder=""
                                    style={ contentsStyle }
                                />
                            </li>
                            <li className="contents3">
                                <TextArea 
                                    textAreaName="contents3"
                                    textAreaValue={ this.props.contents3 }
                                    textAreaOnChange={ this.props.textAreaOnChange }
                                    textAreaPlaceholder=""
                                    style={ contentsStyle }
                                />
                            </li>
                            <li className="contents4">
                                <TextArea 
                                    textAreaName="contents4"
                                    textAreaValue={ this.props.contents4 }
                                    textAreaOnChange={ this.props.textAreaOnChange }
                                    textAreaPlaceholder=""
                                    style={ contentsStyle }
                                />
                            </li>
                            <li className="contents5">
                                <TextArea 
                                    textAreaName="contents5"
                                    textAreaValue={ this.props.contents5 }
                                    textAreaOnChange={ this.props.textAreaOnChange }
                                    textAreaPlaceholder=""
                                    style={ contentsStyle }
                                />
                            </li>
                        </ul>
                     </div>
                </div>
                <ReportFooter />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        pageHeading: state.contentsPage.contentsPageHeading,
        contentsTitle: state.contentsPage.contentsTitle,
        contents1: state.contentsPage.contents1,
        contents2: state.contentsPage.contents2,
        contents3: state.contentsPage.contents3,
        contents4: state.contentsPage.contents4,
        contents5: state.contentsPage.contents5,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        textAreaOnChange: (event) => dispatch({type: actionTypes.UPDATE_TEXT_CONTENTS_PAGE, payload: { event: event }})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentsPage);