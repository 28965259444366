import React from "react";
// 
// Applications list titles component
// 

// 
// components
// 

// Wrap comopnent
import Wrap from "../../../../../hoc/wrap";

// Style
import "./applicationsListTitles.css";

const applicationsListTitles = ( props ) => {
    let statusList = props.statusList.map( status => {
        return <option key={ status.ID }>{ status.Name }</option>;
    });

    statusList.splice(0, 0, <option key={ 0 }>Status</option>);

    return(
        <div className="col-11">
            <div className="row application-list-titles">
                <div className="col-7">
                    <h5>bursary name</h5>
                </div>
                {
                    !props.newBursary ? 
                    <Wrap>
                        <div className="col-2">
                            {/* <h5>closing date</h5> */}
                        </div>
                        <div className="col-3">
                            <div className="form-select-wrap">
                                <select value="Status" onChange={ event => props.updateAllBursaryStatus(event) }>
                                    { statusList }
                                </select>
                            </div>
                        </div>
                    </Wrap>
                    : ""
                }
                
            </div>
        </div>
    ) ;
}

export default applicationsListTitles