import HTTPRequest from "../HTTPConfig/axiosConfig";
import helpers from "../helpers/helpers";
// 
// Student info service : API calls in add student component
// 

const studentInfoService = {
    setSrouceExtProperties,
    getSrouceExtProperties,
    saveLearnerExtProperties,
    getSourceList
}

function setSrouceExtProperties( source, extProperties ) {
    let isExtPropertiesSet = false;

    return HTTPRequest({
        method: "POST",
        url: "Account/SetSourceExtProperties",
        data : {
            data: extProperties,
            source: source
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        console.log(response.data.data);

        if (response.data.success) {
            isExtPropertiesSet = true;
        }
        return isExtPropertiesSet;

    }).catch( error => {
        return isExtPropertiesSet
    });
}

function getSrouceExtProperties( source ) {
    let extProperties = {};

    return HTTPRequest({
        method: "POST",
        url: "Account/GetSourceExtProperties",
        data: {
            source: source
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            extProperties = response.data.data;

        } else {
            extProperties["error"] = true;
            extProperties["errorMessage"] = response.data.reason;
        }
        console.log(extProperties);
        return extProperties;

    }).catch( error => {
        extProperties["error"] = true;
        extProperties["errorMessage"] = "error fetching extPropertiesSet";

        return extProperties;
    });
}

function saveLearnerExtProperties ( extProperties, userToken ) {
    let extPropertiesSave = false;

    return HTTPRequest({
        method: "POST",
        url: "Me/SetExtProperties",
        data: {
            data: extProperties
        },
        headers: { "Authorization": "Bearer " + userToken }

    }).then( response => {
        if ( response.data.success ) {
            extPropertiesSave = true;

        } else {
            extPropertiesSave = false;
        }
        return extPropertiesSave;

    }).catch( error => {
        return extPropertiesSave;
    })
}

function getSourceList( source ) {
    let sourceList = [];

    return HTTPRequest({
        method: "POST",
        url: "account/searchsource",
        data: {
            source: source
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            sourceList = response.data.data;

        } else {
            sourceList["error"] = true;
            sourceList["errorMessage"] = response.data.reason;
        }
        return sourceList;

    }).catch( error => {
        sourceList["error"] = true;
        sourceList["errorMessage"] = "Error fetching source list";
        return sourceList;
    });
}

export default studentInfoService;