// 
// Background page reducer : Dispatched actions for contents page content change
// 

// Action types
import * as actionTypes from "../../actions/actionTypes";
// Utitlies
import * as utilities from "../utilities";
const initialState = {
    attendancePageHeading: "High level attendance stats",
    attendeesBreakdown: "Attendees Breakdown",
    youthAttendees: "8 562 learners in High School",
    DETAttendees: "6 436  leaners in DET",
    FETAttendees: "500 leaners in FET",
    attendanceImageTitle: "EThekwini Municipality",
    attendancePageImage: {
        file: "",
        previewURL: "",
        fileName: ""
    }
}

const attendancePageReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.UPDATE_TEXT_ATTENDANCE_PAGE:
            return utilities.handleTextOnChange(state, action);

        case actionTypes.UPLOAD_IMAGE_ATTENDANCE_PAGE:
            return utilities.handleImageUpload(state, action, "attendancePageImage");

        case actionTypes.SET_ATTENDANCE_EXPO_DATA:
            let DETAttendees = 0;
            let FETAttendees = 0;
            let total = 0;

            action.payload.expoInfo.totalPerGrade.forEach( totalPerGrade => {
                total += parseInt(totalPerGrade.total);

                switch( totalPerGrade.grade ) {
                    case "8":
                    case "9":
                        DETAttendees += parseInt(totalPerGrade.total);
                        break;
                    case "10":
                    case "11":
                    case "12":
                        FETAttendees += parseInt(totalPerGrade.total);
                        break;
                }
            });

            return {
                ...state,
                DETAttendees: DETAttendees + " leaners in DET",
                FETAttendees: FETAttendees + " leaners in FET",
                youthAttendees: total + " leaners in high school"
            }

        default:
            return {
                ...state
            }
    }
}

export default attendancePageReducer;