import React from "react";
// 
// Document info row component
// 

// Style
import "./docInfoRow.css";

const docInfoRow = ( props ) => (
    <div className="col-12 info-row">
        <div className="row no-gutter align-items-center">
            <div className="col-3 info-row-item">
                <input 
                    type="text" 
                    name={ "name_" + props.infoRowId }  
                    value={ props.infoRowName } 
                    onChange={ props.inputOnChange } 
                    placeholder="Document Name"
                />
            </div>
            <div className="col-5 info-row-item">
                <input 
                    type="text" 
                    name={ "description_" + props.infoRowId }  
                    value={ props.infoRowDescription } 
                    onChange={ props.inputOnChange } 
                    placeholder="Document Description"
                />
            </div>
            <div className="col-2 info-row-item">
                <input 
                    type="text" 
                    name={ "color_" + props.infoRowId }  
                    value={ props.infoRowColor } 
                    onChange={ props.inputOnChange } 
                    placeholder="Color Name"
                />
            </div>
            <div className="col-1 info-row-item">
                <select name={ "required_" + props.infoRowId } value={ props.infoRowRequired } onChange={ props.inputOnChange }>
                    <option>Yes</option>
                    <option>No</option>
                </select>
            </div>
            <div className="col-1 info-row-item">
                <a href="#" className={ (props.saveEnabled ? " active": "") } onClick={ props.saveEnabled ? (event) => props.updateRowInfo(event, props.infoRowId) : null } >
                    <i className="fa fa-floppy-o" aria-hidden="true"></i>
                </a>
            </div>
        </div>
    </div>
);

export default docInfoRow;