import React from "react";
// 
// New application institution componenet
// 

// 
// components
// 

// Wrap component
import Wrap from "../../../../../hoc/wrap";
// Button component
import Button from "../../../../../UI/button/button";
// Table Titles component
import TableTitles from "../tableTitles/tableTitles";
// Institution item component
import InstitutionItem from "../../institutionItem/institutionItem";
// New application qualifications
import NewApplicationQualifications from "./newApplicationQualifications/newApplicationQualifications";

// Style
import "./newApplicationInstitution.css";

const newApplicationInstitution = ( props ) => {
    let content = "";
    let applicationInstitutions = [];

    if ( props.showNewApplicationInstitutionsList ) {
        if ( props.newApplicationInstitutions.length > 0 ) {
            applicationInstitutions = props.newApplicationInstitutions.map( (institution,index) => {
                return <InstitutionItem 
                            key={ index }
                            institutionName={ institution.name } 
                            newInstitutionList={ props.newInstitutionList } 
                            searchInstitution={ props.searchInstitution }
                            searchInstitutionText={ props.searchInstitutionText }
                            setSelectedInstitution={ props.setSelectedInstitution }
                            showInstitutionApplications={ props.showInstitutionApplications }
                            position={ index }
                            showDelete
                            deleteNewInstitution={ props.deleteNewInstitution }
                        />
            });
        }

        content = (
            <Wrap>
                {
                    props.newApplicationInstitutions.length > 0 ? <TableTitles /> : ""
                }
                { applicationInstitutions }
                <Button buttonClassName="add-new-institution" clickHandler={ () => props.addNewApplicationInstitution("", "") }>
                    <i className="fa fa-plus"></i>
                    add new institution
                </Button>
            </Wrap>
        )

    } else {
        content = (
            <Wrap>
                <InstitutionItem 
                    institutionName={ props.institutionName } 
                    showApplicationsList={ props.hideInstitutionApplications } 
                    active 
                    showDelete
                    deleteNewInstitution={ props.deleteNewInstitution }
                />
                <NewApplicationQualifications 
                    applicationQualifications={ props.viewedApplications } 
                    institutionName={ props.institutionName } 
                    leanerName={ props.learnerName } 
                    addNewApplicationQualification={ props.addNewApplicationQualification }
                    searchQualification={ props.searchQualification }
                    searchQualificationText={ props.searchQualificationText }
                    qualificationsList={ props.qualificationsList }
                    setSelectedQaulification={ props.setSelectedQaulification }
                    deleteNewQualification={ props.deleteNewQualification }
                    instiutionPosition={ props.institutionPosition }
                />
            </Wrap>
        )
    }

    return content;
}

export default newApplicationInstitution;