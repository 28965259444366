import React from 'react';
// 
// Lessons info component: module info table row lessons  
// 

// 
// Components
// 

// Wrap component
import Wrap from '../../../hoc/wrap';
// Module info component
import ModuleInfo from '../moduleInfo/infoRow';
// Lesson inf component
import LessonInfo from '../moduleInfo/infoRow';
// Modules tables titles component
import InfoTableTitles from '../infoTableTitles/infoTableTitles';
// Slides info component
import SlidesInfo from '../slidesInfo/slidesInfo';
// Loading data component
import LoadingData from "../../../UI/loadingData/loadingData";

// Style
import './lessonsInfo.css';


const lessonsInfo = ( props ) => {
    let viewedLesson;
    let lessonView;

    if ( props.viewedSlides !== 0 ) {
        viewedLesson = props.viewedlessons.filter( lesson => {
            return lesson.ID === props.viewedSlides;
        });

        lessonView = <Wrap>
                        <LessonInfo 
                            index={ 0 }
                            infoRowId={ viewedLesson[0].ID }
                            infoRowName={ viewedLesson[0].Name }
                            infoRowDetails={ "slides: " }
                            noMarginBottom
                            showHideInfoRowDetails={ event => props.showHideLessonsInfoDetails( event, 0 ) }
                            viewInfoButtonArrow={ "down" }
                        />
                        <SlidesInfo 
                            lessonName={ viewedLesson[0].Name } 
                            lessonId={ viewedLesson[0].ID }
                            slidesCount={ props.slides["slide-" + viewedLesson[0].ID].length }
                            slideAsString={ props.modifiedSlides["slide-" + viewedLesson[0].ID].slides }
                            slideId={ "slide-" + viewedLesson[0].ID }
                            slideEditState={ props.modifiedSlides["slide-" + viewedLesson[0].ID].editState }
                            handleUpdateSlides={ props.handleUpdateSlides }
                            handleInputOnChange={ event => props.handleSlideInputOnChange( event ) }
                        />
                     </Wrap>;

    } else {
        if ( Object.keys(props.slides).length > 0 ) {
            lessonView = props.viewedlessons.map( (lesson, index) => {
            return <LessonInfo 
                        index={ index }
                        infoRowId={ "lesson-name-" + lesson.ID }
                        infoRowName={ props.modifiedLessonsNames["lesson-name-" + lesson.ID].name }
                        infoRowDetails={ "slides: " + props.slides["slide-" + lesson.ID].length }
                        key={ lesson.ID }
                        inputOnChange={ props.inputOnChange }
                        showHideInfoRowDetails={ event => props.viewLessonsSlides( event, lesson.ID) }
                        viewInfoButtonArrow={ "right" }
                        inputPlaceHolder="New Lesosn Name"
                        isDraggable
                        saveButtonState={ props.modifiedLessonsNames["lesson-name-" + lesson.ID].editState }
                        handleUpdateRowInfo={ props.modifiedLessonsNames["lesson-name-" + lesson.ID].newLessonSave ?  event => props.handleSaveLesson( event, lesson.ID ) : props.modifiedLessonsNames["lesson-name-" + lesson.ID].editState ? event => props.handleUpdateLesson( event, lesson.ID ) : null }
                        handleDeleteRowInfo={ event => props.handleDeleteLesson( event, lesson.ID, props.moduleId ) }
                    />

        }); } else {
            lessonView = <LoadingData title="fetching lesson info" />
        }
    }
    
    return (
        <Wrap>
            <ModuleInfo 
                index={ 0 }
                infoRowName={ props.moduleName }  
                infoRowId={ "module-name-" + props.moduleId } 
                inputOnChange={ props.handleInputOnChange } 
                infoRowDetails={ props.moduleDetails }
                noMarginBottom
                showHideInfoRowDetails={ event => props.showHideInfoRowDetails( event, 0 ) }
                viewInfoButtonArrow={ props.viewInfoButtonArrow }
            />
            <div className="lessons-info-wrap col-12">
                <div className="page-tool-heading row align-items-center justify-content-between">
                    <div className="col-7">
                        <h3>{"lessons in " + props.moduleName + " module"} </h3>
                    </div>
                    <div className="col-4">
                        <p>{ props.viewedlessons.length + " lesson" + ( props.viewedlessons.length > 1 ? "s" : "" ) + " found" }</p>
                    </div>
                </div>
                <section className="lessons-table-heading row">
                    <div className="col-12">
                        <InfoTableTitles tableItemTitle="lesson name" />
                    </div>
                </section>
                <section className="lessons-table-rows row" onDragOver={ event => props.handleItemDragOver( event ) }>
                    { lessonView }
                </section>
            </div>
        </Wrap>
    );
};

export default lessonsInfo;