import React from "react";
// 
// Status card component: application status card inside dahsboard in messenger
// 

// Style
import "./statusCard.css";

const statusCard = ( props ) => {
    return (
        <div className="status-card">
            <div className={"status " + props.statusColor}></div>
            <div className="status-info">
                <p className="institution-name">{ props.institutionName }</p>
                <div className="status-info-wrap">
                    <i className={"fa fa-" + props.statusIconName + " " + props.statusColor}></i>
                    <p className="status-title">{ props.statusTitle }</p>
                    <p className="status-text">{ props.statusText }</p>
                </div>
            </div>
        </div>
    );
}

export default statusCard;