import HTTPRequest from "../HTTPConfig/axiosConfig";
import helpers from "../helpers/helpers";
// 
// Feeds tool services
// 

const feedsservice = {
    fetchPosts,
    shareNewPost,
    attachePostImage,
    getVideoInfoFromPostText,
    showHidePost,
    updatePlaceholderSetting
}

function fetchPosts( index ) {
    let postsInfo = {};

    return HTTPRequest({
        method: "POST",
        url: "posts/get/limit-10-index-" + index,
        data: {
            destinationType: "public",
            destinationId: 0
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            postsInfo = response.data.data;

        } else {
            postsInfo["error"] = true;
            postsInfo["errorMessage"] = response.data.reason;
        }

        return postsInfo;

    }).catch( error => {
        postsInfo["error"] = true;
        postsInfo["errorMessage"] = "Error fetching posts";
        return postsInfo;
    });
}

function shareNewPost(feedType, postInfo) {
    let postType = "";
    let postText = "";
    let feedPosted = {}

    switch( feedType.toLowerCase() ) {
        case "inline":
            postType = "$inline ";
            postText = postInfo.text;
            break;

        case "nedbank": 
            postType = "$nedbank ";
            postText = "<h3>" + postInfo.heading + "</h3>" + " <p>" + postInfo.text + "</p>$article";
            break;

        case "video":
            postType = "$rndvideo ";
            postText = postInfo.text;
            break;

        case "article":
            postText = "$article ";
            postText = postInfo.text;
            break;

    }   

    if ( postInfo.video && postInfo.video !== "" ) {
        postText = postText + " " + postInfo.video;
    }

    return HTTPRequest({
        method: "POST",
        url: "posts/create",
        data: {
            text: postType + postText,
            destinationType: "public",
            destinationId: 0,
            hasImage: postInfo.image ? true : false,
            internaltext: postInfo.link ? postInfo.link : ""
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            feedPosted = response.data.data;

        } else {
            feedPosted["error"] = true;
            feedPosted["errorMessage"] = response.data.reason;
        }
        return feedPosted

    }).catch(error => {
        feedPosted["error"] = true;
        feedPosted["errorMessage"] = "Error posting";
        return feedPosted;
    });
}

function attachePostImage(postId, image) {
    let postedFile = {};
    let formData = new FormData();
    let data = {
        "postId": postId,
        "isMobile": true
    }

    formData.append("data", JSON.stringify(data));
    formData.append("image", image);

    return HTTPRequest({
        method: "POST",
        url: "posts/attachimage",
        data: formData,
        headers: helpers.getFileAuthorizationsHeader()
    
    }).then( response => {
        if(response.data.success) {
            postedFile = response.data.data

        } else {    
            postedFile["error"] = true;
            postedFile["errorMessage"] = response.data.reason;
        }
        return postedFile;

    }).catch( error => {
        postedFile["error"] = true;
        postedFile["errorMessage"] = "Error posting image";
    });
}

function showHidePost( postId ) {
    let postHidden = "";

    console.log(postId);

    return HTTPRequest({
        method: "POST",
        url: "posts/hidepost",
        data: {
            postId: postId
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if (response.data.success) {
            postHidden = response.data.data;

        } else {
            postHidden = response.data.reason
        }
        return postHidden;

    }).catch( error => {
        return postHidden = "Error hiding post";
    });
}

function getVideoInfoFromPostText(text) {
    const re = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/;

    if (re.exec(text) && re.exec(text)['0']) {
      let url = re.exec(text)['0'];
      let videoId = re.exec(text)['1'];

      return {
          videoUrl: url, 
          videoId: videoId
        };
    }
  }

  function updatePlaceholderSetting( placeholderText ) {
      let placeholderUpdated = false;

      return HTTPRequest({
          method: "POST",
          url: "/settings/SetSetting",
          data: {
              name: "Placeholder",
              value: placeholderText
          },
          headers: helpers.getAuthorizationHeader()

      }).then( response => {
        if ( response.data.success ) {
            placeholderUpdated = true;

        }
        return placeholderUpdated;

      }).catch( error => {
        return placeholderUpdated;
      })
  }

export default feedsservice;