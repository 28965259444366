import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// 
// Toolbar compnent
// 

// 
// Components
// 

// Toolbar items component
import ToolbarItem from './toolbarItem/toolbarItem';
// Toolbar group item component
import ToolbarGroupItem from "./toolbarGroupItem/toolbarGroupItem";

// Style
import './toolbar.css';

class Toolbar extends Component {

    handleToolbarItemClick = ( event, adminTool, pageName ) => {
        event.preventDefault();

        let toolbarItemName = event.target.name;

        switch( toolbarItemName ) {
            case "home":
                this.props.history.push('/' + pageName);
                break;
            
            case "about":
                break;

            case "help":
                break;

            case "settings":
                if ( adminTool === "students" ) {
                    alert("Hello student");
                }

                break;
        }
    }

    render() {
        let toolbarContent = "";
        let pageToolName = this.props.match.params.tool;

        switch ( this.props.adminTool ) {
            case "applications":
                toolbarContent = (
                    <ToolbarGroupItem 
                        groupItemTitle={ this.props.groupItemTitle }
                        toolbarGroupItems={ this.props.toolbarGroupItems }
                        adminTool={ this.props.adminTool }
                        groupItemClick={ this.props.groupItemClick }
                        groupItemTitle="tools"
                        pageToolName={ pageToolName }
                    />
                );
                break;
        }

        return(
            <section id="toolbar">
                <nav id="toolbar-nav">
                    <ToolbarItem 
                        toolbarItemIcon="home" 
                        toolbarItemTitle="home" 
                        toolbarAdminTool={ this.props.adminTool } 
                        toolbarItemClick={ this.handleToolbarItemClick } 
                        adminTool={ this.props.adminTool }
                        pageName="admintools"
                    />
                    { toolbarContent }
                </nav>
                {/* <nav id="toolbar-nav-settings">
                    <ToolbarItem 
                        toolbarItemIcon="info-circle" 
                        toolbarItemTitle="about" 
                        toolbarAdminTool={ this.props.adminTool } 
                        toolbarItemClick={ this.handleToolbarItemClick } 
                        adminTool={ this.props.adminTool }
                    />
                    <ToolbarItem 
                        toolbarItemIcon="question-circle" 
                        toolbarItemTitle="help" 
                        toolbarAdminTool={ this.props.adminTool } 
                        toolbarItemClick={ this.handleToolbarItemClick } 
                        adminTool={ this.props.adminTool }
                    />
                    <ToolbarItem 
                        toolbarItemIcon="cog" 
                        toolbarItemTitle="settings" 
                        toolbarAdminTool={ this.props.adminTool } 
                        toolbarItemClick={ this.handleToolbarItemClick } 
                        adminTool={ this.props.adminTool }
                    />
                </nav> */}
            </section>
        );
    }
}
;

export default withRouter(Toolbar);