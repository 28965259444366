import HTTPRequest from "../HTTPConfig/axiosConfig";
import helpers from "../helpers/helpers";
import nameFilter from "../../../components/pages/applicationsPage/nameFilter/nameFilter";
// 
// applications tool services
// 

const applicationsService = {
    getApplicationCards,
    getApplications,
    getNameSearchApplications,
    getStudentApplications,
    getApplicationStatusList,
    updateApplicationStatus,
    getStudentApplicationDocs,
    requestApplicationsDocs,
    downloadApplicationDocs,
    uploadApplicationDocument,
    searchApplicantName,
    removeFile,
    assignApplication,
    getAdminList,
    assignApplicationToAdmin,
    searchInstitution,
    searchQualification,
    submitApplication,
    sendInstruction,
    getGroupsDashboard,
    getAPSDashboard,
    assignApplicationToAP,
    saveApplicantLoginInfo,
    getApplicantLoginInfo,
    getQualificationMatchInfo,
    getApplicationDetails,
    getUserSubjects,
    saveMarks,
    getBursaries,
    updateBursary,
    searchBursaries,
    getLeanerContactInfo,
}

function getApplications(userId, statusName) {
    let applicationsList = {};

    return HTTPRequest({
        method: "POST",
        url: "applications/GetApplicationsWithStatus",
        data: {
            name: statusName,
            userId: userId
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {

        if (response.data.success) {
            applicationsList = response.data.data;

        } else {
            applicationsList["error"] = true;
            applicationsList["errorMessage"] = response.data.reason;
        }
        
        return applicationsList;

    }).catch( error => {
        applicationsList["error"] = true;
        applicationsList["errorMessage"] = "Error fetching applications list";

        return applicationsList;
    });
}

function getNameSearchApplications( userId ) {
    let applicationsList = {};

    return HTTPRequest({
        method: "POST",
        url: "applications/getLearnerApplications",
        data: {
            userid: userId
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {

        if (response.data.success) {
            applicationsList = response.data.data;

        } else {
            applicationsList["error"] = true;
            applicationsList["errorMessage"] = response.data.reason;
        }
        
        return applicationsList;

    }).catch( error => {
        applicationsList["error"] = true;
        applicationsList["errorMessage"] = "Error fetching applications list";

        return applicationsList;
    });
}

function getApplicationCards( userId ) {
    let applicationsCards = {};

    return HTTPRequest({
        method: "POST",
        url: "applications/getdashboardapplications",
        data: {
            userId: userId
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            applicationsCards = response.data.data;

        } else {
            applicationsCards["error"] = true;
            applicationsCards["errorMessage"] = response.data.reason;
        }
        return applicationsCards; 

    }).catch( error => {
        applicationsCards["error"] = true;
        applicationsCards["errorMessage"] = "Error fetching application cards";

        return applicationsCards;
    })
}

function getStudentApplications(userId, institutionId) {
    let studentApplications = {};

    return HTTPRequest({
        method: "POST",
        url: "applications/GetLearnerApplicationsWithInstitution",
        data: {
            userid: userId,
            institionid: institutionId
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            studentApplications = response.data.data;

        } else {
            studentApplications["error"] = true;
            studentApplications["errorMessage"] = response.data.reason;
        }
        console.log(studentApplications)
        return studentApplications;

    }).catch( error => {
        studentApplications["error"] = true;
        studentApplications["errorMessage"] = "Error fetching student applications";

        return studentApplications;
    });
}

function getApplicationStatusList() {
    let applicationStatusList = [];

    return HTTPRequest({
        method: "POST",
        url: "applications/getapplicationstatus",
        data: {},
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            applicationStatusList = response.data.data;
        
        } else {
            applicationStatusList.push("error");
        }
        return applicationStatusList;

    }).catch( error => {
        return applicationStatusList.push("error");
    });
}

function updateApplicationStatus( userId, qualificationId, statusId, isExtended ) {
    let statusUpdated = false;

    return HTTPRequest({
        method: "POST",
        url: "applications/UpdateStatus",
        data: {
            userId: userId,
            qualificationId: qualificationId,
            statusId: statusId,
            extended: isExtended
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            statusUpdated = true;

        } else {
            statusUpdated = false
        }
        return statusUpdated

    }).catch( error => {
        return statusUpdated
    })
}

function getStudentApplicationDocs ( userId ) {
    let studentDocs = [];

    return HTTPRequest({
        method: "POST",
        url: "applications/learnerdocs",
        data: {
            userId: userId
        },
        headers: helpers.getAuthorizationHeader()
    }).then( response => {
        if (response.data.success) {
            studentDocs = response.data.data;

        } else {
            studentDocs.push("error");
        }
        return studentDocs;

    }).catch( error => {
        studentDocs.push("error");
        return studentDocs;
    });
}

function requestApplicationsDocs ( docsIds, userId, message) {
    return HTTPRequest({
        method: "POST",
        url: "applications/requestdocs",
        data: {
            docIds: docsIds,
            userId: userId,
            message: message
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            return true;

        } else {
            return false;
        }

    }).catch( error => {
        return false
    });
}

function downloadApplicationDocs( docId, userId ) {
    let docName = "";

    return HTTPRequest({
        method: "POST",
        url: "applications/downloaddoc",
        data: {
            docId: docId,
            userId: parseInt(userId)
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            docName = response.data.data;

        } else {
            docName = "error";
        }
        return docName;

    }).catch( error => {
        return docName = "error";
    });
}

function uploadApplicationDocument( docId, userId, doc, docName ) {
    let fileUploaded;
    let formData = new FormData();
    let data = {
        docId: docId,
        userId: userId,
        fileName: docName
    }

    formData.append("data", JSON.stringify(data));
    formData.append("file", doc);

    return HTTPRequest({
        method: "POST",
        url:"applications/uploaddoc",
        data: formData,
        headers: helpers.getFileAuthorizationsHeader()

    }).then( response => {
        if( response.data.success ) {
            fileUploaded = true;

        } else {
            fileUploaded = false;
        }
        return fileUploaded;

    }).catch( error => {
        return fileUploaded = false;
    });
}

function removeFile( fileName ) {
    return HTTPRequest({
        method: "POST",
        url: "applications/removefile",
        data : {
            filename: fileName
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            return true;

        } else {
            return false;
        }

    }).catch( error => {
        return false;
    });
}

function searchApplicantName( name, searchAllUsers, index ) {
    let applicantsList = [];

    return HTTPRequest({
        method: "POST",
        url: "applications/searchstudent/limit-" + index + "-index-0",
        data: {
            search: name,
            searchAllUsers: searchAllUsers
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            applicantsList = response.data.data;

        } else {
            applicantsList[0] = "error";
            applicantsList[1] = response.data.reason;
        }
        return applicantsList;

    }).catch( error => {
        applicantsList[0] = "error";
        applicantsList[1] = "Error fetching user list";
        return applicantsList;
    });
}

function assignApplication( applicantId ) {
    return HTTPRequest({
        method: "POST",
        url: "applications/assign",
        data: {
            applicantId: applicantId
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            return true;

        } else {
            return false;
        }

    }).catch( error => {
        return false;
    });
}

function assignApplicationToAdmin( userId, applicantId) {
    return HTTPRequest({
        method: "POST",
        url: "applications/adminassignC",
        data: {
            userId: userId,
            applicantId: applicantId
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            return true;

        } else {
            return false;
        }

    }).catch( error => {
        return false;
    });
}

function assignApplicationToAP (userId, applicantId, institutionId) {
    return HTTPRequest({
        method: "POST",
        url: "applications/adminassignP",
        data: {
            userId: userId,
            applicantId: applicantId,
            institutionId: institutionId
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            return true;

        } else {
            return false;
        }

    }).catch( error => {
        return false;
    });
}

function getAdminList() {
    let adminList = [];

    return HTTPRequest({
        method: "POST",
        url: "applications/administrators",
        data: {},
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            adminList = response.data.data;

        } else {
            adminList[0] = "error";
            adminList[1] = response.data.reason;
        }
        return adminList;

    }).catch( error => {
        adminList[0] = "error";
        adminList[1] = "Error fetching admin list";
        return adminList;
    });
}

function searchInstitution( search ) {
    let institutionsList = [];

    return HTTPRequest({
        method: "POST",
        url: "institutions/get/limit-10-index-0",
        data: {
            like: search
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            institutionsList = response.data.data.list;

        } else {
            institutionsList[0] = "error";
            institutionsList[1] = response.data.reason;
        }
        return institutionsList;

    }).catch( error => {
        institutionsList[0] = "error";
        institutionsList[1] = "Error fetching institutions list";
        return institutionsList;
    })
}

function searchQualification( institutionId, search, userId ) {
    let qualificationsList = [];

    return HTTPRequest({
        method: "POST",
        url: "qualifications/byinstitution_admin/limit-10-index-0",
        data: {
            institutionId: institutionId,
            like: search,
            userId: userId
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            qualificationsList = response.data.data;

        } else {
            qualificationsList[0] = "error";
            qualificationsList[1] = response.data.reason;
        }
        return qualificationsList;

    }).catch( error => {
        qualificationsList[0] = "error";
        qualificationsList[1] = "Error fetching qualification list";
        return qualificationsList;
    })
}

function submitApplication(qualifications, userId) {
    return HTTPRequest({
        method: "POST",
        url: "applications/ApplyForMe_Many_admin",
        data: {
            qualifications: qualifications,
            userId: userId
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            return true;

        } else {
            return false;
        }

    }).catch( error => {
        return false;
    });
}

function sendInstruction( userId ) {
    return HTTPRequest({
        method: "POST",
        url: "applications/SendInstruction_Admin",
        data: {
            userId: userId
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            return true;

        } else {
            return false;
        }

    }).catch( error => {
        return false;
    });
}

function getGroupsDashboard() {
    let groupsDashboard = {};

    return HTTPRequest({
        method: "POST",
        url: "applications/GetGroupStatuses",
        // url: "applications/administrators",
        data: {},
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            groupsDashboard = response.data.data;
            // groupsDashboard = {
            //     total: 1800,
            //     groups: [
            //         {
            //             name: "Group A",
            //             total: 1800,
            //             institutions: [
            //                 {
            //                     name: "TUT",
            //                     total: 20,
            //                     status: [
            //                         {
            //                             name: "started",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "pending",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "completed",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "rejected",
            //                             value: 10,
            //                         },
            //                     ]
            //                 },
            //                 {
            //                     name: "UJ",
            //                     total: 20,
            //                     status: [
            //                         {
            //                             name: "started",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "pending",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "completed",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "rejected",
            //                             value: 10,
            //                         },
            //                     ]
            //                 },
            //                 {
            //                     name: "UL",
            //                     total: 20,
            //                     status: [
            //                         {
            //                             name: "started",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "pending",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "completed",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "rejected",
            //                             value: 10,
            //                         },
            //                     ]
            //                 },
            //                 {
            //                     name: "WSU",
            //                     total: 20,
            //                     status: [
            //                         {
            //                             name: "started",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "pending",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "completed",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "rejected",
            //                             value: 10,
            //                         },
            //                     ]
            //                 },
            //                 {
            //                     name: "SEFAKO",
            //                     total: 20,
            //                     status: [
            //                         {
            //                             name: "started",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "pending",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "completed",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "rejected",
            //                             value: 10,
            //                         },
            //                     ]
            //                 },
            //                 {
            //                     name: "UNIVEN",
            //                     total: 20,
            //                     status: [
            //                         {
            //                             name: "started",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "pending",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "completed",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "rejected",
            //                             value: 10,
            //                         },
            //                     ]
            //                 },
            //             ]
            //         },
            //         {
            //             name: "Group B",
            //             total: 1800,
            //             institutions: [
            //                 {
            //                     name: "UP",
            //                     total: 20,
            //                     status: [
            //                         {
            //                             name: "started",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "pending",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "completed",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "rejected",
            //                             value: 10,
            //                         },
            //                     ]
            //                 },
            //                 {
            //                     name: "WITS",
            //                     total: 20,
            //                     status: [
            //                         {
            //                             name: "started",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "pending",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "completed",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "rejected",
            //                             value: 10,
            //                         },
            //                     ]
            //                 },
            //                 {
            //                     name: "STELLENBOSH",
            //                     total: 20,
            //                     status: [
            //                         {
            //                             name: "started",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "pending",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "completed",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "rejected",
            //                             value: 10,
            //                         },
            //                     ]
            //                 },
            //                 {
            //                     name: "UCT",
            //                     total: 20,
            //                     status: [
            //                         {
            //                             name: "started",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "pending",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "completed",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "rejected",
            //                             value: 10,
            //                         },
            //                     ]
            //                 },
            //                 {
            //                     name: "NMMU",
            //                     total: 20,
            //                     status: [
            //                         {
            //                             name: "started",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "pending",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "completed",
            //                             value: 10,
            //                         },
            //                         {
            //                             name: "rejected",
            //                             value: 10,
            //                         },
            //                     ]
            //                 },
            //             ]
            //         }
            //     ]
            // }

        } else {
            groupsDashboard["error"] = true;
            groupsDashboard["errorMessage"] = response.data.reason;
        }
        return groupsDashboard; 

    }).catch( error => {
        groupsDashboard["error"] = true;
        groupsDashboard["errorMessage"] = "Error fetching groups dashboard";

        return groupsDashboard;
    })
}

function getAPSDashboard() {
    let APDashboard = {};

    return HTTPRequest({
        method: "POST",
        url: "applications/APDashboard",
        // url: "applications/administrators",
        data: {},
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            APDashboard = response.data.data;
            // APDashboard = [
            //     {
            //         name: "isaac seshoka",
            //         institutions: [
            //             {
            //                 name: "TUT",
            //                 total: 20,
            //                 status: [
            //                     {
            //                         name: "started",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "pending",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "completed",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "rejected",
            //                         value: 10,
            //                     },
            //                 ]
            //             },
            //             {
            //                 name: "UJ",
            //                 total: 20,
            //                 status: [
            //                     {
            //                         name: "started",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "pending",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "completed",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "rejected",
            //                         value: 10,
            //                     },
            //                 ]
            //             },
            //             {
            //                 name: "UL",
            //                 total: 20,
            //                 status: [
            //                     {
            //                         name: "started",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "pending",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "completed",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "rejected",
            //                         value: 10,
            //                     },
            //                 ]
            //             },
            //             {
            //                 name: "WSU",
            //                 total: 20,
            //                 status: [
            //                     {
            //                         name: "started",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "pending",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "completed",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "rejected",
            //                         value: 10,
            //                     },
            //                 ]
            //             },
            //             {
            //                 name: "SEFAKO",
            //                 total: 20,
            //                 status: [
            //                     {
            //                         name: "started",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "pending",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "completed",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "rejected",
            //                         value: 10,
            //                     },
            //                 ]
            //             },
            //             {
            //                 name: "UNIVEN",
            //                 total: 20,
            //                 status: [
            //                     {
            //                         name: "started",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "pending",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "completed",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "rejected",
            //                         value: 10,
            //                     },
            //                 ]
            //             },
            //         ]
            //     },
            //     {
            //         name: "Tshilisanani Ndou",
            //         institutions: [
            //             {
            //                 name: "UP",
            //                 total: 20,
            //                 status: [
            //                     {
            //                         name: "started",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "pending",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "completed",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "rejected",
            //                         value: 10,
            //                     },
            //                 ]
            //             },
            //             {
            //                 name: "WITS",
            //                 total: 20,
            //                 status: [
            //                     {
            //                         name: "started",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "pending",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "completed",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "rejected",
            //                         value: 10,
            //                     },
            //                 ]
            //             },
            //             {
            //                 name: "STELLENBOSH",
            //                 total: 20,
            //                 status: [
            //                     {
            //                         name: "started",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "pending",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "completed",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "rejected",
            //                         value: 10,
            //                     },
            //                 ]
            //             },
            //             {
            //                 name: "UCT",
            //                 total: 20,
            //                 status: [
            //                     {
            //                         name: "started",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "pending",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "completed",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "rejected",
            //                         value: 10,
            //                     },
            //                 ]
            //             },
            //             {
            //                 name: "NMMU",
            //                 total: 20,
            //                 status: [
            //                     {
            //                         name: "started",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "pending",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "completed",
            //                         value: 10,
            //                     },
            //                     {
            //                         name: "rejected",
            //                         value: 10,
            //                     },
            //                 ]
            //             },
            //         ]
            //     },
            // ]

        } else {
            APDashboard["error"] = true;
            APDashboard["errorMessage"] = response.data.reason;
        }
        return APDashboard; 

    }).catch( error => {
        APDashboard["error"] = true;
        APDashboard["errorMessage"] = "Error fetching AP dashboard";

        return APDashboard;
    })
}

function saveApplicantLoginInfo( institutionId, userName, password, userId ) {
    return HTTPRequest({
        method: "POST",
        url: "adminusers/setLocalStorage",
        data: {
            name: "applicant_login_info",
            value: {
                [institutionId]: {
                    userName: userName,
                    password: password
                }
            },
            userId: userId
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            return true;

        } else {
            return false;
        }

    }).catch( error => {
        return false;
    });
}

function getApplicantLoginInfo( userId  ) {
    let applicantLoginInfo = {};

    return HTTPRequest({
        method: "POST",
        url: "adminusers/getLocalStorage",
        data: {
            name: "applicant_login_info",
            userId: userId
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            applicantLoginInfo = response.data.data;

        } else {
            applicantLoginInfo["error"] = true;
            applicantLoginInfo["errorMessage"] = response.data.reason;
        }
        console.log(applicantLoginInfo)
        return applicantLoginInfo;

    }).catch( error => {
        applicantLoginInfo["error"] = true;
        applicantLoginInfo["errorMessage"] = "Error fetching applicatant login info";

        return applicantLoginInfo;
    });
}

function getQualificationMatchInfo( userId, qualificationId) {
    let qualificationMatchInfo = [];

    return HTTPRequest({
        method: "POST",
        url: "match/qualificationmatch_admin",
        data: {
            excludeAlternative: true,
            marks: null,
            qualificationId: qualificationId,
            useGoals: false,
            userId: userId
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            qualificationMatchInfo = response.data.data;

        } else {
            qualificationMatchInfo[0] = "error";
            qualificationMatchInfo[1] = response.data.reason;
        }
        return qualificationMatchInfo;

    }).catch( error => {
        qualificationMatchInfo[0] = "error";
        qualificationMatchInfo[1] = "Error fetching qualification match info";
        return qualificationMatchInfo;
    })
}

function getApplicationDetails ( userId ) {
    let applicantDetails = {};

    return HTTPRequest({
        method: "POST",
        url: "adminusers/getLocalStorage",
        data: {
            name: "bridge_onboard",
            userId: userId
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            applicantDetails = response.data.data;

        } else {
            applicantDetails["error"] = true;
            applicantDetails["errorMessage"] = response.data.reason;
        }
        return applicantDetails;

    }).catch( error => {
        applicantDetails["error"] = true;
        applicantDetails["errorMessage"] = "Error fetching applicatant login info";

        return applicantDetails;
    });
}

function getUserSubjects( userId, year, term ) {
    let userSubjects = [];

    return HTTPRequest({
        method: "POST",
        url: "adminusers/getmarks",
        data: {
            userId: userId,
            year: year,
            term: term
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            userSubjects = response.data.data;

        } else {
            userSubjects[0] = "error";
            userSubjects[1] = response.data.reason;
        }
        return userSubjects;

    }).catch( error => {
        userSubjects[0] = "error";
        userSubjects[1] = "Error fetching user subjects";
        return userSubjects;
    });
}

function saveMarks(marks, userId, termYear, term) {
    return HTTPRequest({
        method: "POST",
        url: "adminusers/savemarks",
        data: {
            marks: marks,
            userId: userId,
            year: termYear,
            term: term
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            return true;

        } else {
            return false;
        }

    }).catch( error => {
        return false;
    })
}

function searchBursaries( name, index ) {
    let bursaryList = [];

    return HTTPRequest({
        method: "POST",
        url: "bursaries/findnew/limit-" + index + "-index-0",
        data: {
            like: name
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            bursaryList = response.data.data;

        } else {
            bursaryList[0] = "error";
            bursaryList[1] = response.data.reason;
        }
        return bursaryList;

    }).catch( error => {
        bursaryList[0] = "error";
        bursaryList[1] = "Error fetching bursary list";
        return bursaryList;
    })
}

function getBursaries( userId ) {
    let burseries = [];

    return HTTPRequest({
        method: "POST",
        url: "applications/GetLearnerBursaries",
        data: {
            userId: userId,
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            burseries = response.data.data;

        } else {
            burseries[0] = "error";
            burseries[1] = response.data.reason;
        }
        return burseries;

    }).catch( error => {
        burseries[0] = "error";
        burseries[1] = "Error fetching learner bursaries";
        return burseries;
    });
}

function updateBursary(userId, bursaryId, statusId) {
    return HTTPRequest({
        method: "POST",
        url: "applications/addLearnerBursary",
        data: {
            userId: userId,
            bursaryId: bursaryId,
            statusId: statusId
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            return true;

        } else {
            return false;
        }

    }).catch( error => {
        return false;
    })
}

function getLeanerContactInfo(userId) {
    let learnerInfoContacts = {};

    return HTTPRequest({
        method: "POST",
        url: "applications/GetStudentContacts",
        data: {
            userId: userId
        },
        headers: helpers.getAuthorizationHeader()

    }).then( response => {
        if ( response.data.success ) {
            learnerInfoContacts = response.data.data;

        } else {
            learnerInfoContacts["error"] = true;
            learnerInfoContacts["errorMessage"] = response.data.reason;
        }
        return learnerInfoContacts;

    }).catch( error => {
        learnerInfoContacts["error"] = true;
        learnerInfoContacts["errorMessage"] = "Error fetching applicatant login info";

        return learnerInfoContacts;
    });
}

export default applicationsService;