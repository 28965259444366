// 
// Usefulness page reducer : Dispatched actions for usefulness page content change
// 

// Action types
import * as actionTypes from "../../actions/actionTypes";
// Utitlies
import * as utilities from "../utilities";
const initialState = {
    usefulnessPageHeading: "Data Usefulness",
    usefulnessBlocks: {
        usefulness01: {
            usefulnessId: "01",
            usefulnessHeading: "Lorem ipsum dolor sit amet",
            usefulnessBody: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin suscipit, dui non imperdiet volutpat, eros turpis venenatis magna, id mollis diam orci sit amet est. Quisque egestas sodales ipsum"
        },
        usefulness02: {
            usefulnessId: "02",
            usefulnessHeading: "Lorem ipsum dolor sit amet",
            usefulnessBody: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin suscipit, dui non imperdiet volutpat, eros turpis venenatis magna, id mollis diam orci sit amet est. Quisque egestas sodales ipsum"
        },
        usefulness03: {
            usefulnessId: "03",
            usefulnessHeading: "Lorem ipsum dolor sit amet",
            usefulnessBody: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin suscipit, dui non imperdiet volutpat, eros turpis venenatis magna, id mollis diam orci sit amet est. Quisque egestas sodales ipsum"
        },
        usefulness04: {
            usefulnessId: "04",
            usefulnessHeading: "Lorem ipsum dolor sit amet",
            usefulnessBody: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin suscipit, dui non imperdiet volutpat, eros turpis venenatis magna, id mollis diam orci sit amet est. Quisque egestas sodales ipsum"
        },
        usefulness05: {
            usefulnessId: "05",
            usefulnessHeading: "Lorem ipsum dolor sit amet",
            usefulnessBody: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin suscipit, dui non imperdiet volutpat, eros turpis venenatis magna, id mollis diam orci sit amet est. Quisque egestas sodales ipsum"
        }
    }
}

const usefulnessPageReducers = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.UPDATE_TEXT_USEFULNESS_PAGE:
            if ( action.payload.event.target.name.split("-").length > 1 ) {
                let usefulnessKey = action.payload.event.target.name.split("-");
                let inputValue = action.payload.event.target.value;
                let usefulnessBlocksCopy = { ...state.usefulnessBlocks }

                usefulnessBlocksCopy[usefulnessKey[0]]["usefulness" + usefulnessKey[1]] = inputValue;

                return {
                    ...state,
                    usefulnessBlocks: usefulnessBlocksCopy
                }

            } else {
                return utilities.handleTextOnChange(state, action);
            }
            
        default:
            return {
                ...state
            }
    }
}

export default usefulnessPageReducers;