import React from "react";
// 
// Status list component : select combo box for status
// 

// Style
import "./statusList.css";

const statusList = ( props ) => {
    let statuses = [];
    
    if ( props.statusList.length > 0 ) {
        statuses = props.statusList.map( (status, index) => {
            return <option key={index}>{ status.Name }</option>;
        });
    }

    return(
        <div className="form-select-wrap">
            <select value={ props.activeStatus } onChange={ props.updateApplicationStatus }>
                { statuses }
            </select>
        </div>
    )
}

export default statusList