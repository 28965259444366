import React, { Component } from 'react';
import HTTPRequest from '../../js/library/HTTPConfig/axiosConfig';
// 
// Manage feeds component : container for manage feeds page
// 

// 
// Services
//

// User service 
import UserServices from "../../js/library/services/userservice";
// Feeds service
import feedsService from "../../js/library/services/feedsservice";

// 
// Components
// 

// Wrap component
import Wrap from '../../components/hoc/wrap';
// Feeds filter component
import FeedsFilter from "../../components/pages/manageFeedsPage/feedsFilter/feedsFilter";
// Feed type component
import FeedType from "../../components/pages/manageFeedsPage/feedType/feedType";
// Post item component
import PostItem from "../../components/pages/manageFeedsPage/postItem/postItem";

// Style
import "./manageFeeds.css";

class ManageFeeds extends Component {
    state={
        feedType: "Inline",
        post: {
            text: "",
            image: null,
            video: "",
            link: "",
            heading: "",
        },
        posts: [],
        createdBy: {},
        sendingPost: false,
        sendingPostError: "",
        adminUserToken: "",
        statusText: "",
    }

    componentWillMount() {
        let userInfo = {};
        if (UserServices.USER_TOKEN && UserServices.USER_TOKEN !== "") {
            this.setState({
                adminUserToken: UserServices.USER_TOKEN
            });
            
        } else {
            userInfo = JSON.parse(localStorage.getItem("userInfo"));
            
            this.setState({
                adminUserToken: userInfo.userToken
            });
        }
    }

    componentDidMount() {
        this.handleFetchPosts(0);
    }

    handleFilterChange = ( event ) => {
        let inputValue = event.target.value;

        this.setState({
            feedType: inputValue
        });
    }

    handlePostInputChange = ( event ) => {
        let inputName = event.target.name;
        let inputValue = event.target.value;
        let postCopy = { ...this.state.post };

        postCopy[inputName] = inputValue;

        this.setState({
            post: postCopy
        });
    }

    handlePostImageSelect = ( event ) => {
        event.preventDefault();

        let postCopy = { ...this.state.post };
        postCopy.image = event.target.files[0];

        this.setState({
            post: postCopy
        })

    }

    handleFetchPosts = ( index ) => {
        feedsService.fetchPosts(index).then( postsInfo => {
            if ( !postsInfo.error ) {
                this.setState({
                    posts: postsInfo.posts,
                    createdBy: postsInfo.CreatedBy
                });
            }
        });
    }

    handleShareNewPost = () => {
        this.setState({
            sendingPost: true
        });

        feedsService.shareNewPost(this.state.feedType, this.state.post).then( response => {
            if ( !response.error ) {
                if ( this.state.post.image ) {
                    feedsService.attachePostImage( response.ID, this.state.post.image ).then( postedIMage => {
                        this.setState({
                            post: {
                                text: "",
                                image: null,
                                video: "",
                                link: "",
                                heading: "",
                            },
                            sendingPost: false
                        });
                    });

                } else {
                    this.setState({
                        post: {
                            text: "",
                            image: null,
                            video: "",
                            link: "",
                            heading: "",
                        },
                        sendingPost: false
                    });
                }

                this.handleFetchPosts(0);

            } else {
                this.setState({
                    sendingPost: false,
                    sendingPostError: response.errorMessage
                });
            }
        });
    }

    handleShowHidePost = ( event ,postId ) => {
        event.preventDefault();

        feedsService.showHidePost(postId).then( response => {
            alert(response);
            this.handleFetchPosts(0);
        });
    }

    handleOnStatusTextChange = ( event ) => {
        let inputText = event.target.value;

        this.setState({
            statusText: inputText
        });
    }

    handleUpdateStatusPlaceHolder = () => {
        let statusPlaceholder = this.state.statusText;

        this.setState({
            sendingPost: true
        });

        feedsService.updatePlaceholderSetting(statusPlaceholder).then( statusUpdated => {
            if ( statusUpdated ) {
                this.setState({
                    statusText: "",
                    sendingPost: false
                });

            } else {
                this.setState({
                    sendingPost: false
                });
            }
        });
    }

    render() {
        let posts = [];

        if ( this.state.posts.length > 0 ) {
            posts = this.state.posts.map( post => {
                let userInfo = this.state.createdBy[post.CreatedBy];

                return <PostItem 
                            post={ post }
                            profilePicture={ userInfo.ProfilePicture } 
                            userName={ userInfo.Name + " " + userInfo.Surname }
                            createdDate={ post.CreatedDate }
                            key={ post.ID }
                            postText={ post.Text }
                            postLikes={ post.Likes }
                            postComments={ post.Comments }
                            showHidePost={ this.handleShowHidePost }
                        />
            });
        }

        return(
            <Wrap>
                <div className="row no-gutters page-tool-heading">
                    <div className="col-12">
                        <h3>manage feeds</h3>
                    </div>
                </div>
                <section className="row feeds-section">
                    <div className="col-6 feeds-container">
                        <h4 className="feeds-section-heading">feeds</h4>
                        <div className="row feeds-posts">
                            { posts }
                        </div>
                    </div>
                    <div className="col-5 offset-1 new-feed-container">
                        <h4 className="feeds-section-heading">new feed</h4>
                        <div className="row new-feed">
                            <FeedsFilter filterValue={ this.state.feedType } onFilterChange={ this.handleFilterChange } />
                            <div className="col-12">
                                <FeedType 
                                    feedType={ this.state.feedType } 
                                    postText={ this.state.post.text } 
                                    onPostInputChange={ this.handlePostInputChange } 
                                    postVideoUrl={ this.state.postVideo }
                                    postHeading={ this.state.post.heading }
                                    postLinkUrl={ this.state.post.link }
                                    onPostFileSelect={ this.handlePostFileSlect }
                                    onSharePost={ this.handleShareNewPost }
                                    sendingPost={ this.state.sendingPost }
                                    postImageSelect={ this.handlePostImageSelect }
                                    onStatusPlaceHolderUpdate={ this.handleUpdateStatusPlaceHolder }
                                    onStatusTextChange={ this.handleOnStatusTextChange }
                                    statusText={ this.props.statusText }
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </Wrap>
        );
    }
}

export default ManageFeeds;