import React from "react";
// 
// Filter component: view users and stats component (probably for now)
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../hoc/wrap";
// Filter list component
import FilterList from "./filterList/filterList";

// Style
import './filter.css';

const filter = ( props ) => {
    let filterButton = "";
    let filterLable  = "";
    let filterSearchButton = "";

    if ( props.filterName === "defaultFilter" ) {
        filterButton = (
            <a className="round-button add-filter" href="#" onClick={ event => props.addExtraFilter(event) }>
                <i className="fa fa-plus" aria-hidden="true"></i>
            </a>
            );

        filterLable = <label className="filter-label">filter by: </label>;

        filterSearchButton = (
            <div className="col-1">
                <a className="round-button search" href="#" onClick={ event => props.fetchData(event) }><i className="fa fa-search" aria-hidden="true"></i></a>
            </div>
        );

    } else {
        filterButton = (
            <a className="round-button remove-filter" href="#" onClick={ event => props.removeExtraFilter(event, props.filterName) } >
                <i className="fa fa-times" aria-hidden="true"></i>
            </a>
            );

        filterLable = <label className="filter-label line-connector"></label>;
    }

    return(
        <Wrap>
            <div className={ "col-11" + (props.filterName !== "defaultFilter" ? " extra-filter": "") }>
                { filterButton }
                { filterLable }
                <FilterList 
                    pageName={ props.pageName }
                    filterName={ props.filterName } 
                    filterValue={ props.filterValue } 
                    onFilterChange={ props.onFilterChange } 
                    filterInputName={ props.filterInputName } 
                    filterInputValue={ props.filterInputValue }
                    activityMinValue={ props.activityMinValue }
                    activityMaxValue={ props.activityMaxValue }
                    actionDateFrom={ props.actionDateFrom }
                    actionDateTo={ props.actionDateTo }
                    createdDateFrom= { props.createdDateFrom }
                    createdDateTo= { props.createdDateTo }
                    onFilterValueChange={ props.onFilterValueChange }   
                    onDateSelect={ props.onDateSelect }
                    schoolNamesList={ props.schoolNamesList }  
                />
            </div>
            { filterSearchButton }
        </Wrap>
    );

}

export default filter;