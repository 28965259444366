import React from "react";
// 
// user info input component
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../../../../hoc/wrap";

// Style
import "./userInfoInput.css";

const userInfoInput = ( props ) => {
    let formInput = "";

    switch( props.intputType ) {
        case "text":
            formInput = <input 
                            type="text" 
                            name={ props.inputName } 
                            className={ props.inputClass } 
                            value={ props.inputValue } 
                            onChange={ props.inputOnChange } 
                            placeholder={ props.inputPlaceholder } 
                        />
            break;
        
        default:
            formInput = "";
            break;
    }

    return(
        <Wrap>
            <label className="form-label">{ props.inputLabel }</label>
            <div className="form-text-wrap">
                { formInput }
            </div>
        </Wrap>
    )
}

export default userInfoInput