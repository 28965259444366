import React, { Component } from "react";
import messengerService from "../../js/library/services/messengerservice";
// 
// Messenger coontainer component
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../components/hoc/wrap";
// User list component
import UserList from "../../components/pages/messengerPage/userList/userList";
// Status card component
import StatusCard from "../../components/pages/messengerPage/statusCard/statusCard";
// Message Item component
import MessageItem from "../../components/pages/messengerPage/messageItem/messageItem";
// Message date component
import MessageDate from "../../components/pages/messengerPage/messageItem/messageDate/messageDate";
// Loading data component
import LoadingData from "../../components/UI/loadingData/loadingData";

// Style 
import "./messenger.css";

// Assest test file
import profilePic from "../../assets/images/character-face.png";
import { truncate } from "fs";

class Messenger extends Component {
    state={
        loading: true,
        ACList: [],
        loadingACList: false,
        ACUserList: [],
        activeACId: "",
        ACUsersList: [],
        activeApplicantId: "",
        loadingACUsersList: false,
        conversation: {},
        loadingConversation: false,
        showConversation: false,
    }

    componentWillMount() {
        messengerService.getACList().then( ACList => {
            if ( ACList[0] !== "error" ) {
                this.setState({
                    loading: false,
                    ACList: ACList
                });
            }
        })
    }

    handleACSelect = ( ACUserId ) =>  {
        this.setState({
            activeACId: ACUserId,
            activeApplicantId: "",
            loadingACUsersList: true,
            showConversation: false,
        });

        messengerService.getACUsersList(ACUserId).then( ACUserList => {
            if ( ACUserList[0] !== "error" ) {
                this.setState({
                    loadingACUsersList: false,
                    ACUserList: ACUserList,
                });
            }
        })
    }

    handleLoadConversations = ( applicantId ) =>  {
        this.setState({
            activeApplicantId: applicantId,
            loadingConversation: true
        });

        messengerService.getConversations(applicantId, this.state.activeACId).then( conversations => {
            if ( !conversations.error ) {
                if ( Object.keys(conversations).length > 0 ) {
                    this.setState({
                        showConversation: true
                    });
                }
                this.setState({
                    conversation: conversations,
                    loadingConversation: false,
                });
            }
        })
    }

    render() {
        let contentView = "";
        let statusCards = [
            {
                institutionName: "WITS (Assigned)",
                statusIconName: "exclamation-triangle",
                statusInfoTitle: "Pending Documents",
                statusInfoText: "documents mi ssing in leaner's application",
                statusColor: "blue",
            },
            {
                institutionName: "UJ (Assigned)",
                statusIconName: "exclamation-triangle",
                statusInfoTitle: "Closing Sate Soon",
                statusInfoText: "Closing date for this application is near",
                statusColor: "orange",
            },
            {
                institutionName: "UCT (Assigned)",
                statusIconName: "exclamation-triangle",
                statusInfoTitle: "No Documents",
                statusInfoText: "There are no dcouments for this application",
                statusColor: "red",
            },

        ].map( (statusCard, index) => {
            return <StatusCard 
                        key={ index }
                        institutionName={ statusCard.institutionName }
                        statusIconName={ statusCard.statusIconName }
                        statusTitle={ statusCard.statusInfoTitle }
                        statusText={ statusCard.statusInfoText }
                        statusColor={ statusCard.statusColor }
                    />; 
        });
        let messageContent = "";

        if ( this.state.loadingConversation ) {
            messageContent = <LoadingData title="fetching conversation" />
        
        } else {
            if ( this.state.showConversation ) {
                messageContent = this.state.conversation.posts.map( post => {
                    return (
                        <Wrap key={ post.ID }>
                            <MessageItem 
                                picPositions={ post.CreatedBy === parseInt(this.state.activeACId) ? "left" : "right" }
                                messageText={ post.Text }
                                messageStatus="read"
                                messageDate={ post.CreatedDate }
                            />
                        </Wrap>
                    )
                });
            }
        }

        if ( this.state.loading ) {
            contentView = (
                <div className="row justify-content-center">
                    <LoadingData title="Fetching messegnger data" />
                </div>
            );
            
        } else {
            contentView = (
                <section className="row no-gutters messenger-container">
                    <div className="col-6">
                        <div className="row no-gutters">
                            <div className="col-12 messenger-user-info">
                                <div className="pro-pic-wrap">
                                    <img src={ profilePic } alt="user profile pic" />
                                </div>
                                <h4>Isaac Seshoka</h4>
                            </div>
                            <div className="col-12">
                                <div className="messenger-search">
                                    <div className="search-field">
                                        <input type="text" placeholder="Search" />
                                        <a href="#">
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 user-lists">
                                <div className="row no-gutters">
                                    <div className="col-6">
                                        <UserList 
                                            listId="ac-list"
                                            userListTitle="AC List"
                                            usersList={ this.state.ACList }
                                            userItemClick={ this.handleACSelect }
                                            activeUserId={ this.state.activeACId }
                                        />
                                    </div>
                                    <div className="col-6">
                                        <UserList 
                                            listId="applicant-list"
                                            userListTitle="AC Users List"
                                            usersList={ this.state.ACUserList }
                                            userItemClick={ this.handleLoadConversations }
                                            loadingACUsersList={ this.state.loadingACUsersList }
                                            loadingDataTitle="fetching applicants"
                                            activeUserId={ this.state.activeApplicantId }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="row no-gutters">
                            <div className="col-12 messenger-settings">
                                <a href="#" className="cog">
                                    <i className="fa fa-cog" aria-hidden="true"></i>
                                </a>
                            </div>
                            <div className="col-12">
                                <div className="row flex-nowrap no-gutters dashboard">
                                    { statusCards }
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters conversation-container">
                            { messageContent }
                        </div>
                    </div>
                </section>
            )
        }

        return (
            <Wrap>
                <div className="row no-gutters page-tool-heading">
                    <div className="col-12">
                        <h3>Messenger</h3>
                    </div>
                </div>
                { contentView }
            </Wrap>
        )
    }
}

export default Messenger;

