import React from "react";
// 
// Document info row component
// 

// Style
import "./statusInfoRow.css";

const statusInfoRow = ( props ) => (
    <div className="col-12 info-row">
        <div className="row no-gutter justify-content-between align-items-center">
            <div className="col-8 info-row-item">
                <input 
                    type="text" 
                    name={ "name_" + props.infoRowId }  
                    value={ props.infoRowName } 
                    onChange={ props.inputOnChange } 
                    placeholder="Document Name"
                />
            </div>
            <div className="col-1 info-row-item">
                <a href="#" className={ (props.saveEnabled ? " active": "") } onClick={ props.saveEnabled ? (event) => props.updateRowInfo(event, props.infoRowId) : null } >
                    <i className="fa fa-floppy-o" aria-hidden="true"></i>
                </a>
            </div>
        </div>
    </div>
);

export default statusInfoRow;