import React from 'react';
import { Switch, Route } from 'react-router-dom';
// 
// Layout component: App layout wrapper
// 

// 
// Components
// 

// Wrap component
import Wrap from '../hoc/wrap';
// Login page component
import LoginPage from '../pages/loginPage/loginPage';
// Admin tools page component
import AdminToolsPage from '../../containers/adminToolsPage/adminToolsPage';
// Add user page component
import AddUserPage from '../pages/addUserPage/addUserPage';
// Manage modules page component
import ManageModulesPage from '../pages/manageModulesPage/manageModulesPage';
// View users page component
import ViewUsersPage from "../pages/viewUsersPage/viewUsersPage";
// Statistics page component
import StatisticsPage from "../pages/statisticsPage/statisticsPage";
// Manage feeds page component
import ManageFeedsPage from "../pages/manageFeedsPage/manageFeedsPage";
// Applications page component
import ApplicationsPage from "../pages/applicationsPage/applicationsPage";
// Expop report page component
import ExpoReportPage from "../pages/expoReportPage/expoReportPage";
// Messenger page component
import MessengerPage from "../pages/messengerPage/messengerPage";

const layout = ( props ) => (
    <Wrap>
        <Switch>
            <Route path='/' exact render={ () => <LoginPage whichHeader='login' handleLogin={ props.handleLogin } onInputChange={ props.handleInputChange } loginLoading={ props.loginLoading } loginErrorMessage={ props.loginErrorMessage } /> } />
            <Route path='/admintools' render={ routeProps => <AdminToolsPage { ...routeProps } { ...props } /> } showProfileNav={props.showProfileNav} toggleProfileNav={ props.toogleProfileNav } handleLogout={ props.handleLogout } />
            <Route path='/addStudent' render={ routeProps => <AddUserPage { ...routeProps } { ...props } /> } showToolsNav={props.showToolsNav} toggleToolsNav={ props.toggleToolsNav } showProfileNav={props.showProfileNav} toggleProfileNav={ props.toogleProfileNav } handleLogout={ props.handleLogout } />
            <Route path='/managemodules' render={ routeProps => <ManageModulesPage { ...routeProps } { ...props } /> } showToolsNav={props.showToolsNav} toggleToolsNav={ props.toggleToolsNav } showProfileNav={props.showProfileNav} toggleProfileNav={ props.toogleProfileNav } handleLogout={ props.handleLogout } />
            <Route path='/viewusers' render={ routeProps => <ViewUsersPage { ...routeProps } { ...props } /> } showToolsNav={props.showToolsNav} toggleToolsNav={ props.toggleToolsNav } showProfileNav={props.showProfileNav} toggleProfileNav={ props.toogleProfileNav } handleLogout={ props.handleLogout } />
            <Route path='/stats' render={ routeProps => <StatisticsPage { ...routeProps } { ...props } /> } showToolsNav={props.showToolsNav} toggleToolsNav={ props.toggleToolsNav } showProfileNav={props.showProfileNav} toggleProfileNav={ props.toogleProfileNav } handleLogout={ props.handleLogout } />
            <Route path='/feeds' render={ routeProps => <ManageFeedsPage { ...routeProps } { ...props } /> } showToolsNav={props.showToolsNav} toggleToolsNav={ props.toggleToolsNav } showProfileNav={props.showProfileNav} toggleProfileNav={ props.toogleProfileNav } handleLogout={ props.handleLogout } />
            <Route path='/applications/:tool' render={ routeProps => <ApplicationsPage { ...routeProps } { ...props } /> } showToolsNav={props.showToolsNav} toggleToolsNav={ props.toggleToolsNav } showProfileNav={props.showProfileNav} toggleProfileNav={ props.toogleProfileNav } handleLogout={ props.handleLogout } />
            <Route path='/report' render={ routeProps => <ExpoReportPage { ...routeProps } { ...props } /> } showToolsNav={props.showToolsNav} toggleToolsNav={ props.toggleToolsNav } showProfileNav={props.showProfileNav} toggleProfileNav={ props.toogleProfileNav } handleLogout={ props.handleLogout } />
            <Route path='/messenger' render={ routeProps => <MessengerPage { ...routeProps } { ...props } /> } showToolsNav={props.showToolsNav} toggleToolsNav={ props.toggleToolsNav } showProfileNav={props.showProfileNav} toggleProfileNav={ props.toogleProfileNav } handleLogout={ props.handleLogout } />
        </Switch>
    </Wrap>
);

export default layout;