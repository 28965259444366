import React, { Component } from "react";
import HTTPRequest from "../../js/library/HTTPConfig/axiosConfig";
import UserServices from "../../js/library/services/userservice";
import viewusersservice from "../../js/library/services/viewusersservice";

// 
// View users component: Container component for view users page
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../components/hoc/wrap";
// Filter component
import Filter from "../../components/UI/filter/filter";
// User info row component
import UserInfoRow from "../../components/pages/viewUsersPage/usersInfoRow/usersInfoRow";
//MessageBox component
import MessageBox from "../../components/pages/viewUsersPage/messageBox/messageBox";
// Button component
import Button from "../../components/UI/button/button";
// Loading data component
import LoadingData from "../../components/UI/loadingData/loadingData";
// User info component
import UserInfo from "../../components/pages/viewUsersPage/usersInfoRow/userInfo/userInfo";

// Style
import "./viewUsers.css";

let SUBJECT_LIST = null;

class ViewUsers extends Component {

    state = {
        filters: {
            defaultFilter: "name"
        },
        filterOptions: {
            source: "",
            username: "",
            name: "",
            school: "",
            schoolId: "",
            province: "EC",
            activitymin: 1,
            activitymax: 1,
            actiondatefrom: new Date(),
            actiondateto: new Date(),
            createddatefrom: new Date(),
            createddateto: new Date(),
            grade: "",
            hasexamnumber: ""
        },
        searchFilter: {},
        schoolList: [],
        users: [],
        showUsers: false,
        showBackDrop: false,
        usersCount: 0,
        smsDate: null,
        smsMessage: "",
        sendingSMS: false,
        adminUserToken: "",
        fetchMoreUsers: false,
        viewUsersIndex: 0,
        viewedUser: null,
        loadingUserInfo: false,
        userRoles: [],
        allUserRoles: [],
        userInfoForm: {
            subjectList: {
                subject_1 : {
                    id: "",
                    name: "",
                    mark: ""
                },
                subject_2 : {
                    id: "",
                    name: "",
                    mark: ""
                },
                subject_3 : {
                    id: "",
                    name: "",
                    mark: ""
                },
                subject_4 : {
                    id: "",
                    name: "",
                    mark: ""
                },
                subject_5 : {
                    id: "",
                    name: "",
                    mark: ""
                },
                subject_6 : {
                    id: "",
                    name: "",
                    mark: ""
                },
                subject_7 : {
                    id: "",
                    name: "",
                    mark: ""
                },
                subject_8 : {
                    id: "",
                    name: "",
                    mark: ""
                },
                subject_9 : {
                    id: "",
                    name: "",
                    mark: ""
                }
            },
            userInfo: {
                gradesMail: {
                    inputValue: null,
                    isViewOnly: true,
                },
            }
        },
        focusedInput: "",
        formEdited: false,
        editedUserId: "",
        currentTerm: "",
        termYears: [],
        subjectSearchList: [],
        savingMarks: false
    }

    componentWillMount() {
        let userInfo = {};
        if (UserServices.USER_TOKEN && UserServices.USER_TOKEN !== "") {
            this.setState({
                adminUserToken: UserServices.USER_TOKEN
            });
            
        } else {
            userInfo = JSON.parse(localStorage.getItem("userInfo"));
            
            this.setState({
                adminUserToken: userInfo.userToken
            });
        }

        viewusersservice.getDefaultTerms().then( response => {
            if ( !response.error ) {
                this.setState({
                    currentTerm: response.term,
                    termYears: response.years
                });
            }
        });
    }

    componentDidMount() {
        HTTPRequest.get("/subjects/getsubjects?type=all").then( response => {
            SUBJECT_LIST = response.data.data;

        }).catch( error => {
            console.log( "error in subjects retrival: " + error );
        });
    }

    handleFilterChange = ( event ) => {
        const inputName = event.target.name;
        const selectedFilter = event.target.value;
        let filtersCopy = { ...this.state.filters };

        filtersCopy[inputName] = selectedFilter;

        this.setState({
            showUsers: false,
            filters: filtersCopy
        });
    }

    handleFilterValueChange = ( event ) => {
        event.preventDefault();

        let inputName = event.target.name;
        let inputValue = event.target.value;
        let filterOptionsCopy = { ...this.state.filterOptions };

        switch( inputName ) {
            case "school":
                HTTPRequest({
                    method: "POST",
                    url: "schools/find/limit-10-index-0",
                    data: {
                        "like": inputValue
                    },
                    headers : { "Authorization": "Bearer " + this.state.adminUserToken }

                }).then( response => {
                    if ( response.data.success ) {
                        let responseData = response.data.data;
                        let schoolNamesList = [];

                        if ( responseData.length >= 1 ) {
                            if ( responseData.length === 1 && responseData[0].Name === "Can't Find School") {
                                schoolNamesList.push(
                                    <li key={ -1 } className="list-no-result">
                                        <em>Can't Find School</em>
                                    </li>
                                );

                            } else {
                                schoolNamesList = responseData.map( schoolObj => {
                                    let province = schoolObj.Province
                                    let district = schoolObj.EIDistrict !== "NOT APPLICABLE" ? " " + schoolObj.EIDistrict  : "";
    
                                    return <li key={ schoolObj.ID } onClick={ () => this.handleSearchItemSelect( schoolObj.ID, schoolObj.Name ) }>{ schoolObj.Name + "(" + province + district + ")" }</li>
                                });
                            }
                        }
                        this.setState({
                            schoolList: schoolNamesList
                        });
                    }
                });

                break;
        }

        filterOptionsCopy[inputName] = inputValue;

        this.setState({
            filterOptions: filterOptionsCopy
        });
    }

    handleFilterDateSelect = ( selectedDate, filterName ) => {
        let filterOptionsCopy = { ...this.state.filterOptions };

        filterOptionsCopy[filterName] = selectedDate;

        console.log(filterName, selectedDate);

        this.setState({
            filterOptions: filterOptionsCopy
        });
    }

    handleSearchItemSelect = ( schoolId, schoolName ) => {
        let filterOptionsCopy = { ...this.state.filterOptions }

        filterOptionsCopy["school"] = schoolName;
        filterOptionsCopy["schoolId"] = schoolId;
        this.setState({
            filterOptions: filterOptionsCopy,
            schoolList: []
        });
    }

    handleFetchUsers = ( event ) => {
        event.preventDefault();
        let filtersObject = {};
        let filters = { ...this.state.filters };
        let filterValues = { ...this.state.filterOptions};
        let dateFrom;
        let dateTo;

        Object.keys(filters).forEach( filterName => {
            let filter = filters[filterName].replace(" ", "-").toLowerCase();

            switch( filter ) {
                case "name":
                    filtersObject["name"] = filterValues["name"];
                    break;

                case "school":
                    filtersObject["school"] = [filterValues["schoolId"]];
                    break;

                case "province":
                    filtersObject["province"] = [filterValues["province"]];
                    break;

                case "activity":
                    filtersObject["activity"] = {
                        min: filterValues["activitymin"],
                        max: filterValues["activitymax"]
                    }
                    break;

                case "action-date":
                    dateFrom = filterValues["actiondatefrom"];
                    dateTo = filterValues["actiondateto"];
                    
                    filtersObject["actiondate"] = {
                        from: dateFrom.getFullYear() + "-" + (dateFrom.getMonth() + 1) + "-" + dateFrom.getDate(),
                        to: dateTo.getFullYear() + "-" + (dateTo.getMonth() + 1) + "-" + dateTo.getDate()
                    }
                    break;

                case "grade":
                    filtersObject["grade"] = filterValues["grade"];
                    break;
                    
                case "source":
                    filtersObject["source"] = filterValues["source"];
                    break;

                case "username":
                    filtersObject["username"] = filterValues["username"];
                    break;

                case "created-date":
                    dateFrom = filterValues["createddatefrom"];
                    dateTo = filterValues["createddateto"];

                    filtersObject["date"] = {
                        from: dateFrom.getFullYear() + "-" + (dateFrom.getMonth() + 1) + "-" + dateFrom.getDate(),
                        to: dateTo.getFullYear() + "-" + (dateTo.getMonth() + 1) + "-" + dateTo.getDate()
                    }
                    break;
            }
        });

        this.setState({
            searchFilter: filtersObject
        });

        HTTPRequest({
            method: "POST",
            url: "adminusers/find/limit-10-index-0",
            data: {
                "filters" : filtersObject
            },
            headers : { "Authorization": "Bearer " + this.state.adminUserToken }

        }).then( response => {
            if ( response.data.success ) {
                this.setState({
                    usersCount: response.data.data.count,
                    users: response.data.data.users,
                    showUsers: true
                });
            }
        }).catch( error => {
            console.log(error);
        });
    }

    handleBackdropHide = () => {
        this.setState({
            showBackDrop: false
        });
    }

    handleViewMessageBox = ( event ) => {
        event.preventDefault();

        this.setState({
            showBackDrop: true
        });
    }

    handleMessageBoxInputChange = ( event ) => {
        let inputValue = event.target.value;

        if ( inputValue.length <= 160 ) {
            this.setState({
                smsMessage: inputValue
            });
        }
    }

    handleSMSDateSelect = ( value ) => {
        this.setState({
            smsDate: value
        });
    }

    handleSendSMS = () => {
        this.setState({
            sendingSMS: true
        });

        console.log(this.state.smsDate);
        console.log(this.state.smsDate == null ? new Date() : this.state.smsDate);

        HTTPRequest({
            method: "POST",
            url: "adminusers/sendsms",
            data: {
                'filters': this.state.searchFilter,
                'message': this.state.smsMessage,
                'placeholders': [],
                'time': this.state.smsDate == null ? new Date() : this.state.smsDate
            },
            headers : { "Authorization": "Bearer " + this.state.adminUserToken }

        }).then( response => {
            if ( response.data.success ) {
                this.setState({
                    showBackDrop: false,
                    smsMessage: "",
                    smsDate: null,
                    sendingSMS: false
                });
            }

        }).catch( error => {
            console.log(error);
        });
    }

    handleAddFilter = ( event ) => {
        event.preventDefault();

        let filtersCount = Object.keys(this.state.filters).length;
        let filtersCopy = { ...this.state.filters };

        filtersCopy["extraFilter" + filtersCount] = "name";
        
        this.setState({
            filters: filtersCopy
        });
    }

    handleRemoveFilter = ( event, filterName ) => {
        event.preventDefault();

        let filtersCopy = { ...this.state.filters };
        delete filtersCopy[filterName];

        this.setState({
            filters: filtersCopy
        });
    }

    handleViewMoreUsers = () => {
        let fetchUsersIndex = this.state.viewUsersIndex + 1;
        let usersCopy = [ ...this.state.users ];
        let newUsers = [];
        let filtersObject = this.state.searchFilter;

        this.setState({
            fetchMoreUsers: true
        });

        viewusersservice.fetchMoreUsers( filtersObject, fetchUsersIndex ).then( users => {
            if ( !users.error ) {
                newUsers = usersCopy.concat(users.users);
                this.setState({
                    viewUsersIndex: fetchUsersIndex,
                    users: newUsers,
                    fetchMoreUsers: false
                });

            } else {
                this.setState({
                    fetchMoreUsers: false
                });
            }
        })
    }

    handleViewUserInfo = ( event, viewedUserKey, userId ) => {
        event.preventDefault();

        this.setState({
            viewedUser: viewedUserKey
        });

        if ( viewedUserKey !== null ) {
            let userInfo = {};
            let userInfoFormCopy = { ...this.state.userInfoForm };

            this.state.users.forEach( user => {
                if ( user.userInfo.user.ID === userId ) {
                    userInfo = user;
                }
            });
            userInfoFormCopy.userInfo.gradesMail.inputValue = userInfo.userInfo.user.GradesMail;

            this.handleFetchUserInfo(userId);

            this.setState({
                editedUserId: userId,
                userInfoForm: userInfoFormCopy,
            })

        } else {
            this.setState({
                viewedUserId: "",
                editedUserId: ""
            });
        }
    }

    handleFetchUserInfo = ( userId ) => {
        let userInfoFormCopy = { ...this.state.userInfoForm };
        let term = this.state.currentTerm;
        let termYear = this.state.termYears[0];        

        this.setState({
            loadingUserInfo: true
        });

        viewusersservice.getUserRoles(userId).then( userRolesInfo => {
            if ( userRolesInfo.length > 0 ) {
                if ( userRolesInfo[0] !== "error" ) {
                    viewusersservice.getUserSubjects(userId, termYear, term).then( response => {
                        if ( response[0] !== "error" ) {
                            response.forEach( (subject, index) => {
                                try {
                                    userInfoFormCopy.subjectList["subject_" + subjectCount].id = subject.SubjectID;
                                    userInfoFormCopy.subjectList["subject_" + subjectCount].name = subject.Name;
                                    userInfoFormCopy.subjectList["subject_" + subjectCount].mark = subject.Mark;
                                
                                } catch (error) {}
                                let subjectCount = index + 1;
                            });

                            this.setState({
                                userInfoForm: userInfoFormCopy,
                                loadingUserInfo: false,
                            })

                        } else {
                            this.setState({
                                loadingUserInfo: false,
                            })
                        }
                    }); 

                    this.setState({
                        userRoles: userRolesInfo,
                        allUserRoles: viewusersservice.USER_ROLES_LIST,
                    });

                } else {
                    this.setState({
                        loadingUserInfo: false
                    });
                }
            }
        });
    }

    handleSubjectInputChange = ( event, subjectLabel ) => {
        let inputName = event.target.name;
        let inputValue = event.target.value;
        let userInfoFormCopy = { ...this.state.userInfoForm };
        let subjectSearchList = [];

        if ( inputName.indexOf("subject") !== -1 ) {
            userInfoFormCopy.subjectList[subjectLabel].name = inputValue;

            if ( SUBJECT_LIST ) {
                subjectSearchList = SUBJECT_LIST.filter( subject => {
                    return subject.Name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;

                }).map( subjectObj => {
                    return (
                        <li key={ subjectObj.ID } onClick={ () => this.handleSearchSubjectSelect( inputName, subjectObj ) }>{ subjectObj.Name }</li>
                    );
                });

                if ( subjectSearchList.length === 0 ) {
                    subjectSearchList.push(
                        <li key={ -1 } className="list-no-result">
                            <em>subject not found</em>
                        </li>
                    );
                }

                this.setState({
                    subjectSearchList: subjectSearchList,
                    focusedInput: inputName
                });
            }

        } else {
            userInfoFormCopy.subjectList[subjectLabel].mark = inputValue;
            this.setState({
                focusedInput: ""
            })
        }

        this.setState({
            userInfoForm: userInfoFormCopy,
            formEdited: true
        });
    }

    handleSearchSubjectSelect = ( inputName, subjectObj ) => {
        let userInfoFormCopy = { ...this.state.userInfoForm };

        userInfoFormCopy.subjectList[inputName].id = subjectObj.ID;
        userInfoFormCopy.subjectList[inputName].name = subjectObj.Name;

        this.setState({
            userInfoForm: userInfoFormCopy,
            focusedInput: ""
        });
    }   

    handleSaveMarks = () => {
        let marks = [];
        let termYear = this.state.termYears[0];
        let term = this.state.currentTerm;
        let userId = this.state.editedUserId;

        this.setState({
            savingMarks: true
        })

        Object.keys(this.state.userInfoForm.subjectList).forEach( subjectKey => {
            let subject = this.state.userInfoForm.subjectList[subjectKey];

            marks.push({
                "SubjectID": subject.id,
                "Mark": subject.mark
            });
        });

        viewusersservice.saveMarks(marks, userId, termYear, term ).then( response => {
            if ( response ) {
                this.setState({
                    savingMarks: false
                })
            } else {
                this.setState({
                    savingMarks: false,
                    errorSavingMarks: true
                })
            }
        })
    }

    handleUserInfoInputChange = ( event ) => {
        // 
    }

    handleSetUserRole = ( event, userId, userRole ) => {
        event.preventDefault();

        let userRoles = this.state.userRoles;

        if ( userRole === undefined ) {
            userRoles.push(parseInt(event.target.value));
            
        } else {
            userRoles = [];
        }

        viewusersservice.setUserRoles(userId, userRoles).then( updated => {
            if ( updated ) {
                this.handleFetchUserInfo(userId);
            }
        });
    }

    handleRemoveUserRole = ( userRoleId ) => {
        let userId = this.state.editedUserId;

        let userRoles = this.state.userRoles.filter( userRole => {
            return userRole !== userRoleId;
        });

        viewusersservice.setUserRoles(userId, userRoles).then( updated => {
            if ( updated ) {
                this.handleFetchUserInfo(userId);
            }
        });
    }

    render() {
        let usersView = "";
        let extraFilters = [];
        let viewMoreButton = "";
        let viewedUser = this.state.viewedUser;
        let usersCount = this.state.usersCount;
        let UserInfoRows;

        if ( Object.keys(this.state.filters).length > 1 ) {
            let filterIndex = 0;
            let filterInputName = "";

            Object.keys(this.state.filters).forEach( filterName => {
                filterInputName = this.state.filters[filterName].toLowerCase();

                if ( filterName !== "defaultFilter" ) {
                    extraFilters[filterIndex] = (
                        <Filter 
                            pageName={ "viewUsers" }
                            filterName={ filterName } 
                            filterValue={ this.state.filters[filterName] } 
                            onFilterChange={ this.handleFilterChange } 
                            removeExtraFilter={ this.handleRemoveFilter } 
                            key={ filterName }
                            filterInputName={ filterInputName }
                            filterInputValue={ this.state.filterOptions[filterInputName] }
                            activityMinValue={ this.state.filterOptions["activitymin"] }
                            activityMaxValue={ this.state.filterOptions["activitymax"] }
                            actionDateFrom={ this.state.filterOptions["actiondatefrom"] }
                            actionDateTo={ this.state.filterOptions["actiondateto"] }
                            createdDateFrom={ this.state.filterOptions["createddatefrom"] }
                            createdDateTo={ this.state.filterOptions["createddateto"] }
                            onFilterValueChange={ this.handleFilterValueChange }
                            onDateSelect={ this.handleFilterDateSelect }
                            schoolNamesList={ this.state.schoolList } 
                        />
                    );
                }
                filterIndex++;
            });
        }
        
        if ( this.state.showUsers ) {
            if ( viewedUser !== null ) {
                let viewedUserInfo = this.state.users[viewedUser];

                UserInfoRows = (
                    <UserInfo 
                        userInfo={ viewedUserInfo }
                        viewUserInfo={ this.handleViewUserInfo }
                        loadingUserInfo={ this.state.loadingUserInfo }
                        userRoles={ this.state.userRoles }
                        allUserRoles={ this.state.allUserRoles }
                        subjectList={ this.state.userInfoForm.subjectList }
                        userId={ this.state.editedUserId }
                        subjectInputChange={ this.handleSubjectInputChange }
                        subjectSearchList={ this.state.subjectSearchList }
                        focusedInput={ this.state.focusedInput }
                        saveMarks={ this.handleSaveMarks }
                        savingMarks={ this.state.savingMarks }
                        userInfoForm={ this.state.userInfoForm.userInfo }
                        setUserRole={ this.handleSetUserRole }
                        removeUserRole={ this.handleRemoveUserRole }
                    />
                )

            } else {
                UserInfoRows = this.state.users.map( (user, index) => {
                return(
                        <UserInfoRow 
                            key={ index }
                            userName={ user.userInfo.user.Name } 
                            userSurname={ user.userInfo.user.Surname } 
                            userEmail={ user.userInfo.user.Email ? user.userInfo.user.Email : "" } 
                            userPhoneNumber={ user.userInfo.user.Phone ? user.userInfo.user.Phone : "" } 
                            key={ user.userInfo.user.Phone  } 
                            arrowDir={ "right" }
                            index={ index }
                            viewUserInfo={ this.handleViewUserInfo }
                            userId={ user.userInfo.user.ID }
                        />
                    );
                });
            }
            
            if ( usersCount > 0 ) {
                if ( this.state.fetchMoreUsers ) {
                    viewMoreButton = <LoadingData title="fetching users" />
    
                } else {
                    viewMoreButton = <Button buttonClassName="view-more" buttonTitle="view more" clickHandler={ this.handleViewMoreUsers } />
                }
            }

            usersView = (
                <Wrap>
                    <MessageBox  
                        backdropShow={ this.state.showBackDrop } 
                        backdropHide={ this.handleBackdropHide } 
                        usersCount={ usersCount } 
                        smsDate={ this.state.smsDate }
                        smsDateSelect={ this.handleSMSDateSelect }
                        smsMessage={ this.state.smsMessage }
                        messageBoxInputChange={ this.handleMessageBoxInputChange }
                        sendSMS={ this.handleSendSMS }
                        sendingSMS={ this.state.sendingSMS }
                    />
                    <section className="row no-gutters justify-content-end users-count">
                        <div className="col-4">
                            <p>
                                { usersCount + " user" + (usersCount > 1 ? "s" : "")+ " found " } 
                                <a className="round-button send-message" href="#" onClick={ event => this.handleViewMessageBox(event) }><i className="fa fa-envelope" aria-hidden="true"></i></a>
                            </p>
                        </div>
                    </section>
                    {
                        usersCount > 0 ?
                                    (
                                        <Wrap>
                                            <section className="row no-gutters users-table-titles">
                                                <div className="col-1">
                                                    <h6>Actions</h6>
                                                </div>
                                                <div className="col-2">
                                                    <h6>name</h6>
                                                </div>
                                                <div className="col-2">
                                                    <h6>surname</h6>
                                                </div>
                                                <div className="col-4">
                                                    <h6>email address</h6>
                                                </div>
                                                <div className="col-2">
                                                    <h6>phone number</h6>
                                                </div>
                                                <div className="col-1">
                                                    <h6 className="right-aligned">edit</h6>
                                                </div>
                                            </section>
                                            <section className="row users-table-rows">
                                            { UserInfoRows }  
                                            </section>
                                            {
                                                viewedUser === null ? 
                                                <div className="row justify-content-end view-more">
                                                    <div className="col-3 d-flex justify-content-end">
                                                        { viewMoreButton }
                                                    </div>
                                                </div> : ""
                                            }
                                        </Wrap>
                                    )
                                    : ""
                    }
                </Wrap>
            );
        }

        return (
            <Wrap>
                <div className="row no-gutters page-tool-heading">
                    <div className="col-12">
                        <h3>view users</h3>
                    </div>
                </div>
                <section className="row no-gutters view-users-filter">
                    <Filter 
                        pageName={ "viewUsers" }
                        filterName={ "defaultFilter" } 
                        filterValue={ this.state.filters.defaultFilter } 
                        onFilterChange={ this.handleFilterChange } 
                        fetchData={ this.handleFetchUsers } 
                        addExtraFilter={ this.handleAddFilter } 
                        filterInputName={ this.state.filters.defaultFilter.toLowerCase() }
                        filterInputValue= { this.state.filterOptions[this.state.filters.defaultFilter.toLowerCase()] }
                        activityMinValue={ this.state.filterOptions["activitymin"] }
                        activityMaxValue={ this.state.filterOptions["activitymax"] }
                        actionDateFrom= { this.state.filterOptions["actiondatefrom"] }
                        actionDateTo= { this.state.filterOptions["actiondateto"] }
                        createdDateFrom= { this.state.filterOptions["createddatefrom"] }
                        createdDateTo= { this.state.filterOptions["createddateto"] }
                        onFilterValueChange={ this.handleFilterValueChange }
                        onDateSelect={ this.handleFilterDateSelect }
                        schoolNamesList={ this.state.schoolList } 
                    />
                    { extraFilters }
                </section>
                { usersView }
            </Wrap>
        );
    }
}

export default ViewUsers;