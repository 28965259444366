import React, { Component } from 'react';
import adminToolsService from "../../js/library/services/admintoolsservice";
import userService from '../../js/library/services/userservice';
// 
// Admin tools page component
// 

// 
// Components
// 

// Wrap component
import Wrap from '../../components/hoc/wrap';
// Header component
import Header from '../../components/header/header';
// Admin tool component
import AdminTool from '../../components/adminTool/adminTool';

class AdminToolsPage extends Component {
    state = {
        adminTools: [],
        adminToolsInfo: {}
    }

    componentDidMount() {
        adminToolsService.getTools().then( tools => {
            this.setState({
                adminTools: tools
            });
        });
    }

    render() {
        let adminTools = [];
        let toolPage = "";
        let adminToolsInfo = adminToolsService.ADMIN_TOOLS_INFO;

        if ( this.state.adminTools.length > 0 && this.state.adminTools[0].indexOf("error") === -1 ) {
            adminTools = this.state.adminTools.map( ( toolName, index) => {
                if ( toolName === "applications" ) {
                    toolPage = toolName + "/manage";

                } else {
                    toolPage = toolName;
                }

                if (adminToolsInfo[toolName]) {
                    return (
                        <div className={ 'col-3' + ( index % 3 === 0 ? ' offset-2' : '') } key={ index }>
                            <AdminTool adminToolIcon={ adminToolsInfo[toolName].icon } adminToolTitle={ adminToolsInfo[toolName].title }  adminToolpage={ toolPage } />
                        </div>
                    );
                }
            });
        }

        return(
            <Wrap>
                <Header 
                    whichHeader="admin-tools" 
                    showProfileNav={ this.props.showProfileNav } 
                    toggleProfileNav={ this.props.toggleProfileNav } 
                    handleLogout={ this.props.handleLogout } 
                />
                <main className="container">
                    <div className="row">
                        { adminTools }
                    </div>
                </main>
            </Wrap>
        );
    }
} 

export default AdminToolsPage;