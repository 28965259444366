import React, { Component} from "react";
// 
// Manage applications documents component : container component for managing applications documents
// 

// 
// Services
// 

// Applications Documents Service
import applicationsDocsService from "../../../js/library/services/applicationsDocsService";

// 
// Components
// 

// Table titles component
import TableTitles from "../../../components/pages/applicationsPage/applicationsList/manageApplicationsDocsPage/tableTitles";
// Wrap component
import Wrap from "../../../components/hoc/wrap";
// Loading data component
import LoadingData from "../../../components/UI/loadingData/loadingData";
// Document info row component
import DocInfoRow from "../../../components/pages/applicationsPage/applicationsList/manageApplicationsDocsPage/docInfoRow/docInfoRow";
// Button component
import Button from "../../../components/UI/button/button";

// Style 
import "./manageApplicationsDocs.css";


class manageApplicationsDocs extends Component {
    state = {
        docList: [],
        docListValues: {},
        loadingDocsList: true
    }

    componentDidMount() {
        let docListValues = {};

        applicationsDocsService.fetchDocuments().then( response => {
            console.log("count")
            if ( response[0] !== "error" ) {
                if ( response.length > 0 ) {
                    response.forEach( docItem => {
                        docListValues[docItem.ID] = {
                                name: docItem.Name,
                                description: docItem.Description,
                                color: docItem.Color,
                                required: docItem.Required ? "Yes" : "No",
                                saveEnabled: false,
                        }
                    });
                }    

                this.setState({
                    docList: response,
                    docListValues: docListValues,
                    loadingDocsList: false
                });
            }
        });
    }

    handleInputChange = ( event ) => {
        event.preventDefault();

        let inputNameProps = event.target.name.split("_");
        let inputName = inputNameProps[0];
        let inputId = inputNameProps[1];
        let inputValue = event.target.value;
        let docListValuesCopy = { ...this.state.docListValues }
        
        docListValuesCopy[inputId][inputName] = inputValue;
        docListValuesCopy[inputId].saveEnabled = true;

        this.setState({
            docListValues: docListValuesCopy
        });
    }

    handleUpdateRowInfo = ( event, docId ) => {
        event.preventDefault();
        let docListValuesCopy = { ...this.state.docListValues };
        let docInfo = this.state.docListValues[docId];
        let name = docInfo.name;
        let required = docInfo.required === "Yes" ? true : false;
        let description = docInfo.description;
        let color = docInfo.color;

        if ( docId !== "new" ) {
            applicationsDocsService.editDocument(name, required, docId, description, color ).then( response => {
                if ( response ) {
                    docListValuesCopy[docId].saveEnabled = false;
    
                    this.setState({
                        docListValues: docListValuesCopy
                    });
                }
            });

        } else {
            applicationsDocsService.saveDocument(name, required, description, color).then( response => {
                if ( response !== "error" ) {
                    let newDocInfo = docListValuesCopy["new"];
                    
                    delete docListValuesCopy["new"];
                    docListValuesCopy[response] = newDocInfo;
                    docListValuesCopy[response].saveEnabled = false;

                    this.setState({
                        docListValues: docListValuesCopy
                    });
                }
            });
        }
    }

    handleAddNewDocInfoRow = () => {
        let docListValuesCopy = { ...this.state.docListValues };
        
        docListValuesCopy["new"] = {
            name: "",
            description: "",
            color: "",
            required: "No",
            saveEnabled: true
        }

        this.setState({
            docListValues: docListValuesCopy
        });
    }

    render() {
        let docsCount = Object.keys(this.state.docListValues).length;
        let pageContent = "";
        let docInfoRows = [];

        if ( this.state.loadingDocsList ) {
            pageContent = (
                <div className="row justify-content-center">
                    <LoadingData title="fetching documents list" />
                </div>
            );

        } else {
            if ( docsCount > 0 ) {
                let docListValues = this.state.docListValues;
                docInfoRows = Object.keys(docListValues).map( docId => {
                    let docItem = docListValues[docId];
                    return <DocInfoRow 
                                key={ docId }
                                infoRowId={ docId }
                                infoRowName={ docItem.name }           
                                infoRowDescription={ docItem.description }           
                                infoRowColor={ docItem.color }          
                                infoRowRequired={ docItem.required }
                                saveEnabled={ docItem.saveEnabled }
                                inputOnChange={ this.handleInputChange }  
                                updateRowInfo={ this.handleUpdateRowInfo }
                            />
                });

                pageContent = (
                    <Wrap>
                        <TableTitles />
                        <section className="row justify-content-center application-docs-container">
                            { docInfoRows }
                        </section>
                    </Wrap>
                );
            }
        }

        return(
            <Wrap>
                <div className="row justify-content-between align-items-center no-gutters page-tool-heading">
                    <div className="col-6">
                        <a href="#" onClick={ event => this.props.navigateToApplications(event) }>
                            <h3>
                                <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                                manage application documents
                            </h3>
                        </a>
                    </div>
                    <div className="col-4">
                        <p>{ docsCount + " document" + ( docsCount > 1 || docsCount == 0 ? "s" : "" ) + " found"}</p>
                    </div>
                </div>
                { pageContent }
                <div className="row justify-content-end application-docs-btn">
                    <div className="col-3">
                        <Button buttonTitle="Add new document" clickHandler={ this.handleAddNewDocInfoRow } />
                    </div>
                </div>
            </Wrap>
        );
    }
}

export default manageApplicationsDocs;