import React from "react";
import formatDate from "../../../../../js/library/formatDate/formatDate";
// 
// Student applications component
// 

//
// Components
// 

// Loading data component
import LoadingData from "../../../../UI/loadingData/loadingData";
// Wrap component 
import Wrap from "../../../../hoc/wrap";
// Applications list titles component
import ApplicationsListTitle from "./applicationListTitles/applicationsListTitle";
// Status list component
import StatusList from "../../statusList/statusList";
// Applicaytion info component
import ApplicationInfo from "./applicationIfno/applicationInfo";
// Match criteria component
import MatchCriteria from "./matchCriteria/matchCriteria";
// Qualification match info component
import QualificationMatchInfo from "./qualificationMatch/qualificationMatchInfo";

// Style
import "./studentApplications.css";

const studentApplication = ( props ) => {
    let content = "";
    let applications = [];

    if ( props.loadingStudentApplications ) {
        content = <LoadingData title="fetching applications" />

    } else {
        if ( props.viewStudentApplication ) {
            Object.keys(props.studentApplications).forEach( institution => {
                applications = props.studentApplications[institution].map( (application, index) => {
                    let ClosingDate = new Date(application.ClosingDate);
                    let matchCriteriaColor = "";
                    let matchCriteriaText = "";
                    let matchCriteriaLabel = "";

                    if ( application.Rate ) {
                        if (application.Rate < 90) {
                            matchCriteriaText = "not likely";
                            matchCriteriaColor = " red";

                        } else if (application.Rate < 100) {
                                matchCriteriaText = "likely";
                                matchCriteriaColor = " orange";

                        } else {
                            matchCriteriaText = "most likely";
                            matchCriteriaColor = " green";
                        }

                        matchCriteriaLabel = (
                            <MatchCriteria 
                                matchCriteriaText={ matchCriteriaText } 
                                matchCriteriaColor={ matchCriteriaColor } 
                                showQualificationMatchInfo={ () => props.showQualificationMatchInfo(props.viewedStudentApplications.studentId, application.QualificationID) }

                            />
                        );
                    }

                    return (
                        <div className="col-11" key={ index }>
                            <div className="row align-items-center application-item">
                                <div className="col-7">
                                    { matchCriteriaLabel }
                                    <p>{ application.Name + ( application.ExtendedProgram ? " (Extended)" : "" ) }</p>
                                </div>
                                <div className="col-2">
                                    <p>{ ClosingDate.getDate() + " - " + ( formatDate.getMonthShortName(ClosingDate.getMonth() + 1) ) + " - " + ClosingDate.getFullYear() }</p>
                                </div>
                                <div className="col-3">
                                    <StatusList 
                                        activeStatus={ application.Status } 
                                        statusList={ props.statusList } 
                                        updateApplicationStatus={ event => props.updateApplicationStatus(event, props.viewedStudentApplications.studentId, application.QualificationID , application.ExtendedProgram, props.viewedStudentApplications.institutionId) } 
                                    /> 
                                </div>
                            </div>
                        </div>
                    )
                })
            });

            content = (
                    <Wrap>
                        <div className="col-11">
                            <ApplicationInfo 
                                viewedStudentApplications={ props.viewedStudentApplications }
                                applicantLoginInfoChange={ props.applicantLoginInfoChange }
                                saveApplicantLoginInfo={ props.saveApplicantLoginInfo }
                            />  
                        </div>
                        <div className="col-11">
                            <h5 className="applications-title">Applications from { Object.keys(props.studentApplications)[0] } for { props.viewedStudentApplications.name }</h5>
                        </div>
                        <ApplicationsListTitle 
                            statusList={ props.statusList } 
                            updateAllApplicationStatus={ props.updateAllApplicationStatus } 
                        />
                        { applications }
                    </Wrap>     
            );
        }
    }

    return(
        <Wrap>
            <QualificationMatchInfo 
                showMatchInfoBackdrop={ props.showMatchInfoBackdrop }
                showQualificationMatchInfo={ props.showQualificationMatchInfo }
                hideQualificationMatchInfo={ props.hideQualificationMatchInfo }
                loadingQualificationMatch={ props.loadingQualificationMatch }
                QMInfo={ props.QMInfo }
            />
            <div className="row justify-content-center student-applications">
                { content }
            </div>
        </Wrap>
    );
}

export default studentApplication;