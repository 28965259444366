import HTTPRequest from '../HTTPConfig/axiosConfig';
import helpers from '../helpers/helpers';
// 
// User stats services : methods that retrive info via API calls from ackend in userstats page
// 



const userstatsservice  = {
    getChartData,
    getTopChartData,
    getTileData,
    IsEmptyChart
}

function getTileData() {

}

function getChartData( filter, actionName, sort ) {
    let chartData = {};

    return HTTPRequest({
        method: "POST",
        url: "adminstats/chartdata_group",
        data: {
            filters: filter,
            actionname: actionName,
            groupby: sort
        },
        headers: helpers.getAuthorizationHeader()

        }).then( response => {
            if ( response.data.success ) {
                chartData = response.data.data;

            } else {
                chartData["error"] = true;
                chartData["errorMessage"] = response.data.reason;
            }
            
            return chartData;
        }).catch( error => {
            chartData["error"] = true;
            chartData["errorMessage"] = "Error fetching chart data" ;

            return chartData;
        });
}

function getTopChartData(filter, topChartData) {
    let chartData = {};

    return HTTPRequest({
        method: "POST",
        url: "adminstats/" + topChartData,
        data: {
            filters: filter,
        },
        headers: helpers.getAuthorizationHeader()

        }).then( response => {
            if ( response.data.success ) {
                chartData = response.data.data;

            } else {
                chartData["error"] = true;
                chartData["errorMessage"] = response.data.reason;
            }
            
            return chartData;
        }).catch( error => {
            chartData["error"] = true;
            chartData["errorMessage"] = "Error fetching chart data" ;

            return chartData;
        });
}

function IsEmptyChart ( chartData, tileName ) {
    let chartHasData = true;

    if ( tileName !== "top 5" ) {
        for (let i = 0; i < Object.keys(chartData).length ; i++) {
            if ( chartData[Object.keys(chartData)[i]].length === 0 ) {
                chartHasData = false;

            } else {
                chartHasData = true;
                break;
            }
        }

    } else {
        if ( chartData.length === 0 ) {
            chartHasData = false
        }
    }

    return chartHasData;
}

export default userstatsservice;