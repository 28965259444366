import axios from 'axios';
import configConstants from "../configConstants/configConstants";
// 
// Axios config files: HTTP request config parameters
// 

const axiosConfig = axios.create({
    baseURL: configConstants.SERVER_NAME + "/gradesmatch/webapi"
});

export default axiosConfig;