import React, { Component } from "react";
import saveAs from "file-saver";
import configConstants from "../../js/library/configConstants/configConstants";
import HTTPRequest from "../../js/library/HTTPConfig/axiosConfig";
// 
// Applications component: container component for applications pagae
// 

// 
// Services
// 

// Applications services
import applicationsService from "../../js/library/services/applicationsservice";
// User services
import userServices from "../../js/library/services/userservice";

//
// Components
// 

// Wrap component
import Wrap from "../../components/hoc/wrap";
// Loading data component
import LoadingData from "../../components/UI/loadingData/loadingData";
// Application card component
import ApplicationCard from "../../components/pages/applicationsPage/applicationCard/applicationCard";
// Applications list component
import ApplicationsList from "../../components/pages/applicationsPage/applicationsList/applicationsList";
// ApplicationInstitutions
import ApplicationInstitutions from "../../components/pages/applicationsPage/applicationsList/applicationInstitutions/applicationInstitutions";
// Name filter component
import NameFilter from "../../components/pages/applicationsPage/nameFilter/nameFilter";
// Groups dashboard component
import GroupsDashboard from "../../components/pages/applicationsPage/applicationsDashboards/groupsDashboard/groupsDashboard";
// APs dashboard component
import APDashboard from "../../components/pages/applicationsPage/applicationsDashboards/APDashboard/apDashboard";

// Style
import "./applications.css";

let SUBJECT_LIST = null;

class Applications extends Component {
    state = {
        applicationsCards: {},
        loadingApplicationCards: true,
        viewedInstitution: {
            id: "",
            name: ""
        },
        viewedStatus: {
            id: "",
            name: ""
        },
        showApplicationsList: false,
        viewedApplications: [],
        loadingViewApplications: false,
        loadingStudentApplications: false,
        viewStudentApplication: false,
        viewedStudentApplications: {
            name: "",
            studentId: "",
            institutionId: "",
            username: "",
            password: "",
            applicationDetails: {},
            contactDetails: {},
            bursariesList: [],
        },
        studentApplications: {},
        nameSearchUserId: "",
        nameSearchText: "",
        nameSearchList: [],
        statusList: [],
        viewStudentApplicationDocs: false,
        viewStudentBursaries: false,
        loadingBursaries: false,
        viewedApplicationDocs: [],
        loadingApplicationDocs: false,
        showDocsModal: false,
        requestedDocs: [],
        requestedDocsText: "",
        uploadDocs: [],
        userRole: "",
        userId: "",
        adminList: [],
        showAdminList: 0,
        adminName: "",
        viewedDocName: "",
        viewedApplicationInstitutions: [],
        searchAllUsers: false,
        newApplicationsList: [],
        institutionList: [],
        institutionSearchText: "",
        showNewApplicationInstitutionsList: true,
        qualificationList: [],
        searchQualificationText: "",
        submitApplication: false,
        groupsDashboard: {},
        APAssignId: "",
        APDashboard: {},
        showMatchInfoBackdrop: false,
        loadingQualificationMatch: false,
        qualificationMatchInfo: [],
        showApplicantInfoBackdrop: false,
        subjectList: {
            subject_1 : {
                id: "",
                name: "",
                mark: ""
            },
            subject_2 : {
                id: "",
                name: "",
                mark: ""
            },
            subject_3 : {
                id: "",
                name: "",
                mark: ""
            },
            subject_4 : {
                id: "",
                name: "",
                mark: ""
            },
            subject_5 : {
                id: "",
                name: "",
                mark: ""
            },
            subject_6 : {
                id: "",
                name: "",
                mark: ""
            },
            subject_7 : {
                id: "",
                name: "",
                mark: ""
            },
            subject_8 : {
                id: "",
                name: "",
                mark: ""
            },
            subject_9 : {
                id: "",
                name: "",
                mark: ""
            }
        },
        userInfo: {
            gradesMail: {
                value: null,
                isValid: true,
            },
            number: {
                value: "",
                isValid: true
            },
            school: {
                id: "",
                value: "",
                search: "",
            },
            parentDetails: {
                name: {
                    value: null,
                    isValid: true,
                },
                number: {
                    value: null,
                    isValid: true,
                },
                email: {
                    value: null,
                    isValid: true,
                },
            }
        },
        schoolNameList: [],
        focusedInput: "",
        subjectSearchList: [],
        savingMarks: false,
        fetchMoreUsers: false,
        minFetchLimit: 0,
        maxFetchLimit: 10,
        nameSearchIndex: 10,
        loadingMoreUsers: false,
        loadingApplicantInfo: false,
        viewNewApplications: true,
        newBursaryItems: [],
    }

    componentDidMount() {
        applicationsService.getApplicationCards(this.state.userId).then( applicationsCards => {
            if ( !applicationsCards.error ) {
                this.setState({
                    applicationsCards: applicationsCards
                });

                applicationsService.getGroupsDashboard().then( dashboard => {
                    if ( !dashboard.error ) {
                        this.setState({
                            // loadingApplicationCards: false,
                            groupsDashboard: dashboard
                        })
                    }
                });

                applicationsService.getAPSDashboard().then( dashboard => {
                    if ( !dashboard.error ) {
                        
                        this.setState({
                            APDashboard: dashboard,
                            loadingApplicationCards: false,
                        });
                        
                        HTTPRequest.get("/subjects/getsubjects?type=all").then( response => {
                            SUBJECT_LIST = response.data.data;
                
                        }).catch( error => {
                            console.log( "error in subjects retrival: " + error );
                        });
                    }
                });
            }
        });

        applicationsService.getApplicationStatusList().then( statusList => {
            if ( statusList[0] !== "error" ) {
                this.setState({
                    statusList: statusList
                });
            }
        });

        applicationsService.getAdminList().then( adminList => {
            if ( adminList[0] !== "error" ) {
                this.setState({
                    adminList: adminList
                });
            }
        })
    }

    componentWillMount() {
        if ( localStorage.getItem("userInfo") ) {
            let userInfo = JSON.parse(localStorage.getItem("userInfo"));

            console.log(userInfo)
                
            this.setState({
                userRole: userInfo.userRole,
                userId: userInfo.userId
            });
        }
    }

    handleShowApplications = (event, institutionName, institutionId, statusName, statusId) => {
        event.preventDefault();

        this.setState({
            loadingViewApplications: true,
            showApplicationsList: true,
            viewStudentApplication: false,
            viewStudentApplicationDocs: false,
            viewStudentBursaries: false,
            viewedInstitution: {
                id: institutionId,
                name: institutionName
            },
            viewedStatus: {
                id: statusId,
                name: statusName
            },
            viewedApplications: {}
        });

        applicationsService.getApplications(institutionId, statusName).then( applications => {
            if ( !applications.error ) {
                let applicationsCopy = { ...applications };
    
                Object.keys(applications).forEach( (userId, index) => {
                    applicationsService.getLeanerContactInfo(userId).then( contactInfo => {
                        if ( !contactInfo.error ) {
                            applicationsCopy[userId].contact = contactInfo;
    
                            this.setState({
                                viewedApplications: applicationsCopy
                            })
                        }
                    })
                })
                // Upgrades from setTime out to when getLearnerInfo is done updating
                setTimeout(() => {
                    this.setState({
                        loadingViewApplications: false
                    })
                }, 5000)
            }
        });
    }

    handleShowApplicationsCards = ( event ) => {
        if ( event ) {
            event.preventDefault();
        } 

        this.setState({
            showApplicationsList: false,
            loadingApplicationCards: true,
            viewStudentApplication: false,
            viewStudentBursaries: false,
            nameSearchList: [],
            nameSearchText: "",
            nameSearchUserId: ""
        });

        applicationsService.getApplicationCards( this.state.userId ).then( applicationsCards => {
            if ( !applicationsCards.error ) {
                this.setState({
                    applicationsCards: applicationsCards,
                });

                applicationsService.getGroupsDashboard().then( dashboard => {
                    if ( !dashboard.error ) {
                        this.setState({
                            // loadingApplicationCards: false,
                            groupsDashboard: dashboard
                        })
                    }
                });

                applicationsService.getAPSDashboard().then( dashboard => {
                    if ( !dashboard.error ) {
                        this.setState({
                            loadingApplicationCards: false,
                            APDashboard: dashboard
                        })
                    }
                });
            }
        });
    }

    handleShowStudentApplications = ( event, studentName, studentId, institutionId ) => {
        event.preventDefault();

        let viewedStudentApplicationsCopy = { ...this.state.viewedStudentApplications };
            viewedStudentApplicationsCopy.name = studentName;
            viewedStudentApplicationsCopy.studentId = studentId;
            viewedStudentApplicationsCopy.institutionId = institutionId;
            viewedStudentApplicationsCopy.username = "";
            viewedStudentApplicationsCopy.password = "";

        this.setState({
            loadingStudentApplications: true,
            viewStudentApplication: true,
            viewStudentApplicationDocs: false,
            viewStudentBursaries: false,
            viewedStudentApplications: viewedStudentApplicationsCopy
        });

        applicationsService.getStudentApplications( studentId, institutionId ).then( studentApplications => {
            if ( !studentApplications.error ) {
                this.setState({
                    studentApplications: studentApplications,
                });

                applicationsService.getApplicantLoginInfo( studentId ).then( applicantLoginInfo => {
                    if ( applicantLoginInfo && (!applicantLoginInfo.error) && (applicantLoginInfo[institutionId])) {
                        let loginInfo = applicantLoginInfo[institutionId];
    
                        viewedStudentApplicationsCopy.username = loginInfo.userName;
                        viewedStudentApplicationsCopy.password = loginInfo.password;
    
                        this.setState({
                            viewedStudentApplications: viewedStudentApplicationsCopy,
                            loadingStudentApplications: false,
                        });

                    } else {
                        this.setState({
                            loadingStudentApplications: false,
                        })
                    }
                });
            }
        });
    }

    handleInputChange = ( event ) => {
        let inputValue = event.target.value;
        let searchAllUsers = this.state.searchAllUsers;
        let nameSearchIndex = this.state.nameSearchIndex;

        if ( inputValue !== "" ) {
            applicationsService.searchApplicantName( inputValue, searchAllUsers, nameSearchIndex ).then( response => {
                if ( response[0] !== "error") {
                    this.setState({
                        nameSearchList: response,
                        searchAllUsers: this.state.searchAllUsers
                    });
                }
            });

        } else {
            this.setState({
                nameSearchList: []
            });
        }
        this.setState({
            nameSearchText: inputValue
        });
    }

    handleSetNameSearchItem = ( name, id ) => {
        this.setState({
            nameSearchUserId: id,
            nameSearchText: name,
            nameSearchList: [],
            loadingViewApplications: true,
            showApplicationsList: true
        });

        applicationsService.getNameSearchApplications(id).then( applications => {
            if ( !applications.error ) {
                this.setState({
                    viewedApplicationInstitutions: applications,
                });

                let viewedStudentApplicationsCopy = { ...this.state.viewedStudentApplications }
                applicationsService.getLeanerContactInfo(id).then( contactInfo => {
                    if ( !contactInfo.error ) {
                        viewedStudentApplicationsCopy.contactDetails = contactInfo;

                        this.setState({
                            viewedStudentApplications: viewedStudentApplicationsCopy,
                            loadingViewApplications: false,
                        })
                    }
                })
            }
        });
    }

    handleChangeApplicationStatus = ( event, userId, qualificationsId, isExtended, institutionId) => {
        event.preventDefault();

        let statusId;

        this.setState({
            loadingStudentApplications: true
        });

        this.state.statusList.forEach( status => {
            if ( status.Name === event.target.value) {
                statusId = status.ID
            }
        });

        applicationsService.updateApplicationStatus(userId, qualificationsId, statusId, isExtended ).then( response => {
            if ( response ) {
                applicationsService.getStudentApplications( userId, institutionId ).then( studentApplications => {
                    if ( !studentApplications.error ) {
                        this.setState({
                            studentApplications: studentApplications,
                            loadingStudentApplications: false
                        });
                    }
                });
            }
        });
    }

    handleChangeAllApplicationStatus = ( event ) => {
        event.preventDefault();

        let userId = this.state.viewedStudentApplications.studentId;
        let globalStatus = event.target.value;
        let statusId = "";
        let qualificationsId = "";
        let isExtended = "";
        let institutionId = this.state.viewedStudentApplications.institutionId;
        let statusUpdated = false;

        this.setState({
            loadingStudentApplications: true,
        });

        this.state.statusList.forEach( status => {
            if ( status.Name === globalStatus) {
                statusId = status.ID
            }
        });

        Object.keys(this.state.studentApplications).forEach( institution => {
            this.state.studentApplications[institution].map( application => {
                qualificationsId = application.QualificationID;
                isExtended = application.ExtendedProgram;

                applicationsService.updateApplicationStatus(userId, qualificationsId, statusId, isExtended ).then( response => {
                    if ( response ) {
                        applicationsService.getStudentApplications( userId, institutionId ).then( studentApplications => {
                            if ( !studentApplications.error ) {
                                this.setState({
                                    studentApplications: studentApplications,
                                    loadingStudentApplications: false
                                });
                            }
                        });
                    }
                });
            });
        });
    }

    handleShowApplicationDocs = ( event, studentId, name, institutionId ) => {
        if ( name === "" ) {
            event.preventDefault();
        }

        let viewedStudentApplicationsCopy = { ...this.state.viewedStudentApplications };
            viewedStudentApplicationsCopy.name = name;
            viewedStudentApplicationsCopy.studentId = studentId;
            viewedStudentApplicationsCopy.institutionId = institutionId;


        this.setState({
            loadingApplicationDocs: true,
            viewStudentApplication: false,
            viewStudentApplicationDocs: true,
            viewStudentBursaries: false,
            viewedStudentApplications: viewedStudentApplicationsCopy,
        });

        applicationsService.getStudentApplicationDocs(studentId).then( studentDocs => {
            if ( studentDocs[0] !== "error" ) {
                this.setState({
                    viewedApplicationDocs: studentDocs,
                    loadingApplicationDocs: false
                });
            }
        });
    }

    handleShowHideDocsModal = () => {
        let showDocsModal = !this.state.showDocsModal;

        if ( !showDocsModal ) {
            this.setState({
                requestedDocsText: "",
                requestedDocs: ""
            });
        }

        this.setState({
            showDocsModal: showDocsModal,
        })
    }

    handleRequestedDocsTextChange = ( event ) => {
        event.preventDefault();

        this.setState({
            requestedDocsText: event.target.value
        })
    }

    handleRequestDocument = ( docId ) => {
        let requestedDocsCopy = [ ...this.state.requestedDocs ];

        requestedDocsCopy.push(docId);

        this.setState({
            requestedDocs: requestedDocsCopy
        });

        this.handleShowHideDocsModal();
    }

    handleSendDocRequest = () => {
        if ( this.state.requestedDocsText !== "" ) {
            let docIds = this.state.requestedDocs;
            let userId = this.state.viewedStudentApplications.studentId;
            let message = this.state.requestedDocsText;
            
            applicationsService.requestApplicationsDocs(docIds, userId, message).then( response => {
                if ( response ) {
                    console.log("request sent");
                    this.setState({
                        requestedDocsText: "",
                        requestedDocs: "",
                        showDocsModal: false
                    });
                }
            });

        } else {
            alert("Add request message");
        }
    }

    handleApplicationDocSelect = ( event, docId, studentId ) => {
        event.preventDefault();

        let doc = event.target.files[0];

        applicationsService.uploadApplicationDocument(docId, studentId, doc, doc.name).then( response => {
            console.log(doc);
            if ( response ) {
                this.handleShowApplicationDocs( event, studentId );
            }
        });
    }

    handleDocumentDownload = ( event, docId, userId, docName ) => {
        event.preventDefault();
        
        let fileName = "";

        if ( docName ) {
            applicationsService.downloadApplicationDocs(docId, userId).then( response => {
                if ( response !== "error" ) {
                    console.log("file downloaded");
                    fileName = response;

                    window.open(configConstants.SERVER_NAME + "/securefiles/" + fileName, "_blank");
                }

                this.setState({
                    viewedDocName: fileName
                });
            });
        }
    }

    handleDeleteDocument = () => {
        let fileName = this.state.viewedDocName;

        if ( fileName !== "" ) {
            applicationsService.removeFile(fileName).then( response => {
                if ( response ) {
                    console.log("file remove");
                }
            });

            this.setState({
                viewedDocName: ""
            });
        }
    }

    handleAssignApplication = ( applicantId ) => {
        let institutionId = this.state.viewedInstitution.institutionId;
        let institutionName = this.state.viewedInstitution.name;
        let statusId = this.state.viewedStatus.statusId;
        let statusName = this.state.viewedStatus.name;
        
        this.setState({
            loadingViewApplications: true,
            showApplicationsList: true,
            viewStudentApplication: false,
            viewStudentApplicationDocs: false,
            viewStudentBursaries: false,
            viewedInstitution: {
                id: institutionId,
                name: institutionName
            },
            viewedStatus: {
                id: statusId,
                name: statusName
            },
        });

        applicationsService.assignApplication(applicantId).then( response => {
            applicationsService.getApplications(statusId, statusName).then( applications => {
                if ( !applications.error ) {
                    this.setState({
                        viewedApplications: applications,
                        loadingViewApplications: false
                    });
                }
            });

            applicationsService.getAdminList().then( adminList => {
                if ( adminList[0] !== "error" ) {
                    this.setState({
                        adminList: adminList
                    });
                }
            });

            this.handleSetAssignedStatus(null, applicantId);
        });
    }

    handleChangeAdmin = ( event ) => {
        event.preventDefault();
        let adminName = event.target.value;
        let adminId = "";

        if ( adminName !== "mine" ) {
            this.state.adminList.forEach( admin => {
                if ( admin.Name + " " + admin.Surname === adminName ) {
                    adminId = admin.ID;
                }
            });

        } else {
            let userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : null;

            if ( userInfo ) {
                adminId = userInfo.userId;
            }
        }

        this.setState({
            loadingApplicationCards: true,
            adminName: adminName,
            userId: adminId
        }); 

        applicationsService.getApplicationCards(adminId).then( applicationsCards => {
            if ( !applicationsCards.error ) {
                this.setState({
                    applicationsCards: applicationsCards,
                    loadingApplicationCards: false
                });
            }
        });
    }

    handleShowAdminList = ( studentId ) => {
        this.setState({
            showAdminList: studentId
        });
    }

    handleAssignApplicationToAdmin = ( adminId, applicationId ) => {
        console.log(`Assigned application ${ applicationId } to user ${ adminId }`)

        let statusId = this.state.viewedStatus.statusId;
        let statusName = this.state.viewedStatus.name;

        this.setState({
            showAdminList: 0,
            loadingViewApplications: true,
            viewedStatus: {
                id: statusId,
                name: statusName
            },
        });

        applicationsService.assignApplicationToAdmin( adminId, applicationId ).then( response => {
            if ( response ) {
                applicationsService.getApplications(statusId, statusName).then( applications => {
                    if ( !applications.error ) {
                        this.setState({
                            viewedApplications: applications,
                            loadingViewApplications: false
                        });
                    }
                });
    
                applicationsService.getAdminList().then( adminList => {
                    if ( adminList[0] !== "error" ) {
                        this.setState({
                            adminList: adminList
                        });
                    }
                });

                this.handleSetAssignedStatus(adminId, applicationId);
            }
        });
    }

    handleExpandInsitution = ( event, institutionName ) => {
        event.preventDefault();

        let viewedApplication = this.state.viewedApplicationInstitutions[institutionName];
        let institutionId = this.state.viewedApplicationInstitutions[institutionName][0].InstitutionID;
        let userId = this.state.nameSearchUserId;
        let viewedInstitution = {};
        let viewedStudentApplicationsCopy = { ...this.state.viewedStudentApplications };
            viewedStudentApplicationsCopy.name = this.state.nameSearchText;
            viewedStudentApplicationsCopy.studentId = this.state.nameSearchUserId;
            viewedStudentApplicationsCopy.institutionId = institutionId;
            viewedStudentApplicationsCopy.username = "";
            viewedStudentApplicationsCopy.password = "";

       

        applicationsService.getApplicantLoginInfo(userId).then( applicantLoginInfo => {
            if ( applicantLoginInfo && (!applicantLoginInfo.error) && (applicantLoginInfo[institutionId])) {
                let loginInfo = applicantLoginInfo[institutionId];
                
                viewedStudentApplicationsCopy.username = loginInfo.userName;
                viewedStudentApplicationsCopy.password = loginInfo.password;

                this.setState({
                    viewedStudentApplications: viewedStudentApplicationsCopy
                });
            }
        });

        this.setState({
            loadingStudentApplications: true,
            viewStudentApplication: false,
            viewedStudentApplications: viewedStudentApplicationsCopy,
            viewedInstitution: {
                id: institutionId,
                name: institutionName
            }
        });

        viewedInstitution[institutionName] = viewedApplication;

        this.setState({
            loadingStudentApplications: false,
            viewStudentApplication: true,
            studentApplications: viewedInstitution
        });
    }

    handleHideExpandedInstitution = ( event ) => {
        event.preventDefault();

        this.setState({
            viewStudentApplication: false,
            viewStudentApplicationDocs: false,
            viewStudentBursaries: false,
        });
    }

    handleSetAssignedStatus = ( adminId, applicantId ) => {
        let applicationsList;
        let qualificationsId;
        let institutionId;
        let isExtended;

        applicationsService.getNameSearchApplications( applicantId ).then( applications => {
            if ( !applications.error ) {
                applicationsList = applications;

                Object.keys(applications).forEach( institutionName => {
                    applications[institutionName].map( application => {
                        qualificationsId = application.QualificationID;
                        isExtended = application.ExtendedProgram;
                        institutionId = application.InstitutionID;

                        applicationsService.updateApplicationStatus(applicantId, qualificationsId, 3, isExtended ).then( response => {
                            if ( response ) {
                                applicationsService.getStudentApplications( applicantId, institutionId ).then( studentApplications => {
                                    if ( !studentApplications.error ) {
                                        this.setState({
                                            studentApplications: studentApplications,
                                            loadingStudentApplications: false
                                        });
                                    }
                                });
                            }
                        });
                    })
                });
            }
        });
    }

    handleSearchAllUsers = (event) => {
        let inputChecked = event.target.checked;

        this.setState({
            searchAllUsers: inputChecked
        })
    }
    
    handleAddNewApplicationInstitution = ( institutionId, institutionName ) => {
        let newApplicationsListCopy = [ ...this.state.newApplicationsList ];

        newApplicationsListCopy.push({position: newApplicationsListCopy.length , id:institutionId, name: institutionName, applications: []})

        this.setState({
            newApplicationsList: newApplicationsListCopy
        });
    }


    handleInstitutionInputChange = ( event ) => {
        let inputValue = event.target.value;

        if ( inputValue.trim() !== "" ) {
            applicationsService.searchInstitution( inputValue ).then( response => {
                if ( response[0] !== "error") {
                    this.setState({
                        institutionList: response,
                    });
                }
            });

        } else {
            this.setState({
                institutionList: []
            });
        }
        this.setState({
            institutionSearchText: inputValue
        });
    }

    handleSetSelectedInstitution = ( position, institutionId, institutionName ) => {
        let newApplicationsListCopy = [ ...this.state.newApplicationsList ];

        newApplicationsListCopy.forEach( institution => {
            if ( institution.position === position ) {
                institution.name = institutionName;
                institution.id = institutionId
            }
        });

        this.setState({
            newApplicationsList: newApplicationsListCopy,
            institutionList: [],
            institutionSearchText: ""
        });
    }

    handleShowInstitutionApplications = ( event, institutionName ) => {
        event.preventDefault();

        let institutionItem = {};

        this.state.newApplicationsList.forEach( institution => {
            if ( institution.name === institutionName ) {
                institutionItem = institution;
            }   
        });

        this.setState({
            showNewApplicationInstitutionsList: false,
            viewedInstitution: {
                id: institutionItem.id,
                name: institutionItem.name,
            },
            viewedApplications: institutionItem.applications
        });
    }

    handleHideInstitutionApplications = ( event ) => {
        event.preventDefault();

        this.setState({
            showNewApplicationInstitutionsList: true,
            viewedApplication: {
                id: "",
                name: ""
            },
            viewedApplications: []
        })
    }

    handleAddNewApplicationQualification = () => {
        let institutionName = this.state.viewedInstitution.name;
        let institutionItem;
        let newApplicationsListCopy = [ ...this.state.newApplicationsList ];

        newApplicationsListCopy.forEach( institution => {
            if ( institution.name === institutionName ) {
                institutionItem = institution;
            }
        });

        institutionItem.applications.push({position: institutionItem.applications.length, id: "", name: "", rate: ""});

        this.setState({
            newApplicationsList: newApplicationsListCopy
        })
    }

    handleSearchQualification = ( event ) => {
        let inputValue = event.target.value;
        let institutionId = this.state.viewedInstitution.id;
        let userId = this.state.nameSearchUserId;

        if ( inputValue.trim() !== "" ) {
            applicationsService.searchQualification( institutionId, inputValue, userId ).then( response => {
                if ( response[0] !== "error") {
                    this.setState({
                        qualificationList: response,
                    });
                }
            });

        } else {
            this.setState({
                qualificationList: []
            });
        }

        this.setState({
            searchQualificationText: inputValue
        });
    }

    handleSetSelectedQaulification = ( position, qualificationId, qualificationName, qualificationRate ) => {
        let institutionName = this.state.viewedInstitution.name;
        let newApplicationsListCopy = [ ...this.state.newApplicationsList ];

        newApplicationsListCopy.forEach( institution => {
            if ( institution.name === institutionName ) {
                institution.applications.forEach( qualification => {
                    if ( qualification.position === position ) {
                        qualification.id = qualificationId
                        qualification.name = qualificationName
                        qualification.rate = qualificationRate
                    }
                });
            }
        });

        this.setState({
            newApplicationsList: newApplicationsListCopy,
            qualificationList: [],
            searchQualificationText: ""
        });
    }

    handleSubmitApplication = () => {
        let qualifications = [];
        let userId = this.state.nameSearchUserId;

        this.setState({
            submitApplication: true
        })

        this.state.newApplicationsList.forEach( institution => {
            institution.applications.forEach( qualification => {
                qualifications.push(qualification.id);
            });
        });

        applicationsService.submitApplication( qualifications, userId ).then( response => {
            if ( response ) {
                applicationsService.sendInstruction( userId ).then( inst_response => {
                    if ( inst_response ) {
                        this.setState({
                            viewedInstitution: {
                                name: "",
                                id: ""
                            },
                            newApplicationsList: [],
                            submitApplication: false
                        })
                        this.handleShowApplicationsCards();
                    }
                });
            }
        })
    }

    handleShowAdminAPList = ( applicantId, institutionId ) => {
        this.setState({
            APAssignId: institutionId,
            institutionToAssign: applicantId + "." + institutionId
        });

        console.log(applicantId, institutionId);
    }

    handleAssignApplicationToAP = ( userId, applicantId, institutionId ) => {
        let statusId = this.state.viewedStatus.statusId;
        let statusName = this.state.viewedStatus.name;

        this.setState({
            showAdminList: 0,
            loadingViewApplications: true,
            viewedStatus: {
                id: statusId,
                name: statusName
            },
        });

        applicationsService.assignApplicationToAP(userId, applicantId, institutionId).then( response => {
            if ( response ) {
                applicationsService.getApplications(statusId, statusName).then( applications => {
                    if ( !applications.error ) {
                        this.setState({
                            viewedApplications: applications,
                            loadingViewApplications: false,
                            APAssignId: "",
                            institutionToAssign: ""
                        });
                    }
                });
    
                applicationsService.getAdminList().then( adminList => {
                    if ( adminList[0] !== "error" ) {
                        this.setState({
                            adminList: adminList
                        });
                    }
                });
            }
        });     
    }

    handleHideAPList = () => {
        this.setState({
            institutionToAssign: ""
        });
    }

    handleHideACList = () => {
        this.setState({
            showAdminList: ""
        });
    }

    handleApplicantLoginInfoChange = ( event ) => {
        let inputValue = event.target.value;
        let inputName = event.target.name;
        let viewedStudentApplicationsCopy = { ...this.state.viewedStudentApplications };

        viewedStudentApplicationsCopy[inputName] = inputValue;

        this.setState({
            viewedStudentApplications: viewedStudentApplicationsCopy
        });
    }

    handleSaveApplicantLoginInfo = () => {
        let userName = this.state.viewedStudentApplications.username;
        let userPassword = this.state.viewedStudentApplications.password;
        let userId = this.state.viewedStudentApplications.studentId;
        let institutionId = this.state.viewedStudentApplications.institutionId;

        this.setState({
            loadingStudentApplications: true,
        });

        applicationsService.saveApplicantLoginInfo( institutionId, userName, userPassword, userId ).then( response => {
            if ( response ) {
                this.setState({
                    loadingStudentApplications: false,
                });
            }
        })
    }

    handleShowQualificationMatchInfo = (userId, qualificationId) => {
        this.setState({
            loadingQualificationMatch: true
        });

        applicationsService.getQualificationMatchInfo(userId, qualificationId).then( qualificationInfo => {
            if ( qualificationInfo[0] !== "error" ) {
                this.setState({
                    showMatchInfoBackdrop: true,
                    loadingQualificationMatch: false,
                    qualificationMatchInfo: qualificationInfo
                });
            }
        });
    }

    handleHideQualificationMatchInfo = () => {
        this.setState({
            showMatchInfoBackdrop: false
        });
    }

    handleShowApplicantInfo = (event, userId) => {
        event.preventDefault();

        let viewedStudentApplicationsCopy = {  ...this.state.viewedStudentApplications };

        this.setState({
            showApplicantInfoBackdrop: true,
            loadingApplicantInfo: true,
        });

        applicationsService.getApplicationDetails(userId).then( applicationDetails => {
            if ( applicationDetails && !applicationDetails.error ) {
                viewedStudentApplicationsCopy.applicationDetails = applicationDetails;

                this.setState({
                    viewedStudentApplications: viewedStudentApplicationsCopy,
                });

                applicationsService.getLeanerContactInfo(userId).then( learnerInfo => {
                    if ( !learnerInfo.error ) {
                        let userInfoCopy = { ...this.state.userInfo };
                        userInfoCopy.gradesMail.value = learnerInfo.GradesMail;
                        userInfoCopy.school.value = learnerInfo.schoolName;
                        userInfoCopy.number.value = learnerInfo.Phone;
                        viewedStudentApplicationsCopy.contactDetails = learnerInfo;

                        this.setState({
                            viewedStudentApplications: viewedStudentApplicationsCopy,
                            userInfo: userInfoCopy,
                            loadingApplicantInfo: false,
                        });
                    }
                });

            } else {
                this.handleHideApplicantInfo();
            }
        })
    }

    handleHideApplicantInfo = () => {
        this.setState({
            showApplicantInfoBackdrop: false,
        })
    }

    handleApplicantInfoOnChange = ( event ) => {
        event.preventDefault();

        let inputName = event.target.name;
        let inputValue = event.target.value;
        let userInfoCopy = { ...this.state.userInfo };

        switch( inputName ) {
            case "gradesmail":
                userInfoCopy.gradesMail.value = inputValue;
                break;

            case "number": 
                userInfoCopy.number.value = inputValue;
                break;

            case "school": 
                let schoolNameList = [];
                userInfoCopy.school.search = inputValue;
                userInfoCopy.id = "";

                HTTPRequest({
                    method: "POST",
                    url   : "/schools/find/limit-10-index-0",
                    data  : { "like": inputValue }

                }).then( response => {
                    if ( response.data.success ) {
                        let responseData = response.data.data;

                        if ( responseData.length >= 1) {
                            schoolNameList = responseData.map( schoolNameObj => {
                                let province = schoolNameObj.Province
                                let district = schoolNameObj.EIDistrict !== "NOT APPLICABLE" ? " " + schoolNameObj.EIDistrict  : "";

                                if ( responseData.length === 1 && responseData[0].Name === "Can't Find School" ) {
                                    return (
                                        <li key={ -1 } className="list-no-result"><em>{ schoolNameObj.Name }</em></li>
                                    );

                                } else {
                                    return (
                                        <li key={ schoolNameObj.ID } onClick={ () => this.handleSearchItemSelect( schoolNameObj ) } >{ schoolNameObj.Name + " (" + province + district + ")" }</li>
                                    );
                                }
                            });
                        }

                    } else {
                        schoolNameList.push(
                            <li key={ -1 } className="list-no-result">
                                <em>{ response.data.reason }</em>
                            </li>
                        );
                    }

                    this.setState({
                        schoolNameList: schoolNameList
                    })
                });
                break;
            
            case "parentName": 
                userInfoCopy.parentDetails.name.value = inputValue;
                break;

            case "parentNumber": 
                userInfoCopy.parentDetails.number.value = inputValue;
                break;

            case "parentEmail": 
                userInfoCopy.parentDetails.email.value = inputValue;
                break;
        }

        this.setState({
            userInfo: userInfoCopy,
        });
    }

    handleSearchItemSelect = ( selectedItem ) => {
        let userInfoCopy = { ...this.state.userInfo }

        userInfoCopy.school.value = selectedItem.Name;
        userInfoCopy.school.search = "";
        userInfoCopy.school.id = selectedItem.ID;

        this.setState({ 
            userInfo: userInfoCopy
        })
    }

    handleSubjectInputChange = ( event, subjectLabel ) => {
        let inputName = event.target.name;
        let inputValue = event.target.value;
        let subjectListCopy = { ...this.state.subjectList };
        let subjectSearchList = [];

        if ( inputName.indexOf("subject") !== -1 ) {
            subjectListCopy[subjectLabel].name = inputValue;

            if ( SUBJECT_LIST ) {
                subjectSearchList = SUBJECT_LIST.filter( subject => {
                    return subject.Name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;

                }).map( subjectObj => {
                    return (
                        <li key={ subjectObj.ID } onClick={ () => this.handleSearchSubjectSelect( inputName, subjectObj ) }>{ subjectObj.Name }</li>
                    );
                });

                if ( subjectSearchList.length === 0 ) {
                    subjectSearchList.push(
                        <li key={ -1 } className="list-no-result">
                            <em>subject not found</em>
                        </li>
                    );
                }

                this.setState({
                    subjectSearchList: subjectSearchList,
                    focusedInput: inputName
                });
            }

        } else {
            subjectListCopy[subjectLabel].mark = inputValue;
            this.setState({
                focusedInput: ""
            })
        }

        this.setState({
            subjectList: subjectListCopy,
        });
    }

    handleSearchSubjectSelect = ( inputName, subjectObj ) => {
        let subjectListCopy = { ...this.state.subjectList };

        subjectListCopy[inputName].id = subjectObj.ID;
        subjectListCopy[inputName].name = subjectObj.Name;

        this.setState({
            subjectList: subjectListCopy,
            focusedInput: ""
        });
    }

    handleSaveMarks = () => {
        let marks = [];
        let termYear = this.state.termYears[0];
        let term = this.state.currentTerm;
        let userId = this.state.editedUserId;

        this.setState({
            savingMarks: true
        })

        Object.keys(this.state.userInfoForm.subjectList).forEach( subjectKey => {
            let subject = this.state.userInfoForm.subjectList[subjectKey];

            marks.push({
                "SubjectID": subject.id,
                "Mark": subject.mark
            });
        });

        applicationsService.saveMarks(marks, userId, termYear, term ).then( response => {
            if ( response ) {
                this.setState({
                    savingMarks: false
                })
            } else {
                this.setState({
                    savingMarks: false,
                    errorSavingMarks: true
                })
            }
        })
    }

    handleShowBursaries = (event, userId, name) => {
        event.preventDefault();

        let viewedStudentApplicationsCopy = { ...this.state.viewedStudentApplications };
        viewedStudentApplicationsCopy.studentId = userId;
        viewedStudentApplicationsCopy.name = name;

        this.setState({
            loadingBursaries: true,
            viewStudentApplication: false,
            viewStudentApplicationDocs: false,
            viewStudentBursaries: true,
            viewedStudentApplications: viewedStudentApplicationsCopy,
        });

        applicationsService.getBursaries( userId ).then( bursaries => {
            if ( bursaries[0] !== "error" ) {
                viewedStudentApplicationsCopy.bursariesList = bursaries;

                this.setState({
                    loadingBursaries: false,
                    viewedStudentApplications: viewedStudentApplicationsCopy,
                })
            }
        })
    }

    handleUpdateAllBursaryStatus = (event, userId) => {
        event.preventDefault();

        let statusId = this.state.statusList.filter( status => {
            return status.Name == event.target.value;
        });

        this.state.viewedStudentApplications.bursariesList.forEach( bursary => {
            this.setState({
                loadingBursaries: true,
            });

            applicationsService.updateBursary(userId, bursary.ID, statusId[0].ID).then( response => {
                if ( response ) {
                    this.setState({
                        loadingBursaries: false,
                    });
                }
            });
        });
    }

    handleUpdateBursaryStatus = ( event, userId, bursaryId ) => {
        event.preventDefault();

        let statusId = this.state.statusList.filter( status => {
            return status.Name == event.target.value;
        });

        let viewedStudentApplicationsCopy = { ...this.state.viewedStudentApplications };

        this.setState({
            loadingBursaries: true,
        });

        applicationsService.updateBursary(userId, bursaryId, statusId[0].ID).then( response => {
            if ( response ) {
                applicationsService.getBursaries( userId ).then( bursaries => {
                    if ( bursaries[0] !== "error" ) {
                        viewedStudentApplicationsCopy.bursariesList = bursaries;

                        this.setState({
                            loadingBursaries: false,
                        });
                    }
                });
            }
        });
    }

    handleShowMoreApplications = ( dir ) => {
        let minFetchLimit = this.state.minFetchLimit;
        let maxFetchLimit = this.state.maxFetchLimit;

        console.log("Nav click")

        if ( dir == "next" ) {
            if ( maxFetchLimit < Object.keys(this.state.viewedApplications).length ) {
                minFetchLimit = minFetchLimit + 10;
                maxFetchLimit = minFetchLimit + 10;
            }

        } else {
            if (minFetchLimit > 0) {
                minFetchLimit = minFetchLimit - 10;
                maxFetchLimit = maxFetchLimit - 10;
            }
        }

        this.setState({
            minFetchLimit: minFetchLimit,
            maxFetchLimit: maxFetchLimit,
        });
    }

    handleViewMoreUsers = () => {
        let nameSearchIndex = this.state.nameSearchIndex + 10;
        let nameSearchText = this.state.nameSearchText;
        let searchAllUsers = this.state.searchAllUsers;

        this.setState({
            loadingMoreUsers: true,
        })

        applicationsService.searchApplicantName( nameSearchText, searchAllUsers, nameSearchIndex ).then( response => {
            if ( response[0] !== "error") {
                this.setState({
                    nameSearchList: response,
                    searchAllUsers: this.state.searchAllUsers,
                    loadingMoreUsers: false,
                });
            }
        });

        this.setState({
            nameSearchIndex: nameSearchIndex
        });
    }

    handleShowNewApplications = () => {
        this.setState({
            viewNewApplications: true
        });
    }

    handleShowNewBursaries = () => {
        this.setState({
            viewNewApplications: false
        });
    }

    handleAddNewBursaryItem = () => {
        let newBursaryItemsCopy = [ ...this.state.newBursaryItems ];

        newBursaryItemsCopy.push({ID: "", Name: ""});

        this.setState({
            newBursaryItems: newBursaryItemsCopy,
        });
    }

    handleResetApplication = () => {
        this.setState({
            viewedApplicationInstitutions: []
        });
    }

    handleSaveStudentInfo = () => {
        this.setState({
            loadingApplicantInfo: true
        })
    }

    handleSaveParentInfo = () => {
        this.setState({
            loadingApplicantInfo: true
        })
    }

    handleSaveUserMarks = () => {
        this.setState({
            loadingApplicantInfo: true
        })
    }

    handleDeleteNewInstitution = ( position ) => {
        let newInstitutionListCopy = [ ...this.state.newApplicationsList ];
        newInstitutionListCopy.splice(position, 1);

        this.setState({
            newApplicationsList: newInstitutionListCopy
        })
    }

    handleDeleteNewQualification = (institutionName, qualificationId) => {
        let newInstitutionListCopy = [ ...this.state.newApplicationsList ];
        let institutionCopy = {}
        let institutionPosition = null;

        newInstitutionListCopy.forEach((institution, index) => {
            if ( institution.name === institutionName ) {
                institutionCopy = institution
                institutionPosition = index;
            }
        });

        let qualificationList = [ ...institutionCopy.applications ];
        qualificationList = qualificationList.filter( qualification => {
            return qualification.id !== qualificationId;
        });

    
        newInstitutionListCopy[institutionPosition].applications = qualificationList;

        this.setState({
            newApplicationsList: newInstitutionListCopy,
            viewedApplications: qualificationList,
        })
    }
    
    render() {
        let pageViewContent;
        let applications = this.state.applicationsCards;
        let applicationsCards = [];

        if ( Object.keys(applications).length > 0 ) {
            applicationsCards = Object.keys(applications).map( (cardName, index) => {
                let adminId = 0;

                if ( cardName !== "all" ) {
                    adminId = this.state.userId;
                }

                return (
                    <ApplicationCard 
                        cardData={ applications[cardName] } 
                        cardName={ cardName }
                        adminName={ this.state.adminName } 
                        key={ index } 
                        showApplicationsList={ this.handleShowApplications } 
                        userId={ this.state.userId }
                        adminList={ this.state.adminList }
                        adminId={ adminId }
                        onAdminChange={ this.handleChangeAdmin }
                    />
                );
            });
        }

        if ( this.state.loadingApplicationCards ) {
            pageViewContent = (
                <LoadingData title="fetching applications cards" />
            );

        } else {
            pageViewContent = (
                <div className="col-12">
                    <NameFilter 
                        name={ this.state.nameSearchText } 
                        nameSearchList={ this.state.nameSearchList } 
                        inputChange={ this.handleInputChange } 
                        setNameSearch={ this.handleSetNameSearchItem }
                        searchAllUsers={ this.state.searchAllUsers }
                        checkSearchAllUsers={ this.handleSearchAllUsers }
                        viewMoreUsers={ this.handleViewMoreUsers }
                        loadingMoreUsers={ this.state.loadingMoreUsers }
                    />
                    <div className="row application-cards">
                        { applicationsCards }
                    </div>
                    <div className="row dashboards-section">
                        <div className="col-2">
                            <h5>Dashboards</h5>
                        </div>
                        <div className="col-10">
                            <hr />
                        </div>
                    </div>
                    {
                        (Object.keys(this.state.groupsDashboard).length) > 0 ?
                            <GroupsDashboard 
                                dashboard={ this.state.groupsDashboard }
                            />
                            : ""
                    }
                    {
                        (Object.keys(this.state.APDashboard).length) > 0 ?
                            <APDashboard 
                                dashboard={ this.state.APDashboard }
                            /> 
                            : ""
                    }
                </div>
            );
        }

        if ( this.state.showApplicationsList ) {
            if ( this.state.loadingViewApplications ) {
                pageViewContent = <LoadingData title="fetching applications" />
            
            } else if (this.state.nameSearchUserId === "") {
                pageViewContent = <ApplicationsList 
                                        viewedApplications={ this.state.viewedApplications } 
                                        viewedStatus={ this.state.viewedStatus } 
                                        viewedInstitution={ this.state.viewedInstitution } 
                                        showApplicationsCards={ this.handleShowApplicationsCards }
                                        showStudentApplications={ this.handleShowStudentApplications }
                                        loadingStudentApplications={ this.state.loadingStudentApplications }
                                        studentApplications={ this.state.studentApplications }
                                        showApplicationsList={ this.handleShowApplications }
                                        viewStudentApplication={ this.state.viewStudentApplication }
                                        viewedStudentApplications={ this.state.viewedStudentApplications }
                                        statusList={ this.state.statusList }
                                        updateApplicationStatus={ this.handleChangeApplicationStatus }
                                        updateAllApplicationStatus={ this.handleChangeAllApplicationStatus }
                                        showApplicationDocs={ this.handleShowApplicationDocs }
                                        viewStudentApplicationDocs={ this.state.viewStudentApplicationDocs }
                                        viewedApplicationDocs={ this.state.viewedApplicationDocs }
                                        viewStudentBursaries={ this.state.viewStudentBursaries }
                                        loadingApplicationDocs={ this.state.loadingApplicationDocs }
                                        showDocsModal={ this.state.showDocsModal }
                                        hideDocsModal={ this.handleShowHideDocsModal }
                                        requestedDocsText={ this.state.requestedDocsText }
                                        requestedDocsTextChange={ this.handleRequestedDocsTextChange }
                                        requestDocument={ this.handleRequestDocument }
                                        sendDocRequest={ this.handleSendDocRequest }
                                        applicationDocSelect={ this.handleApplicationDocSelect }
                                        downloadDoc={ this.handleDocumentDownload }
                                        deleteDoc={ this.handleDeleteDocument }
                                        assignApplication={ this.handleAssignApplication }
                                        adminList={ this.state.adminList }
                                        handleShowAdminList={ this.handleShowAdminList }
                                        showAdminList={ this.state.showAdminList }
                                        assignApplicationToAdmin={ this.handleAssignApplicationToAdmin }
                                        userRole={ this.state.userRole } 
                                        showAdminAPList={ this.handleShowAdminAPList }
                                        assignApplicationToAP={ this.handleAssignApplicationToAP }
                                        APAssignId={ this.state.APAssignId }
                                        institutionToAssign={ this.state.institutionToAssign }
                                        hideAPList={ this.handleHideAPList }
                                        hideACList={ this.handleHideACList }
                                        showMatchInfoBackdrop={ this.state.showMatchInfoBackdrop }
                                        showQualificationMatchInfo={ this.handleShowQualificationMatchInfo }
                                        hideQualificationMatchInfo={ this.handleHideQualificationMatchInfo }
                                        loadingQualificationMatch={ this.state.loadingQualificationMatch }
                                        QMInfo={ this.state.qualificationMatchInfo[0] }
                                        applicantLoginInfoChange={ this.handleApplicantLoginInfoChange }
                                        saveApplicantLoginInfo={ this.handleSaveApplicantLoginInfo }
                                        showApplicantInfoBackdrop={ this.state.showApplicantInfoBackdrop }
                                        showApplicantInfo={ this.handleShowApplicantInfo }
                                        hideApplicantInfo={ this.handleHideApplicantInfo }
                                        applicationInfo={ this.state.viewedStudentApplications.applicationDetails }
                                        applicantContactInfo={ this.state.viewedStudentApplications.contactDetails }
                                        subjectList={ this.state.subjectList }
                                        applicantInfoOnChange={ this.handleApplicantInfoOnChange }
                                        userInfo={ this.state.userInfo }
                                        subjectInputChange={ this.handleSubjectInputChange }
                                        subjectSearchList={ this.state.subjectSearchList }
                                        focusedInput={ this.state.focusedInput }
                                        saveMarks={ this.handleSaveMarks }
                                        savingMarks={ this.state.savingMarks }
                                        showBursaries={ this.handleShowBursaries }
                                        loadingBursaries={ this.state.loadingBursaries }
                                        studentBursaries={ this.state.viewedStudentApplications.bursariesList }
                                        updateAllBursaryStatus={ this.handleUpdateAllBursaryStatus }
                                        updateBursaryStatus={ this.handleUpdateBursaryStatus }
                                        showMoreApplications={ this.handleShowMoreApplications }
                                        fetchMoreUsers={ this.state.fetchMoreUsers }
                                        minFetchLimit={ this.state.minFetchLimit }
                                        maxFetchLimit={ this.state.maxFetchLimit }
                                        loadingApplicantInfo={ this.state.loadingApplicantInfo }
                                        saveStudentInfo={ this.handleSaveStudentInfo }
                                        saveParentInfo={ this.handleSaveParentInfo }
                                        saveUserMarks={ this.handleSaveUserMarks }
                                        schoolList={ this.state.schoolNameList }
                                    />;
            } else {
                pageViewContent = (
                    <ApplicationInstitutions 
                        leanerName={ this.state.nameSearchText }
                        applicationsList={ this.state.viewedApplicationInstitutions }
                        showApplicationsCards={ this.handleShowApplicationsCards }
                        viewStudentApplication={ this.state.viewStudentApplication }
                        viewedStudentApplications={ this.state.viewedStudentApplications }
                        viewStudentApplicationDocs={ this.state.viewStudentApplicationDocs }
                        viewedApplicationDocs={ this.state.viewedApplicationDocs }
                        loadingApplicationDocs={ this.state.loadingApplicationDocs }
                        institutionName={ this.state.viewedInstitution.name }
                        loadingStudentApplications={ this.state.loadingStudentApplications }
                        loadingApplicationDocs={ this.state.loadingApplicationDocs }
                        studentApplications={ this.state.studentApplications }
                        statusList={ this.state.statusList }
                        updateApplicationStatus={ this.handleChangeApplicationStatus }
                        updateAllApplicationStatus={ this.handleChangeAllApplicationStatus }
                        showInstitutionApplications={ this.handleExpandInsitution }
                        showApplicationsList={ this.handleHideExpandedInstitution }
                        learnerName={ this.state.nameSearchText }
                        learnerId={ this.state.nameSearchUserId }
                        institutionId={ this.state.viewedInstitution.id }
                        showApplicationDocs={ this.handleShowApplicationDocs }
                        showDocsModal={ this.state.showDocsModal }
                        hideDocsModal={ this.handleShowHideDocsModal }
                        requestedDocsText={ this.state.requestedDocsText }
                        requestedDocsTextChange={ this.handleRequestedDocsTextChange }
                        requestDocument={ this.handleRequestDocument }
                        sendDocRequest={ this.handleSendDocRequest }
                        applicationDocSelect={ this.handleApplicationDocSelect }
                        downloadDoc={ this.handleDocumentDownload }
                        deleteDoc={ this.handleDeleteDocument }
                        assignApplication={ this.handleAssignApplication }
                        adminList={ this.state.adminList }
                        handleShowAdminList={ this.handleShowAdminList }
                        showAdminList={ this.state.showAdminList }
                        assignApplicationToAdmin={ this.handleAssignApplicationToAdmin }
                        userRole={ this.state.userRole } 
                        newApplicationsList={ this.state.newApplicationsList }
                        addNewApplicationInstitution={ this.handleAddNewApplicationInstitution }
                        newInstitutionList={ this.state.institutionList }
                        searchInstitution={ this.handleInstitutionInputChange }
                        searchInstitutionText={ this.state.institutionSearchText }
                        setSelectedInstitution={ this.handleSetSelectedInstitution }
                        showNewApplicationInstitutionsList={ this.state.showNewApplicationInstitutionsList }
                        showNewInstitutionApplications={ this.handleShowInstitutionApplications }
                        hideInstitutionApplications={ this.handleHideInstitutionApplications }
                        addNewApplicationQualification={ this.handleAddNewApplicationQualification }
                        viewedApplications={ this.state.viewedApplications }
                        searchQualification={ this.handleSearchQualification }
                        searchQualificationText={ this.state.searchQualificationText }
                        qualificationsList={ this.state.qualificationList }
                        setSelectedQaulification={ this.handleSetSelectedQaulification }
                        submitApplication={ this.handleSubmitApplication }
                        showMatchInfoBackdrop={ this.state.showMatchInfoBackdrop }
                        showQualificationMatchInfo={ this.handleShowQualificationMatchInfo }
                        hideQualificationMatchInfo={ this.handleHideQualificationMatchInfo }
                        loadingQualificationMatch={ this.state.loadingQualificationMatch }
                        QMInfo={ this.state.qualificationMatchInfo[0] }
                        applicantLoginInfoChange={ this.handleApplicantLoginInfoChange }
                        saveApplicantLoginInfo={ this.handleSaveApplicantLoginInfo }
                        assignApplicationToAP={ this.handleAssignApplicationToAP }
                        showNewApplications={ this.handleShowNewApplications }
                        showNewBursaries={ this.handleShowNewBursaries }
                        viewNewApplications={ this.state.viewNewApplications }
                        addNewBursaryItem={ this.handleAddNewBursaryItem }
                        newBursaryItems={ this.state.newBursaryItems }
                        resetApplication={ this.handleResetApplication }
                        deleteNewInstitution={ this.handleDeleteNewInstitution }
                        deleteNewQualification={ this.handleDeleteNewQualification }
                    />
                )
            }
        }

        return(
            <Wrap>
                <div className="row no-gutters page-tool-heading">
                    <div className="col-12">
                        <h3>Applications</h3>
                    </div>
                </div>
                <section className="row justify-content-center applications-container">
                    { pageViewContent }
                </section>
            </Wrap>
        );
    }
}

export default Applications;