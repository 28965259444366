import React, { Component} from "react";
// 
// Manage applications status component : container component for managing applications status
// 

// 
// Services
// 

// Applications status Service
import applicationsStatusService from "../../../js/library/services/applicationsStatusService";

// 
// Components
// 

// Table titles component
import TableTitles from "../../../components/pages/applicationsPage/applicationsList/manageApplicationsStatusPage/tableTitles";
// Wrap component
import Wrap from "../../../components/hoc/wrap";
// Loading data component
import LoadingData from "../../../components/UI/loadingData/loadingData";
// Status info row component
import StatusInfoRow from "../../../components/pages/applicationsPage/applicationsList/manageApplicationsStatusPage/statusInfoRow/statusInfoRow";
// Button component
import Button from "../../../components/UI/button/button";

// Style 
import "./manageApplicationsStatus.css";


class manageApplicationsStatus extends Component {
    state = {
        statusList: [],
        statusListValues: {},
        loadingStatusList: true
    }

    componentDidMount() {
        let statusListValues = {};

        applicationsStatusService.fetchStatusList().then( response => {
            if ( response[0] !== "error" ) {
                if ( response.length > 0 ) {
                    response.forEach( statusItem => {
                        statusListValues[statusItem.ID] = {
                                name: statusItem.Name
                        }
                    });
                }    

                this.setState({
                    statusList: response,
                    statusListValues: statusListValues,
                    loadingStatusList: false
                });
            }
        });
    }

    handleInputChange = ( event ) => {
        event.preventDefault();

        let inputNameProps = event.target.name.split("_");
        let inputName = inputNameProps[0];
        let inputId = inputNameProps[1];
        let inputValue = event.target.value;
        let statusListValuesCopy = { ...this.state.statusListValues }
        
        statusListValuesCopy[inputId][inputName] = inputValue;
        statusListValuesCopy[inputId].saveEnabled = true;

        this.setState({
            statusListValues: statusListValuesCopy
        });
    }

    handleUpdateRowInfo = ( event, statusId ) => {
        event.preventDefault();
        let statusListValuesCopy = { ...this.state.statusListValues };
        let statusInfo = this.state.statusListValues[statusId];
        let name = statusInfo.name;
        let required = statusInfo.required === "Yes" ? true : false;
        let description = statusInfo.description;
        let color = statusInfo.color;

        if ( statusId !== "new" ) {
            applicationsStatusService.editStatus(statusId, name ).then( response => {
                if ( response ) {
                    statusListValuesCopy[statusId].saveEnabled = false;
    
                    this.setState({
                        statusListValues: statusListValuesCopy
                    });
                }
            });

        } else {
            applicationsStatusService.addStatus(name).then( response => {
                if ( response !== "error" ) {
                    let newStatusInfo = statusListValuesCopy["new"];
                    
                    delete statusListValuesCopy["new"];
                    statusListValuesCopy[response] = newStatusInfo;
                    statusListValuesCopy[response].saveEnabled = false;

                    this.setState({
                        statusListValues: statusListValuesCopy
                    });
                }
            });
        }
    }

    handleAddNewStatusInfoRow = () => {
        let statusListValuesCopy = { ...this.state.statusListValues };
        
        statusListValuesCopy["new"] = {
            name: "",
            description: "",
            color: "",
            required: "No",
            saveEnabled: true
        }

        this.setState({
            statusListValues: statusListValuesCopy
        });
    }

    render() {
        let statusCount = Object.keys(this.state.statusListValues).length;
        let pageContent = "";
        let statusInfoRows = [];

        if ( this.state.loadingStatusList ) {
            pageContent = (
                <div className="row justify-content-center">
                    <LoadingData title="fetching status list" />
                </div>
            );

        } else {
            if ( statusCount > 0 ) {
                let statusListValues = this.state.statusListValues;
                statusInfoRows = Object.keys(statusListValues).map( statusId => {
                    let statusItem = statusListValues[statusId];
                    return <StatusInfoRow 
                                key={ statusId }
                                infoRowId={ statusId }
                                infoRowName={ statusItem.name }           
                                saveEnabled={ statusItem.saveEnabled }
                                inputOnChange={ this.handleInputChange }  
                                updateRowInfo={ this.handleUpdateRowInfo }
                            />
                });

                pageContent = (
                    <Wrap>
                        <TableTitles />
                        <section className="row justify-content-center application-status-container">
                            { statusInfoRows }
                        </section>
                    </Wrap>
                );
            }
        }

        return(
            <Wrap>
                <div className="row justify-content-between align-items-center no-gutters page-tool-heading">
                    <div className="col-6">
                        <a href="#" onClick={ event => this.props.navigateToApplications(event) }>
                            <h3>
                                <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                                manage application status
                            </h3>
                        </a>
                    </div>
                    <div className="col-4">
                        <p>{ statusCount + " status found"}</p>
                    </div>
                </div>
                { pageContent }
                <div className="row justify-content-end application-status-btn">
                    <div className="col-3">
                        <Button buttonTitle="Add new status" clickHandler={ this.handleAddNewStatusInfoRow } />
                    </div>
                </div>
            </Wrap>
        );
    }
}

export default manageApplicationsStatus;