import React, { Component } from "react";
// 
// Applications page : Page to manage applications
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../hoc/wrap";
// Header component
import Header from "../../header/header";
// Toolbar component
import Toolbar from "../../UI/toolbar/toolbar";
// Main content wrap component
import MainContentWrap from "../../UI/mainContentWrap/mainContentWrap";
// Application component
import Applications from "../../../containers/applications/applications";
// Manage application documents component
import ManageApplicationsDocs from "../../../containers/applications/manageApplicationsDocs/manageApplicationsDocs";
// Manage application status component
import ManageApplicationsStatus from "../../../containers/applications/manageApplicationsStatus/manageApplicationsStatus";

// Style
import "./applicationsPage.css";

class applicationsPage extends Component {    
    handlePageToolNav = ( event, adminTool, pageName ) => {
        event.preventDefault();
        
        switch( pageName ) {
            case "documents":
                this.props.history.push('/' + adminTool + "/" + pageName);
                break;

            case "status":
                this.props.history.push('/' + adminTool + "/" + pageName);
                break;
            
            default:
                this.props.history.push('/' + adminTool + "/manage");
                break;
        }
    }

    handleNavigateToApplications = ( event ) => {
        event.preventDefault();

        this.props.history.push('/' + 'applications' + "/manage");
    }

    render() {
        let pageTool = this.props.match.params.tool;
        let pageContent = "";

        switch( pageTool ) {
            case "manage": 
                pageContent = <Applications />;
                break;

            case "documents": 
                pageContent = <ManageApplicationsDocs navigateToApplications={ this.handleNavigateToApplications } />;
                break;

            case "status": 
                pageContent = <ManageApplicationsStatus navigateToApplications={ this.handleNavigateToApplications } />;
                break;
        }

        let toolbarGroupItems = [
            {
                itemTitle: "documents",
                itemIcon: "file-o",
                pageName: "documents"
            },
            {
                itemTitle: "status",
                itemIcon: "circle-o",
                pageName: "status"
            }
        ]

        return (
            <Wrap>
                <Header 
                    whichHeader="applications"
                    showToolsNav={ this.props.showToolsNav } 
                    toggleToolsNav={ this.props.toggleToolsNav } 
                    showProfileNav={ this.props.showProfileNav } 
                    toggleProfileNav={ this.props.toggleProfileNav } 
                    handleLogout={ this.props.handleLogout }
                />
                <main className="applications-container">
                    <Toolbar adminTool="applications" toolbarGroupItems={ toolbarGroupItems } groupItemClick={ this.handlePageToolNav } />
                    <MainContentWrap>
                        { pageContent }
                    </MainContentWrap>
                </main>
            </Wrap>
        );
    }
}

export default applicationsPage;