import React from "react";
// 
// User list component
// 

// 
// Components
// 

// User item component
import UserItem from "./userItem/userItem";
// Loading data component
import LoadingData from "../../../UI/loadingData/loadingData";
// Wrap component
import Wrap from "../../../hoc/wrap";

// Style
import "./userList.css";

const userList = ( props ) => {
    let contentView = "";
    let userListItems = props.usersList.map( userItem => {
        return (
            <UserItem 
                key={ userItem.ID }
                userName={ userItem.Name + " " + userItem.Surname }
                unreadCount={ userItem.total ? userItem.total : 0} 
                userProfilePic={ userItem.userProfilePic }
                userItemClick={ () => props.userItemClick(userItem.ID) }
                activeClass={ (userItem.ID === props.activeUserId ? "active" : "inactive") }
            />
        )
    });

    if ( props.loadingACUsersList ) {
        contentView = (
            <div className="row justify-content-center">
                <LoadingData title={ props.loadingDataTitle } />
            </div>
        )
    } else {
        if ( userListItems.length > 0 ) {
            contentView= userListItems;
        
        } else {
            contentView = (
                <div className="no-users">
                    <i className="fa fa-user-times" aria-hidden="true"></i>
                    <p>no users present</p>
                </div>
            )
        }
    }

    return (
        <div className={ "user-list " + props.listId }>
            <h6>{ props.userListTitle }</h6>
            { contentView }
        </div>
    )
}

export default userList;