import React from "react";
// 
// AP Item component: : Application programmer item component
// 

// 
// Components
// 

// Allocation item component
import AllocationItem from "../allocationItem/allocationItem";

// Style
import "./apItem.css";

const apItem = ( props ) => {
    let allocationItems = props.allocationItemList.map( (allocationItem, index) => {
        let total = allocationItem.total;
        let completed = allocationItem.completed;
        let progressBarWidth = Math.round( (completed/total) * 100 );
        let progressBarClass = "";

        switch ( progressBarWidth ) {
            case ( progressBarWidth < 25 ):
                progressBarClass = "red";
                break;

            case ( progressBarWidth < 50 ):
                progressBarClass = "orange";
                break;

            case ( progressBarWidth < 75 ):
                progressBarClass = "blue";
                break;

            case ( progressBarWidth <= 100 ):
                progressBarClass = "green";
                break;
        }

        return <AllocationItem 
                    key={ index }
                    institutionName={ allocationItem.Abbreviation }
                    progressBarClass={ progressBarClass }
                    progressBarWidth={ progressBarWidth }
                    completed={ completed }
                    total={ total }
                />
    })

    return(
        <div className="row ap-item">
            <div className="col-4">
                <a href="#">
                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                </a>
                <p className="ap-name">{ props.APName }</p>
            </div>
            <div className="col-8">
                <div className="row flex-nowrap institutions">
                    { allocationItems }
                </div>
            </div>
        </div>
    )
}

export default apItem;