import React from 'react';
// 
// Applicantinfo component: Applicant info pop up component
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../../../../hoc/wrap";
// Backdrop
import Backdrop from "../../../../../UI/backdrop/backdrop";
// Loading data component
import LoadingData from "../../../../../UI/loadingData/loadingData";
// Spinner component
import Spinner from "../../../../../UI/spinner/spinner";
// Button component
import Button from "../../../../../UI/button/button";
// Subject input component
import SubjectInput from "../../../../../UI/subjectInput/subjectInput";

// Style
import "./applicantInfo.css";

// 
// Assests
// 

import ProfiliePic from "../../../../../../assets/images/character-face.png";

const applicantInfo = ( props ) => {
    let contentView = "";

    console.log(props.applicationInfo)

    if ( props.loadingApplicantInfo ) {
        contentView = (
            <div className="loading-info row justify-content-center">
                <LoadingData title="Fetching applicant data" />;
            </div>
        );

    } else {
        let subjectInputList = Object.keys(props.subjectList).map( subjectKey => {
            let subject = props.subjectList[subjectKey];
            return <SubjectInput  
                        subject={ subject.name }
                        subjectLabel={ subjectKey }
                        mark={ subject.mark }
                        markLabel="Mark"
                        subjectInputChange={ props.subjectInputChange }
                        subjectSearchList={ props.subjectSearchList }
                        focusedInput={ props.focusedInput }
                    />
        });

        contentView = (
            <Wrap>
                <div className="row align-items-end header">
                    <div className="col-3">
                        <img src={ ProfiliePic } alt="user profile picture" />
                    </div> 
                    <div className="col-8">
                        <h2>{ props.applicationInfo.name + ' ' + props.applicationInfo.surname }</h2>
                        <p className={ "application-package" + ( props.applicationInfo.package ? " " + props.applicationInfo.package : "no-package" ) }>
                            <span>application package: </span>
                            { 
                                props.applicationInfo.package ?  props.applicationInfo.package : "no package"
                            }
                        </p>
                        <p className={ "application-fees" + (props.applicationInfo.applicationfee ? "" : " no-fee" ) }>
                            <span>application fees: </span>
                            { 
                                props.applicationInfo.applicationfee ? props.applicationInfo.applicationfee : "no fees"
                            }
                        </p>
                    </div>
                </div>
                <div className="row title">
                    <div className="col-12">
                        <h5>Applicant details</h5>
                    </div>
                </div>
                <div className="row applicant-details">
                    <div className="col-4">
                        <label className="form-label">gradesmail</label>
                        <div className="form-text-wrap">
                            <input name="gradesmail" type="text" value={ props.userInfo.gradesMail.value } onChange={ props.applicantInfoOnChange } placeholder="Gradesmail" />
                        </div>
                    </div>
                    <div className="col-4">
                        <label className="form-label">number</label>
                        <div className="form-text-wrap">
                            <input name="number" type="text" value={ props.userInfo.number.value  } onChange={ props.applicantInfoOnChange } placeholder="Learner Number" />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="auto-complete-search" >
                            <label className="form-label">school</label>
                            <div className="form-text-wrap">
                                <input name="school" type="text" value={ props.userInfo.school.search === "" ? props.userInfo.school.value : props.userInfo.school.search } onChange={ props.applicantInfoOnChange } placeholder="School Name" />
                            </div>
                            { console.log(props.userInfo.school.value) }
                            { console.log(props.userInfo.school.value === "") }
                            <div className="search-list" style={{ display: (props.userInfo.school.search && props.userInfo.school.search !== "" ? "block" : "none") }}>
                                <ul>
                                    { props.schoolList }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <Button buttonClassName="save" clickHandler={ props.saveStudentInfo }>
                            <i className="fa fa-floppy-o" aria-hidden="true"></i>
                            save details
                        </Button>
                    </div>
                </div>
                <div className="row title">
                    <div className="col-12">
                        <h5>Parent details</h5>
                    </div>
                </div>
                <div className="row applicant-parent-details">
                    <div className="col-4">
                        <label className="form-label">name</label>
                        <div className="form-text-wrap">
                            <input name="parentName" type="text" value={ props.userInfo.parentDetails.name.value } onChange={ props.applicantInfoOnChange } placeholder="Parent Name" />
                        </div>
                    </div>
                    <div className="col-4">
                        <label className="form-label">number</label>
                        <div className="form-text-wrap">
                            <input name="parentNumber" type="text" value={ props.userInfo.parentDetails.number.value  } onChange={ props.applicantInfoOnChange } placeholder="Parent Number" />
                        </div>
                    </div>
                    <div className="col-4">
                        <label className="form-label">email</label>
                        <div className="form-text-wrap">
                            <input name="parentEmail" type="text" value={ props.userInfo.parentDetails.email.value  } onChange={ props.applicantInfoOnChange } placeholder="Parent Email" />
                        </div>
                    </div>
                    <div className="col-12">
                        <Button buttonClassName="save" clickHandler={ props.saveParentInfo }>
                            <i className="fa fa-floppy-o" aria-hidden="true"></i>
                            save details
                        </Button>
                    </div>
                </div>
                <div className="row title">
                    <div className="col-12">
                        <h5>Applicant Subjects</h5>
                    </div>
                </div>
                <div className="row applicant-subjects">
                    <div className="col-12">
                        <div className="row">
                            { subjectInputList }
                        </div>
                    </div>
                    <div className="col-12">
                        <Button buttonClassName="save" clickHandler={ props.saveUserMarks }>
                            <i className="fa fa-floppy-o" aria-hidden="true"></i>
                            save subjects
                        </Button>
                    </div>
                </div>
            </Wrap>
        );
    }

    return (
        <Wrap>
            <Backdrop 
                backdropShow={ props.showApplicantInfoBackdrop }
                backdropHide={ props.hideApplicantInfoBackdrop }
            />
            <section className="container applicant-info" style={{display: ( props.showApplicantInfoBackdrop ? "block": "none")}}>
                { contentView }
            </section>
        </Wrap>
    )
}

export default applicantInfo;
