import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
// 
// Insights page component : report insights page component
// 

// 
// Components
// 

// Text area component
import TextArea from "../../../components/pages/expoReportPage/reportCopmposerControls/textArea/textArea";
// Report footer
import ReportFooter from "../../../components/pages/expoReportPage/reportPages/reportFooter/reportFooter";

// Style
import "./insightsPage.css";

class insightsPage extends Component {
    render() {

        let pageHeadingStyle = {
            width     : "100%",
            fontSize  : "2em",
            fontWeight: "600",
            resize    : "none",
            marginTop : "1em",
            textAlign : "center",
        }

        let insightHeadingStyle = {
            width          : "100%",
            fontSize       : "1.4em",
            fontWeight     : "400",
            resize         : "none",
            height         :  "3.15em", 
            backgroundColor: "transparent",
            color          : "white",
        }

        let insightBodyStyle = {
            width          : "100%",
            fontSize       : "1.2em",
            fontWeight     : "400",
            resize         : "none",
            height         :  "11.5em", 
            backgroundColor: "transparent",
            color          : "white",
        }

        let insightsBlocks = Object.keys(this.props.insightsBlock).map( (insightKey, index) => {
            return (
                <div className="col-6 insights-block" key={ insightKey }>
                    <div className={"row no-gutters " +  insightKey}>
                        <div className="col-2">
                            <span>{"0" + ( index + 1 )}</span>
                        </div>
                        <TextArea 
                            textAreaName={ insightKey + "-Heading"}
                            textAreaValue={ this.props.insightsBlock[insightKey].insightHeading }
                            textAreaOnChange={ this.props.textAreaOnChange }
                            textAreaPlaceholder=""
                            textAreaCol="10"
                            style={ insightHeadingStyle }
                        />
                    </div>
                    <div className={"row no-gutters " +  insightKey}>
                        <div className="col-2"></div>
                        <TextArea 
                            textAreaName={ insightKey + "-Body" }
                            textAreaValue={ this.props.insightsBlock[insightKey].insightBody }
                            textAreaOnChange={ this.props.textAreaOnChange }
                            textAreaPlaceholder=""
                            textAreaCol="10"
                            style={ insightBodyStyle }
                        />
                    </div>
                </div>
                );
        })

        return (
           <div className="insights-page">
                <div className="row page-title">
                    <TextArea 
                        textAreaName="insightsPageHeading"
                        textAreaValue={ this.props.pageHeading }
                        textAreaOnChange={ this.props.textAreaOnChange }
                        textAreaPlaceholder=""
                        textAreaCol="6"
                        style={ pageHeadingStyle }
                    />
                </div>
                <div className="row justify-content-between">
                     { insightsBlocks }
                </div>
                <ReportFooter />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        pageHeading: state.insightsPage.insightsPageHeading,
        insightsBlock: state.insightsPage.insightsBlocks,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        textAreaOnChange: (event) => dispatch({ type: actionTypes.UPDATE_TEXT_INSIGHTS_PAGE, payload: { event: event }}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(insightsPage);