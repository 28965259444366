import React from "react";
// 
// Expo report print component: print pop up window for expo report
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../../hoc/wrap";
// Cover page component
import CoverPage from "../../../../containers/expoReport/coverPage/coverPage";
// Contents page component
import ContentsPage from "../../../../containers/expoReport/contentsPage/contentsPage";
// Backrgound page component
import BackgroundPage from "../../../../containers/expoReport/backgroundPage/backgroundPage";
// Backrgound page component
import HighLevelPage from "../../../../containers/expoReport/highLevelPage/highLevelPage";
// Backrgound page component
import AttendancePage from "../../../../containers/expoReport/attendancePage/attendancePage";
// Backrgound page component
import SchoolsPage from "../../../../containers/expoReport/schoolsPage/schoolsPage";
// Backrgound page component
import InsightsPage from "../../../../containers/expoReport/insightsPage/insightsPage";
// Backrgound page component
import UsefulnessPage from "../../../../containers/expoReport/usefulnessPage/usefulnessPage";
// Backdrop
import Backdrop from "../../../UI/backdrop/backdrop";
// Button component
import Button from "../../../UI/button/button";

// Style
import "./expoReportPrint.css";

const expoReportPrint = ( props ) => {
    return(
        <Wrap>
            <Backdrop backdropShow={ props.backdropShow } backdropHide={ props.backdropHide } />
            <div className="report-print container" style={{"display": props.backdropShow ? "block" : "none" }}>
                <div className="row">
                    <div className="col-12">
                        <Button clickHandler={ props.printReport }>
                            <i className="fa fa-print" aria-hidden="true"></i>
                            print report
                        </Button>
                    </div>
                    <div className="col-12">
                        <CoverPage />
                        <ContentsPage />
                        <BackgroundPage />
                        <HighLevelPage />
                        <AttendancePage />
                        <SchoolsPage />
                        <InsightsPage />
                        <UsefulnessPage />
                    </div>
                    <div className="col-12">
                        <Button clickHandler={ props.printReport }>
                            <i className="fa fa-print" aria-hidden="true"></i>
                            print report
                        </Button>
                    </div>
                </div>
            </div>
        </Wrap>
    );
}

export default expoReportPrint;