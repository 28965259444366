import * as actionTypes from "../../actions/actionTypes";
// 
// Expo report reducer 
//

const initialState = {
    reportInfo: {}
}

const expoReportReducer = ( state=initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_REPORT_INFO:
            return {
                ...state,
                reportInfo: action.payload.reportInfo
            }
        default :
            return {
                ...state
            }
    }
}

export default expoReportReducer