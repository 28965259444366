import React from 'react';
// 
// User profile link component
// 

// 
// Components
// 

// Wrap component
import Wrap from '../../hoc/wrap';
// Profile Nav component
import ProfileNav from '../../UI/profileNav/profileNav';

// Style
import './userProfileLink.css';

const userProfileLink = ( props ) => (
    <Wrap>
        <a className="user-profile-link" href="#" onClick={ event => props.toggleProfileNav(event) }>
            <i className="fa fa-user" aria-hidden="true"></i>
        </a>
        <a className="user-profile-toggle" href="#" onClick={ event => props.toggleProfileNav(event) }>
            <i className="fa fa-caret-down" aria-hidden="true"></i>
        </a>
        <ProfileNav showProfileNav={ props.showProfileNav } handleLogout={ props.handleLogout } isInsidePage={ props.isInsidePage }  />
    </Wrap>
);

export default userProfileLink;