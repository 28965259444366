import React from 'react';
// 
// Module info component: modules table row component
// 

// 
// Components
// 
import Button from '../../../UI/button/button';
// Draggable component
import { Draggable } from "react-beautiful-dnd";

// Style
import './infoRow.css';

const infoRow = ( props ) => {
    return (
        <Draggable draggableId={ props.infoRowId } index={ props.index }>
            {
                ( provided, snapShot ) => (
                    <div 
                        className={ "info-row col-12" + (props.viewInfoButtonArrow === "down" ? " expanded" : "") } 
                        style={{ marginBottom: props.noMarginBottom ? "0": "" }}
                        { ...provided.draggableProps }
                        ref={ provided.innerRef }
                        >
                        <div className="row align-items-center">
                            <div className="info-row-item col-1">
                                <a href="#" 
                                    className="info-row-dragger" 
                                    { ...provided.dragHandleProps }
                                >
                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                </a>
                                <a href="#" className="view-info" onClick={ props.showHideInfoRowDetails }>
                                    <i className={ "fa fa-caret-" + props.viewInfoButtonArrow } aria-hidden="true"></i>
                                </a>
                            </div>
                            <div className="info-row-item col-5">
                                <input type="text" name={ props.infoRowId }  value={ props.infoRowName } onChange={ props.viewInfoButtonArrow === "right" ? props.inputOnChange : null } placeholder={ props.inputPlaceHolder } />
                            </div>
                            <div className="info-row-item col-4">
                                <p>{ props.infoRowDetails }</p>
                            </div>
                            <div className="info-row-item col-2">
                                <a href="#" className={ (props.saveButtonState ? " saveActive": "") } onClick={ props.saveButtonState ? props.handleUpdateRowInfo : null } >
                                    <i className="fa fa-floppy-o" aria-hidden="true"></i>
                                </a>
                                <span className="line-separator"></span>
                                <a href="#" className="delete" onClick={ props.handleDeleteRowInfo }>
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                )
            }
        </Draggable>
    );
};

export default infoRow;