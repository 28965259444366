import React from "react";
// 
// Application institutions component : A view from learner application search
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../../../hoc/wrap";
// Institution item component
import InstitutionItem from "../institutionItem/institutionItem";
// Student applications component
import StudentApplications from "../studentApplications/studentApplications";
// Student docs component
import StudentDocs from "../studentDocs/studentDocs";
// Button
import Button from "../../../../UI/button/button";
// Table title component
import TableTitles from "./tableTitles/tableTitles";
// New applicatoin institution component
import NewApplicationInstitution from "./newApplicationInstitution/newApplicationInstitution";
// New bursary component
import NewBursaryItem from '../bursaryItem/newBursaryItem/newBursaryItem';

// Style
import "./applicationInstitutions.css";

const applicationInstitutions = ( props ) => {
    let content = "";
    let institutionItems = "";
    let adminList = [];
    let newApplication  = false;

    if ( props.adminList.length > 0 ) {
        adminList = props.adminList.map( (admin, index) => {
            return <li key={ index } onClick={ () => props.assignApplicationToAdmin( admin.ID, props.learnerId ) }>{ admin.Name + ' ' + admin.Surname }</li>
        });
    }

    if ( props.viewStudentApplication ) {

        content = (
            <Wrap>
                <InstitutionItem 
                    institutionName={ props.institutionName } 
                    active={ true } 
                    showApplicationsList={ props.showApplicationsList } 
                    learnerId={ props.learnerId }
                    handleShowAdminList={ props.handleShowAdminList }
                    showAdminList={ props.showAdminList }
                    institutionId={ props.institutionId }
                    adminList={ props.adminList }
                    assignApplicationToAP={ props.assignApplicationToAP }
                />
                <StudentApplications 
                    loadingStudentApplications={ props.loadingStudentApplications }
                    viewStudentApplication={ props.viewStudentApplication }
                    studentApplications={ props.studentApplications }
                    viewedStudentApplications={ props.viewedStudentApplications }
                    statusList={ props.statusList }
                    updateApplicationStatus={ props.updateApplicationStatus }
                    updateAllApplicationStatus={ props.updateAllApplicationStatus } 
                    showBackdrop={ props.showBackdrop }
                    showQualificationMatchInfo={ props.showQualificationMatchInfo }
                    hideQualificationMatchInfo={ props.hideQualificationMatchInfo }
                    loadingQualificationMatch={ props.loadingQualificationMatch }    
                    QMInfo={ props.QMInfo }   
                    applicantLoginInfoChange={ props.applicantLoginInfoChange }
                    saveApplicantLoginInfo={ props.saveApplicantLoginInfo }   
                />
            </Wrap>
        )

    } else if ( props.viewStudentApplicationDocs ) {
        content = (
            <Wrap>
                <div className="row side-borders">
                    <div className="col-12">
                        <Button buttonClassName="close" clickHandler={ props.showApplicationsList }>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </Button>
                    </div>
                </div>
                <StudentDocs 
                    loadingApplicationDocs={ props.loadingApplicationDocs } 
                    applicationDocs={ props.viewedApplicationDocs } 
                    showDocsModal={ props.showDocsModal }
                    hideDocsModal={ props.hideDocsModal }
                    requestedDocsText={ props.requestedDocsText }
                    requestedDocsTextChange={ props.requestedDocsTextChange }
                    requestDocument={ props.requestDocument }
                    sendDocRequest={ props.sendDocRequest }
                    applicationDocSelect={ props.applicationDocSelect }
                    studentId={ props.viewedStudentApplications.studentId }
                    downloadDoc={ props.downloadDoc }
                    deleteDoc={ props.deleteDoc }
                />
            </Wrap>
        );

    } else {
        if ( Object.keys(props.applicationsList).length > 0 ) {
            institutionItems = Object.keys(props.applicationsList).map( (institutionName, index) => {
                return <InstitutionItem 
                            key={ index } 
                            institutionName={ institutionName } 
                            active={ false } 
                            showInstitutionApplications={ props.showInstitutionApplications } 
                            learnerId={ props.learnerId }
                            handleShowAdminList={ props.handleShowAdminList }
                            showAdminList={ props.showAdminList }
                            institutionId={ props.applicationsList[institutionName][0].InstitutionID }
                            adminList={ props.adminList }
                            assignApplicationToAP={ props.assignApplicationToAP }
                        />
            });
    
            content = (
                <Wrap>
                    <TableTitles />
                    { institutionItems }
                    <Button buttonClassName="reset" clickHandler={ props.resetApplication }>
                        <i className="fa fa-refresh" aria-hidden="true"></i>
                        Reset application
                    </Button>
                </Wrap>
            )
        } else {
            newApplication = true;
            if ( props.viewNewApplications ) {
                content = <NewApplicationInstitution 
                                learnerName={ props.learnerName }
                                institutionName={ props.institutionName }
                                newApplicationInstitutions={ props.newApplicationsList } 
                                addNewApplicationInstitution={ props.addNewApplicationInstitution } 
                                newInstitutionList={ props.newInstitutionList }
                                searchInstitution={ props.searchInstitution }
                                searchInstitutionText={ props.searchInstitutionText }
                                setSelectedInstitution={ props.setSelectedInstitution }
                                showInstitutionApplications={ props.showNewInstitutionApplications }
                                showNewApplicationInstitutionsList={ props.showNewApplicationInstitutionsList }
                                hideInstitutionApplications={ props.hideInstitutionApplications }
                                addNewApplicationQualification={ props.addNewApplicationQualification }
                                viewedApplications={ props.viewedApplications }
                                searchQualification={ props.searchQualification }
                                searchQualificationText={ props.searchQualificationText }
                                qualificationsList={ props.qualificationsList }
                                setSelectedQaulification={ props.setSelectedQaulification }
                                deleteNewInstitution={ props.deleteNewInstitution }
                                deleteNewQualification={ props.deleteNewQualification }
                           />
            } else {
                content = <NewBursaryItem 
                                setSelectedBursary={ "" }
                                newBursaryList={ [] }
                                searchBursary={ "" }
                                searchBursaryText={ "" }
                                newBursaryItems={ props.newBursaryItems }
                                addNewBursaryItem={ props.addNewBursaryItem }
                            />;
            }
        }
    }

    return(
        <div className="col-12 applications-list">
            <h4 className="title-heading">
                <a href="#" onClick={ event => props.showApplicationsCards(event) }>
                    <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                </a>
                Applications List
            </h4>
            <div className="row">
                <div className="col-12 learner-info">
                    <h4>Applications for :</h4>
                    <div className="row no-gutters justify-content-between">
                        <div className="col-4">
                            <h3>
                                { props.learnerName }
                                <br />
                                <span style={{marginTop: "0.8em", color: "#9DA2AB", fontSize: "0.95em"}}>{ "( " + props.viewedStudentApplications.contactDetails.UserName + " )"}</span>
                            </h3>
                        </div>
                        <div className="col-8" style={{ textAlign: "right" }}>
                            {
                                !newApplication ?    <Wrap>
                                                        <Button buttonClassName="view-docs" clickHandler={ event => props.showApplicationDocs( event, props.learnerId, props.learnerName, props.institutionId ) }>
                                                            <i className="fa fa-file-text" aria-hidden="true"></i>
                                                            Documents
                                                        </Button>
                                                        <Button buttonClassName="assign"  clickHandler={ () => props.handleShowAdminList(props.learnerId) }>
                                                            <i className="fa fa-heart" aria-hidden="true"></i>
                                                            Assign AC
                                                        </Button>
                                                    </Wrap>

                                                    : 
                                                    <Wrap>
                                                        <Button buttonClassName="submit"  clickHandler={ props.showNewApplications }>
                                                            <i className="fa fa-university" aria-hidden="true"></i>
                                                            institutions
                                                        </Button>
                                                        <Button buttonClassName="submit"  clickHandler={ props.showNewBursaries }>
                                                            <i className="fa fa-money" aria-hidden="true"></i>
                                                            bursaries
                                                        </Button>
                                                        <Button buttonClassName="submit"  clickHandler={ props.submitApplication }>
                                                            <i className="fa fa-floppy-o" aria-hidden="true"></i>
                                                            Submit application
                                                        </Button>
                                                    </Wrap>
                            }
                        </div>
                    </div>
                    {
                        adminList.length > 0 ?
                            <ul className="adminList" style={{ "display": props.showAdminList === props.learnerId ? "block" : "none" }}>
                                { adminList }
                            </ul>
                            : ""
                    }
                </div>
            </div>
            <div className="row no-gutters">
                <div className="col-12">
                    { content }
                </div>
            </div>
        </div>
    )
}

export default applicationInstitutions;