import React from "react";
// 
// toobal group item : toolbar group link
// 

// 
// Components
// 

// ToolbarItem component
import ToolbarItem from "../toolbarItem/toolbarItem";
// Wrap component
import Wrap from "../../../hoc/wrap";

// Style
import "./toolbarGroupItem.css";

const toolbarGroupItem = ( props ) => {
    let toolbarGroupItems = props.toolbarGroupItems.map( (groupItem, index) => {
        return (
            <ToolbarItem 
                key={ index }
                toolbarItemTitle={ groupItem.itemTitle }
                toolbarItemIcon={ groupItem.itemIcon }
                adminTool={ props.adminTool }
                toolbarItemClick={ props.groupItemClick }
                pageName={ groupItem.pageName }
                active={ props.pageToolName === groupItem.itemTitle }
            />
        );
    });
     
    return(
        <Wrap>
            <p className="group-item-title" >{ props.groupItemTitle }</p>
            <nav className="toolbar-group-item">
                { toolbarGroupItems }
            </nav>
        </Wrap>
    );
}

export default toolbarGroupItem;