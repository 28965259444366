import React from "react";
// 
// Text area component : Text area  editor for report
// 

// 
// Components
// 

// Button component
import Button from "../../../../UI/button/button";

// Style
import "./textArea.css";
 
const textArea = ( props ) => (
    <div className={"text-area-wrap col-" + props.textAreaCol}>
        <textarea 
            name={ props.textAreaName } 
            value={ props.textAreaValue } 
            onChange={ (event) => props.textAreaOnChange(event) } 
            plcaeholder={ props.textAreaPlaceholder } 
            style={ props.style } 
        />
        <a href="#" className="round-button">
            <i className="fa fa-floppy-o" aria-hidden="true"></i>
        </a>
    </div>
);

export default textArea;