import React from "react";
// 
// User info row component
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../../hoc/wrap";

// Style
import "./usersInfoRow.css";

const userInfoRow = ( props ) => (
    <Wrap>
        <div className={"user-info-row col-12" + ( props.index === null ? " expanded" : "" ) }>
            <div className="row no-gutters">
                <div className="user-info-row-item col-1">
                    <a href="#" className="view-info"  onClick={ event => props.viewUserInfo( event, props.index, props.userId ) }>
                        <i className={ "fa fa-caret-" + props.arrowDir } aria-hidden="true"></i>
                    </a>
                    <span className="line-separator"></span>
                    <a href="#">
                        <i className="fa fa-unlock-alt" aria-hidden="true"></i>
                    </a>
                </div>
                <div className="user-info-row-item col-2">
                    <input type="text" name="user-name" value={ props.userName } placeholder="e.g John" />
                </div>
                <div className="user-info-row-item col-2">
                    <input type="text" name="user-surname" value={ props.userSurname } placeholder="e.g Doe" />
                </div>
                <div className="user-info-row-item col-4">
                    <input type="text" name="user-email" value={ props.userEmail } placeholder="e.g example@example.com" />
                </div>
                <div className="user-info-row-item col-2">
                    <input type="text" name="user-phone-numer" value={ props.userPhoneNumber } placeholder="e.g 0712345678" />
                </div>
                <div className="user-info-row-item col-1">
                    <a href="#" onClick={ event => props.setUserRole( event, props.userId, [] ) }>
                        <i className="fa fa-floppy-o" aria-hidden="true"></i>
                    </a>
                    <span className="line-separator"></span>
                    <a href="#">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </div>
    </Wrap>
);

export default userInfoRow;