import React from "react";
// 
// New form group component
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../../../hoc/wrap";
// Button component
import Button from "../../../../UI/button/button";

// Style
import "./newFormGroup.css";

const newFormGroup = ( props ) => {
    let formGroupItems = props.formGroupFields.map( (fieldName, index) => {
        return(
            <div className="input-wrap col-4" key={ index }>
                <div className="form-text-wrap">
                    <input type="text" name={ props.sourceName + "_" + index } value={ fieldName } onChange={ event => props.formGroupItemOnChange(event, props.sourceName)  } placeholder="new form field" />
                </div>
            </div>
        );
    });

    return(
        <Wrap>
            <div className="row form-group-items">
                <div className="col-12">
                    <h3 className="form-group-title">Form group for: { props.sourceName }</h3>
                </div>
                { formGroupItems }
            </div>
            <div className="row justify-content-end btns-container">
                <div className="col-2">
                    <Button buttonClassName="form-cancel" buttonTitle="cancel" clickHandler={ event => props.cancelFormGroupItem( event, props.sourceName ) } />
                </div>
                <div className="col-2">
                    <Button buttonTitle="add" clickHandler={ event => props.addFormGroupItem( event, props.sourceName ) } />
                </div>
            </div>
        </Wrap>
    );
}

export default newFormGroup;