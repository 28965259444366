import React from 'react';
// 
// Toolbar item component: layout component of toolbar item
// 

// Style
import './toolbarItem.css';

const toolbarItem = ( props ) => (
    <a className={"toolbarItem" +  ( props.active ? " active" : "" ) } name={ props.toolbarItemTitle } href="#" onClick={ event => props.toolbarItemClick(event, props.adminTool, props.pageName) }>
        <i className={ "fa fa-" + props.toolbarItemIcon } aria-hidden="true"></i>
        { props.toolbarItemTitle }
    </a>
);

export default toolbarItem;