import React from "react";
// 
// Subject input component: user subject and mark input field
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../hoc/wrap";

// Style
import "./subjectInput.css";

const subjectInput = ( props ) => (
    <Wrap>
        <div className="col-4 subject-input" >
            <div className="auto-complete-search">
                <label className="form-label">{ props.subjectLabel.replace("_", " ") }</label>
                <div className="form-text-wrap">
                    <input type="text" className="subject-input" name={ props.subjectLabel } value={ props.subject } onChange={ ( event ) => props.subjectInputChange(event, props.subjectLabel ) } placeholder={ props.subjectLabel.replace("_", " ") } />
                </div>
                <div className="search-list" style={{"display": ( (props.focusedInput === props.subjectLabel) && props.subject !== "" ? "block" : "none" )}}>
                    <ul>
                        { props.subjectSearchList }
                    </ul>
                </div>
            </div>
        </div>
        <div className="col-2 subject-input">
            <label className="form-label">{ props.markLabel }</label>
            <div className="form-text-wrap">
                <input type="text" className="mark-input" name={ props.markLabel } value={ props.mark } onChange={ ( event ) => props.subjectInputChange(event, props.subjectLabel ) } maxLength="3" placeholder="100" />
            </div>
        </div>
    </Wrap>
)

export default subjectInput;