import React from "react";
// 
// Chart info component : Chart info in user stats page
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../../../../hoc/wrap";

// Total count card component
import TotalCountCard from "../totalCountCard/totalCountCard";
// Pie chart
import PieChart from "../pieChart/pieChart";

// Style
import "./chartInfo.css";

const chartInfo = ( props ) => {

    let chartInfoData = "";
    let chartDescriptionInfo = "";
    let qualificationsMatched = "";
    let careersMatched = "";

    switch( props.tileTitle ) {
        case "qualifications":
        case "careers":
            chartInfoData = (
                <Wrap>
                    <div className="col-6">
                        <TotalCountCard totalCount={ props.totalMatched } totalTitle="matched" />
                    </div>
                    <div className="col-6">
                        <TotalCountCard totalCount={ props.totalLiked } totalTitle="liked" />
                    </div>
                    <div className="col-6">
                        <TotalCountCard totalCount={ props.totalUnliked } totalTitle="unliked" />
                    </div>
                </Wrap>
            );

            chartDescriptionInfo = ( props.title === "qualifications" ? <p>The Graph indicates the different total counts of users that viewed a qualification, matched with a qualification, liked a qualification as well as unliked a qualification on the platform.</p> : <p>The Graph shows the different total counts of users that viewed a career, liked a career as well as unliked a career on the platform.</p> )
            break;

        case "bursaries":
            chartInfoData = (
                <Wrap>
                    <div className="col-6">
                        <TotalCountCard totalCount={ props.totalMatched } totalTitle="matched" />
                    </div>
                    <div className="col-6">
                        <TotalCountCard totalCount={ props.totalVisitors } totalTitle="matchers" />
                    </div>
                </Wrap>
            );
            
            chartDescriptionInfo = <p>Graph shows the total count of users that viewed and matched or applied for a bursary through the platform.</p>
            break;


        case "users":
            chartInfoData = (
                <Wrap>
                    <div className="col-6">
                        <TotalCountCard totalCount={ props.totalNewUsers } totalTitle="new user" />
                    </div>
                </Wrap>
            );

            chartDescriptionInfo = <p>Graphs visualize a comparison between users that are recently registered and  existing users on the platform.</p>
            break;

        case "home page visits":
            chartInfoData = (
                <Wrap>
                    <div className="col-6">
                        <TotalCountCard totalCount={ props.totalVisits } totalTitle="total visits" />
                    </div>
                    <div className="col-6">
                        <TotalCountCard totalCount={ props.totalVisitors } totalTitle="total visitors" />
                    </div>
                </Wrap>
            );

            chartDescriptionInfo = <p>Graph illustrates how many users have visited the home page over a period of time</p>
            break;

        case "top 5":
            props.topFive.forEach( (topFiveItem, i) => {  
                
                if ( i === 0 ) {
                    qualificationsMatched = topFiveItem.map((qualification, index) => {
                        return (
                            <li key={ index }>
                                <span>
                                    { (index + 1) + ". " +  qualification.Name }
                                </span>
                                <h6>{ qualification.Institution }</h6>
                            </li>
                        );
                    });  

                } else {
                    careersMatched = topFiveItem.map((career, index) => {
                        return (
                            <li key={ index }>
                                <span>
                                    { (index + 1) + ". " +  career.Name }
                                </span>
                            </li>
                        );
                    });
                }
            });

            chartInfoData = (
                <Wrap>
                    <div className="col-12">
                        <h4 className="top-five-qualifications-m-heading"><i className="fa fa-certificate" aria-hidden="true"></i>top 5 qualifications matched</h4>
                        <ul>
                            { qualificationsMatched }
                        </ul>
                    </div>
                    <div className="col-12">
                        <h4 className="top-five-careers-heading"><i className="fa fa-briefcase" aria-hidden="true"></i>top 5 careers liked</h4>
                        <ul>
                            { careersMatched }
                        </ul>
                    </div>
                </Wrap>
            )
            break;
    }

    return(
        <div className="chart-info">
            <h4>{ props.tileTitle+ " stats info" }</h4>
            <div className="row justify-content-center align-items-center stats-info">
                <div className="col-11">
                    <div className="row">
                        { chartInfoData }
                    </div>
                </div>
                <div className="col-11">
                    { chartDescriptionInfo }
                </div>
            </div>
        </div>
    );
}

export default chartInfo;