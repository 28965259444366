export const UPDATE_TEXT_COVER_PAGE = "UPDATE_TEXT_COVER_PAGE";
export const UPLOAD_IMAGE_COVER_PAGE = "UPLOAD_IMAGE_COVER_PAGE";
export const UPDATE_TEXT_CONTENTS_PAGE = "UPDATE_TEXT_CONTENTS_PAGE";
export const UPDATE_TEXT_BACKGROUND_PAGE = "UPDATE_TEXT_BACKGROUND_PAGE";
export const UPLOAD_IMAGE_BACKGROUND_PAGE = "UPLOAD_IMAGE_BACKGROUND_PAGE";
export const UPDATE_TEXT_INSIGHTS_PAGE = "UPDATE_TEXT_INSIGHTS_PAGE";
export const UPDATE_TEXT_USEFULNESS_PAGE = "UPDATE_TEXT_USEFULNESS_PAGE";
export const UPDATE_TEXT_ATTENDANCE_PAGE = "UPDATE_TEXT_ATTENDANCE_PAGE";
export const UPLOAD_IMAGE_ATTENDANCE_PAGE = "UPLOAD_IMAGE_ATTENDANCE_PAGE";
export const UPDATE_TEXT_SCHOOLS_PAGE = "UPDATE_TEXT_SCHOOLS_PAGE";
export const UPLOAD_IMAGE_SCHOOLS_PAGE = "UPLOAD_IMAGE_SCHOOLS_PAGE";
export const UPDATE_TEXT_HIGH_LEVEL_PAGE = "UPDATE_TEXT_HIGH_LEVEL_PAGE";
export const SET_REPORT_INFO = "SET_REPORT_INFO";
export const SET_HIGH_LEVEL_EXPO_DATA = "SET_HIGH_LEVEL_EXPO_DATA";
export const SET_ATTENDANCE_EXPO_DATA = "SET_ATTENDANCE_EXPO_DATA";
export const SET_SCHOOLS_EXPO_DATA = "SET_SCHOOLS_EXPO_DATA";