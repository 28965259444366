import React from "react";
// 
// Filter list component
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../../hoc/wrap";

// 
// Dependencies
// 

// Date picker component
import DatePicker from "react-date-picker";

// Style
import "./filterList.css";

const filterList = ( props ) => {

    const PROVINCE_LIST = [
        {
            code: "EC", 
            name: "Eastern Cape"
        },
        {
            code: "FS", 
            name: "Free State"
        },
        {
            code: "GT", 
            name: "Gauteng"
        },
        {
            code: "KZ", 
            name: "KwaZulu-Natal"
        },
        {
            code: "LP", 
            name: "Limpopo"
        },
        {
            code: "MP", 
            name: "Mpumalanga"
        },
        {
            code: "NC", 
            name: "Northern Cape"
        },
        {
            code: "NW", 
            name: "North West"
        },
        {
            code: "WC", 
            name: "Western Cape"
        },
    ]

    let filterOptions = "";
    let filterValue = props.filterValue.toLowerCase().replace(" ", "-");
    let schoolNamesList = props.schoolNamesList.length > 0 ? props.schoolNamesList : "";
    let filterOptionsList = "";

    switch ( filterValue ) {
        case "name":
        case "source":
        case "username":
        case "grade":
            let inputPlaceHolder = "";
                    
            switch ( filterValue ) {
                case "name": 
                    inputPlaceHolder = "Name of User";
                    break;

                case "source": 
                    inputPlaceHolder = "Source";
                    break;

                case "username": 
                    inputPlaceHolder = "User Name";
                    break;

                case "grade": 
                    inputPlaceHolder = "Grade Numbers (separated by a ,)";
                    break;
            }

            filterOptions = (
                <input name={ props.filterInputName } type="text" placeholder={ inputPlaceHolder } value={ props.filterInputValue } onChange={ event => props.onFilterValueChange(event) }/>
            );
            break;

        case "province":
            filterOptions = (
                <select name={ props.filterInputName } value={ props.filterInputValue } onChange={ event => props.onFilterValueChange(event) }>
                    {
                        PROVINCE_LIST.map( province => {
                            return (
                                <option key={ province.code } value={ province.code }>{ province.name }</option>
                            )
                        })
                    }
                </select>
            )
            break;

        case "school":
            filterOptions = (
                <Wrap>
                    <input name={ props.filterInputName } type="text" placeholder="School Name" value={ props.filterInputValue } onChange={ event => props.onFilterValueChange(event) }/>
                    <div className="search-list" style={{ display: schoolNamesList.length > 0 ? "block" : "none" }}>
                        <ul>
                            { schoolNamesList }
                        </ul>
                    </div>
                </Wrap>
            );
            break;
            
        case "activity": 
                filterOptions = (
                    <Wrap>
                        <label className="filter-label">Min Points: </label>
                        <div className="points-input-wrap">
                            <input name={ props.filterInputName + "min" } type="text" value={ props.activityMinValue } onChange={ event => props.onFilterValueChange(event)} />
                        </div>
                        <label className="filter-label">Max Points: </label>
                        <div className="points-input-wrap">
                            <input name={ props.filterInputName + "max"} type="text" value={ props.activityMaxValue } onChange={ event => props.onFilterValueChange(event)} />
                        </div>
                    </Wrap>
                );
            break;
        
        case "action-date": 
        case "created-date": 
            let dateFromValue = "";
            let dateToValue = "";

            if (filterValue === "action-date") {
                dateFromValue = props.actionDateFrom;
                dateToValue = props.actionDateTo;

            } else {
                dateFromValue = props.createdDateFrom;
                dateToValue = props.createdDateTo;
            }

            filterOptions = (
                <Wrap>
                    <label className="filter-label">from: </label>
                    <div className="date-input-wrap">
                        <DatePicker name={ props.filterInputName + "from" } onChange={ (value) => props.onDateSelect(value, (props.filterInputName + "from").replace(" ", "")) } value={ dateFromValue } clearIcon={ null } showLeadingZeros={ true } />
                    </div>
                    <label className="filter-label">to: </label>
                    <div className="date-input-wrap">
                        <DatePicker name={ props.filterInputName + "to" } onChange={ (value) => props.onDateSelect(value, (props.filterInputName + "to").replace(" ", "")) }  value={ dateToValue } clearIcon={ null } showLeadingZeros={ true } />
                    </div>
                </Wrap>
            );
            break;
    }

    switch ( props.pageName ) {
        case "viewUsers" : 
            filterOptionsList = (
                    <Wrap>
                        <option>Name</option>
                        <option>School</option>
                        <option>Province</option>
                        <option>Activity</option>
                        <option>Action Date</option>
                        <option>Grade</option>
                        <option>Source</option>
                        <option>Username</option>
                        <option>Created Date</option>
                    </Wrap>
                );
            break;
        
        case "expoReport" : 
            filterOptionsList = (
                    <Wrap>
                        <option>Source</option>
                        <option>Created Date</option>
                        <option>School</option>
                        <option>Province</option>
                        <option>Grade</option>
                    </Wrap>
                );
            break;
        
        default:
            filterOptionsList = (
                    <Wrap>
                        <option>School</option>
                        <option>Province</option>
                        <option>Action Date</option>
                        <option>Grade</option>
                        <option>Source</option>
                        <option>Username</option>
                        <option>Created Date</option>
                    </Wrap>
                );
            break;
    }

    return(
        <Wrap>
            <div className="filter-list-wrap">
                <select name={ props.filterName } disabled={ (props.filterName === "defaultFilter" && props.pageName === "userStats") ? true: false } value={ props.filterValue } onChange={ event => props.onFilterChange(event) } >
                    { filterOptionsList }
                </select>
            </div>
            <div className={ "filter-option-" + filterValue + "-wrap" }>
                { filterOptions } 
            </div>
        </Wrap>
    );
};

export default filterList;