import React from 'react';
// 
// Notifications component: Layout for notifications tray
// 

// Style
import './notifications.css';

const notifications = ( props ) => (
    <a className="notifications-bell" href="#" >
        <i className="fa fa-bell" aria-hidden="true"></i>
    </a>
);

export default notifications;