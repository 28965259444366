import React, { Component } from "react";
import HTTPRequest from "../../js/library/HTTPConfig/axiosConfig";
import expoReportService from "../../js/library/services/expoReportService";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";
// 
// Expo report component : container component for expo report page
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../components/hoc/wrap";
// Filter component
import Filter from "../../components/UI/filter/filter";
// Button component
import Button from "../../components/UI/button/button";
// Cover page component
import CoverPage from "./coverPage/coverPage";
// Contents page component
import ContentsPage from "./contentsPage/contentsPage";
// Background page component
import BackgroundPage from "./backgroundPage/backgroundPage";
// Insights page component
import InsightsPage from "./insightsPage/insightsPage";
// Usefulness page component
import UsefulnessPage from "./usefulnessPage/usefulnessPage";
// Attdendance page component
import AttendancePage from "./attendancePage/attendancePage";
// Schools page component
import SchoolsPage from "./schoolsPage/schoolsPage";
// High level page compoonent
import HighLevelPage from "./highLevelPage/highLevelPage";
// Loading data component
import LoadingData from "../../components/UI/loadingData/loadingData";
// Expo report print
import ExpoReportPrint from "../../components/pages/expoReportPage/expoReportPrint/expoReportPrint";

// Style
import "./expoReport.css";

class ExpoReport extends Component {
    state = {
        filters: {
            defaultFilter: "source"
        },
        filterOptions: {
            source: "",
            username: "",
            name: "",
            school: "",
            schoolId: "",
            province: "EC",
            activitymin: 1,
            activitymax: 1,
            actiondatefrom: new Date(),
            actiondateto: new Date(),
            createddatefrom: new Date(),
            createddateto: new Date(),
            grade: "",
            hasexamnumber: ""
        },
        searchFilter: {},
        schoolList: [],
        viewedPage: 1,
        showBackDrop: false,
        loadReport: false,
        loadReportError: false,
        loadingReaport: false,
        reportData: {},
        showReportPrint: false
    }

    handleFilterChange = ( event ) => {
        const inputName = event.target.name;
        const selectedFilter = event.target.value;
        let filtersCopy = { ...this.state.filters };

        filtersCopy[inputName] = selectedFilter;

        this.setState({
            showUsers: false,
            filters: filtersCopy
        });
    }

    handleFilterValueChange = ( event ) => {
        event.preventDefault();

        let inputName = event.target.name;
        let inputValue = event.target.value;
        let filterOptionsCopy = { ...this.state.filterOptions };

        switch( inputName ) {
            case "school":
                HTTPRequest({
                    method: "POST",
                    url: "schools/find/limit-10-index-0",
                    data: {
                        "like": inputValue
                    },
                    headers : { "Authorization": "Bearer " + this.state.adminUserToken }

                }).then( response => {
                    if ( response.data.success ) {
                        let responseData = response.data.data;
                        let schoolNamesList = [];

                        if ( responseData.length >= 1 ) {
                            if ( responseData.length === 1 && responseData[0].Name === "Can't Find School") {
                                schoolNamesList.push(
                                    <li key={ -1 } className="list-no-result">
                                        <em>Can't Find School</em>
                                    </li>
                                );

                            } else {
                                schoolNamesList = responseData.map( schoolObj => {
                                    let province = schoolObj.Province
                                    let district = schoolObj.EIDistrict !== "NOT APPLICABLE" ? " " + schoolObj.EIDistrict  : "";
    
                                    return <li key={ schoolObj.ID } onClick={ () => this.handleSearchItemSelect( schoolObj.ID, schoolObj.Name ) }>{ schoolObj.Name + "(" + province + district + ")" }</li>
                                });
                            }
                        }
                        this.setState({
                            schoolList: schoolNamesList
                        });
                    }
                });

                break;
        }

        filterOptionsCopy[inputName] = inputValue;

        this.setState({
            filterOptions: filterOptionsCopy
        });
    }

    handleFilterDateSelect = ( selectedDate, filterName ) => {
        let filterOptionsCopy = { ...this.state.filterOptions };

        filterOptionsCopy[filterName] = selectedDate;

        console.log(filterName, selectedDate);

        this.setState({
            filterOptions: filterOptionsCopy
        });
    }

    handleSearchItemSelect = ( schoolId, schoolName ) => {
        let filterOptionsCopy = { ...this.state.filterOptions }

        filterOptionsCopy["school"] = schoolName;
        filterOptionsCopy["schoolId"] = schoolId;
        this.setState({
            filterOptions: filterOptionsCopy,
            schoolList: []
        });
    }

    handleAddFilter = ( event ) => {
        event.preventDefault();

        let filtersCount = Object.keys(this.state.filters).length;
        let filtersCopy = { ...this.state.filters };

        filtersCopy["extraFilter" + filtersCount] = "name";
        
        this.setState({
            filters: filtersCopy
        });
    }

    handleRemoveFilter = ( event, filterName ) => {
        event.preventDefault();

        let filtersCopy = { ...this.state.filters };
        delete filtersCopy[filterName];

        this.setState({
            filters: filtersCopy
        });
    }

    handleReportPageNav = ( dir ) => {
        let pageNumber = this.state.viewedPage;

        if ( dir === "next" ) {
            pageNumber = pageNumber < 8 ? pageNumber + 1: pageNumber;

        } else {
            pageNumber = pageNumber > 1 ? pageNumber - 1 : pageNumber; 
        }

        this.setState({
            viewedPage: pageNumber
        });
    }

    handleFetchReprotData = () => {
        let filterOptions = this.state.filterOptions;
        let filters = {
            source: filterOptions.source,
            createdDateStart: filterOptions.createddatefrom,
            createdDateEnd: filterOptions.createddateto
        }

        this.setState({
            loadingReaport: true
        })

        expoReportService.fetchExpoReportData(filters).then( reportData => {
            if ( !reportData.error ) {
                this.props.setReportInfo(reportData);

                this.setState({
                    reportData: reportData,
                    loadReport: true,
                    loadingReaport: false
                });

            } else {
                this.setState({
                    loadReportError: true,
                    loadingReaport: false
                });
            }
        });
    }

    handleBackdropHide = () => {
        this.setState({
            showBackDrop: false
        });
    }

    handleViewExpoReportPrint = ( event ) => {
        event.preventDefault();

        this.setState({
            showBackDrop: true
        });
    }

    handleReportPrint = () => {
        window.print();
    }
    
    render() {
        let extraFilters = [];
        let reportPage;
        let reportPageName;
        let report = "";

        if ( this.state.loadReport ) {
            switch( this.state.viewedPage ) {
                case 1:
                    reportPage = <CoverPage />;
                    reportPageName = "Cover Page";
                    break;
                case 2:
                    reportPage = <ContentsPage />;
                    reportPageName = "Contents Page";
                    break;
                case 3:
                    reportPage = <BackgroundPage />;
                    reportPageName = "Background Page";
                    break;
                case 4:
                    reportPage = <HighLevelPage />;
                    reportPageName = "High Level Attendance Stats Page";
                    break;
                case 5:
                    reportPage = <AttendancePage />;
                    reportPageName = "Attendance Breakdown Page";
                    break;
                case 6:
                    reportPage = <SchoolsPage />;
                    reportPageName = "Schools Represented at Expo Page";
                    break;
                case 7:
                    reportPage = <InsightsPage />;
                    reportPageName = "Data Insights Page";
                    break;
                case 8:
                    reportPage = <UsefulnessPage />;
                    reportPageName = "Data Usefulness Page";
                    break;
            }
            
            report = (
                <Wrap>
                    <ExpoReportPrint 
                        backdropShow={ this.state.showBackDrop }
                        backdropHide={ this.handleBackdropHide }
                        printReport={ this.handleReportPrint }
                    />
                    <section className="row report-edits-header">
                    <div className="col-12">
                        <h3>expo page edits</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mollis,
                           dolor sit amet dignissim venenatis, mauris felis bibendum lectus, sed
                           dignissim velit nulla ac ipsum
                        </p>
                    </div>
                    </section>
                    <section className="row report-title justify-content-between">
                        <div className="col-8">
                            <h3>{ reportPageName }</h3>
                        </div>
                        <div className="col-4">
                            <a href="#" onClick={ this.handleViewExpoReportPrint }>
                                <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                download PDF report
                            </a>
                        </div>
                    </section>
                    <section className="report-content">
                        <div className="col-12">
                            { reportPage }
                        </div>
                    </section>
                    <section className="row justify-content-between report-page-navigation">
                        <div className="col-3">
                            <Button clickHandler={ () => this.handleReportPageNav("previous") }>
                                <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                                { "prev page " + this.state.viewedPage + "/8" }
                            </Button>
                        </div>
                        <div className="col-3">
                            <Button clickHandler={ () => this.handleReportPageNav("next") }>
                                { "next page " + this.state.viewedPage + "/8" }
                                <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                            </Button>
                        </div>
                    </section>
                </Wrap>
            )
        }

        if ( this.state.loadingReaport ) {
            report =(
                <div className="row justify-content-center loading-report">
                    <LoadingData title="fetching report data" />
                </div>
            )
        }

        if ( Object.keys(this.state.filters).length > 1 ) {
            let filterInputName = "";

            Object.keys(this.state.filters).forEach( (filterName, index) => {
                filterInputName = this.state.filters[filterName].toLowerCase();

                if ( filterName !== "defaultFilter" ) {
                    extraFilters[index] = (
                        <Filter 
                            pageName={ "expoReport" }
                            filterName={ filterName } 
                            filterValue={ this.state.filters[filterName] } 
                            onFilterChange={ this.handleFilterChange } 
                            removeExtraFilter={ this.handleRemoveFilter } 
                            key={ filterName }
                            filterInputName={ filterInputName }
                            filterInputValue={ this.state.filterOptions[filterInputName] }
                            activityMinValue={ this.state.filterOptions["activitymin"] }
                            activityMaxValue={ this.state.filterOptions["activitymax"] }
                            actionDateFrom={ this.state.filterOptions["actiondatefrom"] }
                            actionDateTo={ this.state.filterOptions["actiondateto"] }
                            createdDateFrom={ this.state.filterOptions["createddatefrom"] }
                            createdDateTo={ this.state.filterOptions["createddateto"] }
                            onFilterValueChange={ this.handleFilterValueChange }
                            onDateSelect={ this.handleFilterDateSelect }
                            schoolNamesList={ this.state.schoolList } 
                        />
                    );
                }
            });
        }

        return(
            <Wrap>
                <div className="row no-gutters page-tool-heading">
                    <div className="col-12">
                        <h3>Expo Report</h3>
                    </div>
                </div>
                <section className="row no-gutters view-users-filter">
                    <Filter 
                        pageName={ "expoReport" }
                        filterName={ "defaultFilter" } 
                        filterValue={ this.state.filters.defaultFilter } 
                        onFilterChange={ this.handleFilterChange } 
                        fetchData={ this.handleFetchReprotData } 
                        addExtraFilter={ this.handleAddFilter } 
                        filterInputName={ this.state.filters.defaultFilter.toLowerCase() }
                        filterInputValue= { this.state.filterOptions[this.state.filters.defaultFilter.toLowerCase()] }
                        activityMinValue={ this.state.filterOptions["activitymin"] }
                        activityMaxValue={ this.state.filterOptions["activitymax"] }
                        actionDateFrom= { this.state.filterOptions["actiondatefrom"] }
                        actionDateTo= { this.state.filterOptions["actiondateto"] }
                        createdDateFrom= { this.state.filterOptions["createddatefrom"] }
                        createdDateTo= { this.state.filterOptions["createddateto"] }
                        onFilterValueChange={ this.handleFilterValueChange }
                        onDateSelect={ this.handleFilterDateSelect }
                        schoolNamesList={ this.state.schoolList } 
                    />
                    { extraFilters }
                </section>
                { report }
            </Wrap>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setReportInfo: ( reportInfo ) => dispatch({type: actionTypes.SET_REPORT_INFO, payload:{ reportInfo: reportInfo }})
    }
}

export default connect("", mapDispatchToProps)(ExpoReport);