import React from 'react';
// 
// Manage modules page: page to manage modules - adding/modifying new modules, thier classes and slides
// 

// 
// Components
// 

// Wrap component
import Wrap from '../../hoc/wrap';
// Header component
import Header from '../../header/header';
// Toolbar component
import Toolbar from '../../UI/toolbar/toolbar';
// Main content wrap component
import MainContentWrap from '../../UI/mainContentWrap/mainContentWrap';
// Manage modules comoponent
import ManageModules from '../../../containers/manageModules/manageModules';

// Style
import './manageModulesPage.css';

const manageModulesPage = ( props ) => (
    <Wrap>
        <Header 
            whichHeader="modules" 
            showToolsNav={ props.showToolsNav } 
            toggleToolsNav={ props.toggleToolsNav } 
            showProfileNav={ props.showProfileNav } 
            toggleProfileNav={ props.toggleProfileNav } 
            handleLogout={ props.handleLogout }
        />
        <main className="manage-modules">
            <Toolbar adminTool="modules" />
            <MainContentWrap>
                <ManageModules />
            </MainContentWrap>
        </main>
    </Wrap>
);

export default manageModulesPage