import React from 'react';

// Style
import "./subjectItem.css";

 const subjectItem = ( props ) => {
    return (
        <div className="row subject-item">
            <div className="col-4 subject-name">
                <p>
                    <i className={ "fa fa-" + ( props.requirementMet ? "smile" : "frown" ) + "-o " + ( props.requirementMet ? "green" : "red" ) } aria-hidden="true"></i>
                    { props.subjectName }
                </p>
            </div>
            <div className="col-8">
                <div className="row justify-content-end subject-marks">
                     <div className="col-4 mark-item my-mark">
                        <p>My Mark</p>
                        <span>{ props.subjectMark + "%" }</span>
                     </div>
                     {
                         props.subjectRequiredMark ?
                            <div className="col-4 mark-item  required-mark">
                                <p>Required Mark</p>
                                <span>{ props.subjectRequiredMark + "%" }</span>
                            </div>
                            : ""
                     }
                     <div className="col-4 mark-item  aps-points">
                        <p>APS Points</p>
                        <span>{ props.APSPoints }</span>
                     </div>
                </div>
            </div>
        </div>
    )
}

export default subjectItem;
