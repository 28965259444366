import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import Chart from "react-google-charts";
// 
// 
// 

// 
// Components
// 

// Image area component
import ImageArea from "../../../components/pages/expoReportPage/reportCopmposerControls/imageArea/imageArea";
// Text area component
import TextArea from "../../../components/pages/expoReportPage/reportCopmposerControls/textArea/textArea";
// Report footer
import ReportFooter from "../../../components/pages/expoReportPage/reportPages/reportFooter/reportFooter";

// 
// Assets
// 

// Background picture
import schoolsPagePic from "../../../assets/images/schoolsPage.png";

// Style
import "./schoolsPage.css";

class SchoolsPage extends Component {
    componentDidMount() {
        this.props.setSchoolsExpoData(this.props.expoInfo);
    }

    render() {

        let schoolsPageImage = schoolsPagePic;

        if ( this.props.schoolsPageImage.previewURL !== "" ) {
            schoolsPageImage = this.props.schoolsPageImage.previewURL;
        }

        let pageHeadingStyle = {
            width     : "100%",
            fontSize  : "2em",
            fontWeight: "600",
            resize    : "none",
            marginTop : "1em",
            textAlign : "center",
        }

        let subjectsTitleStyle = {
            width          : "100%",
            fontSize       : "1.2em",
            fontWeight     : "600",
            resize         : "none",
            height         :  "4em", 
            backgroundColor: "transparent",
            color          : "white"
        }

        let subjectStyle = {
            width          : "100%",
            fontSize       : "1.1em",
            fontWeight     : "400",
            resize         : "none",
            height         :  "4em", 
            backgroundColor: "transparent",
            color          : "white"
        }

        let chartLegendStyle = {
            width          : "100%",
            fontSize       : "1.1em",
            fontWeight     : "400",
            resize         : "none",
            height         :  "4em", 
            backgroundColor: "transparent",
        }

        return (
            <div className="schools-page">
                <div className="row">
                    <div className="col-12">
                        <div className="row page-title">
                        <TextArea 
                            textAreaName="schoolsPageHeading"
                            textAreaValue={ this.props.pageHeading }
                            textAreaOnChange={ this.props.textAreaOnChange }
                            textAreaPlaceholder=""
                            textAreaCol="12"
                            style={ pageHeadingStyle }
                        />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <ImageArea 
                        imageAreaCol="6"
                        imageAreaPreview={ schoolsPageImage }
                        imageAreaName={ this.props.schoolsPageImage.fileName }
                        imageUpload={ this.props.imageUpload }
                        imageAreaWidth="335em"
                        imageAreaHeight=""
                        imageAreaAlt="Background Picture"
                    />
                    <div className="col-6">
                        <ul>
                            <li className="subject-title">
                                <TextArea 
                                    textAreaName="subjectsTitle"
                                    textAreaValue={ this.props.subjectsTitle }
                                    textAreaOnChange={ this.props.textAreaOnChange }
                                    textAreaPlaceholder=""
                                    textAreaCol="12"
                                    style={ subjectsTitleStyle }
                                />
                            </li>
                            <li className="subject">
                                <div className="row">
                                    <div className="col-2 mathematics-icon">
                                        <i className="fa fa-clock-o subject-icon" aria-hidden="true"></i>
                                    </div>
                                    <div className="col-10 mathematics-title">
                                        <TextArea 
                                            textAreaName="mathematics"
                                            textAreaValue={ this.props.mathematics }
                                            textAreaOnChange={ this.props.textAreaOnChange }
                                            textAreaPlaceholder=""
                                            textAreaCol="12"
                                            style={ subjectStyle }
                                        />                                    
                                    </div>
                                </div>
                            </li>
                            <li className="subject">
                                <div className="row">
                                    <div className="col-2 physics-icon">
                                        <i className="fa fa-clock-o subject-icon" aria-hidden="true"></i>
                                    </div>
                                    <div className="col-10 physics-title">
                                        <TextArea 
                                            textAreaName="physics"
                                            textAreaValue={ this.props.physics }
                                            textAreaOnChange={ this.props.textAreaOnChange }
                                            textAreaPlaceholder=""
                                            textAreaCol="12"
                                            style={ subjectStyle }
                                        />                                    
                                    </div>
                                </div>
                            </li>
                            <li className="subject">
                                <div className="row">
                                    <div className="col-2 life-science-icon">
                                        <i className="fa fa-clock-o subject-icon" aria-hidden="true"></i>
                                    </div>
                                    <div className="col-10 life-science-title">
                                        <TextArea 
                                            textAreaName="lifeScience"
                                            textAreaValue={ this.props.lifeScience }
                                            textAreaOnChange={ this.props.textAreaOnChange }
                                            textAreaPlaceholder=""
                                            textAreaCol="12"
                                            style={ subjectStyle }
                                        />                                    
                                    </div>
                                </div>
                            </li>
                            <li className="subject">
                                <div className="row">
                                    <div className="col-2 accounting-icon">
                                        <i className="fa fa-clock-o subject-icon" aria-hidden="true"></i>
                                    </div>
                                    <div className="col-10 accounting-title">
                                        <TextArea 
                                            textAreaName="accounting"
                                            textAreaValue={ this.props.accounting }
                                            textAreaOnChange={ this.props.textAreaOnChange }
                                            textAreaPlaceholder=""
                                            textAreaCol="12"
                                            style={ subjectStyle }
                                        />                                    
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <Chart
                            width={'300px'}
                            height={'300px'}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={ this.props.chartData }
                            options={{
                                title: '',
                                // Just add this option
                                pieHole: 0.5,
                                legend: 'none',
                            }}
                            rootProps={{ 'data-testid': '3' }}
                        />
                    </div>
                    <div className="col-4 chart-legend">
                        <div className="row grade-10-total">
                            <div className="col-2">
                                <i className="fa fa-circle subject-icon" aria-hidden="true"></i>
                            </div>
                            <div className="col-10">
                                <TextArea 
                                    textAreaName="grade10Total"
                                    textAreaValue={ this.props.grade10Total }
                                    textAreaOnChange={ this.props.textAreaOnChange }
                                    textAreaPlaceholder=""
                                    textAreaCol="12"
                                    style={ chartLegendStyle }
                                />                                    
                            </div>
                        </div>
                        <div className="row grade-11-total">
                            <div className="col-2">
                                <i className="fa fa-circle subject-icon" aria-hidden="true"></i>
                            </div>
                            <div className="col-10">
                                <TextArea 
                                    textAreaName="grade11Total"
                                    textAreaValue={ this.props.grade11Total }
                                    textAreaOnChange={ this.props.textAreaOnChange }
                                    textAreaPlaceholder=""
                                    textAreaCol="12"
                                    style={ chartLegendStyle }
                                />                                    
                            </div>
                        </div>
                        <div className="row grade-12-total">
                            <div className="col-2">
                                <i className="fa fa-circle subject-icon" aria-hidden="true"></i>
                            </div>
                            <div className="col-10">
                                <TextArea 
                                    textAreaName="grade12Total"
                                    textAreaValue={ this.props.grade12Total }
                                    textAreaOnChange={ this.props.textAreaOnChange }
                                    textAreaPlaceholder=""
                                    textAreaCol="12"
                                    style={ chartLegendStyle }
                                />                                    
                            </div>
                        </div>
                    </div>
                </div>
                <ReportFooter />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        pageHeading: state.schoolsPage.schoolsPageHeading,
        subjectsTitle: state.schoolsPage.subjectsTitle,
        mathematics: state.schoolsPage.mathematics,
        physics: state.schoolsPage.physics,
        lifeScience: state.schoolsPage.lifeScience,
        accounting: state.schoolsPage.accounting,
        schoolsPageImage: state.schoolsPage.schoolsPageImage,
        chartData: state.schoolsPage.chartData,
        grade10Total: state.schoolsPage.grade10Total,
        grade11Total: state.schoolsPage.grade11Total,
        grade12Total: state.schoolsPage.grade12Total,
        expoInfo: state.expoReport.reportInfo,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        textAreaOnChange: (event) => dispatch({type: actionTypes.UPDATE_TEXT_SCHOOLS_PAGE, payload: { event: event }}),
        imageUpload: (event) => dispatch({ type: actionTypes.UPLOAD_IMAGE_SCHOOLS_PAGE, payload: { event: event }}),
        imageUpload: (event) => dispatch({ type: actionTypes.UPLOAD_IMAGE_SCHOOLS_PAGE, payload: { event: event }}),
        setSchoolsExpoData: (expoInfo) => dispatch({type: actionTypes.SET_SCHOOLS_EXPO_DATA, payload: { expoInfo: expoInfo }})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolsPage);