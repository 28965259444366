import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import Chart from "react-google-charts";

// 
// Components
// 

// Text area component
import TextArea from "../../../components/pages/expoReportPage/reportCopmposerControls/textArea/textArea";
// Report footer
import ReportFooter from "../../../components/pages/expoReportPage/reportPages/reportFooter/reportFooter";

// Style
import "./highLevelPage.css";

class HighLevelPage extends Component {

    componentDidMount() {
        this.props.setHighLevelDataExpo(this.props.expoInfo);
    }

    render() {
        let pageHeadingStyle = {
            width     : "100%",
            fontSize  : "2em",
            fontWeight: "600",
            resize    : "none",
            marginTop : "1em",
            textAlign : "center",
            float     : "right",
        }

        let pageContentText = {
            width     : "100%",
            fontSize  : "1.2em",
            fontWeight: "400",
            resize    : "none",
            textAlign : "left",
        }

        let schoolListTextStyle = {
            width     : "100%",
            height    : "15em",
            fontSize  : "1.2em",
            fontWeight: "400",
            resize    : "none",
            textAlign : "left",
        }

        let gradesListTextStyle = {
            width     : "100%",
            height    : "10em",
            fontSize  : "1.2em",
            fontWeight: "400",
            resize    : "none",
            textAlign : "left",
        }

        return (
            <div className="high-level-page">
                <div className="row justify-content-end">
                    <div className="col-8">
                        <div className="row page-title">
                        <TextArea 
                            textAreaName="highLevelPageHeading"
                            textAreaValue={ this.props.pageHeading }
                            textAreaOnChange={ this.props.textAreaOnChange }
                            textAreaPlaceholder=""
                            textAreaCol="12"
                            style={ pageHeadingStyle }
                        />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className="total blue">
                             <p>
                                 { this.props.eventAttendees }
                             </p>
                         </div>
                        <div className="row">
                            <div className="col-2">
                                <i className="text-icon green fa fa-calendar-o" aria-hidden="true"></i>
                            </div>
                            <TextArea 
                                textAreaName="eventDuration"
                                textAreaValue={ this.props.eventDuration }
                                textAreaOnChange={ this.props.textAreaOnChange }
                                textAreaPlaceholder=""
                                textAreaCol="10"
                                style={ pageContentText }
                            />
                            <div className="col-2">
                                <i className="text-icon green fa fa-users" aria-hidden="true"></i>
                            </div>
                            <TextArea 
                                textAreaName="dataTeam"
                                textAreaValue={ this.props.dataTeam }
                                textAreaOnChange={ this.props.textAreaOnChange }
                                textAreaPlaceholder=""
                                textAreaCol="10"
                                style={ pageContentText }
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="total blue">
                             <p>
                                 { this.props.totalGrade12 }
                             </p>
                         </div>
                        <div className="row">
                            <div className="col-2">
                                <i className="text-icon blue fa fa-graduation-cap" aria-hidden="true"></i>
                            </div>
                            <TextArea 
                                textAreaName="gradesList"
                                textAreaValue={ this.props.gradesList }
                                textAreaOnChange={ this.props.textAreaOnChange }
                                textAreaPlaceholder=""
                                textAreaCol="10"
                                style={ gradesListTextStyle }
                            />
                        </div>
                    </div>
                    <div className="col-4">
                         <div className="schools-total">
                             <p>
                                 { this.props.totalSchools }
                             </p>
                         </div>
                         <div className="row">
                            <div className="col-2">
                                <i className="text-icon blue fa fa-university" aria-hidden="true"></i>
                            </div>
                            <TextArea 
                                textAreaName="schoolsList"
                                textAreaValue={ this.props.schoolsList }
                                textAreaOnChange={ this.props.textAreaOnChange }
                                textAreaPlaceholder=""
                                textAreaCol="10"
                                style={ schoolListTextStyle }
                            />
                        </div>
                    </div>
                </div>
                <ReportFooter />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        pageHeading: state.highLevelPage.highLevelPageHeading,
        eventDuration: state.highLevelPage.eventDuration,
        dataTeam: state.highLevelPage.dataTeam,
        gradesList: state.highLevelPage.gradesList,
        schoolsList: state.highLevelPage.schoolsList,
        totalSchools: state.highLevelPage.totalSchools,
        eventAttendees: state.highLevelPage.eventAttendees,
        totalGrade12: state.highLevelPage.totalGrade12,
        expoInfo: state.expoReport.reportInfo,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        textAreaOnChange: (event) => dispatch({type: actionTypes.UPDATE_TEXT_HIGH_LEVEL_PAGE, payload: { event: event }}),
        setHighLevelDataExpo: (expoInfo) => dispatch({type: actionTypes.SET_HIGH_LEVEL_EXPO_DATA, payload: { expoInfo: expoInfo }}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HighLevelPage);