import React from "react";
// 
// New form section component : component for adding a new dynamic form section
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../../hoc/wrap";
// Backdrop component
import Backdrop from "../../../UI/backdrop/backdrop";
// Button component
import Button from "../../../UI/button/button";
// New form group component
import NewFormGroup from "./newFormGroup/newFormGroup";
// Spinner component
import Spinner from "../../../UI/spinner/spinner";

// Style
import "./newFormSection.css";

const newFormSection = ( props ) => {
    let newFormSection = [];
    let saveBtnContent = "";

    if ( props.loadingNewForm ) {
        saveBtnContent = <Spinner />

    } else {
        saveBtnContent = "save";
    }

    if ( Object.keys(props.newFormGroups).length > 0 ) {
        newFormSection = Object.keys(props.newFormGroups).map( (formGroupName, index) => {
            return <NewFormGroup 
                        sourceName={ formGroupName } 
                        formGroupFields={ props.newFormGroups[formGroupName] } 
                        addFormGroupItem={ props.addFormGroupItem } 
                        cancelFormGroupItem={ props.cancelNewFormGroupItem }
                        formGroupItemOnChange={ props.formGroupItemOnChange } 
                        key={ index } 
                    />;
        });
    }

    return(
        <Wrap>
            <Backdrop backdropShow={ props.backdropShow } backdropHide={ props.backdropHide } />
            <section className="container new-form-section" style={{ display: props.backdropShow ? "block" : "none" }}>
                <div className="row">
                    <h3 className="section-title">create new form section</h3>   
                </div>
                <div className="row bg">
                    <div className="col-5">
                        <div className="auto-complete-search">
                            <label className="form-label" style={{marginLeft: "0.4em", marginRight: "1.25em"}}>Source :</label>
                            <div className="form-text-wrap" style={{display: "inline-block"}}>
                                <input type="text" value={ props.formGroupSource } onChange={ event => props.formGroupSourceChange(event) } placeholder="source" />
                            </div>
                            <div className="search-list" style={{ display: props.formGroupSourceList.length > 0 ? "block" : "none" }}>
                                <ul>
                                    { props.formGroupSourceList }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="auto-complete-search">
                            {/* <input type="checkbox" /> */}
                            <label className="form-label" style={{marginLeft: "0.4em", marginRight: "1.25em"}}>Copy From Source :</label>
                            <div className="form-text-wrap" style={{display: "inline-block"}}>
                                <input type="text" placeholder="source" value={ props.copyFromSource } onChange={ event => props.copyFromSourceChange(event) } />
                            </div>
                            <div className="search-list" style={{ display: props.copyFromSourceList.length > 0 ? "block" : "none", left: "11.7em" }}>
                                <ul>
                                    { props.copyFromSourceList }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center bg">
                    <label className="form-label" style={{marginRight: "1.25em"}}>Form group title :</label>
                    <div className="form-text-wrap" style={{width: "14.5em", display: "inline-block"}}>
                        <input type="text" placeholder="Form Group Title" value={ props.newFormGroupTitle }  onChange={ event => props.newFormGroupTitleOnChange(event) } />
                    </div>
                    <Button buttonTitle="add form group" clickHandler={ event => props.createNewFormGroup(event) } />
                </div>
                { newFormSection }
                <div className="row btns-container">
                    <Button buttonClassName="form-cancel" buttonTitle="cancel" clickHandler={ event => props.cancelFormGroup( event ) } />
                    <Button clickHandler={ event => props.saveFormGroup( event ) }>
                        { saveBtnContent }
                    </Button>
                </div>
            </section>
        </Wrap>
    )
}

export default newFormSection;