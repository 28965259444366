import React, { Component } from "react";
// 
// Student documents component : list view of student applications docs
//

// 
// Components
// 

// Loading data component
import LoadingData from "../../../../UI/loadingData/loadingData";
// Docs list titles
import DocsListTitles from "./docsListTitles/docsListTitles";
// Button component
import Button from "../../../../UI/button/button";
// Wrap component
import Wrap from "../../../../hoc/wrap";
// Request docs modal component
import RequestDocsModal from "./requestDocsModal/requestDocsModal";

// Style
import "./studentDocs.css";

class StudentDocs extends Component {

    componentWillUnmount() {
        this.props.deleteDoc();

        console.log("Docuemnt removed");
    }

    render() {
        let content = "";
        let applicationDocs = [];

        if ( this.props.loadingApplicationDocs ) {
            content = <LoadingData title="fetching documents" />

        } else {
            applicationDocs = this.props.applicationDocs.map( (doc, index) => {
                    return(
                        <div className="col-11" key={ index }>
                            <div className="row application-item doc-row">
                                <div className="col-1">
                                    <input type="checkbox" />
                                </div>
                                <div className="col-8">
                                    <a href="#" onClick={ event => this.props.downloadDoc(event, doc.ID, this.props.studentId, doc.FileName) } className={"doc-button doc-download" + (doc.FileName ? " available" : "")}>
                                        <i className="fa fa-download" aria-hidden="true"></i>
                                    </a>
                                    <a href="#" onClick={ event => this.props.downloadDoc(event, doc.ID, this.props.studentId, doc.FileName) } className={"doc-button doc-view" + (doc.FileName ? " available" : "")}>
                                        { doc.Name }
                                    </a>                                
                                </div>
                                <div className="col-3">
                                    <Button buttonClassName="doc-request" clickHandler={ () => this.props.requestDocument( doc.ID ) }>
                                        <i className="fa fa-envelope" aria-hidden="true"></i>
                                        request
                                    </Button>
                                    <input type="file" name={ doc.ID +  "_" + doc.Name } style={{ display: "none" }} ref={ ref => this["docUpload" + doc.ID] = ref } onChange={ event => this.props.applicationDocSelect(event, doc.ID, this.props.studentId ) } />
                                    <Button buttonClassName="doc-upload" clickHandler={ () => this["docUpload" + doc.ID].click() }>
                                        <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                                        upload
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )
                })

            content = (
                <Wrap>
                    <RequestDocsModal 
                        showDocsModal={ this.props.showDocsModal } 
                        hideDocsModal={ this.props.hideDocsModal } 
                        requestedDocsText={ this.props.requestedDocsText }
                        requestedDocsTextChange={ this.props.requestedDocsTextChange }
                        sendDocRequest={ this.props.sendDocRequest }
                    />
                    <div className="col-11">
                        <h5 className="applications-title">Application documents</h5>
                    </div>
                    <DocsListTitles />
                    { applicationDocs }
                    <div className="col-11">
                        <div className="row justify-content-end doc-buttons">
                            <div className="col-2">
                                <Button buttonClassName="doc-request" clickHandler={ this.props.hideDocsModal }>
                                    <i className="fa fa-envelope" aria-hidden="true"></i>
                                    request
                                </Button>
                            </div>
                        </div>
                    </div>
                </Wrap>
            )
        }

        return(
            <div className="row justify-content-center student-applications">
                { content }
            </div>
        )
    }
    
}

export default StudentDocs;