import React from 'react';
// 
// Form input item component: Add user form input items
// 

// 
// Components
// 

// Wrap component
import Wrap from '../../../hoc/wrap';

// Style
import './formInputItem.css';

const formInputItem = ( props ) => {
    let formInputItem = "";
    let inputWrapWidth= "";

    if ( props.inputMaxLength === "2" || props.inputMaxLength === "3" || props.inputType === "select") {
        inputWrapWidth = "6.5em";

    } else {
        inputWrapWidth = "16.4125em";
    }

    switch( props.inputType ) {
        case "text":
            formInputItem = (
                <Wrap>
                    <input type="text" 
                        name={ props.inputName } 
                        value={ props.inputValue } 
                        onChange={ props.inputOnChange }
                        onBlur={ props.inputFocusOut }
                        maxLength={ props.inputMaxLength }
                        style={ (props.inputMaxLength === "2" || props.inputMaxLength === "3") ? { width: "2.7em" } : { width: "12.2em" } }
                        placeholder={ props.inputPlaceholder }
                        autocomplete="off"
                    />
                    <i className="fa fa-info-circle" area-hidden="true"></i>
                </Wrap>
            );
        break;
        
        case "radio":
            if ( props.inputName === "gender" ) {
                formInputItem = (
                    <Wrap>
                        <div>
                            <input type="radio" 
                                name={ props.inputName } 
                                value="M" 
                                checked={ props.inputChecked === "M" }
                                onChange={ props.inputOnChange }  
                            />
                            <label>male</label>
                        </div>
                        <div>
                            <input type="radio" 
                                name={ props.inputName } 
                                value="F"
                                checked={ props.inputChecked === "F" }
                                onChange={ props.inputOnChange }  
                            />
                            <label>female</label>
                        </div>
                    </Wrap>
                );   
            }
            break;

        case "select":
            formInputItem = (
                <select 
                    name={ props.inputName } 
                    value={ props.inputValue }  
                    onChange={ props.inputOnChange }>
                    { 
                        Object.keys( props.inputOptions ).map( (key) => {
                            return <option key={ key }>{ props.inputOptions[ key ] }</option>
                        })
                    }
                </select>
            );
            break;

            default:
                    formInputItem = null;
                break;
    }

    return ( 
        <Wrap>
            <label className="form-label">{ props.inputLabel }</label>
            <div className={ "form-" + props.inputType + "-wrap" + ( props.IsValidUserName === false  || props.IsValidSource === false ? " invalid-input" : "" )} style={{ width: inputWrapWidth }}>
                { formInputItem }
            </div>
        </Wrap>
    );
};

export default formInputItem;