import React from "react";
// 
// Userr access card component: Component card for user access right
// 

// 
// Components
// 

// Button component
import Button from "../../../../../UI/button/button";

// Style
import "./userAccessCard.css";

const userAccessCard = ( props ) => {
    return(
        <div className="user-access-card">
            <p>{ props.accessRightName }</p>
            <Button buttonClassName="del-access-right" clickHandler={ props.removeUserRole } >
                <i className="fa fa-trash" aria-hidden="true"></i>
            </Button>
        </div>
    );
}

export default userAccessCard;