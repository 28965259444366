import React from "react";
import formatDate from "../../../../../js/library/formatDate/formatDate";
// 
// Student applications component
// 

//
// Components
// 

// Loading data component
import LoadingData from "../../../../UI/loadingData/loadingData";
// Wrap component 
import Wrap from "../../../../hoc/wrap";
// Applications list titles component
import ApplicationsListTitle from "./applicationListTitles/applicationsListTitle";
// Status list component
import StatusList from "../../statusList/statusList";

// Style
import "./bursaryItem.css";

const BursaryItem = ( props ) => {
    let content = "";
    let bursaries = [];

    if ( props.loadingBursaries ) {
        content = <LoadingData title="fetching bursaries" />

    } else {
        if ( props.viewStudentBursaries ) {
            if ( props.studentBursaries.length > 0 ) {
                bursaries = props.studentBursaries.map( (bursary, index) => {
                    let ClosingDate = new Date(bursary.ApplicationCloseDate);
    
                    return (
                        <div className="col-11" key={ index }>
                            <div className="row align-items-center application-item">
                                <div className="col-7">
                                    <p>{ bursary.Name }</p>
                                </div>
                                <div className="col-2">
                                    {/* <p>{ ClosingDate.getDate() + " - " + ( formatDate.getMonthShortName(ClosingDate.getMonth() + 1) ) + " - " + ClosingDate.getFullYear() }</p> */}
                                </div>
                                <div className="col-3">
                                    <StatusList 
                                        activeStatus={ bursary.Status } 
                                        statusList={ props.statusList } 
                                        updateApplicationStatus={ event => props.updateBursaryStatus(event, props.viewedStudentApplications.studentId, bursary.ID) } 
                                    /> 
                                </div>
                            </div>
                        </div>
                    )
                });
            }

            content = (
                    <Wrap>
                        <div className="col-11">
                            <h5 className="bursaries-title">Bursary applications for { props.viewedStudentApplications.name }</h5>
                        </div>
                        {
                            props.studentBursaries.length > 0 ?
                            <Wrap>
                                <ApplicationsListTitle 
                                    statusList={ props.statusList } 
                                    updateAllBursaryStatus={ props.updateAllBursaryStatus } 
                                    />
                                { bursaries }
                            </Wrap>

                            : <p><em>No bursaries found</em></p>
                        }
                        
                    </Wrap>     
            );
        }
    }

    return(
        <Wrap>
            <div className="row justify-content-center student-bursaries">
                { content }
            </div>
        </Wrap>
    );
}

export default BursaryItem;