import React from "react";
// 
// Table titles comoponent: application institution table titles component
// 

// Style
import "./tableTitles.css";

const tableTitles = () => {
    return (
        <div className="row table-titles">
            <div className="col-1">
                <h5>Action</h5>
            </div>
            <div className="col-4">
                <h5>Institution Name</h5>
            </div>
        </div>
    )
}

export default tableTitles;