import React, { Component } from 'react';
import IsValidEmail from '../../js/library/isValidEmail';
import HTTPRequest from '../../js/library/HTTPConfig/axiosConfig';
import UserServices from "../../js/library/services/userservice";
import studentInfoService from "../../js/library/services/studentInfoService";

// 
// Student info form component: container component for student info form
// 

// 
// Components
// 

// Wrap component
import Wrap from '../../components/hoc/wrap';
// Form input item component
import FormInputItem from '../../components/pages/addUserPage/formInputItem/formtInputItem';
// Button component
import Button from '../../components/UI/button/button';
// Spinner component
import Spinner from '../../components/UI/spinner/spinner';
// Modal component
import Modal from '../../components/UI/modal/modal';
// New form section component
import NewFormSection from "../../components/pages/addUserPage/newFormSection/newFormSection";

// Style
import './studentInfoForm.css';

const RACE_LIST = {
    black   : "Black", 
    white   : "White", 
    indian  : "Indian", 
    asian   : "Asian", 
    coloured: "Coloured"
}

let SUBJECT_LIST = null

class StudentInfoForm extends Component {
    state = {
        studentInfoFormValues: {
            name      : "",
            surname   : "",
            cellNumber: "",
            gender    : "",
            grade     : "",
            race      : "black",
            schoolName: "",
            userName  : "",
            password  : "",
            source    : "",
            subject1  : "",
            mark1     : "",
            subject2  : "",
            mark2     : "",
            subject3  : "",
            mark3     : "",
            subject4  : "",
            mark4     : "",
            subject5  : "",
            mark5     : "",
            subject6  : "",
            mark6     : "",
            subject7  : "",
            mark7     : "",
            subject8  : "",
            mark8     : "",
            subject9  : "",
            mark9: "",
            careers: ["", "", ""],
           careerIds : [0,0,0]
        },
        searchQueries: {
            schoolName: "",
            subject1  : "",
            subject2  : "",
            subject3  : "",
            subject4  : "",
            subject5  : "",
            subject6  : "",
            subject7  : "",
            subject8  : "",
            subject9  : "",
        },
        selectedSubjects: {
            subject1: "",
            subject2: "",
            subject3: "",
            subject4: "",
            subject5: "",
            subject6: "",
            subject7: "",
            subject8: "",
            subject9: ""
        },
        selectedSchool : "",
        schoolNameList : null,
        subjectList: null,
        careerList: [],
        displayCareerIndex : null,
        userToken      : "",
        studentCreated : false,
        userNameIsValid: null,
        sourceIsValid: null,
        loading        : false,
        showBackdrop   : false,
        modalContent   : {
            modalIcon   : "",
            modalContent: ""
        },
        adminUserToken: "",
        userName: "",
        formGroupSource: "",
        newFormGroupTitle: "",
        showNewFormBackdrop: false,
        newFormGroups: {},
        loadingNewForm: false,      
        sourceFormSections: {},
        sourceList: [],
        showStudentMarks: false,
        formGroupSourceList: [],
        copyFromSource: "",
        copyFromSourceList: []
    }

    componentWillMount() {
        let userInfo = {};
        if (UserServices.USER_TOKEN && UserServices.USER_TOKEN !== "") {
            this.setState({
                adminUserToken: UserServices.USER_TOKEN
            });
            
        } else {
            userInfo = JSON.parse(localStorage.getItem("userInfo"));
            
            this.setState({
                adminUserToken: userInfo.userToken,
                userName: userInfo.userName
            });
        }
    }
 
    componentDidMount() {
        HTTPRequest.get("/subjects/getsubjects?type=all").then( response => {
            SUBJECT_LIST = response.data.data;

        }).catch( error => {
            console.log( "error in subjects retrival: " + error );
        });
    }

    createStudent = () => {
        this.setState({ loading: true });
        let studentCreated = false;
        let state = "onboard";

        const selectedSubjecstList = this.state.selectedSubjects;

        const studentInfo = {
            "username": this.state.studentInfoFormValues.userName,
            "name"    : this.state.studentInfoFormValues.name,
            "surname" : this.state.studentInfoFormValues.surname,
            "usertype": "student",
            "password": this.state.studentInfoFormValues.password,
            "source"  : this.state.studentInfoFormValues.source,
            "promocode": this.state.userName
        }

        const studentSubjects = Object.values(selectedSubjecstList).filter( subjectId => {
            return subjectId !== "";
        });

        if ( studentSubjects.length === 0 ) {
            state = "fsetup";
        }  
        
        if ( this.state.studentInfoFormValues.password === "random" ) {
            state = "password"
        }

        const studentUpdateDetails = {
            "schoolID": this.state.selectedSchool,
            "grade"   : this.state.studentInfoFormValues.grade,
            "race"    : this.state.studentInfoFormValues.race,
            "gender"  : this.state.studentInfoFormValues.gender,
            "State": state
        }

        if ( this.state.studentInfoFormValues.careerIds.length > 0 ) {
            studentUpdateDetails["careers"] = this.state.studentInfoFormValues.careerIds;
        }

        HTTPRequest({
            method: "POST",
            url   : "/account/register",
            data  : studentInfo,
            headers: { "Authorization": "Bearer " + this.state.adminUserToken }

        }).then( response => {
            console.log("registered");
            if ( response.data.success ) {
                console.log("user created");
                studentCreated = true;
                this.setState({
                    userToken: response.data.data.token
                })

                if( this.state.userToken ) {     
                    console.log("\ntoken"); 

                    HTTPRequest({
                        method: "POST",
                        url: "/me/updatedetails",
                        data: { "profile": studentUpdateDetails},
                        headers: { "Authorization": "Bearer " + this.state.userToken }

                    }).then( response => {
                        console.log("\nUpdatedetails");
                        if ( response.data.success ) {
                            console.log("user details updated");
                            studentCreated = true;

                            if ( studentSubjects.length > 0 ) {
                                HTTPRequest({
                                    method: "POST",
                                    url   : "/me/addsubjects",
                                    data: { "subjects": studentSubjects },
                                    headers: { "Authorization": "Bearer " + this.state.userToken }

                                }).then( response => {
                                    console.log("\nUpdate Subjects");
                                    if ( response.data.success ) {
                                        console.log("user subjects added");
                                        let studentMarks = []
                                        let moreThanZero = false
                                        for ( let i = 1; i < Object.values( selectedSubjecstList ).length + 1; i++) {
                                            if ( selectedSubjecstList[ "subject" + i ] !== "" ) {
                                                studentMarks.push({ 
                                                    "SubjectID" : selectedSubjecstList[ "subject" + i ], 
                                                    "Mark"      : this.state.studentInfoFormValues[ "mark" + i ] 
                                                });

                                                if (parseInt(this.state.studentInfoFormValues["mark" + i]) > 0) {
                                                    moreThanZero = true;
                                                }
                                            } 
                                        }


                                        if (moreThanZero) {
                                            HTTPRequest({
                                                method: "POST",
                                                url: "/me/savemarks",
                                                data: { "marks": studentMarks },
                                                headers: { "Authorization": "Bearer " + this.state.userToken }

                                            }).then(response => {
                                                console.log("user marks saved");
                                                studentCreated = true;
                                                this.setState({
                                                    showBackdrop: true,
                                                    loading: false,
                                                    modalContent: {
                                                        modalIcon: "check-circle",
                                                        modalContent: "student added"
                                                    }
                                                })

                                            }).catch(error => {
                                                this.setState({
                                                    showBackdrop: true,
                                                    loading: false,
                                                    modalContent: {
                                                        modalIcon: "times-circle",
                                                        modalContent: "unable to save user marks"
                                                    }
                                                })
                                            });
                                        }
                                        else {
                                            studentCreated = true;
                                            this.setState({
                                                showBackdrop: true,
                                                loading: false,
                                                modalContent: {
                                                    modalIcon: "check-circle",
                                                    modalContent: "student added"
                                                }
                                            })
                                        }
                                    }

                                }).catch( error => {
                                    this.setState({
                                        showBackdrop: true,
                                        loading     : false,
                                        modalContent: {
                                            modalIcon   : "times-circle",
                                            modalContent: "unable to add user subjects"
                                        }
                                    })
                                });

                            } else {
                                this.setState({
                                    showBackdrop: true,
                                    loading     : false,
                                    modalContent: {
                                        modalIcon   : "check-circle",
                                        modalContent: "student added"
                                    }
                                })
                            }
                        }

                    }).catch( error => {
                        this.setState({
                            showBackdrop: true,
                            loading     : false,
                            modalContent: {
                                modalIcon   : "times-circle",
                                modalContent: "unable to update user details"
                            }
                        })
                    });

                    if ( Object.keys(this.state.sourceFormSections).length > 0 ) {
                        studentInfoService.saveLearnerExtProperties(this.state.sourceFormSections, this.state.userToken).then( response=> {
                            if ( response ) {
                                console.log("saved student ext props");
                            }
                        });
                    }
                }

            } else {
                this.setState({
                    showBackdrop: true,
                    loading     : false,
                    modalContent: {
                        modalIcon   : "times-circle",
                        modalContent: response.data.reason
                    }
                })
            }

        }).catch( error => {
            this.setState({
                showBackdrop: true,
                loading     : false,
                modalContent: {
                    modalIcon   : "times-circle",
                    modalContent: "unable to register user"
                }
            })
        });

        return studentCreated;
    }

    handleFormCancel = ( event ) => {
        event.preventDefault();

        let stateCopy = { ...this.state };
        
        stateCopy.studentInfoFormValues["name"] = ""; 
        stateCopy.studentInfoFormValues["surname"] = ""; 
        stateCopy.studentInfoFormValues["gender"] = ""; 
        stateCopy.studentInfoFormValues["race"] = "black"; 
        stateCopy.studentInfoFormValues["userName"] = ""; 
        stateCopy.studentInfoFormValues.careerIds = [0, 0, 0];
        stateCopy.studentInfoFormValues.careers = ["", "", ""]
        stateCopy.userToken = "";
        stateCopy.studentCreated = false;
        stateCopy.userNameIsValid = false;
        stateCopy.showBackdrop = false;
        stateCopy.modalContent["modalIcon"] = "";
        stateCopy.modalContent["modalContent"] = "";

        let sourceFormSectionsCopy = { ...this.state.sourceFormSections }

        Object.keys(sourceFormSectionsCopy).forEach( source => {
            Object.keys(sourceFormSectionsCopy[source]).forEach( formSection => {
                Object.keys(sourceFormSectionsCopy[source][formSection]).forEach( formField => {
                    sourceFormSectionsCopy[source][formSection][formField] = "";
                });
            });
        });

        stateCopy.sourceFormSections = sourceFormSectionsCopy;

        this.setState( stateCopy );
    }

    handleFormSubmit = ( event ) => {
        event.preventDefault();

        let modalContent = "";

        if ( ! this.state.userNameIsValid ) {
            this.setState({
                showBackdrop: true,
                loading     : false,
                modalContent: {
                    modalIcon   : "times-circle",
                    modalContent: "user name is invalid"
                }
            });

        } else if ( ! this.state.sourceIsValid ) {
            this.setState({
                showBackdrop: true,
                loading     : false,
                modalContent: {
                    modalIcon   : "times-circle",
                    modalContent: "Source is invalid"
                }
            });

        } else {
            this.createStudent();
        }
    }

    handleOnChange = ( event ) => {
        const inputValue              = event.target.value;
        const inputName               = event.target.name;
        let studentInfoFormValuesCopy = { ...this.state.studentInfoFormValues };
        let searchQueriesCopy         = { ...this.state.searchQueries };
        let schoolNameList            = [];
        let subjectList               = [];
        let responseData              = null;

        if ( inputName === "grade" || inputName.indexOf("mark") !== -1 || inputName === "cellNumber" ) {
            if ( event.target.validity.valid ) {
                if ( inputName.indexOf("mark") !== -1 ) {
                    if ( parseInt(inputValue) > 100 ) {
                        alert("Only enter marks between 0 and 100");
                        
                    } else {
                        studentInfoFormValuesCopy[ inputName ] = inputValue;
                    }
                    
                } else {
                    studentInfoFormValuesCopy[ inputName ] = inputValue;
                }
    
            } else {
                studentInfoFormValuesCopy[ inputName ] = this.state.studentInfoFormValues[ inputName ];
            }

        } else {
            studentInfoFormValuesCopy[ inputName ] = inputValue;
        }

        if ( inputName === "schoolName" || inputName.indexOf("subject") !== -1 ) {
            searchQueriesCopy[ inputName ] = inputValue;

            this.setState({
                searchQueries: searchQueriesCopy
            })
        } 

        if ( inputName === "schoolName" ) {
            this.setState({
                selectedSchool: ""
            })

            HTTPRequest({
                method: "POST",
                url   : "/schools/find/limit-10-index-0",
                data  : { "like": inputValue }

            }).then( response => {
                

                if ( response.data.success ) {
                    responseData = response.data.data;

                    if ( responseData.length >= 1) {
                        schoolNameList = responseData.map( schoolNameObj => {
                            let province = schoolNameObj.Province
                            let district = schoolNameObj.EIDistrict !== "NOT APPLICABLE" ? " " + schoolNameObj.EIDistrict  : "";

                            if ( responseData.length === 1 && responseData[0].Name === "Can't Find School" ) {
                                return (
                                    <li key={ -1 } className="list-no-result"><em>{ schoolNameObj.Name }</em></li>
                                );

                            } else {
                                return (
                                    <li key={ schoolNameObj.ID } onClick={ () => this.handleSearchItemSelect( inputName, schoolNameObj ) } >{ schoolNameObj.Name + " (" + province + district + ")" }</li>
                                );
                            }
                        });
                    }

                } else {
                    schoolNameList.push(
                        <li key={ -1 } className="list-no-result">
                            <em>{ response.data.reason }</em>
                        </li>
                    );
                }

                this.setState({
                    schoolNameList: schoolNameList
                })
            });
        }
       
        if ( inputName.indexOf("subject") !== -1 ) {
            let selectedSubjectsCopy = { ...this.state.selectedSubjects };
            selectedSubjectsCopy[inputName] = "";

            this.setState({
                selectedSubjects: selectedSubjectsCopy
            });

            if ( SUBJECT_LIST ) {
                subjectList = SUBJECT_LIST.filter( subject => {
                    return subject.Name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;

                }).map( subjectObj => {
                    return (
                        <li key={ subjectObj.ID } onClick={ () => this.handleSearchItemSelect( inputName, subjectObj ) }>{ subjectObj.Name }</li>
                    );
                });

                if ( subjectList.length === 0 ) {
                    subjectList.push(
                        <li key={ -1 } className="list-no-result">
                            <em>subject not found</em>
                        </li>
                    );
                }

                this.setState({
                    subjectList: subjectList
                })
            }
        }

        if ( inputName === "source" ) {
            let sourceFormSections = {};
            let sourceList = [];

            if ( inputValue !== "" ) {
                studentInfoService.getSourceList(inputValue).then( response => {
                    if ( response.length > 0 ) {
                        if ( response[0] !== "error" ) {
                            sourceList = response.map( (source, index) => {
                                return(
                                    <li key={ index } onClick={ () => this.handleSetSource(source) }>
                                        { source }
                                    </li>
                                );
                            });
    
                            this.setState({
                                sourceList: sourceList
                            });
                        }
    
                    } else {
                        this.setState({
                            sourceList: []
                        });
                    }
                });

            } else {
                this.setState({
                    sourceList: []
                });
            }


            this.setState({
                formGroupSource: inputValue
            });

            this.setState({
                newFormGroups: {},
                sourceFormSections: {}
            });

            studentInfoService.getSrouceExtProperties( inputValue ).then( response => {
                console.log(response);
                if ( response !== null ) {
                    if ( !response.error ) {
                        sourceFormSections[inputValue] = this.setSourceFormSections(response);

                        this.setState({
                            newFormGroups: response,
                            sourceFormSections: sourceFormSections
                        });
                    }
                }
            });
        }

        this.setState({
            studentInfoFormValues: studentInfoFormValuesCopy
        });
    }

    handleSetSource = ( source ) => {
        let studentInfoFormValuesCopy = { ...this.state.studentInfoFormValues };
        let sourceFormSections = {};
        studentInfoFormValuesCopy["source"] = source;

        this.setState({
            studentInfoFormValues: studentInfoFormValuesCopy,
            formGroupSource: source,
            sourceList: [],
            formGroupSourceList: []
        });

        studentInfoService.getSrouceExtProperties( source ).then( response => {
            if ( response !== null ) {
                if ( !response.error ) {
                    sourceFormSections[source] = this.setSourceFormSections(response);

                    this.setState({
                        newFormGroups: response,
                        sourceFormSections: sourceFormSections
                    });
                }
            }
        });
    }

    careerSearch = (inputValue, index) => {
        let studentInfoCopy = { ...this.state.studentInfoFormValues };
        studentInfoCopy.careers[index] = inputValue;
        this.setState({ studentInfoFormValues: studentInfoCopy });

        HTTPRequest({
            method: "POST",
            url: "/careers/occupations/limit-10-index-0",
            data: { "like": inputValue }

        }).then(response => {
            this.setState({
                careerList: response.data.data,
                displayCareerIndex:index
            })
        });
    }

    handleFocusOut = ( event ) => {
        const inputName       = event.target.name;
        let searchQueriesCopy = { ...this.state.searchQueries }

        searchQueriesCopy[ inputName ] = "";

        this.setState({
            searchQueries: searchQueriesCopy
        })
    }

    setSelectedCareer = (index, career) => {
        let studentInfoValuesCopy = { ...this.state.studentInfoFormValues };
        studentInfoValuesCopy.careers[index] = career.Name;
        studentInfoValuesCopy.careerIds[index] = career.ID;
      
        this.setState({
            studentInfoFormValues: studentInfoValuesCopy,
            careerList: []
        });
    }

    handleSearchItemSelect = ( inputName, selectedItem ) => {
        let searchQueriesCopy         = { ...this.state.searchQueries }
        let studentInfoFormValuesCopy = { ...this.state.studentInfoFormValues }

        studentInfoFormValuesCopy[ inputName ] = selectedItem.Name;
        searchQueriesCopy[ inputName ]         = "";

        if ( inputName === "schoolName" ) {
            this.setState({ 
                studentInfoFormValues: studentInfoFormValuesCopy,
                selectedSchool       : selectedItem.ID,
                searchQueries        : searchQueriesCopy,
            })

        } else if ( inputName.indexOf("subject") !== -1 ) {
            let selectedSubjectsCopy= { ...this.state.selectedSubjects }

            selectedSubjectsCopy[ inputName ] = selectedItem.ID;

            this.setState({
                studentInfoFormValues: studentInfoFormValuesCopy,
                searchQueries        : searchQueriesCopy,
                selectedSubjects     : selectedSubjectsCopy
            })
        }
    }

    handleUserNameValidation = ( event ) => {
        let userNameIsValid = false;
        const inputValue    = event.target.value;

        if ( isNaN(inputValue) ) {
            userNameIsValid = IsValidEmail(inputValue);

        } else {
            if ( inputValue.length === 10 ) {
                userNameIsValid = true;
            }
        }

        this.setState({
            userNameIsValid: userNameIsValid
        })
    }

    handleSourceValidation = ( event ) => {
        let sourceIsValid = false;
        const inputValue  = event.target.value;

        if ( inputValue !== "" ) {
            sourceIsValid = true;
        }

        this.setState({
            sourceIsValid: sourceIsValid
        });
    }

    handleFormGroupSourceChange = ( event ) => {
        event.preventDefault();

        let inputValue = event.target.value;
        let sourceList = [];

        if ( inputValue !== "" ) {
            studentInfoService.getSourceList(inputValue).then( response => {
                if ( response.length > 0 ) {
                    if ( response[0] !== "error" ) {
                        sourceList = response.map( (source, index) => {
                            return(
                                <li key={ index } onClick={ () => this.handleSetSource(source) }>
                                    { source }
                                </li>
                            );
                        });
    
                        this.setState({
                            formGroupSourceList: sourceList
                        });
                    }
    
                } else {
                    this.setState({
                        formGroupSourceList: []
                    });
                }
            })

        } else {
            this.setState({
                formGroupSourceList: []
            });
        }

        this.setState({
            formGroupSource: inputValue,
        });
    }

    handleFormGroupTitleChange = ( event ) => {
        let source = event.target.value;

        this.setState({
            newFormGroupTitle: source
        });
    }

    handleShowHideNewFormSection = ( event ) => {
        event.preventDefault();

        let showNewFormBackdropCopy = this.state.showNewFormBackdrop;

        this.setState({
            showNewFormBackdrop: !showNewFormBackdropCopy
        });
    }

    handleAddFormGroup = ( event ) => {
        event.preventDefault();

        let newFormGroupsCopy = { ...this.state.newFormGroups };

        if ( this.state.formGroupSource !== "" ) {
            if ( this.state.newFormGroupTitle !== "" ) {
                if ( newFormGroupsCopy[this.state.newFormGroupTitle] !== undefined ) {
                    alert("Form group already added");
    
                } else {
                    newFormGroupsCopy[this.state.newFormGroupTitle] = [""];
    
                    this.setState({
                        newFormGroups: newFormGroupsCopy,
                        newFormGroupTitle: ""
                    });
                }
    
            } else {
                alert("Form group title cannot be empty");
            }
            
        } else {
            alert("Source must be selected first");
        }
    }

    handleCancelFormGroup = ( event ) => {
        event.preventDefault();

        this.setState({
            newFormGroups: {},
            sourceFormSections: {},
            showNewFormBackdrop: false
        });
    }

    handleCancelFormGroupItem = ( event, formGroupTitle ) => {
        event.preventDefault();

        let newFormGroupsCopy = { ...this.state.newFormGroups };
        newFormGroupsCopy[formGroupTitle] = [""];

        this.setState({
            newFormGroups: newFormGroupsCopy
        });
    }

    handleAddFormGroupItem = ( event, sourceName ) => {
        event.preventDefault();

        let newFormGroupsCopy = { ...this.state.newFormGroups };
        let formGroupItems = newFormGroupsCopy[sourceName];
        formGroupItems.push("");
        newFormGroupsCopy[sourceName] = formGroupItems;

        this.setState({
            newFormGroups: newFormGroupsCopy
        });
    }

    handleFormGroupItemOnChange = ( event, formGroupTitle ) => {
        event.preventDefault();

        let inputName = event.target.name;
        let inputValue = event.target.value;
        let formGroupItemIndex = inputName.replace(formGroupTitle + "_", "");
        let newFormGroupsCopy = { ...this.state.newFormGroups };
        let formGroupItems = newFormGroupsCopy[formGroupTitle];

        formGroupItems[parseInt(formGroupItemIndex)] = inputValue;
        newFormGroupsCopy[formGroupTitle] = formGroupItems;

        this.setState({
            newFormGroups: newFormGroupsCopy
        });
    }

    handleSaveFormGroup = ( event ) => {
        event.preventDefault();

        this.setState({
            loadingNewForm: true
        })

        if ( Object.keys(this.state.newFormGroups).length> 0 ) {
            let sourceFormSections = {};

            sourceFormSections[this.state.formGroupSource] = this.setSourceFormSections(this.state.newFormGroups);

            this.setState({
                sourceFormSections: sourceFormSections,
            });

            studentInfoService.setSrouceExtProperties(this.state.formGroupSource, this.state.newFormGroups).then( response => {
                if ( response ) {
                    this.setState({
                        showNewFormBackdrop: false,
                        loadingNewForm: false
                    });
                }
            });

        } else {
            alert("Add atleast one form group");
        }
    }

    setSourceFormSections = ( newFormGroups ) => {
        let sourceFormSectionsTitles = {};

        Object.keys(newFormGroups).forEach( formGroupTitle => {
            let sourceFormSectionsItems = {};

            newFormGroups[formGroupTitle].forEach( formGroupItem => {
                sourceFormSectionsItems[formGroupItem] = "";
            });
            sourceFormSectionsTitles[formGroupTitle] = sourceFormSectionsItems;
        });

        return sourceFormSectionsTitles;
    }

    handlesourceFormInputChange = ( event, formGroupTitle, formGroupItem ) => {
        event.preventDefault();

        let inputValue = event.target.value;
        let sourceFormSectionsCopy = { ...this.state.sourceFormSections }

        sourceFormSectionsCopy[this.state.formGroupSource][formGroupTitle][formGroupItem] = inputValue;

        this.setState({
            sourceFormSections: sourceFormSectionsCopy
        });
    }

    handleToggleFormSection = ( event ) => {
        event.preventDefault();

        let showStudentMarks = !this.state.showStudentMarks

        this.setState({
            showStudentMarks: showStudentMarks
        });
    }

    handleCopyFromSourceChange = ( event ) => {
        event.preventDefault()

        let inputValue = event.target.value;
        let sourceList = [];

        if ( inputValue !== "" ) {
            studentInfoService.getSourceList(inputValue).then( response => {
                if ( response.length > 0 ) {
                    if ( response[0] !== "error" ) {
                        sourceList = response.map( (source, index) => {
                            return(
                                <li key={ index } onClick={ () => this.handleSetCopyFromSource(source) }>
                                    { source }
                                </li>
                            );
                        });
    
                        this.setState({
                            copyFromSourceList: sourceList
                        });
                    }
    
                } else {
                    this.setState({
                        copyFromSourceList: []
                    });
                }
            });

        } else {
            this.setState({
                copyFromSourceList: []
            });
        }

        this.setState({
            copyFromSource: inputValue
        });
    }

    handleSetCopyFromSource = ( source ) => {

        studentInfoService.getSrouceExtProperties( source ).then( response => {
            console.log(response);
            if ( response !== null ) {
                if ( !response.error ) {
                    this.setState({
                        newFormGroups: response,
                    });
                }
            }
        });

        this.setState({
            copyFromSource: source,
            copyFromSourceList: []
        });
    }

    render() {
        let addButtonContent = "";
        let newFormGroupsContent = "";

        if ( this.state.loading ) {
            addButtonContent = <Spinner />

        } else {
            addButtonContent = "add student"
        }

        if ( Object.keys(this.state.newFormGroups).length > 0 && Object.keys(this.state.sourceFormSections).length > 0) {
            console.log(this.state.newFormGroups, this.state.sourceFormSections, this.state.formGroupSource);

            newFormGroupsContent = Object.keys(this.state.newFormGroups).map( (formGroupTitle, index) => {
                return (
                        <div className="row no-gutters form-section" key={ index }>
                            <div className="col-12">
                                <h3>{ formGroupTitle }</h3>
                            </div>
                            <div className="col-12"> 
                                <div className="row no-gutters form-inputs">
                                { 
                                    this.state.newFormGroups[formGroupTitle].map( (formGroupItem, index) => {
                                        return (
                                            <div className="col-4" key={ index }>
                                                <FormInputItem 
                                                    inputName={ formGroupItem }
                                                    inputLabel={ formGroupItem } 
                                                    inputType="text" 
                                                    inputValue={ this.state.sourceFormSections[this.state.formGroupSource][formGroupTitle][formGroupItem] }
                                                    inputOnChange={ event => this.handlesourceFormInputChange(event, formGroupTitle, formGroupItem) } 
                                                    inputMaxLength="50"
                                                    inputPlaceholder=""
                                                />
                                            </div>
                                        );
                                    })
                                }
                                </div>
                            </div>
                        </div>
                );
            });
        }

        return(
            <Wrap>
                <Modal 
                    backdropShow={this.state.showBackdrop } 
                    backdropHide={ this.handleFormCancel } 
                    modalContent={ this.state.modalContent.modalContent } 
                    modalIcon={ this.state.modalContent.modalIcon }
                />
                <NewFormSection 
                    backdropShow={ this.state.showNewFormBackdrop } 
                    backdropHide={ this.handleShowHideNewFormSection }
                    formGroupSource={ this.state.formGroupSource }
                    formGroupSourceChange={ this.handleFormGroupSourceChange }
                    formGroupSourceList={ this.state.formGroupSourceList }
                    newFormGroupTitle={ this.state.newFormGroupTitle }
                    newFormGroupTitleOnChange={ this.handleFormGroupTitleChange }
                    createNewFormGroup={ this.handleAddFormGroup }
                    newFormGroups={ this.state.newFormGroups }
                    addFormGroupItem={ this.handleAddFormGroupItem }
                    cancelNewFormGroupItem={ this.handleCancelFormGroupItem }
                    formGroupItemOnChange={ this.handleFormGroupItemOnChange }
                    saveFormGroup={ this.handleSaveFormGroup }
                    cancelFormGroup={ this.handleCancelFormGroup }
                    loadingNewForm={ this.state.loadingNewForm }
                    copyFromSource={ this.state.copyFromSource }
                    copyFromSourceList={ this.state.copyFromSourceList }
                    copyFromSourceChange={ this.handleCopyFromSourceChange }
                />
                <form id="student-info">
                    <div className="row no-gutters form-section">
                        <div className="col-12">
                            <h3>student info</h3>
                        </div>
                        <div className="col-12">
                            <div className="row no-gutters form-inputs">
                                <div className="col-4">
                                    <FormInputItem 
                                        inputName="name" 
                                        inputLabel="name" 
                                        inputType="text" 
                                        inputValue={ this.state.studentInfoFormValues.name } 
                                        inputOnChange={ this.handleOnChange } 
                                        inputMaxLength="50"
                                        inputPlaceholder="John"
                                    />
                                </div>
                                <div className="col-4">
                                <FormInputItem 
                                        inputName="surname" 
                                        inputLabel="surname" 
                                        inputType="text" 
                                        inputValue={ this.state.studentInfoFormValues.surname } 
                                        inputOnChange={ this.handleOnChange } 
                                        inputMaxLength="50"
                                        inputPlaceholder="Doe"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters form-section">
                        <div className="col-12">
                            <h3>student info</h3>
                        </div>
                        <div className="col-12">
                            <div className="row no-gutters form-inputs">
                                <div className="col-4">
                                    <FormInputItem 
                                        inputName="gender" 
                                        inputLabel="gender" 
                                        inputType="radio" 
                                        inputChecked={ this.state.studentInfoFormValues.gender } 
                                        inputValue="M"
                                        inputOnChange={ this.handleOnChange } 
                                    />
                                </div>
                                <div className="col-2">
                                    <FormInputItem 
                                        inputName="grade" 
                                        inputLabel="grade" 
                                        inputType="text" 
                                        inputValue={ this.state.studentInfoFormValues.grade } 
                                        inputOnChange={ this.handleOnChange } 
                                        inputMaxLength="2"
                                        inputPlaceholder="12"
                                    />
                                </div>
                                <div className="col-2">
                                    <FormInputItem 
                                        inputName="race" 
                                        inputLabel="race" 
                                        inputType="select" 
                                        inputValue={ this.state.studentInfoFormValues.race.charAt(0).toUpperCase() + this.state.studentInfoFormValues.race.slice(1) } 
                                        inputOnChange={ this.handleOnChange } 
                                        inputOptions={ RACE_LIST }
                                    />
                                </div>
                                <div className="col-4">
                                    <div className="auto-complete-search" >
                                        <FormInputItem 
                                            inputName="schoolName" 
                                            inputLabel="school name" 
                                            inputType="text" 
                                            inputValue={ this.state.studentInfoFormValues.schoolName } 
                                            inputOnChange={ this.handleOnChange } 
                                            //inputFocusOut={ this.handleFocusOut }
                                            inputMaxLength="50"
                                            inputPlaceholder="Search For School"
                                        />
                                        <div className="search-list" style={{ display: this.state.searchQueries.schoolName === "" ? "none" : "block" }}>
                                            <ul>
                                                { this.state.schoolNameList }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row no-gutters form-inputs">
                                <div className="col-4">
                                    <FormInputItem 
                                        inputName="userName" 
                                        inputLabel="user name" 
                                        inputType="text" 
                                        inputValue={ this.state.studentInfoFormValues.userName } 
                                        inputOnChange={ this.handleOnChange } 
                                        inputFocusOut={ this.handleUserNameValidation }
                                        inputMaxLength="50"
                                        inputPlaceholder="email or phone number"
                                        IsValidUserName={ this.state.userNameIsValid }
                                    />
                                </div>
                                <div className="col-4">
                                    <FormInputItem 
                                        inputName="password" 
                                        inputLabel="password" 
                                        inputType="text" 
                                        inputValue={ this.state.studentInfoFormValues.password } 
                                        inputOnChange={ this.handleOnChange } 
                                        //inputFocusOut={ this.handleFocusOut }
                                        inputMaxLength="50"
                                        inputPlaceholder="**************"
                                    />
                                </div>
                                <div className="col-4">
                                    <div className="auto-complete-search">
                                        <FormInputItem 
                                            inputName="source" 
                                            inputLabel="source" 
                                            inputType="text" 
                                            inputValue={ this.state.studentInfoFormValues.source } 
                                            inputOnChange={ this.handleOnChange } 
                                            inputFocusOut={ this.handleSourceValidation }
                                            inputMaxLength="50"
                                            inputPlaceholder="GPEXPO"
                                            IsValidSource={ this.state.sourceIsValid }
                                        />
                                        <div className="search-list" style={{ display: this.state.sourceList.length === 0 ? "none" : "block" }}>
                                            <ul>
                                                { this.state.sourceList }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters form-section">
                        <div className="col-12">
                            <Button buttonClassName="toggle-form-section" clickHandler={ event => this.handleToggleFormSection(event) }>
                                <i className={"fa fa-" + ( this.state.showStudentMarks ? "minus" : "plus")} aria-hidden="true"></i>
                            </Button>
                            <h3>student marks</h3>
                        </div>
                        <div className="col-12" style={{display: (this.state.showStudentMarks ? "block" : "none")}}>
                            <div className="row no-gutters form-inputs">
                                <div className="col-4">
                                    <div className="auto-complete-search">
                                        <FormInputItem 
                                            inputName="subject1" 
                                            inputLabel="subject 1" 
                                            inputType="text" 
                                            inputValue={ this.state.studentInfoFormValues.subject1 } 
                                            inputOnChange={ this.handleOnChange } 
                                            // inputFocusOut={ this.handleFocusOut }
                                            inputMaxLength="50"
                                            inputPlaceholder="Search Subject 1"
                                        />
                                        <div className="search-list" style={{ display: this.state.searchQueries.subject1 === "" ? "none" : "block" }}>
                                            <ul>
                                                { this.state.subjectList }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <FormInputItem 
                                        inputName="mark1" 
                                        inputLabel="mark 1" 
                                        inputType="text" 
                                        inputValue={ this.state.studentInfoFormValues.mark1 } 
                                        inputOnChange={ this.handleOnChange } 
                                        inputMaxLength="3"
                                        inputPlaceholder="100"
                                    />
                                </div>
                                <div className="col-4">
                                    <div className="auto-complete-search">
                                        <FormInputItem 
                                            inputName="subject2" 
                                            inputLabel="subject 2" 
                                            inputType="text" 
                                            inputValue={ this.state.studentInfoFormValues.subject2 } 
                                            inputOnChange={ this.handleOnChange } 
                                            //inputFocusOut={ this.handleFocusOut }
                                            inputMaxLength="50"
                                            inputPlaceholder="Search Subject 2"
                                        />
                                        <div className="search-list" style={{ display: this.state.searchQueries.subject2 === "" ? "none" : "block" }}>
                                            <ul>
                                                { this.state.subjectList }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <FormInputItem 
                                        inputName="mark2" 
                                        inputLabel="mark 2" 
                                        inputType="text" 
                                        inputValue={ this.state.studentInfoFormValues.mark2 } 
                                        inputOnChange={ this.handleOnChange } 
                                        inputMaxLength="3"
                                        inputPlaceholder="100"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12" style={{display: (this.state.showStudentMarks ? "block" : "none")}}>
                            <div className="row no-gutters form-inputs">
                                <div className="col-4">
                                    <div className="auto-complete-search">
                                        <FormInputItem 
                                            inputName="subject3" 
                                            inputLabel="subject 3" 
                                            inputType="text" 
                                            inputValue={ this.state.studentInfoFormValues.subject3 } 
                                            inputOnChange={ this.handleOnChange } 
                                            //inputFocusOut={ this.handleFocusOut }
                                            inputMaxLength="50"
                                            inputPlaceholder="Search Subject 3"
                                        />
                                        <div className="search-list" style={{ display: this.state.searchQueries.subject3 === "" ? "none" : "block" }}>
                                            <ul>
                                                { this.state.subjectList }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <FormInputItem 
                                        inputName="mark3" 
                                        inputLabel="mark 3" 
                                        inputType="text" 
                                        inputValue={ this.state.studentInfoFormValues.mark3 } 
                                        inputOnChange={ this.handleOnChange } 
                                        inputMaxLength="3"
                                        inputPlaceholder="100"
                                    />
                                </div>
                                <div className="col-4">
                                    <div className="auto-complete-search">
                                        <FormInputItem 
                                            inputName="subject4" 
                                            inputLabel="subject 4" 
                                            inputType="text" 
                                            inputValue={ this.state.studentInfoFormValues.subject4 } 
                                            inputOnChange={ this.handleOnChange } 
                                            //inputFocusOut={ this.handleFocusOut }
                                            inputMaxLength="50"
                                            inputPlaceholder="Search Subject 4"
                                        />
                                        <div className="search-list" style={{ display: this.state.searchQueries.subject4 === "" ? "none" : "block" }}>
                                            <ul>
                                                { this.state.subjectList }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <FormInputItem 
                                        inputName="mark4" 
                                        inputLabel="mark 4" 
                                        inputType="text" 
                                        inputValue={ this.state.studentInfoFormValues.mark4 } 
                                        inputOnChange={ this.handleOnChange } 
                                        inputMaxLength="3"
                                        inputPlaceholder="100"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12" style={{display: (this.state.showStudentMarks ? "block" : "none")}}>
                            <div className="row no-gutters form-inputs">
                                <div className="col-4">
                                    <div className="auto-complete-search">
                                        <FormInputItem 
                                            inputName="subject5" 
                                            inputLabel="subject 5" 
                                            inputType="text" 
                                            inputValue={ this.state.studentInfoFormValues.subject5 } 
                                            inputOnChange={ this.handleOnChange } 
                                            //inputFocusOut={ this.handleFocusOut }
                                            inputMaxLength="50"
                                            inputPlaceholder="Search Subject 5"
                                        />
                                        <div className="search-list" style={{ display: this.state.searchQueries.subject5 === "" ? "none" : "block" }}>
                                            <ul>
                                                { this.state.subjectList }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <FormInputItem 
                                        inputName="mark5" 
                                        inputLabel="mark 5" 
                                        inputType="text" 
                                        inputValue={ this.state.studentInfoFormValues.mark5 } 
                                        inputOnChange={ this.handleOnChange } 
                                        inputMaxLength="3"
                                        inputPlaceholder="100"
                                    />
                                </div>
                                <div className="col-4">
                                    <div className="auto-complete-search">
                                        <FormInputItem 
                                            inputName="subject6" 
                                            inputLabel="subject 6" 
                                            inputType="text" 
                                            inputValue={ this.state.studentInfoFormValues.subject6 } 
                                            inputOnChange={ this.handleOnChange } 
                                            //inputFocusOut={ this.handleFocusOut }
                                            inputMaxLength="50"
                                            inputPlaceholder="Search Subject 6"
                                        />
                                        <div className="search-list" style={{ display: this.state.searchQueries.subject6 === "" ? "none" : "block" }}>
                                            <ul>
                                                { this.state.subjectList }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                <FormInputItem 
                                        inputName="mark6" 
                                        inputLabel="mark 6" 
                                        inputType="text" 
                                        inputValue={ this.state.studentInfoFormValues.mark6 } 
                                        inputOnChange={ this.handleOnChange } 
                                        inputMaxLength="3"
                                        inputPlaceholder="100"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12" style={{display: (this.state.showStudentMarks ? "block" : "none")}}>
                            <div className="row no-gutters form-inputs">
                                <div className="col-4">
                                    <div className="auto-complete-search">
                                        <FormInputItem 
                                            inputName="subject7" 
                                            inputLabel="subject 7" 
                                            inputType="text" 
                                            inputValue={ this.state.studentInfoFormValues.subject7 } 
                                            inputOnChange={ this.handleOnChange } 
                                            //inputFocusOut={ this.handleFocusOut }
                                            inputMaxLength="50"
                                            inputPlaceholder="Search Subject 7"
                                        />
                                        <div className="search-list" style={{ display: this.state.searchQueries.subject7 === "" ? "none" : "block" }}>
                                            <ul>
                                                { this.state.subjectList }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <FormInputItem 
                                        inputName="mark7" 
                                        inputLabel="mark 7" 
                                        inputType="text" 
                                        inputValue={ this.state.studentInfoFormValues.mark7 } 
                                        inputOnChange={ this.handleOnChange } 
                                        inputMaxLength="3"
                                        inputPlaceholder="100"
                                    />
                                </div>
                                <div className="col-4">
                                    <div className="auto-complete-search">
                                        <FormInputItem 
                                            inputName="subject8" 
                                            inputLabel="subject 8" 
                                            inputType="text" 
                                            inputValue={ this.state.studentInfoFormValues.subject8 } 
                                            inputOnChange={ this.handleOnChange } 
                                            //inputFocusOut={ this.handleFocusOut }
                                            inputMaxLength="50"
                                            inputPlaceholder="Search Subject 8"
                                        />
                                        <div className="search-list" style={{ display: this.state.searchQueries.subject8 === "" ? "none" : "block" }}>
                                            <ul>
                                                { this.state.subjectList }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <FormInputItem 
                                        inputName="mark8" 
                                        inputLabel="mark 8" 
                                        inputType="text" 
                                        inputValue={ this.state.studentInfoFormValues.mark8 } 
                                        inputOnChange={ this.handleOnChange } 
                                        inputMaxLength="3"
                                        inputPlaceholder="100"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12" style={{display: (this.state.showStudentMarks ? "block" : "none")}}>
                            <div className="row no-gutters form-inputs">
                                <div className="col-4">
                                    <div className="auto-complete-search">
                                        <FormInputItem 
                                            inputName="subject9" 
                                            inputLabel="subject 9" 
                                            inputType="text" 
                                            inputValue={ this.state.studentInfoFormValues.subject9 } 
                                            inputOnChange={ this.handleOnChange } 
                                            //inputFocusOut={ this.handleFocusOut }
                                            inputMaxLength="50"
                                            inputPlaceholder="Search Subject 9"
                                        />
                                        <div className="search-list" style={{ display: this.state.searchQueries.subject9 === "" ? "none" : "block" }}>
                                            <ul>
                                                { this.state.subjectList }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <FormInputItem 
                                        inputName="mark9" 
                                        inputLabel="mark 9" 
                                        inputType="text" 
                                        inputValue={ this.state.studentInfoFormValues.mark9 } 
                                        inputOnChange={ this.handleOnChange } 
                                        inputMaxLength="3"
                                        inputPlaceholder="100"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters form-section">
                        <div className="col-12">
                            <h3>Career Interests</h3>
                        </div>
                        <div className="col-12">
                            <div className="row no-gutters form-inputs">
                                {[1, 2, 3].map((item, index) => 
                                    <div className="col-4" key={ index }>
                                        <div className="auto-complete-search" >
                                        <FormInputItem
                                            inputName="career"
                                            inputLabel={ "career " + item }
                                            inputType="text"
                                            inputValue={this.state.studentInfoFormValues.careers[index]}
                                            inputOnChange={(event) => { this.careerSearch(event.target.value,index) }}
                                            inputMaxLength="50"
                                            inputPlaceholder=""
                                            inputIndex={index}
                                        />
                                        <div className="search-list" style={{ display: this.state.careerList.length > 0 && index == this.state.displayCareerIndex? "block" : "none" }}>
                                            <ul>
                                                {
                                                    this.state.careerList.map(
                                                        (career, i) =>
                                                            <li onClick={() => {this.setSelectedCareer(index, career) }} key={ i }>
                                                                {career.Name}
                                                            </li>
                                                    )
                                                }
                                            </ul>
                                            </div>
                                      </div>
                                    </div>
                                )}
                            </div>
                         </div>
                     </div>
                     { newFormGroupsContent }
                    <div className="row no-gutters form-buttons">
                        <div className="col-4">
                            <Button buttonClassName="form-cancel" buttonTitle="add section" clickHandler={ event => this.handleShowHideNewFormSection(event) } />
                        </div>
                        <div className="col-6 offset-2">
                            <div className="row no-gutters justify-content-end">
                                <div className="col-4">
                                    <Button buttonClassName="form-cancel" buttonTitle="cancel" clickHandler={ this.handleFormCancel } />
                                </div>
                                <div className="col-4">
                                    <Button buttonClassName="form-add-student" clickHandler={ this.handleFormSubmit }>
                                        { addButtonContent }
                                    </Button> 
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Wrap>
        );
    }
}

export default StudentInfoForm;