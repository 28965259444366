import React, { Component } from 'react';
import HTTPRequest from '../../js/library/HTTPConfig/axiosConfig';
import UserServices from "../../js/library/services/userservice";
// 
// Manage modules component : container for manage modules page
// 

// 
// Components
// 

// Wrap component
import Wrap from '../../components/hoc/wrap';
// Modules table titles component
import InfoTableTitles from '../../components/pages/manageModulesPage/infoTableTitles/infoTableTitles';
// Modules info component
import ModuleInfo from '../../components/pages/manageModulesPage/moduleInfo/infoRow';
// Button component
import Button from '../../components/UI/button/button';
// Lessons info component
import LessonsInfo from '../../components/pages/manageModulesPage/lessonsInfo/lessonsInfo';
// LoadingData component
import LoadingData from "../../components/UI/loadingData/loadingData";
// DradDropContent, Droppable components
import { DragDropContext, Droppable } from "react-beautiful-dnd";

// Style
import './manageModules.css';

class ManageModules extends Component {
    state = {
        modules: [],
        modifiedModuleNames: {},
        lessons: [],
        modifiedLessonsNames: {},
        viewedLessons: 0,
        slides: [],
        modifiedSlides: {},
        viewedSlides: 0,
        adminUserToken: ""
    }

    componentWillMount() {
        let userInfo = {};
        if (UserServices.USER_TOKEN && UserServices.USER_TOKEN !== "") {
            this.setState({
                adminUserToken: UserServices.USER_TOKEN
            });
            
        } else {
            userInfo = JSON.parse(localStorage.getItem("userInfo"));
            
            this.setState({
                adminUserToken: userInfo.userToken
            });
        }
    }

    componentDidMount () {
        HTTPRequest({
            method: 'POST',
            url: '/modules/getmodules',
            data: {
                "parentId": 0
            },
            headers: { "Authorization": "Bearer " + this.state.adminUserToken }
        }).then( response => {
            if ( response.data.success ) {
                this.setState({
                    modules: response.data.data
                });

                this.state.modules.forEach( module => {
                    let modifiedModuleNamesCopy = { ...this.state.modifiedModuleNames };
                    modifiedModuleNamesCopy["module-name-" + module.ID] = { id: module.ID, name: module.Name, rank: module.Rank, editState: false, newModuleSave: false };

                    this.setState({
                        modifiedModuleNames: modifiedModuleNamesCopy
                    });

                    HTTPRequest({
                        method: 'POST',
                        url: '/modules/getmodules',
                        data: {
                            "parentId": module.ID
                        },
                        headers : { "Authorization": "Bearer " + this.state.adminUserToken }

                    }).then( response => {
                        let modifiedLessonsNamesCopy = { ...this.state.modifiedLessonsNames }
                        let lessons = [ ...this.state.lessons ]
                        lessons[module.ID] = response.data.data;

                        lessons[module.ID].forEach( lesson => {
                            modifiedLessonsNamesCopy["lesson-name-" + lesson.ID] = { Id: lesson.ID, moduleId: module.ID, name: lesson.Name, rank: lesson.Rank, editState: false, newLessonSave: false };
                        });

                        this.setState({
                            lessons: lessons,
                            modifiedLessonsNames: modifiedLessonsNamesCopy
                        });

                        this.state.lessons.forEach( lessons => {
                            if ( lessons && lessons.length > 0) {
                               lessons.forEach( lesson => {
                                   HTTPRequest({
                                       method: "POST",
                                       url: "/modules/getslides",
                                       data: {
                                           "moduleItemId": lesson.ID
                                       },
                                       headers : { "Authorization": "Bearer " + this.state.adminUserToken }

                                   }).then( response => {
                                       if ( response.data.success ) {
                                            let slidesCopy = { ...this.state.slides };
                                            let modifiedSlidesCopy = { ...this.state.modifiedSlides }
                                            slidesCopy["slide-" + lesson.ID] = response.data.data;
                                            modifiedSlidesCopy["slide-" + lesson.ID] = {lessonId: lesson.ID, slides: JSON.stringify(response.data.data), editState: false};

                                            this.setState({
                                                slides: slidesCopy,
                                                modifiedSlides: modifiedSlidesCopy
                                            });
                                       }

                                   }).catch( error => {
                                       console.log(error, lesson.ID);
                                   });
                               });
                            }
                        });
                    });
                });
            } else {
                console.log(response.data.reason);
            }
        }).catch( error => {
            console.log("Error fetching modules: " + error);
        });
    }

    handleModuleInputOnChange = ( event ) => {
        let inputName = event.target.name;
        let modifiedModuleNamesCopy = { ...this.state.modifiedModuleNames };

        modifiedModuleNamesCopy[inputName].name = event.target.value;
        modifiedModuleNamesCopy[inputName].editState = true;

        this.setState({
            modifiedModuleNames: modifiedModuleNamesCopy
        });
    }

    handleLessonInputOnChange = ( event ) => {
        let inputName = event.target.name;
        let modifiedLessonsNamesCopy = { ...this.state.modifiedLessonsNames };

        modifiedLessonsNamesCopy[inputName].name = event.target.value;
        modifiedLessonsNamesCopy[inputName].editState = true;

        this.setState({
            modifiedLessonsNames: modifiedLessonsNamesCopy
        });
    }

    handleSlideInputOnChange = ( event ) => {
        let inputName = event.target.name;
        let modifiedSlidesCopy = { ...this.state.modifiedSlides }

        modifiedSlidesCopy[inputName].slides = event.target.value;
        modifiedSlidesCopy[inputName].editState = true;

        this.setState({
            modifiedSlides: modifiedSlidesCopy
        });
    }

    handleShowHideLessonsInfo = ( event, modelId ) => {
        event.preventDefault();

        this.setState({
            viewedLessons: modelId
        });
    }

    handleShowSlidesInfo = ( event, lessonId ) => {
        event.preventDefault();

        this.setState({
            viewedSlides: lessonId
        });
    }

    handleAddNewModule = () => {
        let modulesCopy = [ ...this.state.modules ];
        let modifiedModuleNamesCopy = { ...this.state.modifiedModuleNames }
        let lessonsCopy = [ ...this.state.lessons ];
        const newModelId = this.state.modules.length + 1;

        modulesCopy.push( {ID: newModelId, Name: "", Rank: newModelId} );
        modifiedModuleNamesCopy["module-name-" + newModelId] = {newModuleSave: true, editState: true, id: newModelId, name: "", rank: newModelId};
        lessonsCopy.push( [] );

        this.setState({
            modules: modulesCopy,
            modifiedModuleNames: modifiedModuleNamesCopy,
            lessons: lessonsCopy
        });
    }

    handleAddNewLesson = ( moduleId ) => {
        let lessonsCopy = [ ...this.state.lessons ];
        let moduleLessonsCopy = [ ...this.state.lessons[moduleId] ];
        let modifiedLessonsNamesCopy = { ...this.state.modifiedLessonsNames }
        let slidesCopy = { ...this.state.slides };
        let modifiedSlidesCopy = { ...this.state.modifiedSlides };
        const newLessonId = this.state.lessons.length + 1;

        moduleLessonsCopy.push( {"ID": newLessonId, "Name": "", "Rank": newLessonId} );
        modifiedLessonsNamesCopy["lesson-name-" + newLessonId] = { Id: newLessonId, editState: true, moduleId: moduleId, name: "", newLessonSave: true, rank: newLessonId};
        slidesCopy["slide-" + newLessonId] = [];
        lessonsCopy[moduleId] = moduleLessonsCopy;
        modifiedSlidesCopy["slide-" + newLessonId] = { editState: false, lessonId: newLessonId, slide: [] }

        this.setState({
            lessons: lessonsCopy,
            modifiedLessonsNames: modifiedLessonsNamesCopy,
            slides: slidesCopy,
            modifiedSlides: modifiedSlidesCopy
        });
    }

    handleUpdateModule = ( event, moduleId ) => {
        event.preventDefault();

        const module = this.state.modifiedModuleNames["module-name-" + moduleId];

        HTTPRequest({
            method: "POST",
            url: "/modules/updatemodule",
            data: {
                "id": module["id"],
                "name": module["name"],
                "rank": module["rank"]
            },
            headers: { "Authorization": "Bearer " + this.state.adminUserToken }

        }).then( response => {
            if ( response.data.success ) {
                let modifiedLessonsNamesCopy = { ...this.state.modifiedModuleNames };
                modifiedLessonsNamesCopy["module-name-" + moduleId].editState = false;

                this.setState({
                    modifiedModuleNames: modifiedLessonsNamesCopy
                })

            } else {
                console.log( response.data.reason );
            }

        }).catch( error => {
            console.log( error );
        });
    }

    handleUpdateLesson = ( event, lessonId ) => {
        event.preventDefault();

        const lesson = this.state.modifiedLessonsNames["lesson-name-" + lessonId];

        HTTPRequest({
            method: "POST",
            url: "/modules/updatemoduleitem",
            data: {
                "id": lessonId,
                "moduleId": lesson.moduleId,
                "name": lesson.name,
                "rank": lesson.rank
            },
            headers: { "Authorization": "Bearer " + this.state.adminUserToken }
        }).then( response => {
            if ( response.data.success ) {
                let modifiedLessonsNamesCopy = { ...this.state.modifiedLessonsNames };
                modifiedLessonsNamesCopy["lesson-name-" + lessonId].editState = false;

                this.setState({
                    modifiedLessonsNames: modifiedLessonsNamesCopy
                });
            
            } else {
                console.log( response.data.reason );
            }

        }).catch( error => {
            console.log( error );
        });
    }

    handleSaveModule = ( event, moduleId ) => {
        event.preventDefault();

        const module = this.state.modifiedModuleNames["module-name-" + moduleId];

        HTTPRequest({
            method: "POST",
            url: "/modules/createmodule",
            data: {
                "name": module.name,
                "rank": module.rank
            },
            headers: { "Authorization": "Bearer " + this.state.adminUserToken }

        }).then( response => {
            if ( response.data.success ) {
                let modifiedModuleNamesCopy = { ...this.state.modifiedModuleNames };
                modifiedModuleNamesCopy["module-name-" + moduleId].newModuleSave = false;
                modifiedModuleNamesCopy["module-name-" + moduleId].editState = false;

                this.setState({
                    modifiedModuleNames: modifiedModuleNamesCopy
                });

            } else {
                console.log(response.data.reason);
            }

        }).catch( error => {
            console.log(error);
        });
    }

    handleSaveLesson = ( event, lessonId ) => {
        event.preventDefault();

        const lesson = this.state.modifiedLessonsNames["lesson-name-" + lessonId];

        HTTPRequest({
            method: "POST",
            url: "/modules/createmoduleitem",
            data: {
                "moduleId": lesson.moduleId,
                "name": lesson.name,
                "rank": lesson.rank
            },
            headers: { "Authorization": "Bearer " + this.state.adminUserToken }

        }).then( response => {
            if ( response.data.success ) {
                let modifiedLessonsNamesCopy = { ...this.state.modifiedLessonsNames }
                modifiedLessonsNamesCopy["lesson-name-" + lessonId].newLessonSave = false;
                modifiedLessonsNamesCopy["lesson-name-" + lessonId].editState = false;

                this.setState({
                    modifiedLessonsNames: modifiedLessonsNamesCopy
                });
            
            } else {
                console.log(response.data.reason);
            }

        }).catch( error => {
            console.log(error);
        });
    }

    handleUpdateSlides = ( event, slideId ) => {
        event.preventDefault();

        let slide = this.state.modifiedSlides["slide-" + slideId];

        HTTPRequest({
            method: "POST",
            url: "/modules/createlesson",
            data: {
                "moduleItemId": slideId,
                "slides" : JSON.parse(slide.slides)
            },
            headers: { "Authorization": "Bearer " + this.state.adminUserToken }

        }).then( response => {
            if ( response.data.success ) {
                console.log("success true");
                let modifiedSlidesCopy = { ...this.state.modifiedSlides };
                let slidesCopy = { ...this.state.slides };
                modifiedSlidesCopy["slide-" + slideId].editState = false;
                slidesCopy["slide-" + slideId] = JSON.parse(modifiedSlidesCopy["slide-" + slideId].slides);

                this.setState({
                   modifiedSlides: modifiedSlidesCopy,
                   slides: slidesCopy
                });

            } else {
                console.log("success false")
                console.log(response.data.reason);
            }
        }).catch( error  => {
            console.log("success error")
            console.log(error)
        });
    }

    handleDeleteModule = ( event, moduleId ) => {
        event.preventDefault();

        HTTPRequest({
            method: "POST",
            url: "/modules/deletemoduleitem",
            data: {
                "id": moduleId
            },
            headers : { "Authorization": "Bearer " + this.state.adminUserToken }

        }).then( response => {
            if ( response.data.success ) {
                const modulesCopy = [ ...this.state.modules ];
                let modifiedModuleNamesCopy = { ...this.state.modifiedModuleNames };

                delete modifiedModuleNamesCopy["module-name-" + moduleId];

                let newModulesCopy = modulesCopy.filter( module => {
                    return module.ID !== moduleId;
                });
                
                this.setState({
                    modules: newModulesCopy,
                    modifiedModuleNames: modifiedModuleNamesCopy
                });

            } else {
                console.log(response.data.reason);
            }

        }).catch( error => {
            console.log(error);
        });
    }

    handleDeleteLesson = ( event, lessonId, moduleId ) => {
        event.preventDefault();

        HTTPRequest({
            method: "POST",
            url: "/modules/deletemoduleitem",
            data: {
                "id": lessonId
            },
            headers : { "Authorization": "Bearer " + this.state.adminUserToken }

        }).then( response => {
            if ( response.data.success ) {
                let lessonsCopy = [ ...this.state.lessons ];
                const moduleLessonsCopy = [ ...lessonsCopy[moduleId] ];
                let modifiedLessonsNamesCopy = { ...this.state.modifiedLessonsNames }
                
                delete modifiedLessonsNamesCopy["lesson-name-" + lessonId];

                let newModuleLessonCopy = moduleLessonsCopy.filter( lesson => {
                    return lesson.ID !== lessonId;
                });

                lessonsCopy[moduleId] = newModuleLessonCopy;

                this.setState({
                    lessons: lessonsCopy,
                    modifiedLessonsNames: modifiedLessonsNamesCopy
                });

            } else {
                console.log(response.data.reason);
            }

        }).catch( error => {
            console.log(error);
        } );
    }

    onDragEnd = ( draggedData ) => {
        console.log('Drag: End');
        console.log(draggedData);

        const { destination, source } = draggedData;
        let prevIndex;
        let currentIndex = destination.index;
        let nextIndex;
        let prevRank;
        let CurrentRank;
        let nextRank;
        let newRank;
        let moduleId;

        if ( source.index > destination.index ) {
            if ( currentIndex - 1 >= 0 ) {
                prevIndex = currentIndex - 1;
                nextIndex = currentIndex + 1;

                prevRank = this.state.modules[prevIndex].Rank;
                nextRank = this.state.modules[currentIndex].Rank;

                newRank = (prevRank + nextRank) / 2;

            } else {
                nextIndex = 0;
                nextRank = this.state.modules[nextIndex].Rank;

                newRank = ( nextRank / 2 );
            }

        } else {
            if ( (currentIndex + 1) < this.state.modules.length ) {
                prevIndex = currentIndex + 1;
                nextIndex = prevIndex + 1;
                
                prevRank = this.state.modules[prevIndex].Rank;
                nextRank = this.state.modules[currentIndex].Rank;
    
                newRank = (prevRank + nextRank) / 2;

            } else {
                nextIndex = this.state.modules.length - 1;
                nextRank = this.state.modules[nextIndex].Rank;

                newRank = ( nextRank + 0.5 );
            }
        }
        let modifiedModuleNamesCopy = { ...this.state.modifiedModuleNames };
        let modulesCopy = [ ...this.state.modules ];
        let moduleCopy = modulesCopy[source.index];
        moduleId = moduleCopy.ID
        modifiedModuleNamesCopy["module-name-" + moduleId].rank = newRank;
        modifiedModuleNamesCopy["module-name-" + moduleId].editState = true;
        moduleCopy.Rank = newRank;

        modulesCopy.splice(source.index, 1);
        modulesCopy.splice(destination.index, 0, moduleCopy);

        this.setState({
            modules: modulesCopy,
            modifiedLessonsNames: modifiedModuleNamesCopy
        });

        console.log("PrevIndex : " + prevIndex + " CurrentIndex : " + currentIndex + " NextIndex : " + nextIndex)
        console.log("PrevRank : " + prevRank + " NewRank : " + newRank);
    }

    onDragUpdate = ( draggedData ) => {
        console.log('Drag: Update');
        console.log(draggedData);
    }

    onDragStart = ( draggedData ) => {
        console.log('Drag: Start');
        console.log(draggedData);

    }

    render() {
        let modulesCount = this.state.modules.length;
        let moduleView;
        let moduleViewAddBtn;

        if ( this.state.viewedLessons !== 0 ) {
            let viewedLessons = this.state.lessons[this.state.viewedLessons];
            let viewedModule  = this.state.modules.filter( module => {
                return module.ID === this.state.viewedLessons;
            });
            
            moduleView = <LessonsInfo 
                            moduleId={ viewedModule[0].ID } 
                            moduleName={ viewedModule[0].Name } 
                            key={ viewedModule[0].ID } 
                            inputOnChange={ this.handleLessonInputOnChange }
                            modifiedLessonsNames={ this.state.modifiedLessonsNames }
                            moduleDetails={ "lessons: " + viewedLessons.length } 
                            viewedlessons={ viewedLessons }
                            viewLessonsSlides={ this.handleShowSlidesInfo }
                            viewedSlides={ this.state.viewedSlides }
                            modifiedSlides={ this.state.modifiedSlides }
                            slides={ this.state.slides }
                            showHideInfoRowDetails={ event => this.handleShowHideLessonsInfo( event, 0 ) }
                            showHideLessonsInfoDetails={ this.handleShowSlidesInfo }
                            viewInfoButtonArrow={ "down" }
                            handleItemDragOver={ this.handleItemDragOver }
                            handleUpdateLesson={ this.handleUpdateLesson }
                            handleSaveLesson={ this.handleSaveLesson }
                            handleSlideInputOnChange={ this.handleSlideInputOnChange }
                            handleUpdateSlides={ this.handleUpdateSlides }
                            handleDeleteLesson={ this.handleDeleteLesson }
                        />

            if ( this.state.viewedSlides === 0 ) {
                moduleViewAddBtn = <div className="col-12 button-row">
                                        <Button clickHandler={ () => this.handleAddNewLesson( this.state.viewedLessons ) }> 
                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                            add lesson
                                        </Button>
                                    </div>
            }

        } else {
            let lessonsCount = this.state.lessons.filter( lesson => lesson).length;

            if ( this.state.modules.length !== 0 && ( this.state.modules.length === lessonsCount ) ) {
                if ( this.state.modules.length === Object.keys(this.state.modifiedModuleNames).length ) {
                    moduleView = this.state.modules.map( (module, index) => {''
                        return <ModuleInfo 
                                index={ index }
                                infoRowName={ this.state.modifiedModuleNames["module-name-" + module.ID].name } 
                                infoRowId={ "module-name-" + module.ID } 
                                key={ module.ID } 
                                inputOnChange={ this.handleModuleInputOnChange } 
                                infoRowDetails={ "lessons: " + (this.state.lessons[module.ID] ? this.state.lessons[module.ID].length : "") }
                                showHideInfoRowDetails={  event => this.handleShowHideLessonsInfo( event, module.ID ) }
                                viewInfoButtonArrow={ "right" }
                                inputPlaceHolder="New Model Name"
                                handleModuleEditRow={ this.handleModuleEditRow }
                                saveButtonState={ this.state.modifiedModuleNames["module-name-" + module.ID].editState }
                                handleUpdateRowInfo={ this.state.modifiedModuleNames["module-name-" + module.ID].newModuleSave ? event => this.handleSaveModule( event, module.ID ) :  this.state.modifiedModuleNames["module-name-" + module.ID].editState ? event => this.handleUpdateModule( event, module.ID ) : null }
                                handleDeleteRowInfo={ event => this.handleDeleteModule( event, module.ID ) }
                                />
                    });
                }

                moduleViewAddBtn = <div className="col-12 button-row">
                                        <Button clickHandler={ this.handleAddNewModule }> 
                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                            add module
                                        </Button>
                                    </div>
            } else {
                moduleView = <LoadingData title="fetching modules" />
            }
        }

        return (
            <DragDropContext onDragEnd={ this.onDragEnd } onDragUpdate={ this.onDragUpdate } onDragStart={ this.onDragStart }>
                <div className="page-tool-heading row no-gutters align-items-center justify-content-between">
                    <div className="col-4">
                        <h3>manage modules</h3>
                    </div>
                    <div className="col-4">
                        <p>{ modulesCount + " module" + ( modulesCount > 1 ? "s" : "" ) + " found" }</p>
                    </div>
                </div>
                <section className="modules-table-heading row no-gutters">
                    <div className="col-12">
                        <InfoTableTitles tableItemTitle="module name" />
                    </div>
                </section>
                <Droppable droppableId={ "module-rows" } >
                    {
                        ( provided, snapShot ) => (
                            <section 
                                className="modules-table-rows row justify-content-center" 
                                { ...provided.droppableProps }
                                ref={ provided.innerRef }
                            >
                                { moduleView }
                                { moduleViewAddBtn }
                                { provided.placeholder }
                            </section>
                        )
                    }
                </Droppable>
            </DragDropContext>
        );
    };
}

export default ManageModules;