import React from "react";
// 
// User item component
// 

// Style
import "./userItem.css";

// Assests
import userProfilePic from "../../../../../assets/images/character-face.png"

const userItem = ( props ) => (
    <div className={ "user-item " + props.activeClass } onClick={ props.userItemClick }>
        {/* user props.userProofilePic to display user profile pic in src for img */}
        <div className="pro-pic-wrap">
            <img src={ userProfilePic } alt="user-profile-pic" />
        </div>
        <h5>{ props.userName }</h5>
        <div className="messages-cocunt">
            <i className="fa fa-envelope" aria-hidden="true"></i>
            <span>{ props.unreadCount + " unread" }</span>
        </div>
    </div>
)

export default userItem;