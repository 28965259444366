import React from "react";
// 
// New application qualification institution
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../../../../../hoc/wrap";
// New qualification component
import NewApplicationQualification from "./newApplicationQualification/newApplicationQualification";
// Button component
import Button from "../../../../../../UI/button/button";

// Style
import "./newApplicationQualifications.css";

const newApplicationQualifications = ( props ) => {
    let content = "";
    let applicationQualifications = [];

    if ( props.applicationQualifications.length > 0 ) {
        applicationQualifications = props.applicationQualifications.map( (qualification, index) => {
            return (
                    <NewApplicationQualification 
                        key={ index }
                        qualificationName={ qualification.name } 
                        qualificationId={ qualification.id }
                        qualificationRate={ qualification.rate }
                        newQualificationList={ props.qualificationsList  } 
                        searchQualification={ props.searchQualification }
                        searchQualificationText={ props.searchQualificationText }
                        qualificationsList={ props.qualificationsList }
                        setSelectedQaulification={ props.setSelectedQaulification }
                        position={ index }
                        deleteNewQualification={ props.deleteNewQualification }
                        institutionName={ props.institutionName }
                    />
            )
        });
    }

    content = (
        <Wrap>
            <div className="col-11">
                <h5 className="applications-title">Applications from { props.institutionName } for { props.leanerName }</h5>
            </div>
            {
                applicationQualifications.length > 0 ? 
                <div className="col-11">
                    <div className="row justify-content-between table-titles">
                        <div className="col-4">
                            <h5>Course name</h5>
                        </div>
                        <div className="col-4">
                            <h5>Rate</h5>
                        </div>
                    </div>
                </div> : ""
            }
            <div className="col-11">
                { applicationQualifications }
            </div>
            <div className="col-12">
                <Button buttonClassName="add-new-qualification" clickHandler={ props.addNewApplicationQualification }>
                    <i className="fa fa-plus"></i>
                    add new qualification
                </Button>
            </div>
        </Wrap>
    )

    return (
        <div className="row justify-content-center student-applications">
            { content }
        </div>
    );
}

export default newApplicationQualifications;