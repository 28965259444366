import React from "react";
// 
// Chart sort filter component : chart sort filter with days, month and years
// 

// Style
import "./chartSortFilter.css";

const chartSortFilter = ( props ) => (
    <div className="chart-sort-filter">
        <input type="radio" name="chart-sort-filter" value="day" onChange={ event => props.setSortFilterOption(event) } checked={ props.sortFilterOption === "day" } />
        <label>day</label>
        <input type="radio" name="chart-sort-filter" value="month" onChange={ event => props.setSortFilterOption(event) } checked={ props.sortFilterOption === "month" } />
        <label>month</label>
        <input type="radio" name="chart-sort-filter" value="year" onChange={ event => props.setSortFilterOption(event) } checked={ props.sortFilterOption === "year" } />
        <label>year</label>
    </div>
);

export default chartSortFilter;