import React, { Component } from "react";
import HTTPRequester from "../../../js/library/HTTPConfig/axiosConfig";
import helpers from "../../../js/library/helpers/helpers";
// 
// Batch register component : used to registere leaners manually in a batch
// 

class BatchRegister extends Component {

    state = {
        leaners: [
            
        ],
        userTokens: []
    }

    componentWillMount() {
        this.state.leaners.forEach( leaner => {
            HTTPRequester({
                method: "POST",
                url: "account/register",
                data: {
                    'username': leaner.number,
                    'name': leaner.name,
                    'surname': leaner.surname,
                    'usertype': 'student',
                    'password': '1',
                    'source': 'Marobathota'
                },
                headers: helpers.getAuthorizationHeader()
        
                }).then( response => {
                    if ( response.data.success ) {
                        console.log("[REGISSTER] Leaner: " + leaner.name +   " " + leaner.surname + "  Registered")

                    } else {
                        console.log("[REGISSTER] Leaner: " + leaner.name + " " + leaner.surname + "  Not Updated")
                    }
                    
                }).catch( error => {
                    console.log( "[REGISSTER] Error registering leaner: " +  leaner.name + " " + leaner.surname);
                });
        });
    }

    render() {
        return(
            <div>Done!</div>
        )
    }
}

export default BatchRegister;