import React, { Component } from 'react';
// 
// Profile Nav component : Menu list shwon when profile icon is click
// 

// Style 
import './profileNav.css';

class ProfileNav extends Component {
    render() {
        let profileNavStyle = {
            display:  this.props.showProfileNav ? 'block' : 'none',
            top: this.props.isInsidePage ? '4.1em' : '3em',
            left: this.props.isInsidePage ? '2em' : '-4em'
        }
        return(
            <div className="profile-nav-container" style={ profileNavStyle }>
                <ul>
                    <li>
                        <a href="#">
                            <i className="fa fa-user" aria-hidden="true"></i>
                            profile
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i className="fa fa-cog" aria-hidden="true"></i>
                            settings
                        </a>
                    </li>
                    <li>
                        <a href="#" onClick={ event => this.props.handleLogout(event) }>
                            <i className="fa fa-sign-out" aria-hidden="true"></i>
                            sign out
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}

export default ProfileNav;