// 
// Text area reducer : method for handlind "UPDATE_TEXT" action dispatch
// 
import * as actionTypes from "../../actions/actionTypes";
import * as utilities from "../utilities";

const initialState = {
    coverPageHeading: "THABISHA MATRIC CAREER EXPO REPORT",
    coverPageImage: {
        file: "",
        previewURL: "",
        fileName: ""
    }
}

const textAreaReducer = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.UPDATE_TEXT_COVER_PAGE:
            return utilities.handleTextOnChange(state, action);

        case actionTypes.UPLOAD_IMAGE_COVER_PAGE:
                return utilities.handleImageUpload( state, action, "coverPageImage" );

        default:
            return {
                ...state
            }
    }
}

export default textAreaReducer;