import React from 'react';
// 
// Modal component : Pop up component for status reporting
// 

// 
// Components
// 

// Wrap component
import Wrap from '../../hoc/wrap';
// Backdrop component
import Backdrop from '../backdrop/backdrop';
// Button component
import Button from '../button/button';

// Style
import './modal.css';

const modal = ( props ) => (
    <Wrap>
        <Backdrop backdropShow={ props.backdropShow } backdropHide={ props.backdropHide } />
        <section className="form-modal" style={{ display: props.backdropShow ? "block" : "none" }}>
            <Button buttonClassName="modal-close" clickHandler={ props.backdropHide }>
                <i className="fa fa-times"></i>
            </Button>
            <p>
                <i className={ "fa fa-" + props.modalIcon } area-hidden="true"></i>
                { props.modalContent }
            </p>
        </section>
    </Wrap>
);

export default modal;