import React from "react";
// 
// Chart no info component : Displays a message saying chart has no informatino / records
// 

// Style
import "./chartNoInfo.css";

const chartNoInfo = () => (
    <div className="row justify-content-center chart-no-info">
        <div className="col-col-10">
            <i className="fa fa-bar-chart" aria-hidden="true"></i>
            <p>Chart has no data</p>
        </div>
    </div>
);

export default chartNoInfo;