import moment from "moment";

const dateFormat = {
    getMonthFullName,
    getMonthShortName,
    getDateOneMonthAgo
}

function getMonthShortName ( monthNumber ) {
    let monthNames = {
        "1": "Jan",
        "2": "Feb",
        "3": "Mar",
        "4": "Apr",
        "5": "May",
        "6": "Jun",
        "7": "Jul",
        "8": "Aug",
        "9": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec"
    }

    return monthNames[monthNumber];
}

function getMonthFullName ( monthNumber ) {
    let monthNames = {
        "1": "January",
        "2": "February",
        "3": "March",
        "4": "April",
        "5": "May",
        "6": "June",
        "7": "July",
        "8": "Agust",
        "9": "September",
        "10": "October",
        "11": "November",
        "12": "December"
    }

    return monthNames[monthNumber];
}

function getDateOneMonthAgo() {
    return new Date(moment().subtract(1, 'months').format());

}

export default dateFormat;