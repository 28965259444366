import React from 'react';
// 
// Spinner component
// 

// Style 
import './spinner.css';

const spinner = () => (
    <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
    </div>
)

export default spinner;