import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import coverPageReducer from "./store/reducers/coverPageReducers/coverPageReducer";
import contentsPageReducer  from "./store/reducers/contentsPageReducers/contentsPageReducer";
import backgroundPageReducer from "./store/reducers/backgroundPageReducers/backgroundPageReducer";
import insightsPageReducer from "./store/reducers/insightsPageReducer/insightsPageReducer";
import usefulnessPageReducer from "./store/reducers/usefulnessPageReducers/usefulnessPageReducers";
import attendancePageReducer from "./store/reducers/attendancePageReducer/attendancePageReducer";
import schoolsPageReducer from "./store/reducers/schoolsPageReducer/schoolsPageReducer";
import highLevelPageReducer from "./store/reducers/highLevelPageReducer/highLevelPageReducer";
import expoReportReducer from "./store/reducers/expoReportReducer/expoReportReducer";
import { createStore, combineReducers } from "redux";
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

// 
// Components
// 

// App component
import App from './App';
// 
// Style
// 

//Index style 
import './index.css';

// 
// Layout external dependecies
// 

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
// Fontawesome
import 'font-awesome/css/font-awesome.min.css';

const reducer = combineReducers({
    coverPage: coverPageReducer,
    contentsPage: contentsPageReducer,
    backgroundPage: backgroundPageReducer,
    insightsPage: insightsPageReducer,
    usefulnessPage: usefulnessPageReducer,
    attendancePage: attendancePageReducer,
    schoolsPage: schoolsPageReducer,
    highLevelPage: highLevelPageReducer,
    expoReport: expoReportReducer
})

// Remove dev tools extention when pushing to prop
const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

const Application = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(Application, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
