import React from "react";
// 
// Institution item component
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../../../hoc/wrap";
// Button component
import Button from "../../../../UI/button/button";

// Style
import "./institutionItem.css";

const institutionItem = ( props ) => {
    let content = "";
    let institutionList = [];
    let adminList = [];

    if ( props.adminList && props.adminList.length > 0 ) {
        adminList = props.adminList.map( (admin, index) => {
            return <li key={ index } onClick={ () => props.assignApplicationToAP( admin.ID, props.learnerId , props.institutionId ) }>{ admin.Name + ' ' + admin.Surname }</li>
        });
    }

    if ( props.institutionName !== "" ) {
        content = (
            <div className="col-4">
                <Button buttonClassName="assign"  clickHandler={ () => props.handleShowAdminList(props.institutionId) }>
                    <i className="fa fa-heart" aria-hidden="true"></i>
                </Button>
                <a href="#" className="institution" onClick={ ( event ) => props.showInstitutionApplications(event, props.institutionName) }>
                    { props.institutionName }
                </a>
                {
                    adminList.length > 0 ?
                        <ul className="adminList ap" style={{ "display": props.showAdminList === props.institutionId ? "block" : "none" }}>
                            { adminList }
                        </ul>
                        : ""
                }
            </div>
        ); 

    } else {
        if ( props.newInstitutionList && props.newInstitutionList.length > 0 ) {
            institutionList = props.newInstitutionList.map( institution => {
                return <li key={ institution.ID } onClick={ () => props.setSelectedInstitution(props.position, institution.ID, institution.Name) }>{ institution.Name }</li>
            });
        }

        content = (
            <div className="col-4">
                <div className="auto-complete-search">
                    <div className="form-text-wrap">
                        <input type="text" name="institution-" value={ props.searchInstitutionText } onChange={ props.searchInstitution } placeholder="Institution"></input>
                    </div>
                    {
                        props.newInstitutionList.length > 0 ? (
                            <div className="search-list">
                                <ul>
                                    { institutionList }
                                </ul>   
                            </div>
                        ) : ""
                    }
                </div>    
            </div>
        );
    }

    return(
        <div className="row align-items-center application-item">
            <div className="col-1">
                <a href="#" className="view-info" onClick={ props.active ? event => props.showApplicationsList(event) : event => props.showInstitutionApplications(event, props.institutionName) } >
                    <i className={ 'fa fa-caret-' + ( props.active ? "down" : "right" ) } aria-hidden="true"></i>
                </a>
            </div>
            { content }
            <div className="col-7 d-flex justify-content-end" >
                <div style={{ display: props.showDelete ? "block" : "none" }} >
                    <Button buttonClassName="delete-institution" clickHandler={ () => props.deleteNewInstitution(props.position) }>
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default institutionItem;