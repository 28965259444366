// 
// Background page reducer : Dispatched actions for contents page content change
// 

// Action types
import * as actionTypes from "../../actions/actionTypes";
// Utitlies
import * as utilities from "../utilities";
const initialState = {
    insightsPageHeading: "Data Insights",
    insightsBlocks: {
        insight01: {
            insightId: "01",
            insightHeading: "Day1 uncaptured users",
            insightBody: "During our day 1 registration period, we had a large volume of unregistered attendees due to the late start of the event Our stats have conservatively offset the missed attendees by a 15 margin for the youth and 5 for the scholars as they were captured in buses",
            insightShwon: true
        },
        insight02: {
            insightId: "02",
            insightHeading: "Duplicated effort with the Municipality",
            insightBody: "The unemployed youth registration by the Municipality official had a significant impact on the registration process, causing confusion on where registrations are captured",
            insightShwon: true
        },
        insight03: {
            insightId: "03",
            insightHeading: "Incomplete learner data",
            insightBody: "While emphasis is put on the completion of full learner data, our final captured data had missing phone numbers While this was in minority, it does have a significant impact on the usefulness of data post the Career Expo",
            insightShwon: true
        },
        insight04: {
            insightId: "04",
            insightHeading: "Early registrations",
            insightBody: "Visitors and some schools mentioned on the day that there was an online registration that was completed prior the event A large number of visitors on Day 2 and 3 indicated that they were registered already",
            insightShwon: true
        }
    }
}

const insightsPageReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.UPDATE_TEXT_INSIGHTS_PAGE:
            if ( action.payload.event.target.name.split("-").length > 1 ) {
                let insightKey = action.payload.event.target.name.split("-");
                let inputValue = action.payload.event.target.value;
                let insightsBlocksCopy = { ...state.insightsBlocks }

                insightsBlocksCopy[insightKey[0]]["insight" + insightKey[1]] = inputValue;

                return {
                    ...state,
                    insightsBlocks: insightsBlocksCopy
                }

            } else {
                return utilities.handleTextOnChange(state, action);
            }
            

        default:
            return {
                ...state
            }
    }
}

export default insightsPageReducer;