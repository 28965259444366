import React from "react";
// 
// Application card component: application tile component in applications page
// 

// Style
import "./applicationCard.css";

const applicationCard = ( props ) => {
    let statusContent = "";
    let statusList = [];
    let adminList = [];
    let adminName = props.adminName;
    let cardName = props.cardName;

    if ( props.cardData.length > 0 ) {
        statusList = props.cardData.map( (status, index) => {
            return (
                <li key={ index }>
                    <a href="#" onClick={ event => props.showApplicationsList(event,  cardName, props.adminId, status.Status, status.ID ) } >{ status.Status }
                        <span className="status-points">{ status.Total }</span>
                    </a>
                </li>
            );
        });

        statusContent = (
            <ul>
                { statusList }
            </ul>
        )
    } else {
        statusContent = <p className="no-applications">You have no applications assigned to you yet</p>;
    }

    
    adminList = props.adminList.map( (admin, index) => {
        return <option key={ index }>{ admin.Name + " " + admin.Surname }</option>;
    });

    adminList.splice(0, 0, <option key="-1">mine</option>)
    
    return(
        <div className="col-6 application-card">
            <div className="admin-name-wrap">
                <div className="form-select-wrap">
                    <select value={ adminName } onChange={ event => props.onAdminChange(event) } disabled={ cardName === "all" }>
                        { 
                            cardName === "all" ? <option>all</option> : adminList
                        }
                    </select>
                </div>
            </div>
            <div className="status-list-contanier">
                { statusContent }
            </div>
        </div>
    );
}

export default applicationCard;