import React from "react";
// 
// Allocation item component: AP institution progress item
// 

// Style
import "./allocationItem.css";


const allocationItem = ( props ) => {
    return(
        <div className="col-2 allocation-item">
            <p className="institution-name">{ props.institutionName }</p>
            <div className="progress-bar-bg">
                <div className={"progress-bar " + props.progressBarClass} style={{ width: props.progressBarWidth + "%" }}></div>
            </div>
            <p className="progress-precent">{ props.completed + " / " +  props.total }</p>
        </div>
    );
}

export default allocationItem;