import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
// 
// Cover page component: report cover page component
// 

// 
// Components
// 

// Text area component
import TextArea from "../../../components/pages/expoReportPage/reportCopmposerControls/textArea/textArea";
// Image area component
import ImageArea from "../../../components/pages/expoReportPage/reportCopmposerControls/imageArea/imageArea";
// Report footer component
import ReportFooter from "../../../components/pages/expoReportPage/reportPages/reportFooter/reportFooter";

// 
// Assets
// 

// logo image
import logo from "../../../assets/images/logo.png";

// Style
import "./coverPage.css";

class CoverPage extends Component {
    render() {
        let textAreaSTyle = {
            width     : "100%",
            fontSize  : "3.5em",
            fontWeight: "600",
            resize    : "none",
            marginTop : "1em",
            textAlign : "center",
        }
    
        return (
            <div className="cover-page">
                <div className="row justify-content-center">
                    <TextArea 
                        textAreaName="coverPageHeading"
                        textAreaValue={ this.props.textAreaValue }
                        textAreaOnChange={ this.props.textAreaOnChange }
                        textAreaPlaceholder=""
                        textAreaCol="10"
                        style={ textAreaSTyle }
                    />
                </div>
                <div className="row justify-content-center">
                    <img src={ logo } alt="gradesmatch logo" />
                </div>
                <div className="row justify-content-center">
                    <ImageArea 
                        imageAreaPreview={ this.props.coverPageImage }
                        imageAreaCol="10"
                        imageAreaName={ this.props.coverPageFileName }
                        imageUpload={ this.props.imageUpload }
                        imageAreaWidth=""
                        imageAreaHeight=""
                        imageAreaAlt="Partner logo image"
                    />
                </div>
                <ReportFooter />
            </div>
        )
    }
} 

const mapStateToProps = state => {
    return {
        textAreaValue: state.coverPage.coverPageHeading,
        coverPageImage: state.coverPage.coverPageImage.previewURL,
        coverPageFileName: state.coverPage.coverPageImage.fileName
    }
}

const mapDispatchToProps = dispatch => {
    return {
        textAreaOnChange: (event) => dispatch({type: actionTypes.UPDATE_TEXT_COVER_PAGE, payload: { event: event }}),
        imageUpload: (event) => dispatch({type: actionTypes.UPLOAD_IMAGE_COVER_PAGE, payload: { event: event }})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoverPage);