// 
// Reducer utilities : Common dipatched action logic
// 

export const handleTextOnChange = ( state, action ) => {
    let inputName = action.payload.event.target.name;
    let inputValue = action.payload.event.target.value;

    return {
        ...state,
        [inputName]: inputValue
    }
}

export const handleImageUpload = ( state, action, imagePositionName ) => {
    let uploadedImage = { ...state[imagePositionName] };
    let selectedFile = action.payload.event.target.files[0];

    uploadedImage.file = selectedFile;
    uploadedImage.previewURL = URL.createObjectURL(selectedFile);
    uploadedImage.fileName = selectedFile.name;

    return {
        ...state,
        [imagePositionName]: uploadedImage
    }
}

