import React, { Component } from "react";
// 
// Feed type component 
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../../hoc/wrap";
// Button component
import Button from "../../../UI/button/button";
// Spinner component
import Spinner from "../../../UI/spinner/spinner";

// Style
import "./feedType.css";

class FeedType extends Component {

    render() {
        let feedTypeContent = "";
        let sharePostButtonContent = "share";
        let shareButtonClick;

        if ( this.props.sendingPost ) {
            sharePostButtonContent = <Spinner />;
        }
    
        switch( this.props.feedType.toLowerCase() ) {
            case "inline":
                shareButtonClick = this.props.onSharePost;

                feedTypeContent = (
                    <Wrap>
                        <h4>{ "compose new " + this.props.feedType.toLowerCase() + " post" }</h4>
                        <div className="col-12">
                        <label>post text:</label>
                            <textarea name="text" value={ this.props.postText } onChange={ event => this.props.onPostInputChange(event) } ></textarea>
                        </div>
                        <div className="col-12">
                            <div className="file-upload-wrap">
                                <input name="postImage"  ref={ ref => this.imageUpload = ref } type="file" style={{ display: "none" }} onChange={ event => this.props.postImageSelect(event) } />
                                <a href="#" className="post-image" onClick={ event => this.imageUpload.click() }>
                                    <i className="fa fa-upload" aria-hidden="true"></i>
                                </a>
                                <label>Add image</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <label>video url: </label>
                            <input name="video" type="text" value={ this.props.postVideoUrl } onChange={ event => this.props.onPostInputChange(event) } />
                        </div>
                        <div className="col-12">
                            <label>Link URL: </label>
                            <input name="link" type="text" value={ this.props.postLinkUrl } onChange={ event => this.props.onPostInputChange(event) } />
                        </div>
                    </Wrap>
                );
                break;

            case "article":
                shareButtonClick = this.props.onSharePost;

                feedTypeContent = (
                    <Wrap>
                        <h4>{ "compose new " + this.props.feedType.toLowerCase() + " post" }</h4>
                        <div className="col-12">
                        <label>post text:</label>
                            <textarea name="text" value={ this.props.postText } onChange={ event => this.props.onPostInputChange(event) } ></textarea>
                        </div>
                        <div className="col-12">
                            <div className="file-upload-wrap">
                                <input name="postImage"  ref={ ref => this.imageUpload = ref } type="file" style={{ display: "none" }} onChange={ event => this.props.postImageSelect(event) } />
                                <a href="#" className="post-image" onClick={ event => this.imageUpload.click() }>
                                    <i className="fa fa-upload" aria-hidden="true"></i>
                                </a>
                                <label>Add image</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <label>Link URL: </label>
                            <input name="link" type="text" value={ this.props.postLinkUrl } onChange={ event => this.props.onPostInputChange(event) } />
                        </div>
                    </Wrap>
                );
                break;

            case "nedbank":
                shareButtonClick = this.props.onSharePost;

                feedTypeContent = (
                    <Wrap>
                        <h4>{ "compose new " + this.props.feedType.toLowerCase() + " post" }</h4>
                        <div className="col-12">
                        <label>post text:</label>
                            <textarea name="text" value={ this.props.postText } onChange={ event => this.props.onPostInputChange(event) } ></textarea>
                        </div>
                        <div className="col-12">
                            <label>post heading: </label>
                            <input name="heading" type="text" value={ this.props.postHeading } onChange={ event => this.props.onPostInputChange(event) } />
                        </div>
                        <div className="col-12">
                            <div className="file-upload-wrap">
                                <input name="postImage"  ref={ ref => this.imageUpload = ref } type="file" style={{ display: "none" }} onChange={ event => this.props.postImageSelect(event) } />
                                <a href="#" className="post-image" onClick={ event => this.imageUpload.click() }>
                                    <i className="fa fa-upload" aria-hidden="true"></i>
                                </a>
                                <label>Add image</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <label>Link URL: </label>
                            <input name="link" type="text" value={ this.props.postLinkUrl } onChange={ event => this.props.onPostInputChange(event) } />
                        </div>
                    </Wrap>
                );
                break;
                
            case "video":
                shareButtonClick = this.props.onSharePost;

                feedTypeContent = (
                    <Wrap>
                        <h4>{ "compose new " + this.props.feedType.toLowerCase() + " post" }</h4>
                        <div className="col-12">
                        <label>post text:</label>
                            <textarea name="text" value={ this.props.postText } onChange={ event => this.props.onPostInputChange(event) } ></textarea>
                        </div>
                        <div className="col-12">
                            <label>video url: </label>
                            <input name="video" type="text" value={ this.props.postVideoUrl } onChange={ event => this.props.onPostInputChange(event) } />
                        </div>
                    </Wrap>
                );
                break;

            case "status":
                    shareButtonClick = this.props.onStatusPlaceHolderUpdate;

                feedTypeContent = (
                    <Wrap>
                        <h4>update status placeholder</h4>
                        <div className="col-12">
                        <label>status place holder:</label>
                            <textarea name="text" value={ this.props.statusText } onChange={ event => this.props.onStatusTextChange(event) } />
                        </div>
                    </Wrap>
                )
                break;
        }
    
        return(
            <div className="feed-type-container row">
                { feedTypeContent } 
                <Button clickHandler={ shareButtonClick } >
                    { sharePostButtonContent }
                </Button>
            </div>
        );
    }
};

export default FeedType;