import React from "react";
// 
// Manage feeds tool page component
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../hoc/wrap";
// Header component
import Header from "../../header/header";
// Toolbar component
import Toolbar from '../../UI/toolbar/toolbar';
// Main content wrap component
import MainContentWrap from '../../UI/mainContentWrap/mainContentWrap';
// Manage feeds component
import ManageFeeds from "../../../containers/manageFeeds/manageFeeds";

// Style
import "./manageFeedsPage.css";

const manageFeedsToolPage = ( props ) => (
    <Wrap>
        <Header 
            whichHeader="feeds" 
            showToolsNav={ props.showToolsNav } 
            toggleToolsNav={ props.toggleToolsNav } 
            showProfileNav={ props.showProfileNav } 
            toggleProfileNav={ props.toggleProfileNav } 
            handleLogout={ props.handleLogout }
        />
        <main className="manage-feeds">
            <Toolbar adminTool="feeds"/>
            <MainContentWrap>
                <ManageFeeds />
            </MainContentWrap>
        </main>
    </Wrap>
);

export default manageFeedsToolPage;