import React from "react";
import Helper from "../../../../../js/library/helpers/helpers";
// 
// User stats tile component
// 

// 
// Components
// 

// Wrap component
import Wrap from "../../../../hoc/wrap";

// Style
import "./userStatsTile.css";

const userStatsTile = ( props ) => {
    let tileContent = "";
    let tileIcon = "";

    switch( props.tileTitle.toLowerCase()) {
        case "home page visits" :
            tileIcon = "home";
            tileContent = (
                <Wrap>
                    <div className="col-3">
                        <h3>
                            { Helper.formatNumber(props.visitsCount) }
                        </h3>
                        <p>visits</p>
                    </div>
                    <div className="col-3">
                        <h3>
                            { Helper.formatNumber(props.todayCount) }
                        </h3>
                        <p>visitors</p>
                    </div>
                </Wrap>
            );
            break;
        
        case "top 5" :
            tileIcon = "star";
            break;
        
        case "users" :
            tileIcon = "user";
            tileContent = (
                <Wrap>
                    <div className="col-3">
                        <h3>
                            { Helper.formatNumber(props.totalCount) }
                        </h3>
                        <p>users</p>
                    </div>
                    <div className="col-3">
                        <h3>
                            { Helper.formatNumber(props.newCount) }
                        </h3>
                        <p>today</p>
                    </div>
                </Wrap>
            );
            break;
        
        case "qualifications" :
            tileIcon = "certificate";
            tileContent = (
                <Wrap>
                    <div className="col-3">
                        <h3>
                            { Helper.formatNumber(props.viewedCount) }
                        </h3>
                        <p>matches</p>
                    </div>
                    <div className="col-3">
                        <h3>
                            { Helper.formatNumber(props.likedCount) }
                        </h3>
                        <p>liked</p>
                    </div>
                    <div className="col-3">
                        <h3>
                            { Helper.formatNumber(props.unlikedCount) }
                        </h3>
                        <p>unliked</p>
                    </div>
                </Wrap>
            );
            break;
        
        case "careers" :
            tileIcon = "briefcase";
            tileContent = (
                <Wrap>
                    <div className="col-3">
                        <h3>
                            { Helper.formatNumber(props.viewedCount) }
                        </h3>
                        <p>matches</p>
                    </div>
                    <div className="col-3">
                        <h3>
                            { Helper.formatNumber(props.likedCount) }
                        </h3>
                        <p>liked</p>
                    </div>
                    <div className="col-3">
                        <h3>
                            { Helper.formatNumber(props.unlikedCount) }
                        </h3>
                        <p>unliked</p>
                    </div>
                </Wrap>
            );
            break;
        
        case "bursaries" :
            tileIcon = "graduation-cap";
            tileContent = (
                <Wrap>
                    <div className="col-3">
                        <h3>
                            { Helper.formatNumber(props.viewedCount) }
                        </h3>
                        <p>matches</p>
                    </div>
                    <div className="col-3">
                        <h3>
                            { Helper.formatNumber(props.matchedCount) }
                        </h3>
                        <p>matchers</p>
                    </div>
                </Wrap>
            );
            break;
    }

    return(
        <Wrap>
            <div className={ "col-4 stats-tile " + props.tileStatus } onClick={ props.onTileClick }>
                <div className="row no-gutters tile-heading">
                    <div className="col-12">
                        <h4>
                            <i className={ "fa fa-" + tileIcon } aria-hidden="true"></i>
                            { props.tileTitle }
                        </h4>
                    </div>
                </div>
                <div className="row no-gutters justify-content-center align-items-center tile-content">
                    { tileContent }
                    <span className="view-tile-stats"><i className="fa fa-bar-chart" aria-hidden="true"></i>click here to view tile stats</span>
                </div>
            </div>
        </Wrap>
    ); 
}

export default userStatsTile;