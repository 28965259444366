import React from 'react';
// 
// Match criteria compnent: qualificartion match criteria label component
// 

// Style
import "./matchCriteria.css";

const matchCriteria = ( props ) => {     
    return (
        <div className={"match-criteria" + props.matchCriteriaColor} onClick={ props.showQualificationMatchInfo }>
            { props.matchCriteriaText }
        </div>
    );
}

export default matchCriteria;
